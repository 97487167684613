import config from 'config';

import erc20Abi from './abis/erc20.json';
import bulAbi from './abis/bulToken.json';
import bTokenAbi from './abis/bToken.json';
import aEthAbi from './abis/aEth.json';
import comptrollerAbi from './abis/comptroller.json';
import voteAbi from './abis/vote.json';
import interestModelAbi from './abis/interestModel.json';
import referralAbi from './abis/referral.json';
import multiFeeDistribution from './abis/multiFeeDistribution.json';
import chefIncentivesController from './abis/chefIncentivesController.json';
import lockZap1 from './abis/lockZap1.json';
import liquidityZap from './abis/liquidityZap.json';
import eligibilityDataProvider from './abis/eligibilityDataProvider.json';
import compounder from './abis/compounder.json';

export const LIQUIDITY_CLOSE_FACTOR = 0.5;
export const LIQUIDITY_INCENTIVE = 1.1;

export const WEB3_PROVIDER = {
  1329: config.seiWeb3Provider,
  713715: config.seiWeb3TestProvider,
};

export const ERC20_TOKEN_ABI = erc20Abi;
export const CONTRACT_BUL_TOKEN_ABI = bulAbi;

export const CONTRACT_TOKEN_ADDRESS_TEMP = {
  713715: {
    wsei: {
      id: 'wsei',
      symbol: 'WSEI',
      address: '0x657389E6c95308BD24a9aCB40FDe474d4D580d63',
      decimals: 18,
    },
  },
  1329: {
    wsei: {
      id: 'wsei',
      symbol: 'WSEI',
      address: '0xE30feDd158A2e3b13e9badaeABaFc5516e95e8C7',
      decimals: 18,
    },
  },
};

export const CONTRACT_TOKEN_ADDRESS = {
  713715: {
    usdt: {
      id: 'usdt',
      symbol: 'USDT',
      address: '0x95E9cF2b2b5EB0eA01DFAEE85FD1Db1d7524cB0B',
      decimals: 6,
    },
    usdc: {
      id: 'usdc',
      symbol: 'USDC',
      address: '0xB4a96deAA55909d95AE98536cC9F7B628fE85ba5',
      decimals: 6,
    },
    wbtc: {
      id: 'wbtc',
      symbol: 'WBTC',
      address: '0xBbDbe137D1Ba8e03a24f6A3c06A09628ab3c2451',
      decimals: 8,
    },
    weth: {
      id: 'weth',
      symbol: 'WETH',
      address: '0xe9296B4450B8A59791A4d1A9637231a26f950E76',
      decimals: 18,
    },
    sei: {
      id: 'sei',
      symbol: 'SEI',
      decimals: 18,
    },
    bul: {
      id: 'bul',
      symbol: 'BUL',
      address: '0xdFe7b73EC1149Aba24Fc1A1f820538379fb18A11',
      decimals: 18,
    },
  },
  1329: {
    usdt: {
      id: 'usdt',
      symbol: 'USDT',
      address: '0xB75D0B03c06A926e488e2659DF1A861F860bD3d1',
      decimals: 6,
    },
    usdc: {
      id: 'usdc',
      symbol: 'USDC',
      address: '0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
      decimals: 6,
    },
    isei: {
      id: 'isei',
      symbol: 'iSEI',
      address: '0x5Cf6826140C1C56Ff49C808A1A75407Cd1DF9423',
      decimals: 6,
    },
    wsei: {
      id: 'wsei',
      symbol: 'WSEI',
      address: '0xE30feDd158A2e3b13e9badaeABaFc5516e95e8C7',
      decimals: 18,
    },
    sei: {
      id: 'sei',
      symbol: 'SEI',
      decimals: 18,
    },
    bul: {
      id: 'bul',
      symbol: 'BUL',
      address: '0xF6a7f9636980459Db50530d517d6A37e6f3F6b20',
      decimals: 18,
    },
  },
};

export const CONTRACT_BETH_ABI = aEthAbi;
export const CONTRACT_BBEP_ABI = bTokenAbi;
export const CONTRACT_BBEP_ADDRESS = {
  713715: {
    usdt: {
      id: 'usdt',
      symbol: 'bUSDT',
      address: '0x83165E4e0B77ed5503f4B37C15C598852fb82c14',
    },
    usdc: {
      id: 'usdc',
      symbol: 'bUSDC',
      address: '0x00159a95C36717e583826D89e9631cc512e189Ac',
    },
    wbtc: {
      id: 'wbtc',
      symbol: 'bWBTC',
      address: '0xB6a4716F2731CCd436Dbb5f27A534c3104710c76',
    },
    weth: {
      id: 'weth',
      symbol: 'bWETH',
      address: '0x32F472Ce830DDB441D92e807ef3FaB6a235816A8',
    },
    sei: {
      id: 'sei',
      symbol: 'bSEI',
      address: '0xD3a8c68a78c26117639Cf7A672a40528F094Ef2c',
    },
  },
  1329: {
    usdt: {
      id: 'usdt',
      symbol: 'bUSDT',
      address: '0x3eb49619C7A414a73d676b60a009CB91B0943fb0',
    },
    usdc: {
      id: 'usdc',
      symbol: 'bUSDC',
      address: '0xbd1D5508B9739e9e306E8E343d5392235689592b',
    },
    isei: {
      id: 'isei',
      symbol: 'bISEI',
      address: '0x7b2A9E4Fa51B422F4a97513D4A46558830f0cC61',
    },
    wsei: {
      id: 'wsei',
      symbol: 'bWSEI',
      address: '0x628032341d7cC16e2399E8C90bfD9b5A912d3e72',
    },
    sei: {
      id: 'sei',
      symbol: 'bSEI',
      address: '0x98f5ee2889dCE70a5A94Fe46e6f69A9801956843',
    },
  },
};

export const CONTRACT_COMPTROLLER_ABI = comptrollerAbi;
export const CONTRACT_COMPTROLLER_ADDRESS = {
  713715: '0xA514E7Cf87ea3AE6564d836bC8ACeEAb2Dbe4c9d',
  1329: '0x89bf46c24E6e9A426519Bcb0023Da6312bD3EbFF',
};

export const CONTRACT_VOTE_ABI = voteAbi;
export const CONTRACT_VOTE_ADDRESS = {
  713715: '0x488c84e3151c1845fB05d38623AB3Db9A3e91A36',
  1329: '',
};

export const CONTRACT_INTEREST_MODEL_ABI = interestModelAbi;

export const MULTICALL_ADDRESS = {
  713715: '0x3790Cd8F6B730d86748Dd286A51C3712EA893Ad7',
  1329: '0x675C5cab8FEE4cB6b1959D62ee1b918E2C5fAA96',
};

export const REFERRAL_ABI = referralAbi;
export const REFERRAL_ADDRESS = {
  713715: '0xa59b05666A04A6F172563CdFAF09EDD9503a36cC',
  1329: '',
};

export const MULTI_FEE_DISTRIBUTION_ABI = multiFeeDistribution;
export const MULTI_FEE_DISTRIBUTION = {
  713715: '0x75340Ed1A73B7A335c9992604fAFF70F5bDB4d93',
  1329: '',
};

export const DLP_ADDRESS = {
  713715: '0x0d9B6875Acf5aFaDBeD68285ec05c5a555669dA1',
  1329: '',
};

export const CHEF_INCENTIVES_CONTROLLER_ABI = chefIncentivesController;
export const CHEF_INCENTIVES_CONTROLLER = {
  713715: '0x25528EB48A3063B90BA36E0c5A15425a7ef56B49',
  1329: '',
};

export const LOCK_ZAP_ABI = {
  713715: lockZap1,
  1329: lockZap1,
};
export const LOCK_ZAP = {
  713715: '0x7e3D6DAFA54D5a01CA2895dF8A4F75eD87Fc5317',
  1329: '',
};

export const LIQUIDITY_ZAP_ABI = liquidityZap;
export const LIQUIDITY_ZAP = {
  713715: '0x4539cfA1d9FB18b2988066495e9317A9FA031e33',
  1329: '',
};

export const ELIGI_DATA_PROVIDER_ABI = eligibilityDataProvider;
export const ELIGI_DATA_PROVIDER = {
  713715: '0xe60E6C6d31BfE4dE66eB03Fefbd0F9D2c09209d4',
  1329: '',
};

export const COMPOUNDER_ABI = compounder;
export const COMPOUNDER = {
  713715: '0xC56C2904f5E545547c2FA76552F808f2408B8dE5',
  1329: '',
};
