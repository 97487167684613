/* eslint-disable max-len */
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from '../../UI/Toast';

const ProposerInfo = ({ address }) => {
  const { t } = useTranslation();

  return (
    <div className="text-white mb-14">
      <div className="text-2xl font-Inter font-bold mb-5">{t('Details')}</div>

      <div className="flex border-y-2-gradient lg:w-1/2 items-center py-2 px-8 bg-tp-30 rounded-lg shadow-lg justify-between mt-2">
        <p className="text-base">{address}</p>
        <CopyToClipboard
          text={address}
          onCopy={() => {
            toast.success({
              title: t(`Address_copied`),
            });
          }}
        >
          <Copy className="copy-icon" />
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default compose(withRouter)(ProposerInfo);

export const Copy = (props) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        d="M9.25 3.0625C7.734 3.0625 6.5 4.296 6.5 5.8125V16.8125C6.5 18.329 7.734 19.5625 9.25 19.5625H17.25C18.766 19.5625 20 18.329 20 16.8125V10.0625H15.0186C13.9056 10.0625 13 9.15647 13 8.04297V3.0625H9.25ZM14.5 3.50195V8.04395C14.5 8.32995 14.7326 8.5625 15.0186 8.5625H19.5605L14.5 3.50195ZM5.5 5.5625L4.89062 5.96875C4.33412 6.33975 4 6.96431 4 7.63281V17.3125C4 19.936 6.1265 22.0625 8.75 22.0625H15.4297C16.0987 22.0625 16.7233 21.7284 17.0938 21.1719L17.5 20.5625H8.75C6.955 20.5625 5.5 19.1075 5.5 17.3125V5.5625ZM10.75 12.0625H15.75C16.164 12.0625 16.5 12.398 16.5 12.8125C16.5 13.227 16.164 13.5625 15.75 13.5625H10.75C10.336 13.5625 10 13.227 10 12.8125C10 12.398 10.336 12.0625 10.75 12.0625ZM10.75 15.0625H15.75C16.164 15.0625 16.5 15.398 16.5 15.8125C16.5 16.227 16.164 16.5625 15.75 16.5625H10.75C10.336 16.5625 10 16.227 10 15.8125C10 15.398 10.336 15.0625 10.75 15.0625Z"
        fill="white"
      />
    </svg>
  );
};
