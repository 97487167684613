import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Styles = styled.div`
    //   flex: 1;
    width: 66%;
    background: rgba(0, 0, 0, 0.1);
    @media (max-width: 768px) {
        width: 100%;
    }
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    .overflow-wrap-break-word {
        overflow-wrap: break-word;
    }
`;
function LinkRenderer(props) {
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    );
}

const ProposalDetails = ({ proposalInfo }) => {
    const { t } = useTranslation();
    return (
        <Styles className="rounded px-8 py-5 border-y-2-gradient">
            <div>
                <div className="text-2xl font-semibold pb-4 border-b-1-gradient">
                    {t('Details')}
                </div>
                <div className="font-bold my-4">{t('Operation')}</div>
                <div className="flex flex-col justify-end space-y-1">
                    {(proposalInfo.actions || []).map((s, idx) => (
                        <ReactMarkdown
                            components={{ a: LinkRenderer }}
                            className="overflow-wrap-break-word text-right"
                            key={idx}
                        >
                            {s.title}
                        </ReactMarkdown>
                    ))}
                </div>
                <div className="font-bold mt-6">
                    {t('Description')}
                </div>
                <div className="mt-4 pl-8">
                    <ReactMarkdown>{proposalInfo.description}</ReactMarkdown>
                </div>
            </div>
        </Styles>
    );
};

ProposalDetails.defaultProps = {
    proposalInfo: {},
};

export default ProposalDetails;
