import styled from 'styled-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SemiCircleProgress from 'components/common/SemiCircleProgress';
import FireImage from '../../assets/images/fire.png';
import GreyFireImage from '../../assets/images/fire_emoji.png';
import Switch from 'components/UI/Switch';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .circle {
    height: 110px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    color: #ffffff;
  }
`;

const Styles = styled.span`
  display: flex;
  .fire-image {
    height: 20px;
    padding-right: 3px;
    @media (max-width: 767px) {
      height: 14px;
      padding-right: 2px;
    }
  }
`;

const Progress = ({ percent, withBUL, setwithBUL }) => {
  const { t } = useTranslation();

  const fixedPercent = useMemo(() => {
    let result = Number(percent);
    if (!percent || percent === 0) {
      result = 0;
    } else if (percent > 100) {
      result = 100;
    } else if (percent < 0) {
      result = 0;
    }

    return result.toFixed(0);
  }, [percent]);

  return (
    <section>
      <Container className="Progress relative mt-2">
        <SemiCircleProgress percent={100} color={'rgba(255, 255, 255, 0.20)'} />
        <SemiCircleProgress percent={fixedPercent} color={'#0462ab'} />

        <span className="text-xs mt-10 text-white">{t('MIN')}</span>

        <div className="circle flex flex-col items-center justify-center">
          <span className="text-xl font-bold">
            {percent > 10000 ? 'Infinity' : percent.toFixed(1)}%
          </span>
          <div className="flex flex-col items-center text-center">
            <div className=" font-bold text-xs">
              <Styles className="flex items-center text-center">
                <img
                  className="fire-image"
                  src={withBUL ? FireImage : GreyFireImage}
                  alt="fire"
                />
              </Styles>
            </div>
          </div>
        </div>
        <span className="text-xs mt-10 text-white">{t('MAX')}</span>
      </Container>

      <div className="flex flex-col items-center">
        <Switch
          wrapperClassName={'pt-1 pb-0'}
          value={withBUL}
          onChange={() => setwithBUL((oldVal) => !oldVal)}
          style={'small'}
          activeStateColor={'bg-white'}
          inactiveStateColor={'bg-gray'}
        />
        <span>with BUL</span>
      </div>
    </section>
  );
};

export default Progress;
