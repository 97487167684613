export default {
  isProduction: process.env.REACT_APP_ENVIRONMENT === 'production',
  appUrl: process.env.REACT_APP_URL || 'https://app.belugas.io',
  apiSeiUrl: process.env.REACT_APP_SEI_API,
  defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE || 'en',
  walletConnnectID: process.env.REACT_APP_WALLET_CONNECT_ID,
  seiWeb3Provider: process.env.REACT_APP_SEI_WEB3_PROVIDER,
  seiWeb3TestProvider: process.env.REACT_APP_SEI_WEB3_TEST_PROVIDER,
  secondWeb3Provider: process.env.REACT_APP_SEI_WEB3_PROVIDER,
  secondWeb3TestProvider: process.env.REACT_APP_SEI_WEB3_TEST_PROVIDER,


  // Temp Data
  disableButtonsForComingSoon: true,
};
