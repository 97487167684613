import React, { useMemo, useRef } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import BigNumber from 'bignumber.js';
import commaNumber from 'comma-number';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getBigNumber } from '../../utilities/common';

const Styles = styled.div`
    width: 100%;
    .custom-text2xl {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .market-chart-tooltip {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
    }
    .market-chart-time-label {
        padding: 4px 8px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
    }

    .gradient-border-tb {
        > div {
            background: rgba(255, 255, 255, 0.03);
            box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
            padding: 24px;
            border-radius: 8px;
        }
    }
`;

const TYPES = {
    Supply: 'supply',
    Borrow: 'borrow',
};

const format = commaNumber.bindWith(',', '.');

const MarketHistoryChart = ({ data, marketType }) => {
    const { t } = useTranslation();
    const lineChart = useRef();
    const barChart = useRef();
    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.classList.add('market-chart-tooltip');
            tooltipEl.style.opacity = 1;
            tooltipEl.style.transform = 'translate(-50%, 0)';

            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    const externalTooltipHandler = (context, formatter) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);
        const dateLabel = document.getElementById('market-chart-date-label');

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            dateLabel.style.opacity = 0;
            lineChart?.current?.tooltip?.setActiveElements([]);
            barChart?.current?.tooltip?.setActiveElements([]);
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const lineIndex = lineChart?.current?.data?.labels?.findIndex(
                (i) => titleLines[0] === i,
            );
            if (lineIndex > -1) {
                lineChart?.current?.tooltip?.setActiveElements([
                    {
                        datasetIndex: 0,
                        index: lineIndex,
                    },
                ]);
                barChart?.current?.tooltip?.setActiveElements([
                    {
                        datasetIndex: 0,
                        index: lineIndex,
                    },
                ]);
            }

            const bodyLines = [
                lineChart?.current?.data.datasets[0].data[lineIndex] || '',
                lineChart?.current?.data.datasets[1].data[lineIndex] + 0.5 ||
                '',
            ];

            titleLines.forEach((title) => {
                dateLabel.textContent = moment(title).format('MMM D');
            });
            const tableBody = document.createElement('div');
            tableBody.classList.add(
                'flex',
                'flex-col',
                'items-center',
                'justify-center',
                'text-center',
            );
            bodyLines.forEach((body, i) => {
                // const newBody = body[0].split(":");
                const span1 = document.createElement('span');
                span1.classList.add('custom-text2xl');
                const text1 = document.createTextNode(`${body}`);
                if (i === 1) {
                    span1.style.marginTop = '20px';
                }
                const spanLabel = document.createElement('span');
                spanLabel.classList.add('custom-text2xl');
                const textLabel = document.createTextNode(
                    i === 0 ? t('Supply_APY') : 'Borrow APY',
                );

                // const span2 = document.createElement('span');
                // const text2 = document.createTextNode(`${body[1]}`);

                span1.appendChild(text1);
                spanLabel.appendChild(textLabel);
                // span2.appendChild(text2);
                tableBody.appendChild(span1);
                tableBody.appendChild(spanLabel);
                // tableBody.appendChild(span2);
            });

            // Remove old children
            while (tooltipEl.firstChild) {
                tooltipEl.firstChild.remove();
            }

            // Add new children
            tooltipEl.appendChild(tableBody);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        dateLabel.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 80 + 'px';
        dateLabel.style.left = tooltip.caretX + 'px';
        dateLabel.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transform = 'translate(-50%, -115%)';
    };

    const externalTooltipHandler_Bar = (context, formatter) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);
        const dateLabel = document.getElementById('market-chart-date-label');

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            dateLabel.style.opacity = 0;
            lineChart?.current?.tooltip?.setActiveElements([]);
            barChart?.current?.tooltip?.setActiveElements([]);
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const lineIndex = lineChart?.current?.data?.labels?.findIndex(
                (i) => titleLines[0] === i,
            );
            if (lineIndex > -1) {
                lineChart?.current?.tooltip?.setActiveElements([
                    {
                        datasetIndex: 0,
                        index: lineIndex,
                    },
                ]);
                barChart?.current?.tooltip?.setActiveElements([
                    {
                        datasetIndex: 0,
                        index: lineIndex,
                    },
                ]);
            }

            // const bodyLines = tooltip.body.map(b => b.lines);
            const bodyLines = [
                barChart?.current?.data.datasets[0].data[lineIndex] || '',
                barChart?.current?.data.datasets[1].data[lineIndex] + 0.5 || '',
            ];

            titleLines.forEach((title) => {
                dateLabel.textContent = moment(title).format('MMM D');
            });
            const tableBody = document.createElement('div');
            tableBody.classList.add(
                'flex',
                'flex-col',
                'items-center',
                'justify-center',
                'text-center',
            );
            bodyLines.forEach((body, i) => {
                const span1 = document.createElement('span');
                span1.classList.add('custom-text2xl');
                const text1 = document.createTextNode(`${body}`);
                if (i === 1) {
                    span1.style.marginTop = '20px';
                }
                const spanLabel = document.createElement('span');
                spanLabel.classList.add('custom-text2xl');
                const textLabel = document.createTextNode(
                    i === 0 ? t('Total_Supply') : t('Total_Borrow'),
                );

                // const span2 = document.createElement('span');
                // const text2 = document.createTextNode(`${body[1]}`);

                span1.appendChild(text1);
                spanLabel.appendChild(textLabel);
                // span2.appendChild(text2);
                tableBody.appendChild(span1);
                tableBody.appendChild(spanLabel);
                // tableBody.appendChild(span2);
            });

            // Remove old children
            while (tooltipEl.firstChild) {
                tooltipEl.firstChild.remove();
            }

            // Add new children
            tooltipEl.appendChild(tableBody);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        dateLabel.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        // tooltipEl.style.top = positionY + tooltip.caretY + 14 + 'px';
        // 950 1070
        let topVal = positionY + tooltip.caretY + 14;
        if (topVal <= 1000) {
            topVal = 1000;
        }
        if (topVal >= 1070) {
            topVal = 1070;
        }
        // tooltipEl.style.top = topVal + 'px';
        tooltipEl.style.top = 1000 + 'px';
        dateLabel.style.left = tooltip.caretX + 'px';
        dateLabel.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transform = 'translate(-50%, -115%)';
    };

    const lineOptions = useMemo(() => {
        return {
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: (context) =>
                        externalTooltipHandler(context, function (value) {
                            const result = value.replace(/,/g, '');
                            return `${new BigNumber(result)
                                .dp(2, 1)
                                .toString(10)}%`;
                        }),
                },
            },
            layout: {
                padding: {
                    top: 10,
                    bottom: 10,
                },
            },
            scales: {
                y: {
                    display: false,
                },
                x: {
                    display: false,
                },
            },
            maintainAspectRatio: false,
            responsive: true,
        };
    }, []);

    const barOptions = useMemo(() => {
        return {
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: (context) =>
                        externalTooltipHandler_Bar(context, function (value) {
                            const result = value.replace(/,/g, '');
                            return `$${format(
                                getBigNumber(result).dp(2, 1).toString(10),
                            )}`;
                        }),
                },
            },
            scales: {
                y: {
                    display: false,
                },
                x: {
                    display: false,
                },
            },
            maintainAspectRatio: false,
            responsive: true,
        };
    }, []);

    const lineData = useMemo(() => {
        const filteredDataSupply = data?.map((item) =>
            item?.['supplyApy'] < Infinity
                ? item
                : {
                    ...item,
                    supplyApy: 0,
                },
        );

        const filteredDataBorrow = data?.map((item) =>
            item?.['borrowApy'] < Infinity
                ? item
                : {
                    ...item,
                    borrowApy: 0,
                },
        );

        return (canvas) => {
            const ctx = canvas.getContext('2d');
            let gradientSupply = ctx.createLinearGradient(
                0,
                0,
                ctx.canvas.width,
                0,
            );
            gradientSupply.addColorStop(0, '#690201');
            gradientSupply.addColorStop(0.2, '#990100');
            gradientSupply.addColorStop(0.6, '#ffffff');
            gradientSupply.addColorStop(1, '#FFFFFF');
            let gradientBorrow = ctx.createLinearGradient(
                0,
                0,
                ctx.canvas.width,
                0,
            );
            gradientBorrow.addColorStop(0, '#002D74');
            gradientBorrow.addColorStop(0.2, '#2D82E8');
            // gradientBorrow.addColorStop(0.8, "#ffffff");
            gradientBorrow.addColorStop(1, '#FFFFFF');
            return {
                labels: filteredDataSupply?.map((item) => item?.createdAt),
                datasets: [
                    {
                        label: t('Supply_APY'),
                        data: filteredDataSupply?.map(
                            (item) => Number(item?.['supplyApy']) || 0.0,
                        ),
                        fill: false,
                        backgroundColor: 'transparent',
                        borderColor: gradientBorrow,
                        borderWidth: 4,
                        hoverBackgroundColor: '#3AB67A',
                        hoverBorderColor: '#fff',
                        hoverBorderWidth: 4,
                        hoverRadius: 2,
                        pointRadius: 2,
                        pointHitRadius: 2,
                        pointBorderWidth: 0,
                        tension: 0.1,
                    },
                    {
                        label: 'Borrow APY',
                        data: filteredDataBorrow?.map(
                            (item) => Number(item?.['borrowApy']) || 0.0,
                        ),
                        fill: false,
                        backgroundColor: 'transparent',
                        borderColor: '#FF9800',
                        borderWidth: 4,
                        hoverBackgroundColor: '#fd5353',
                        hoverBorderColor: '#fff',
                        hoverBorderWidth: 4,
                        hoverRadius: 2,
                        pointRadius: 2,
                        pointHitRadius: 2,
                        pointBorderWidth: 0,
                        tension: 0.1,
                    },
                ],
            };
        };
    }, [data, marketType]);

    const barData = useMemo(() => {
        return (canvas) => {
            const ctx = canvas.getContext('2d');
            let gradientSupply = ctx.createLinearGradient(0, 0, 0, 200);
            gradientSupply.addColorStop(0, '#690201');
            gradientSupply.addColorStop(1, '#990100');
            let gradientBorrow = ctx.createLinearGradient(0, 0, 0, 200);
            gradientBorrow.addColorStop(0, '#002D74');
            gradientBorrow.addColorStop(1, '#2D82E8');
            return {
                labels: data?.map((item) => item?.createdAt),
                datasets: [
                    {
                        label: t('Total_Supply'),
                        data: data?.map(
                            (item) => Number(item?.['totalSupply']) || 0.0,
                        ),
                        fill: false,
                        backgroundColor: gradientBorrow,
                        hoverBackgroundColor: '#343435',
                    },
                    {
                        label: t('Total_Borrow'),
                        data: data?.map(
                            (item) => Number(item?.['totalBorrow']) || 0.0,
                        ),
                        fill: false,
                        backgroundColor: '#FF9800',
                        hoverBackgroundColor: '#343435',
                    },
                ],
            };
        };
    }, [data]);

    return (
        <Styles className={'flex flex-col items-stretch'}>
            <div
                style={{ height: '200px' }}
                className={'flex flex-col items-stretch mb-18'}
            >
                <Line
                    data={lineData}
                    type={'line'}
                    options={lineOptions}
                    height={120}
                    // width={null}
                    ref={lineChart}
                />
            </div>
            <div
                style={{ height: '230px' }}
                className={'flex flex-col items-stretch'}
            >
                <Bar
                    data={barData}
                    type={'bar'}
                    options={barOptions}
                    height={160}
                    // width={null}
                    ref={barChart}
                />
            </div>
            <div
                style={{ height: 40 }}
                className={'flex flex-col items-stretch relative text-white'}
            >
                <div
                    className="market-chart-time-label absolute"
                    style={{ top: 8 }}
                    id={'market-chart-date-label'}
                />
            </div>
        </Styles>
    );
};

const Chart = React.memo(MarketHistoryChart);

export default Chart;
