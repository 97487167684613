import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import commaNumber from 'comma-number';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import BigNumber from 'bignumber.js';

import arrowUp from '../../../assets/icons/arrowUp.svg';
import arrowDown from '../../../assets/icons/arrowDown.png';
import styled from 'styled-components';
import { useActiveWeb3React } from '../../../hooks';
import { getEtherscanLink } from 'utils';
import { MediumLineGradient } from 'components/common/lineGradient';

const Styles = styled.div`
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(16px);
  border-radius: 8px;

  .custom-text-size {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  @media (max-width: 920px) {
    padding: 1.25rem 10px;
    .custom-text-size {
      font-size: 12px;
    }
  }

  svg.user-icon {
    path {
      fill: #000000;
    }
  }

  .view-more-btn {
    background: #3060f6;
    border-radius: 10px;
  }
`;
const format = commaNumber.bindWith(',', '.');

const Transactions = ({ address, transactions }) => {
  const { t } = useTranslation();
  const { requiredChainId } = useActiveWeb3React();
  const [data, setData] = useState([]);
  const getDate = (timestamp) => {
    const startDate = moment(timestamp * 1000);
    const curDate = moment(new Date());
    const duration = moment.duration(curDate.diff(startDate));

    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours()) - days * 24;
    return `${days} ${t('days')}${hours ? `, ${hours}${t('hrs')}` : ''} ${t(
      'ago',
    )}`;
  };

  useEffect(() => {
    const tempData = [];
    transactions.forEach((tx) => {
      if (tx.type === 'vote') {
        tempData.push({
          action: tx.support ? t('Received_Votes') : t('Lost_Votes'),
          age: getDate(tx.blockTimestamp),
          result: format(
            new BigNumber(tx.votes)
              .div(new BigNumber(10).pow(18))
              .dp(4, 1)
              .toString(10),
          ),
          isReceived: tx.support,
        });
      } else {
        tempData.push({
          action:
            tx.to.toLowerCase() === address.toLowerCase()
              ? `${t('Received')} BUL`
              : `${t('Sent')} BUL`,
          age: getDate(tx.blockTimestamp),
          result: format(
            new BigNumber(tx.amount)
              .div(new BigNumber(10).pow(18))
              .dp(4, 1)
              .toString(10),
          ),
          isReceived: tx.to.toLowerCase() === address.toLowerCase(),
        });
      }
    });
    setData([...tempData, ...mocs]);
  }, [transactions, address]);

  const handleLink = () => {
    window.open(
      getEtherscanLink(requiredChainId, address, 'address'),
      '_blank',
    );
  };

  return (
    <Styles className="text-white col-span-3 mt-5 px-8">
      <p className="text-2xl font-bold py-5 border-y-2-gradient">
        {t('Transactions')}
      </p>

      <div className="flex justify-between pt-8 pb-2.5 text-base font-bold">
        <div className="">{t('Action')}</div>
        <div className="">{t('Age')}</div>
        <div className="">{t('Result')}</div>
      </div>

      {data.length === 0 && (
        <div className="flex items-center justify-center font-medium text-lg py-8">
          {t('No_Transactions')}
        </div>
      )}

      {data &&
        data.map((item, index) => (
          <div key={index} className="grid grid-cols-3 py-3 text-sm">
            <div className="justify-self-start text-center">{item.action}</div>
            <div className="text-center">{item.age}</div>
            <div className="justify-end items-center flex w-full">
              <div className="text-right mr-2">{item.result}</div>
              {item.isReceived ? (
                <img src={arrowUp} alt="" />
              ) : (
                <img
                  src={arrowDown}
                  alt=""
                  style={{ height: '20px', width: '9px' }}
                />
              )}
            </div>
          </div>
        ))}

      {data.length > 0 && (
        <div className="flex justify-center my-10">
          <button
            className="focus:outline-none text-white blue-purple-gradient text-lg py-2 w-full rounded-lg"
            onClick={handleLink}
          >
            View All
          </button>
        </div>
      )}

      <MediumLineGradient />
    </Styles>
  );
};

export default compose(withRouter)(Transactions);

const mocs = [
  {
    action: 'Received Votes',
    age: '0 days ago',
    result: '2.0000',
    isReceived: true,
  },
  {
    action: 'Against Votes',
    age: '0 days ago',
    result: '1.0000',
    isReceived: false,
  },
];
