import { Fragment} from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'
import styled from 'styled-components';
import arrowDown from '../../assets/icons/sortDown.svg';

const Styles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-dropdown {
    background: linear-gradient(90deg, #4776e6 0%, #0061a7 100%);
    padding-right: 0.75rem;
  }

  .option-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .arrow-down {
      margin: 0 0 0 5px;
    }
    div {
    }
  }
`;

export default function NavDropdown({
  label,
  options
}) {
  return (
    <Styles>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="text-white btn-solid btn-dropdown">
            <div className="option-container">
              <div>
                {label}
              </div>
              <img className="arrow-down" src={arrowDown} alt='' />
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg
           ring-1 ring-black ring-opacity-5 focus:outline-none"
            style={{ zIndex: 1 }}
          >
            <div className="px-1 py-1 ">
              {
                options.map((option, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <Link to={`${option.link}`}
                        className={`${active ? 'text-blue3' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {option.label}
                      </Link>
                    )}
                  </Menu.Item>
                ))
              }
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </Styles>
  )
}

function EditInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13V16H7L16 7L13 4L4 13Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
    </svg>
  )
}

function EditActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13V16H7L16 7L13 4L4 13Z"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
    </svg>
  )
}

function DuplicateInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4H12V12H4V4Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <path
        d="M8 8H16V16H8V8Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
    </svg>
  )
}
