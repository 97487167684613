import styled from 'styled-components';
import { matchSorter } from 'match-sorter';
import { useTable, useSortBy, useExpanded } from 'react-table';
import React, { Fragment, useEffect, useState } from 'react';

import sortUp from '../../assets/icons/sortUp.svg';
import sortDown from '../../assets/icons/sortDown.svg';
import { useWindowSize } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

const Styles = styled.div`
    width: 100%;
    overflow: auto;
    border-radius: 10px;
    margin-top: 2rem;

    table {
        background: rgba(255, 255, 255, 0.03);
        color: white;
        border-spacing: 0;
        border-radius: 10px;
        width: 100%;
        thead {
            border-radius: 6px 6px 0px 0px;
            border-bottom: solid 1px #8a8fa7;
        }

        tbody tr:hover,
        th:hover {
            background: rgba(255, 255, 255, 0.2);
        }

        tr {
            .custom-text-color {
                color: #0094ff;
            }
        }

        tr.custom-border-bottom {
            border-bottom: 5px solid #eeeeee;
        }

        th {
            font-size: 1rem;
            font-weight: 500;
            user-select: none;
            padding: 32px 15px;
            white-space: nowrap;
        }

        th,
        td.horizontal {
            margin: 0;
            text-align: right;

            :last-child {
                border-right: 0;
            }

            :first-child {
                text-align: left;
            }
        }

        td.horizontal {
            padding: 15px 15px;
            font-size: 14px;
        }

        @media (min-width: 1024px) {
            th,
            td.horizontal {
                :nth-child(2) {
                    width: 25%;
                }
                :nth-child(3) {
                    width: 25%;
                }
                :nth-child(4) {
                    width: 25%;
                }
            }
        }
    }
`;

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({ columns, data }) {
    const { t } = useTranslation();
    const [isTableHorizontal, setIsTableHorizontal] = useState(true);
    const { width } = useWindowSize() || {};
    useEffect(() => {
        if (width < 1024) {
            setIsTableHorizontal(false);
        } else {
            setIsTableHorizontal(true);
        }
    }, [width]);

    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
                defaultColumn: 'rank',
                autoResetSortBy: false,
            },
            useSortBy,
            useExpanded,
        );

    return (
        <table {...getTableProps()} className="">
            {isTableHorizontal && (
                <thead className="text-xl">
                    {[headerGroups[1]].map((headerGroup) => (
                        // eslint-disable-next-line react/jsx-key
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => {
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps(),
                                        )}
                                        key={column.Header}
                                    >
                                        <div className="flex items-center gap-3">
                                            <span className="flex-1">
                                                {column.render('Header')}
                                            </span>
                                            {index !== 0 && (
                                                <>
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <img
                                                                className="inline relative left-1"
                                                                src={sortDown}
                                                                alt="sort down"
                                                            />
                                                        ) : (
                                                            <img
                                                                className="inline relative left-1"
                                                                src={sortUp}
                                                                alt="sort up"
                                                            />
                                                        )
                                                    ) : (
                                                        <div className="inline-flex flex-col space-y-0.5">
                                                            <img
                                                                className="inline w-2.5"
                                                                src={sortUp}
                                                                alt="sort up"
                                                            />
                                                            <img
                                                                className="inline w-2.5"
                                                                src={sortDown}
                                                                alt="sort down"
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
            )}
            <tbody {...getTableBodyProps()} className="text-lg">
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <Fragment key={i}>
                            {isTableHorizontal ? (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <td
                                                {...cell.getCellProps()}
                                                className="horizontal"
                                            >
                                                <div
                                                    className={
                                                        cell.column.Header ===
                                                        '#'
                                                            ? ''
                                                            : cell.column
                                                                  .Header ===
                                                              t('Supply')
                                                            ? ''
                                                            : ''
                                                    }
                                                >
                                                    {cell.render(t('Cell'))}
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ) : (
                                row.cells.map((cell, index) => {
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            key={index}
                                            className={
                                                index === row.cells.length - 1
                                                    ? 'custom-border-bottom'
                                                    : ''
                                            }
                                        >
                                            {cell.column.Header !== '' && (
                                                <td className="p-5 w-1/2">
                                                    {typeof cell.column
                                                        .Header === 'string'
                                                        ? cell.column.Header
                                                        : cell.column.Header()}
                                                </td>
                                            )}
                                            <td
                                                className="p-5 w-1/2"
                                                colSpan={
                                                    cell.column.Header !== ''
                                                        ? 1
                                                        : 2
                                                }
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </Fragment>
                    );
                })}
            </tbody>
        </table>
    );
}

function Application({ columns, data }) {
    return (
        <Styles>
            <Table columns={columns} data={data} />
        </Styles>
    );
}

const App = React.memo(Application);

export default App;
