import commaNumber from 'comma-number';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { accountActionCreators, connectAccount } from '../core';
import { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import * as constants from '../utilities/constants';
import { useActiveWeb3React } from '../hooks';
import Layout from '../layouts/MainLayout/MainLayout';
import {
    currencyFormatter,
    getBigNumber,
    getFormatStringFromBignumber,
} from '../utilities/common';
import MarketTable from '../components/Market/MarketTable';
import styled from 'styled-components';
import totalSupplyImg from '../assets/icons/totalSupply-icon.svg';
import totalBorrowImg from '../assets/icons/totalBorrow-icon.svg';
import totalReservesImg from '../assets/icons/totalReserves-icon.svg';
import totalSupplierImg from '../assets/icons/totalSupplier-icon.svg';
import totalBorrowerImg from '../assets/icons/totalBorrower-icon.svg';
import availabilityImg from '../assets/icons/availability-icon.svg';
import AlertHelper from 'components/common/AlertHelper';
import TwitterSection from '../components/common/TwitterSection';

const format = commaNumber.bindWith(',', '.');

const Styles = styled.div`
    .summary-cards-container {
        > div {
            min-width: 74px;
            flex: 1 1 160px;
            white-space: nowrap;
        }
        img {
            height: 30px;
            width: 30px;
        }
    }
    .border-dashed {
        &:hover {
            box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
        }
    }
    .border-custom {
        border-left: 2px solid #ececec;
    }
    .table-container {
        min-height: 295px;
        @media (max-width: 768px) {
            min-height: 0px;
        }
    }
`;

const Market = ({ history, settings }) => {
    const { t } = useTranslation();
    const { requiredChainId, account } = useActiveWeb3React();
    const [totalSupply, setTotalSupply] = useState('0');
    const [totalBorrow, setTotalBorrow] = useState('0');
    const [totalSupplier, setTotalSupplier] = useState('0');
    const [totalBorrower, setTotalBorrower] = useState('0');
    const [availableLiquidity, setAvailableLiquidity] = useState('0');
    const [totalReserves, setTotalReserves] = useState(0);

    //============================< Same as Dashboard >===========================
    const [borrowPercent, setBorrowPercent] = useState('0');
    const [tvl, setTvl] = useState(0);
    const [circulatingSupply, setCirculatingSupply] = useState(0);

    useEffect(() => {
        if (account) {
            const totalBorrowBalance = getBigNumber(
                settings.totalBorrowBalance,
            );
            const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
            const total = BigNumber.maximum(totalBorrowLimit, 0);
            setBorrowPercent(
                total.isZero() || total.isNaN()
                    ? 0
                    : totalBorrowBalance
                          .div(total)
                          .times(100)
                          .dp(0, 1)
                          .toString(10),
            );
        }
        setTvl(settings.totalLiquidity);
        setCirculatingSupply(settings.circulatingSupply);
    }, [settings.totalBorrowBalance, settings.totalBorrowLimit, account]);

    //============================</- Same as Dashboard ->===========================

    const markets = settings.markets.map((market) => {
        const decimals = market.underlyingSymbol
            ? settings.decimals[market.underlyingSymbol.toLowerCase()]?.token
            : 18;
        market.reserveUSD = new BigNumber(market.totalReserves).div(
            new BigNumber(10).pow(decimals),
        );
        market.utilization = new BigNumber(market.totalBorrowsUsd)
            .div(market.totalSupplyUsd)
            .times(100)
            .dp(2, 1)
            .toString(10);
        return market;
    });

    const getTotalInfo = async () => {
        const tempTS = (settings.markets || []).reduce(
            (accumulator, market) => {
                return new BigNumber(accumulator).plus(
                    new BigNumber(market.totalSupplyUsd),
                );
            },
            0,
        );
        const tempTB = (settings.markets || []).reduce(
            (accumulator, market) => {
                return new BigNumber(accumulator).plus(
                    new BigNumber(market.totalBorrowsUsd),
                );
            },
            0,
        );
        const tempAL = (settings.markets || []).reduce(
            (accumulator, market) => {
                return new BigNumber(accumulator).plus(
                    new BigNumber(market.liquidity),
                );
            },
            0,
        );
        const tempTSR = (settings.markets || []).reduce(
            (accumulator, market) => {
                return new BigNumber(accumulator).plus(
                    new BigNumber(market.supplierCount),
                );
            },
            0,
        );
        const tempTBR = (settings.markets || []).reduce(
            (accumulator, market) => {
                return new BigNumber(accumulator).plus(
                    new BigNumber(market.borrowerCount),
                );
            },
            0,
        );
        const tempRSV = (settings.markets || []).reduce(
            (accumulator, market) => {
                return new BigNumber(accumulator).plus(
                    new BigNumber(market.reserveUSD).times(market.tokenPrice),
                );
            },
            0,
        );

        setTotalSupply(tempTS !== 0 ? tempTS.dp(2, 1).toString(10) : tempTS);
        setTotalBorrow(tempTB !== 0 ? tempTB.dp(2, 1).toString(10) : tempTB);
        setAvailableLiquidity(
            tempAL !== 0 ? tempAL.dp(2, 1).toString(10) : tempAL,
        );
        setTotalSupplier(
            tempAL !== 0 ? tempTSR.dp(2, 1).toString(10) : tempTSR,
        );
        setTotalBorrower(
            tempAL !== 0 ? tempTBR.dp(2, 1).toString(10) : tempTBR,
        );
        setTotalReserves(
            tempRSV !== 0 ? tempRSV.dp(2, 1).toString(10) : tempRSV,
        );
    };

    useEffect(() => {
        if (settings.markets && settings.dailyBelugas) {
            getTotalInfo();
        }
    }, [settings.markets]);

    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                columns: [
                    {
                        Header: 'Coin',
                        accessor: 'underlyingSymbol',
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value }) => {
                            return (
                                <div className="w-full h-full flex justify-end lg:justify-start items-center ml-0">
                                    <img
                                        className={'w-11 h-11'}
                                        src={`/images/coins/${
                                            constants.CONTRACT_TOKEN_ADDRESS[
                                                requiredChainId
                                            ][value.toLowerCase()]?.id
                                        }.png`}
                                        alt={value}
                                    />
                                    <div className="pl-4 ml-4 sm:pl-8 sm:ml-8 h-full border-custom flex flex-col justify-start items-start">
                                        <span className="font-semibold text-base text-gray5">
                                            {t('Name')}
                                        </span>
                                        <span className="font-bold text-lg mt-2.5 text-white">
                                            {value}
                                        </span>
                                    </div>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Supply',
                        accessor: 'totalSupplyUsd',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value, row }) => {
                            return (
                                <div className="flex flex-col justify-start items-end h-full text-white">
                                    <span className="font-semibold text-base text-gray5 whitespace-nowrap">
                                        {t('Supply')}
                                    </span>
                                    <span className="font-bold text-lg mt-1.5">
                                        {currencyFormatter(value, '')}
                                    </span>
                                    <span className="text-xs text-midBlue">
                                        {format(
                                            new BigNumber(value)
                                                .div(
                                                    new BigNumber(
                                                        row?.original?.tokenPrice,
                                                    ),
                                                )
                                                .dp(0, 1)
                                                .toString(10),
                                        )}
                                    </span>
                                </div>
                            );
                        },
                    },
                    {
                        Header: t('Supply_APY'),
                        accessor: 'supplyApy',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value, row }) => {
                            const apyValue = new BigNumber(value || 0).plus(
                                row?.original?.supplyAdditionalApy,
                            );
                            const color = apyValue.isNegative();

                            return (
                                <div className="flex flex-col justify-start items-end h-full text-white">
                                    <span className="font-semibold text-base text-gray5 whitespace-nowrap">
                                        {t('Supply_APY')}
                                    </span>
                                    <span className="font-bold text-lg mt-1.5">
                                        {getFormatStringFromBignumber(
                                            new BigNumber(
                                                row?.original?.supplyAdditionalApy,
                                            ),
                                        )}{' '}
                                        %
                                    </span>
                                    <span
                                        className={`text-xs text-${
                                            color ? 'red' : 'green'
                                        }`}
                                    >
                                        {getFormatStringFromBignumber(apyValue)}{' '}
                                        %
                                    </span>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Borrow',
                        accessor: 'totalBorrowsUsd',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value, row }) => {
                            return (
                                <div className="flex flex-col justify-start items-end h-full text-white">
                                    <span className="font-semibold text-base text-gray5 whitespace-nowrap">
                                        {t('Borrow')}
                                    </span>
                                    <span className="font-bold text-lg mt-1.5">
                                        {currencyFormatter(value, '')}
                                    </span>
                                    <span className="text-xs text-midBlue">
                                        {format(
                                            new BigNumber(value)
                                                .div(
                                                    new BigNumber(
                                                        row?.original?.tokenPrice,
                                                    ),
                                                )
                                                .dp(0, 1)
                                                .toString(10),
                                        )}
                                    </span>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Borrow APY',
                        accessor: 'borrowBulApy',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value, row }) => {
                            const apyValue = new BigNumber(value || 0).plus(
                                row?.original?.borrowAdditionalApy,
                            );
                            const color = apyValue.isNegative();

                            return (
                                <div className="flex flex-col justify-start items-end h-full text-white">
                                    <span className="font-semibold text-base text-gray5 whitespace-nowrap">
                                        {t('Borrow_APY')}
                                    </span>
                                    <span className="font-bold text-lg mt-1.5">
                                        {getFormatStringFromBignumber(
                                            new BigNumber(
                                                row?.original?.borrowAdditionalApy,
                                            ),
                                        )}{' '}
                                        %
                                    </span>
                                    <span
                                        className={`text-xs text-${
                                            color ? 'red' : 'green'
                                        }`}
                                    >
                                        {getFormatStringFromBignumber(apyValue)}{' '}
                                        %
                                    </span>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'CF',
                        toolTip: true,
                        accessor: 'collateralFactor',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value, row }) => {
                            let collateralFactor = `${new BigNumber(value || 0)
                                .div(new BigNumber(10).pow(18))
                                .times(100)
                                .dp(2, 1)
                                .toString(10)} %`;
                            return (
                                <div className="flex flex-col justify-start items-end h-full text-white">
                                    <div className="tooltip relative">
                                        <div className="tooltip-label font-semibold text-base text-gray5 whitespace-nowrap flex">
                                            CF
                                        </div>
                                        <span className="label">
                                            {t('Collateral_Factor')}
                                        </span>
                                    </div>
                                    <span className="font-bold text-lg mt-1.5 whitespace-nowrap">
                                        {collateralFactor}
                                    </span>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Reserves',
                        accessor: 'reserveUSD',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value, row }) => {
                            const reserveUSD = new BigNumber(value)
                                .times(row?.original?.tokenPrice)
                                .dp(2, 1)
                                .toString(10);

                            return (
                                <div className="flex flex-col justify-start items-end h-full text-white">
                                    <span className="font-semibold text-base text-gray5 whitespace-nowrap">
                                        {t('Reserves')}
                                    </span>
                                    <span className="font-bold text-lg mt-1.5">
                                        {currencyFormatter(reserveUSD, '')}
                                    </span>
                                    <span className="text-xs text-midBlue">
                                        {value.dp(2, 1).toString(10)}
                                    </span>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Liquidity',
                        accessor: 'liquidity',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value, row }) => {
                            const liquidityTokens = new BigNumber(value)
                                .div(row?.original?.tokenPrice)
                                .dp(2, 1)
                                .toString(10);
                            return (
                                <div className="flex flex-col justify-start items-end h-full text-white">
                                    <span className="font-semibold text-base text-gray5 whitespace-nowrap">
                                        {t('Liquidity')}
                                    </span>
                                    <span className="font-bold text-lg mt-1.5">
                                        {currencyFormatter(value, '')}
                                    </span>
                                    <span className="text-xs text-midBlue">
                                        {liquidityTokens}
                                    </span>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Utiliz.',
                        accessor: 'utilization',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value, row }) => {
                            return (
                                <div className="flex flex-col justify-start items-end h-full text-white">
                                    <span className="font-semibold text-base text-gray5 whitespace-nowrap">
                                        {t('Utiliz')}
                                    </span>
                                    <span className="font-bold text-lg mt-1.5">
                                        {new BigNumber(value).isLessThan(0.01)
                                            ? '0.01'
                                            : new BigNumber(value)
                                                  .dp(2, 1)
                                                  .toString(10)}
                                        %
                                    </span>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Price',
                        accessor: 'tokenPrice',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value }) => {
                            return (
                                <div className="flex flex-col justify-start items-end h-full text-white">
                                    <span className="font-semibold text-base text-gray5 whitespace-nowrap">
                                        {t('Price')}
                                    </span>
                                    <span className="font-bold text-lg mt-1.5">
                                        {currencyFormatter(value, 'price')}
                                    </span>
                                </div>
                            );
                        },
                    },
                ],
            },
        ];
    }, [requiredChainId]);

    return (
        <>
            <Layout title={'Market'} hideFooter={true} tvl={tvl}>
                <TwitterSection tvl={tvl} circulatingSupply={circulatingSupply}/>
                {/* <MarketTopInfo /> */}
                <Styles className="text-white relative flex flex-col items-center justify-center">
                    <div className="max-w-7xl w-full mt-10">
                        <div className="relative flex flex-col justify-center items-center w-full py-6">
                            <span className={'text-4xl font-bold text-white'}>
                                {t('All_Markets')}
                            </span>

                            <div className="mt-12 w-full flex items-center justify-start summary-cards-container flex-wrap gap-2">
                                <div
                                    className="py-2.5 px-3.5 border border-dashed rounded-2xl border-blue2 
                                 hover:bg-white_o1 hover:border-transparent"
                                >
                                    <div className="flex items-center">
                                        <img
                                            src={totalSupplyImg}
                                            alt={'total supply'}
                                        />
                                        <span className="ml-2.5 text-base font-bold">
                                            {t('Total_Supply')}
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-start">
                                        <span className="text-lg fontsemiBold">
                                            $ {format(totalSupply)}
                                        </span>
                                        <span className="text-xs ml-3"></span>
                                    </div>
                                </div>

                                <div
                                    className="py-2.5 px-3.5 border border-dashed rounded-2xl border-blue2 
                                 hover:bg-white_o1 hover:border-transparent"
                                >
                                    <div className="flex items-center">
                                        <img
                                            src={totalBorrowImg}
                                            alt={'total borrow'}
                                        />
                                        <span className="ml-2.5 text-base font-bold">
                                            {t('Total_Borrow')}
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-start">
                                        <span className="text-lg fontsemiBold">
                                            $ {format(totalBorrow)}
                                        </span>
                                        <span className="text-xs ml-3"></span>
                                    </div>
                                </div>

                                <div
                                    className="py-2.5 px-3.5 border border-dashed rounded-2xl border-blue2 
                                 hover:bg-white_o1 hover:border-transparent"
                                >
                                    <div className="flex items-center">
                                        <img
                                            src={availabilityImg}
                                            alt={'Available Liquidity'}
                                        />
                                        <span className="ml-2.5 text-base font-bold">
                                            {t('Available_Liquidity')}
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-start">
                                        <span className="text-lg fontsemiBold">
                                            $ {format(availableLiquidity)}
                                        </span>
                                        <span className="text-xs ml-3"></span>
                                    </div>
                                </div>

                                <div
                                    className="py-2.5 px-3.5 border border-dashed rounded-2xl border-blue2 
                                 hover:bg-white_o1 hover:border-transparent"
                                >
                                    <div className="flex items-center">
                                        <img
                                            src={totalReservesImg}
                                            alt={'Total Reserves'}
                                        />
                                        <span className="ml-2.5 text-base font-bold">
                                            {t('Total_Reserves')}
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-start">
                                        <span className="text-lg fontsemiBold">
                                            $ {format(totalReserves)}
                                        </span>
                                        <span className="text-xs ml-3"></span>
                                    </div>
                                </div>

                                <div
                                    className="py-2.5 px-3.5 border border-dashed rounded-2xl border-blue2 
                                 hover:bg-white_o1 hover:border-transparent"
                                >
                                    <div className="flex items-center">
                                        <img
                                            src={totalSupplierImg}
                                            alt={'Total Supplier'}
                                        />
                                        <span className="ml-2.5 text-base font-bold">
                                            {t('Total_Suppliers')}
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-start">
                                        <span className="text-lg fontsemiBold">
                                            {format(totalSupplier)}
                                        </span>
                                        <span className="text-xs ml-3"></span>
                                    </div>
                                </div>

                                <div
                                    className="py-2.5 px-3.5 border border-dashed rounded-2xl border-blue2 
                                 hover:bg-white_o1 hover:border-transparent"
                                >
                                    <div className="flex items-center">
                                        <img
                                            src={totalBorrowerImg}
                                            alt={'Total Borrowers'}
                                        />
                                        <span className="ml-2.5 text-base font-bold">
                                            {t('Total_Borrowers')}
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-start">
                                        <span className="text-lg fontsemiBold">
                                            {format(totalBorrower)}
                                        </span>
                                        <span className="text-xs ml-3"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mt-9 table-container">
                                <MarketTable
                                    data={markets}
                                    columns={columns}
                                    onRowClick={(row) =>
                                        history.push(
                                            `/market/${row?.original?.underlyingSymbol}`,
                                        )
                                    }
                                />
                                {/* <MarketCards
                data={markets}
                columns={columns}
                onClick={(row) => history.push(`/market/${row?.underlyingSymbol}`)}
              /> */}
                            </div>
                        </div>
                    </div>
                </Styles>
            </Layout>
        </>
    );
};

const StyledProgressBar = styled.div`
    margin-top: 10px;
    position: relative;
    width: 100%;
    height: 20px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    margin-bottom: 40px;

    @media only screen and (max-width: 768px) {
        width: 150px;
    }
    .progress-line {
        position: absolute;
        border-right: 2px solid #0061a7;
        height: 20px;
        background: #0061a7;
        width: 25%;
        border-radius: 8px 0 0 8px;
        bottom: 0px;
    }
    .progress-active-bar {
        position: absolute;
        background: #0061a7;
        padding: 7px;
        height: 26px;
        bottom: -3px;
        border-radius: 8px;
        transform: translateX(-7px);
    }
`;

const ProgressBar = () => {
    return (
        <StyledProgressBar>
            <div className="progress-line" />
            <div className="progress-active-bar" style={{ left: '25%' }} />
        </StyledProgressBar>
    );
};
const MarketTopInfo = () => {
    const { t } = useTranslation();

    return (
        <StyledMarketTopInfo className="flex text-white gap-4 font-bold mb-8">
            <div className="flex-1">
                <p className="text-lg">{t('Available_Credit')}</p>
                <div className="my-8 text-6xl font-LibreFranklinBold">
                    41.58<span className="text-4xl ml-4">USD</span>
                </div>
                <div className="mb-4 text-lg">
                    {t('Borrow_Balance')}
                    <span className="ml-4 text-red1">-1.50%</span>
                </div>
                <div className="mb-4 text-lg">{t('Borrow_Limit')}</div>
                <ProgressBar />
                <p className="mt-10 inline-block mb-4 text-2xl font-LibreFranklinBold">
                    {t('My_BUL')}
                </p>
                <div className="flex">
                    <div className="flex-1">
                        <div className="text-base font-LibreFranklinThin font-light mb-2">
                            {t('Net_BUL')}
                        </div>
                        <div className="text-2xl font-LibreFranklinBold">
                            0.00%
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="text-base font-LibreFranklinThin font-light mb-2">
                            {t('Supply_BUL')}
                            <AlertHelper
                                color="#ffffff"
                                text={t('My_Earned_DESC')}
                            />
                        </div>
                        <div className="text-2xl font-LibreFranklinBold">
                            0.00%
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="text-base font-LibreFranklinThin font-light mb-2">
                            {t('Borrow_BUL')}
                            <AlertHelper
                                color="#ffffff"
                                text={t('My_Earned_DESC')}
                            />
                        </div>
                        <div className="text-2xl font-LibreFranklinBold">
                            0.00%
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-1">
                <p className="mb-4 text-lg">{t('My_Supply_Borrow')}</p>
                <div className="mb-4 text-lg">
                    {t('Supply_Balance')}
                    <span className="ml-4 text-red1">-1.77%</span>
                </div>
                <ProgressBar />
                <div className="mb-4 text-lg">
                    {t('Supply_Balance')}
                    <span className="ml-4 text-red1">-1.77%</span>
                </div>
                <ProgressBar />
                <div className="mb-4 text-lg">
                    {t('Supply_Balance')}
                    <span className="ml-4 text-red1">-1.77%</span>
                </div>
                <ProgressBar />

                <div className="flex w-full gap-2 mb-6">
                    <div style={{ width: '60%' }}>
                        <p className="text-sm mb-2">BUL</p>
                        <p className="text-xs mb-2">38.1%</p>
                        <div className="bg-midBlue rounded-2xl h-4"></div>
                    </div>
                    <div style={{ width: '22%' }}>
                        <p className="text-sm mb-2">BUL</p>
                        <p className="text-xs mb-2">14.02%</p>
                        <div className="bg-gray5 rounded-2xl h-4"></div>
                    </div>
                    <div style={{ width: '18%' }}>
                        <p className="text-sm mb-2">BUL</p>
                        <p className="text-xs mb-2">12.67%</p>
                        <div className="bg-white_o1 rounded-2xl h-4"></div>
                    </div>
                </div>
                <div className="flex w-full gap-2 mb-4">
                    <div style={{ width: '60%' }}>
                        <p className="text-sm mb-2">BUL</p>
                        <p className="text-xs mb-2">38.1%</p>
                        <div className="bg-midBlue rounded-2xl h-4"></div>
                    </div>
                    <div style={{ width: '22%' }}>
                        <p className="text-sm mb-2">BUL</p>
                        <p className="text-xs mb-2">14.02%</p>
                        <div className="bg-gray5 rounded-2xl h-4"></div>
                    </div>
                    <div style={{ width: '18%' }}>
                        <p className="text-sm mb-2">BUL</p>
                        <p className="text-xs mb-2">12.67%</p>
                        <div className="bg-white_o1 rounded-2xl h-4"></div>
                    </div>
                </div>
            </div>
        </StyledMarketTopInfo>
    );
};

const StyledMarketTopInfo = styled.div``;

Market.defaultProps = {
    history: {},
    settings: {},
};

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
    const { setSetting, getMarketHistory } = accountActionCreators;

    return bindActionCreators(
        {
            setSetting,
            getMarketHistory,
        },
        dispatch,
    );
};

export default compose(
    withRouter,
    connectAccount(mapStateToProps, mapDispatchToProps),
)(Market);
