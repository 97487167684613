/* eslint-disable max-len */
export const EditWhiteIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="#fff"
                d="M12.086.188a1.4 1.4 0 00-.968.396L9.586 2.092l3.871 3.81 1.532-1.508c.514-.506.514-1.27 0-1.905L13.054.584a1.4 1.4 0 00-.968-.396zM8.174 3.361l-6.09 6.35c-.13 0-.241.148-.241.277L.43 14.552c-.13.13-.01.387.121.516.131.13.232.12.363.12h.162l4.637-1.39c.131 0 .111-.03.242-.158l6.331-6.19-4.113-4.088zM2.61 10.346l1.936.397.403 2.024-2.58.635-.525-.516.766-2.54z"
            ></path>
        </svg>
    );
};
