/* eslint-disable max-len */

export const Logo = () => {
    return (
        <svg
            width="175"
            height="42"
            viewBox="0 0 175 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M67.9968 21.4521C67.6287 20.6935 67.1359 19.9733 66.5214 19.3625C66.3779 19.2147 66.2157 19.0893 66.0691 18.9607C66.4995 18.4335 66.8489 17.8838 67.1546 17.2729C67.6069 16.3021 67.8502 15.2026 67.8502 14.0421C67.8502 9.96901 64.6342 6.63208 60.6821 6.63208H48.308V32.1123H61.5399C65.4733 32.1123 68.708 28.7561 68.708 24.7023C68.708 23.5192 68.4615 22.4423 67.9905 21.4521H67.9968ZM61.543 27.9331H52.3661V10.8113H60.6821C62.4039 10.8113 63.817 12.2676 63.817 14.0421C63.817 15.8167 62.4039 17.2729 60.6821 17.2729H55.4854V21.4521H61.543C63.2648 21.4521 64.656 22.9309 64.656 24.7023C64.656 26.4736 63.2617 27.9331 61.543 27.9331Z"
                fill="white"
            />
            <path
                d="M88.1473 22.7831C88.1473 21.8348 88.0038 20.8832 87.7355 20.0184C87.4922 19.173 87.1242 18.3725 86.6719 17.6524C85.0343 15.1384 82.2675 13.47 79.1139 13.47C77.2704 13.47 75.5486 14.0422 74.1356 15.0131C72.4137 16.1929 71.1223 17.9481 70.4673 20.0152C70.2209 20.88 70.0774 21.8316 70.0774 22.7799C70.0774 23.2461 70.1179 23.7283 70.1585 24.1719C70.1585 24.1783 70.1616 24.1848 70.1616 24.1944C70.5765 26.8659 72.0488 29.1387 74.1324 30.5468C75.5455 31.5402 77.2673 32.1124 79.1108 32.1124C82.2643 32.1124 85.028 30.4246 86.6688 27.93L83.2875 25.5864C82.3891 27.0041 80.8513 27.93 79.1108 27.93C76.8431 27.93 74.9029 26.358 74.3227 24.1912C74.3196 24.1848 74.3196 24.1783 74.3165 24.1687H88.0412C88.1036 23.7251 88.1442 23.2429 88.1442 22.7767L88.1473 22.7831ZM74.9154 20.0184C75.82 18.5814 77.3546 17.6524 79.117 17.6524C80.8794 17.6524 82.3954 18.5782 83.2937 19.9767C83.2937 19.9992 83.3155 19.9992 83.3155 20.0184H74.9154Z"
                fill="white"
            />
            <path
                d="M90.7175 6.63208H94.7726V32.1123H90.7175V6.63208Z"
                fill="white"
            />
            <path
                d="M97.5955 13.4731H101.651V23.6479C101.651 25.9914 103.535 27.9332 105.808 27.9332C108.082 27.9332 109.948 25.9914 109.948 23.6479V13.4731H114.003V23.6479C114.003 28.2932 110.316 32.1123 105.808 32.1123C101.301 32.1123 97.5955 28.29 97.5955 23.6479V13.4731Z"
                fill="white"
            />
            <path
                d="M134.322 22.7831V13.47H130.267V15.0131C128.832 14.039 127.113 13.47 125.288 13.47C123.463 13.47 121.723 14.0422 120.31 15.0131C117.849 16.6815 116.252 19.5523 116.252 22.7799C116.252 26.0076 117.849 28.8783 120.31 30.5468C121.661 31.4984 123.342 32.0706 125.104 32.1124L126.804 34.7485H118.713V38.9309H134.318L131.636 34.7485L129.936 32.1124L129.325 31.119C129.649 30.9486 129.958 30.759 130.267 30.5468C132.703 28.8783 134.322 26.0301 134.322 22.7799V22.7831ZM127.072 27.5956C126.517 27.8078 125.906 27.9332 125.291 27.9332C124.062 27.9332 122.974 27.4671 122.116 26.7309C120.99 25.7986 120.313 24.3873 120.313 22.7831C120.313 19.9542 122.528 17.6524 125.291 17.6524C128.055 17.6524 130.27 19.9542 130.27 22.7831C130.27 24.9788 128.938 26.8595 127.076 27.5956H127.072Z"
                fill="white"
            />
            <path
                d="M136.574 22.7831C136.574 19.5523 138.193 16.6815 140.629 15.013C142.064 14.0422 143.782 13.4731 145.626 13.4731C147.469 13.4731 149.169 14.0422 150.604 15.013V13.4731H154.659V32.1156H150.604V30.5532C149.169 31.5465 147.469 32.1156 145.626 32.1156C143.782 32.1156 142.061 31.5465 140.629 30.5532C138.193 28.8847 136.574 26.0364 136.574 22.7831ZM150.607 22.7831C150.607 19.9541 148.374 17.6523 145.629 17.6523C142.884 17.6523 140.632 19.9541 140.632 22.7831C140.632 25.6121 142.865 27.9332 145.629 27.9332C148.393 27.9332 150.607 25.6314 150.607 22.7831Z"
                fill="white"
            />
            <path
                d="M162.445 27.9332H169.451C170.271 27.9332 170.945 27.2355 170.945 26.3933C170.945 25.551 170.268 24.8727 169.451 24.8727H162.445C161.933 24.8727 161.441 24.7891 160.97 24.6605C159.862 24.3455 158.88 23.6897 158.162 22.7831C157.691 22.1916 157.323 21.4747 157.095 20.6935C156.974 20.2081 156.911 19.7001 156.911 19.1729C156.911 16.5754 158.633 14.3379 160.966 13.6853C161.437 13.5374 161.93 13.4731 162.442 13.4731H169.448C169.959 13.4731 170.452 13.5374 170.942 13.6853C173.256 14.3411 174.997 16.5786 174.997 19.1729H170.942C170.942 18.3499 170.265 17.6523 169.448 17.6523H162.442C161.643 17.6523 160.966 18.3499 160.966 19.1729C160.966 19.9959 161.643 20.6935 162.442 20.6935H169.448C169.959 20.6935 170.452 20.7771 170.942 20.9057C172.049 21.2207 173.01 21.899 173.727 22.7863C174.198 23.3971 174.566 24.0947 174.794 24.8759C174.916 25.3613 175 25.8693 175 26.3965C175 29.0133 173.259 31.2315 170.945 31.9066C170.452 32.032 169.962 32.1188 169.451 32.1188H162.445C161.933 32.1188 161.441 32.0352 160.97 31.9066C158.633 31.2315 156.914 29.0133 156.914 26.3965H160.97C160.97 27.242 161.646 27.9364 162.445 27.9364V27.9332Z"
                fill="white"
            />
            <path
                d="M19.9227 41.0622C8.93669 41.0622 0 31.8519 0 20.5327C0 9.21351 8.93669 0 19.9227 0C30.9088 0 39.8423 9.2103 39.8423 20.5327C39.8423 31.8551 30.9057 41.0622 19.9227 41.0622ZM19.9227 0.723322C9.32347 0.723322 0.701834 9.60893 0.701834 20.5327C0.701834 31.4565 9.32347 40.3389 19.9227 40.3389C30.522 40.3389 39.1405 31.4532 39.1405 20.5327C39.1405 9.61214 30.5189 0.723322 19.9227 0.723322Z"
                fill="white"
            />
            <path
                d="M18.8497 18.1055C17.733 18.9574 16.6163 19.8158 15.4778 20.6291C14.7541 21.1467 13.8152 21.5968 13.1726 22.2172C14.9225 20.5295 15.2407 18.3916 15.2407 18.3916C11.3104 15.0515 13.0946 6.31378 19.6077 6.16269C26.1238 6.0116 26.888 12.3157 26.888 12.3157C28.1825 9.24243 25.6434 5.98266 25.5779 5.90229C28.4633 8.10119 31.1396 7.56754 32.1284 7.25249C28.9623 4.18239 24.6983 2.30176 20.0101 2.30176C11.3198 2.30176 4.09557 8.75379 2.60145 17.2601C2.60145 17.2601 2.53594 28.7464 14.9475 33.3596C22.8018 36.2786 28.2511 33.9254 31.1926 31.8036C31.2488 31.7619 31.3049 31.7233 31.361 31.6815C31.3891 31.6622 31.4172 31.6397 31.4422 31.6204C31.5108 31.569 31.5763 31.5207 31.6387 31.4693C31.701 31.4179 31.7666 31.3696 31.8289 31.3182C31.9038 31.2603 31.9755 31.2025 32.0442 31.1414C32.2064 31.0064 32.3592 30.8746 32.4996 30.746C32.5401 30.7106 32.5776 30.6753 32.615 30.6399C32.6743 30.5852 32.7273 30.5338 32.7803 30.4824C32.8333 30.4309 32.8832 30.3827 32.9332 30.3345C32.9831 30.2863 33.0267 30.2381 33.0735 30.1963C33.1016 30.1641 33.1328 30.1352 33.1609 30.1063L33.2014 30.0645C33.4728 29.7783 33.6475 29.5662 33.7255 29.4729C33.4478 28.9682 32.9862 28.7432 32.824 28.6757C32.8177 28.6757 32.8146 28.6725 32.8084 28.6693C32.7834 28.6596 32.7678 28.6564 32.7678 28.6564C31.9599 28.3638 30.1633 28.007 26.5075 29.2768C22.5242 30.6592 19.1647 28.007 19.1647 28.007C19.1647 28.007 22.0345 30.058 26.2361 28.4603C34.1528 25.4513 35.4161 29.5469 35.4161 29.5469C35.4161 29.5469 39.5117 22.7187 36.3082 17.6555C34.8234 15.3055 27.4651 11.5217 18.8559 18.0991L18.8497 18.1055Z"
                fill="white"
            />
            <path
                d="M33.9158 30.6786C33.7192 32.2249 30.731 35.3047 28.2075 36.687C28.1576 36.7127 28.1108 36.7417 28.0609 36.7674C25.7963 37.9633 23.251 38.6705 20.5528 38.7541C20.3719 38.7606 20.1879 38.7638 20.007 38.7638C10.2374 38.7638 2.31763 30.6015 2.31763 20.5328C2.31763 19.4173 2.41432 18.3243 2.60148 17.2634C2.60148 17.2634 2.53597 28.7498 14.9475 33.363C22.8018 36.282 28.2512 33.9288 31.1926 31.807C31.2488 31.7652 31.3049 31.7266 31.3611 31.6849C31.3892 31.6656 31.4172 31.6431 31.4422 31.6238C31.5763 31.5241 31.7042 31.4245 31.829 31.3248C31.9038 31.2669 31.9756 31.2091 32.0442 31.148C32.2064 31.013 32.3592 30.8812 32.4996 30.7526C32.5402 30.7172 32.5776 30.6819 32.615 30.6465C32.6743 30.5918 32.7273 30.5404 32.7803 30.489C32.8334 30.4375 32.8833 30.3893 32.9332 30.3411C32.9831 30.2929 33.0268 30.2446 33.0736 30.2029L33.2014 30.071C33.4728 29.7849 33.6475 29.5728 33.7255 29.4795C33.8877 29.7753 33.9875 30.1675 33.922 30.6786H33.9158Z"
                fill="#D3D3D3"
            />
        </svg>
    );
};
