import { useState } from 'react';
import Header from './Header';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import close from '../../assets/icons/close.svg';
import bgBlue from '../../assets/images/Sea-Animals.png';
import bellOutline from '../../assets/icons/bellOutline.svg';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';

const Styles = styled.div`
    background-size: ${({ bgPlain }) =>
        bgPlain ? '100% 100%' : '100% 1850px'};
    background-color: #02222d;

    .main {
        min-height: calc(100vh - 420px);
    }

    .global-toolbar {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        padding-left: 3.5rem;
        padding-right: 3.5rem;
        border-bottom: 2px solid #ffffff;
    }
    .search-icon {
        path {
            fill: #990100;
        }
    }
    .global-search-input::placeholder {
        color: #c4c4c4;
    }
    .family {
        font-family: Inter;
    }
    .subtract-sidebar-width {
        max-width: none;
        width: calc(100% - 100px);
    }
    .my-contents.open {
        @media (min-width: 1024px) {
            // ${(props) => (props.path.includes('trade') ? 'width: 75%;' : '')}
            ${(props) => 'width: 75%;'}
        }
    }
    .mb-400 {
        margin-bottom: 400px;
    }
    .md-mb-500 {
        margin-bottom: 500px;
    }
    .custom-bg {
        background: #690201;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
    }
    .toast-container {
        background: #3b46f1;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }
    .fill-white {
        path {
            fill: #ffffff;
        }
    }
`;

/** @dev
 *background class was define at tailwind.css
 */
const getBg = (background) => {
    if (background === 'liquidatePage') return 'bg-liquidate';
    if (background === 'dashboard') return 'bg-dashboard';
    if (background === 'market') return 'bg-market';
};

function MainLayout({
    children,
    containerStyle = {},
    bgPlainNHeaderFull = false,
    containerClass,
    hideSummary = true,
    background,
}) {
    const { t } = useTranslation();
    const [alertStrip, setAlertStrip] = useState(false);
    const { pathname, search } = useLocation();
    const path = `${pathname}${search}`;
    const bg = getBg(background);

    return (
        <Styles
            className={
                containerClass ||
                `${bg} flex flex-col items-center relative overflow-hidden`
            }
            path={path}
            background={background}
            bgPlain={bgPlainNHeaderFull}
            hideSummary={hideSummary}
            style={containerStyle}
        >
            {alertStrip && (
                <div className="bg-white flex items-center justify-between py-3 px-8 w-full">
                    <div className="flex items-center justify-center">
                        <img src={bellOutline} alt={''} />
                        <span className="ml-4 text-sm">2023-06-10</span>
                        <span className="ml-4 text-sm">
                            Launch of BUL Mainnet and BUL Redenomination Plan
                        </span>
                    </div>
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            setAlertStrip(false);
                        }}
                    >
                        <img src={close} alt={''} />
                    </div>
                </div>
            )}

            <section className={`min-h-screen max-w-7xl px-8 w-full`}>
                <Header />

                <div className="main max-w-[1200px]">
                    <ToastContainer
                        autoClose={5000}
                        transition={Zoom}
                        hideProgressBar
                        position={toast.POSITION.TOP_RIGHT}
                    />
                    {children}
                </div>

                <Footer />
            </section>
        </Styles>
    );
}

export default MainLayout;
