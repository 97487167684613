import { useCallback, useState } from 'react';
import { AlertCircle } from 'react-feather';
import styled from 'styled-components';
import Tooltip from './Tooltip';

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  background-color: transparent;
  color: #ffffff;

  :hover,
  :focus {
    opacity: 0.7;
  }
`;

export default function AlertHelper({ text, color = '#fff' }) {
  const [show, setShow] = useState(false);

  const open = useCallback(() => setShow(true), [setShow]);
  const close = useCallback(() => setShow(false), [setShow]);

  return (
    <span style={{ marginLeft: 4 }}>
      <Tooltip text={text} show={show}>
        <QuestionWrapper
          onClick={open}
          onMouseEnter={open}
          onMouseLeave={close}
          className={'tooltip-icon'}
        >
          <AlertCircle size={16} color={color} />
        </QuestionWrapper>
      </Tooltip>
    </span>
  );
}
