import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connectAccount } from '../../core';
import { getEtherscanLink } from '../../utils';
import { useActiveWeb3React, useWeb3 } from '../../hooks';
import commaNumber from 'comma-number';
import DelegationTypeModal from './Modals/NewDelegationTypeModal';
import logo from '../../assets/icons/logo-compact-2.svg';
import styled from 'styled-components';
import ConnectWalletButton from 'components/common/ConnectWalletButton';
import config from 'config';

const Styles = styled.div`
    // filter: drop-shadow(0px 0px 19px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.03);
    color: white;

    @media (max-width: 1024px) {
        .voting-wallet-content {
            flex-direction: column;
        }
        .summary {
            max-width: none !important;
        }
    }
`;

const format = commaNumber.bindWith(',', '.');

const VotingWallet = ({
    balance,
    settings,
    earnedBalance,
    delegateAddress,
    delegateStatus,
    votingWeight,
}) => {
    const { requiredChainId, account } = useActiveWeb3React();
    const web3 = useWeb3();
    const { t } = useTranslation();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingEarn, setIsLoadingEarn] = useState(false);

    useEffect(() => {
        if (!earnedBalance) {
            setIsLoadingEarn(true);
            return;
        }
        setIsLoadingEarn(false);
    }, [earnedBalance]);

    const getBefore = (value) => {
        const position = value.indexOf('.');
        return position !== -1 ? value.slice(0, position + 5) : value;
    };

    const getAfter = (value) => {
        const position = value.indexOf('.');
        return position !== -1 ? value.slice(position + 5) : null;
    };

    return (
        <Styles className="relative flex-1 max-w-450">
            <div className="text-2xl font-libre font-semibold px-8 py-5 border-y-2-gradient">
                {t('Voting_Wallet')}
            </div>
            <div className={'flex flex-col voting-wallet-content mt-4'}>
                <div className={'flex-1 summary'}>
                    <div className="flex flex-col justify-center items-center">
                        <img
                            src={logo}
                            alt={'logo'}
                            height={'150px'}
                            width={'150px'}
                        />
                        <div className="text-lg font-bold">
                            {t('BUL_Balance')}
                        </div>

                        <div className="font-libre font-semibold mt-5 text-4xl">
                            {
                                <>
                                    {getBefore(format(balance))}
                                    <span className="font-libre font-semibold">
                                        {getAfter(format(balance))}
                                    </span>
                                </>
                            }
                        </div>
                    </div>
                    {/* <div className="flex justify-between items-start p-6">
                        <div className=" text-lg font-bold">
                            {t('BUL_Earned')}
                        </div>
                        <div className=" text-sm flex flex-col justify-center items-end">
                            <div className="flex justify-center items-center">
                                <span>
                                    {getFormatStringFromBignumber(
                                        earnedBalance.div(1e18),
                                    )}{' '}
                                    BUL
                                </span>
                                <AlertHelper text={t('Collect_BUL_desc')} />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center p-6">
                        <div className="text-lg font-bold">
                            {t('Voting_Weight')}
                        </div>
                        <div className="text-sm">
                            {
                                <>
                                    {getBefore(format(votingWeight))}
                                    <span>
                                        {getAfter(format(votingWeight))}
                                    </span>
                                </>
                            }
                        </div>
                    </div> */}
                </div>
                {delegateStatus && (
                    <div className="flex justify-between items-start p-6 border-t border-solid border-gray4">
                        <div className="text-lg font-bold">
                            {t('Delegating_To')}
                        </div>
                        <div className="text-sm flex flex-col justify-center items-end">
                            {
                                <a
                                    className="text-black no-underline focus:outline-none"
                                    href={getEtherscanLink(
                                        requiredChainId,
                                        delegateAddress,
                                        'address',
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {delegateStatus === 'self'
                                        ? 'Self'
                                        : `${delegateAddress.substr(
                                              0,
                                              4,
                                          )}...${delegateAddress.substr(
                                              delegateAddress.length - 4,
                                              4,
                                          )}`}
                                </a>
                            }
                            <button
                                className="focus:outline-none bg-midBlue text-white py-3 px-6 rounded text-sm mt-6 w-full"
                                onClick={() => setIsOpenModal(true)}
                            >
                                {t('Change')}
                            </button>
                        </div>
                    </div>
                )}
                {!delegateStatus && (
                    <div className="flex-1 p-6 setup-voting">
                        <div className="text-lg font-inter font-bold">
                            {t('Setup_Voting')}
                        </div>
                        <div className="text-sm font-libre mt-3">
                            {t('You_can_vote')}
                        </div>
                        <div className="flex justify-center mt-8">
                            {account ? (
                                <button
                                    className="font-inter focus:outline-none bg-blue-gradient rounded-lg text-white font-medium py-4 px-24"
                                    disabled={config.disableButtonsForComingSoon}
                                    onClick={() => {
                                        setIsOpenModal(true);
                                    }}
                                >
                                    Get Started
                                </button>
                            ) : (
                                <ConnectWalletButton
                                    class={`font-inter focus:outline-none bg-blue-gradient rounded-lg text-white font-medium py-4 px-24`}
                                />
                            )}
                        </div>
                    </div>
                )}
                <div className="border-b-2-gradient px-6"></div>
            </div>

            <DelegationTypeModal
                visible={isOpenModal}
                balance={balance}
                delegateStatus={delegateStatus}
                address={account ? account : ''}
                onCancel={() => setIsOpenModal(false)}
            />
        </Styles>
    );
};

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

export default compose(connectAccount(mapStateToProps, undefined))(
    VotingWallet,
);

// @todo: connect delegate modal
