export const ArrowRightIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="13"
            fill="none"
            viewBox="0 0 16 13"
        >
            <path
                fill="#fff"
                d="M10 12.562l-1.4-1.45 3.55-3.55H0v-2h12.15L8.6 2.012 10 .562l6 6-6 6z"
            ></path>
        </svg>
    );
};
