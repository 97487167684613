import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import MainLayout from 'layouts/MainLayout/MainLayout';
import ReferralHistory from './ReferralHistory';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { useActiveWeb3React } from 'hooks';
import useCopyClipboard from 'hooks/useCopyClipboard';
import { restService } from 'utilities';
import Loading from 'components/UI/Loading';
import { useReferralClaimCallback } from 'hooks/useReferral';
import AlertHelper from 'components/common/AlertHelper';
import config from 'config';

const VaultContainer = styled.div`
    .main-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 3em;
        padding: 0px 20px;
        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .border-y-1-gradient {
        @media (max-width: 768px) {
            margin-bottom: 40px;
        }
    }
    .container {
        background: rgba(255, 255, 255, 0.03);
        box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
        padding: 32px;
        border-radius: 8px;
        margin-bottom: 0;
        height: 265px;

        @media (max-width: 768px) {
            height: auto;
        }

        .divider {
            width: 100%;
            height: 3px;
            background-color: #0000008f;
            margin: 2rem 0;
        }

        .display-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            @media (max-width: 768px) {
                margin-top: 20px;
                grid-template-columns: repeat(1, 1fr);
            }
            .item-container {
                display: flex;
                flex-direction: column;
                font-size: 16px;
                @media (max-width: 768px) {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;
                }
                .item-title {
                    display: flex;
                    align-items: center;
                    font-family: LibreFranklin_Bold;
                }
                .item-content {
                    margin-top: 16px;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    font-family: LibreFranklin_Thin;
                    gap: 4px;

                    @media (max-width: 768px) {
                        margin-top: 0px;
                    }
                    span {
                        padding-left: 7px;
                        font-weight: 400;
                        font-size: 12px;
                        margin-top: 2px;
                        @media (max-width: 768px) {
                            font-size: 12px;
                            margin-top: 1px;
                        }
                    }
                    img {
                        width: 20px;
                        height: 20px;
                        @media (max-width: 768px) {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }

        .claim-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .title {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 800;
                margin-bottom: 15px;
                @media (max-width: 768px) {
                    font-size: 16px;
                }
                span {
                    margin: 0 10px 0 5px;
                    color: #4776e6;
                }
                img {
                    width: 25px;
                    height: 25px;
                    @media (max-width: 768px) {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .claim-button {
                width: 100%;
                height: 54px;
                line-height: 54px;
                text-align: center;
                padding: 0px 0px;
                background-image: linear-gradient(to right, #4776e6, #0061a7);
                color: white;
                border-radius: 8px;
                border: none;
                cursor: pointer;
                font-size: 16px;
                font-weight: 600;
                position: relative;
                & > div {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    margin-bottom: -1px;
                    margin-right: 5px;
                }
            }
        }

        .info {
            font-size: 16px;
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }
        .referral-link-container {
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media (max-width: 768px) {
                height: 80px;
            }
            .title {
                font-size: 16px;
                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
            .link {
                display: flex;
                align-items: center;
                font-size: 14px;
                .icon-container {
                    display: inline-flex;
                    justify-content: center;
                    align-items: flex-end;
                    .icon {
                        cursor: pointer;
                        margin-left: 10px;
                        svg.copy {
                            width: 21px;
                            height: 21px;
                            path {
                                fill: #0061a7;
                            }
                        }
                        svg.twitter {
                            width: 18px;
                            height: 18px;
                            path {
                                fill: #0061a7;
                            }
                        }
                        @media (max-width: 768px) {
                            svg.copy {
                                width: 18px;
                                height: 18px;
                            }
                            svg.twitter {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
                span {
                    display: inline-block;
                }
                @media (max-width: 768px) {
                    font-size: 12px;
                    margin-bottom: 5px;
                    flex-direction: column;
                    span {
                        width: 100%;
                        margin-bottom: 6px;
                    }
                }
            }
        }
        .create-container {
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 0px;
            @media (max-width: 768px) {
                height: 80px;
            }
            .claim-button {
                display: block;
                width: 100%;
                height: 54px;
                line-height: 54px;
                text-align: center;
                background-image: linear-gradient(to right, #4776e688, #0061a788);
                color: white;
                border-radius: 8px;
                border: none;
                cursor: pointer;
                font-size: 16px;
                font-weight: 600;
            }
        }
        .warning {
            text-align: center;
            padding: 10px 30px;
            font-size: 14px;
            border-radius: 8px;
            background-color: rgba(255, 255, 255, 0.03);

            @media (max-width: 768px) {
                font-size: 12px;
            }
        }
    }
`;

function Referral() {
    const { t } = useTranslation();
    const { account, requiredChainId } = useActiveWeb3React();
    const [_, setCopied] = useCopyClipboard();
    const { handleClaim } = useReferralClaimCallback();

    const [myReferralCode, setMyReferralCode] = useState('');
    const [referralReward, setReferralReward] = useState(new BigNumber(0));
    const [claimedReward, setClaimedReward] = useState(new BigNumber(0));
    const [firstRef, setFirstRef] = useState(0);
    const [secondRef, setSecondRef] = useState(0);

    const [claimLoading, setclaimLoading] = useState(false);

    const getMyReferralCode = async () => {
        const apiRequest = await restService({
            chainId: requiredChainId,
            api: `/v1/referral?address=${account}`,
            method: 'GET',
        });

        if (apiRequest?.data?.data) {
            setMyReferralCode(apiRequest?.data?.data?.referral);
        }
    };

    const getReferralReward = async () => {
        const apiRequest = await restService({
            chainId: requiredChainId,
            api: `/v1/referral/reward?address=${account}`,
            method: 'GET',
        });

        if (apiRequest?.data?.data) {
            setReferralReward(
                new BigNumber(apiRequest?.data?.data?.reward || 0),
            );
            setClaimedReward(
                new BigNumber(apiRequest?.data?.data?.claimedAmount || 0),
            );
            setFirstRef(apiRequest?.data?.data?.referrer || 0);
            setSecondRef(apiRequest?.data?.data?.referrerOfReferrer || 0);
        }
    };

    useEffect(() => {
        if (account) {
            getMyReferralCode();
            getReferralReward();
        }
    }, [account, requiredChainId]);

    const onCreateReferral = async () => {
        const apiRequest = await restService({
            chainId: requiredChainId,
            api: `/v1/referral`,
            method: 'POST',
            params: {
                address: account,
            },
        });
        if (apiRequest?.data?.data) {
            setMyReferralCode(apiRequest?.data?.data?.referral);
        } else if (apiRequest?.data?.message) {
            NotificationManager.error(t(apiRequest?.data?.message));
        }
    };

    const onClaim = async () => {
        if (!account) return;
        setclaimLoading(true);
        const apiRequest = await restService({
            chainId: requiredChainId,
            api: `/v1/referral/claim`,
            method: 'POST',
            params: {
                address: account,
            },
        });
        if (apiRequest?.data?.data) {
            const res = apiRequest?.data?.data;
            const tx = await handleClaim(
                res.amount,
                res.nonce,
                res.code,
                res.v,
                res.r,
                res.s,
            );
            if (tx) {
                NotificationManager.success(t('Claim_successfully'));
                getReferralReward();
            } else {
                NotificationManager.error(t('Tx_rejected'));
            }
            setclaimLoading(false);
        } else if (apiRequest?.data?.message) {
            NotificationManager.error(t(apiRequest?.data?.message));
            setclaimLoading(false);
        }
    };

    return (
        <div className=" bg-white flex flex-col">
            <MainLayout
                mainClassName="pt-4"
                title={t('Referral')}
                hideFooter={true}
                noBg={true}
                hideSummary={true}
            >
                <div className="pt-8 text-white flex flex-col justify-between items-center grow overflow-auto">
                    <VaultContainer className="max-w-7xl w-full">
                        <div className="main-container mt-1 mb-6 mx-auto gap">
                            <div className="border-y-1-gradient">
                                <div className="container">
                                    <div className="info">
                                        {t('Referral_Reward_Info')}
                                    </div>
                                    <div>
                                        {myReferralCode ? (
                                            <div className="referral-link-container">
                                                <div className="title">
                                                    {t('Your_referral_link')}
                                                </div>
                                                <div className="link">
                                                    <span>
                                                        {`${config.appUrl}?ref=`}
                                                        {myReferralCode}
                                                    </span>
                                                    <div className="icon-container">
                                                        <div
                                                            className="icon"
                                                            onClick={() => {
                                                                setCopied(
                                                                    `${config.appUrl}?ref=${myReferralCode}`,
                                                                );
                                                                NotificationManager.success(
                                                                    `${t(
                                                                        'share_referral',
                                                                    )}`,
                                                                    t(
                                                                        'referral_copy_successfully',
                                                                    ),
                                                                );
                                                            }}
                                                        >
                                                            <Copy className="copy" />
                                                        </div>
                                                        <div
                                                            className="icon"
                                                            onClick={() => {
                                                                window.open(
                                                                    `https://twitter.com/intent/tweet?text=Welcome to the Official @belugasdefi Community, The SEI’s Cryptocurrencies Money Markets Platform.%0a%0a${config.appUrl}?ref=${myReferralCode}`,
                                                                    '_blank',
                                                                );
                                                            }}
                                                        >
                                                            <Twitter className="twitter" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="create-container">
                                                <button
                                                    className="claim-button"
                                                    disabled={!account}
                                                    onClick={onCreateReferral}
                                                >
                                                    +{' '}
                                                    {t('Create_referral_link')}
                                                </button>
                                            </div>
                                        )}
                                        <div className="border-y-1-gradient mb-3">
                                            <div className="warning">
                                                {t('No_Supply')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-y-1-gradient">
                                <div className="container">
                                    <div className="display-grid">
                                        <div className="item-container">
                                            <div className="item-title">
                                                {t('Total_Referees')}
                                                <AlertHelper
                                                    text={t(
                                                        'Users_Referred_Alert',
                                                    )}
                                                />
                                            </div>
                                            <div className="item-content">
                                                {`${new BigNumber(firstRef)
                                                    .plus(secondRef)
                                                    .toFormat(0)}`}
                                                <span>
                                                    {`(${t(
                                                        'Tier1',
                                                    )} - ${new BigNumber(
                                                        firstRef,
                                                    ).toFormat(0)} / ${t(
                                                        'Tier2',
                                                    )} - ${new BigNumber(
                                                        secondRef,
                                                    ).toFormat(0)})`}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="item-container">
                                            <div className="item-title">
                                                {t('Total_Rewards')}
                                                <AlertHelper
                                                    text={t(
                                                        'Claimable_Reward_Alert',
                                                    )}
                                                />
                                            </div>
                                            <div className="item-content">
                                                <img
                                                    src={`/images/coins/logo.svg`}
                                                    alt={'bul'}
                                                />
                                                {`${referralReward
                                                    .plus(claimedReward)
                                                    .div(1e18)
                                                    .toFormat(2)} BUL`}
                                            </div>
                                        </div>
                                        <div className="item-container">
                                            <div className="item-title">
                                                {t('Total_Claimed')}
                                                <AlertHelper
                                                    text={t(
                                                        'Total_Claimed_Alert',
                                                    )}
                                                />
                                            </div>
                                            <div className="item-content">
                                                <img
                                                    src={`/images/coins/logo.svg`}
                                                    alt={'bul'}
                                                />
                                                {`${claimedReward
                                                    .div(1e18)
                                                    .toFormat(2)} BUL`}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="divider-2-gradient" />
                                    <div className="claim-container">
                                        <div className="title">
                                            <img
                                                src={`/images/coins/logo.svg`}
                                                alt={'bul'}
                                            />
                                            <span>
                                                {`${referralReward
                                                    .div(1e18)
                                                    .toFormat(4)} BUL `}
                                            </span>
                                            {t('Available')}
                                        </div>
                                        <button
                                            className="claim-button"
                                            disabled={
                                                referralReward.eq(0) ||
                                                claimLoading
                                            }
                                            onClick={onClaim}
                                        >
                                            {claimLoading && (
                                                <Loading
                                                    size={'12px'}
                                                    margin={'3px'}
                                                />
                                            )}
                                            {t('Claim')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ReferralHistory />
                    </VaultContainer>
                </div>
            </MainLayout>
        </div>
    );
}

export default Referral;
