import { accountActionCreators, connectAccount } from '../core';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { promisify } from '../utilities';
import BigNumber from 'bignumber.js';
import * as constants from '../utilities/constants';
import Layout from '../layouts/MainLayout/MainLayout';
import { useActiveWeb3React } from '../hooks';
import MarketInfo from '../components/Market/MarketInfo';
import MarketDetailsChart from '../components/Market/MarketDetailsChart';
import MarketSummary from '../components/Market/MarketSummary';
import styled from 'styled-components';
import commaNumber from 'comma-number';
import whaleImg from '../assets/images/whaleImg.png';
import TwitterSection from '../components/common/TwitterSection';

const format = commaNumber.bindWith(',', '.');

const Styles = styled.div`
    font-family: 'Inter';
    .custom-border {
        border-bottom: 4px solid #ce2322;
    }

    .supply-legend {
        background: #569edf;
    }
    .borrow-legend {
        background: #ff9800;
    }

    .card {
        // background: linear-gradient(180deg, #F9FAFB70 0%, #C4C4C470 100%);
        // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
        // border-radius: 10px;
    }
    .highlighted {
        background: #ff9800;
        border-radius: 10px;
    }
    .tokenImg {
        height: auto;
        width: 125px;
    }

    @media (max-width: 710px) {
        .graph-container {
            flex-direction: column;
            > div {
                width: 100%;
                margin-left: 0;
            }
        }
    }
`;

const StyledWhale = styled.div`
    position: absolute;
    width: 720px;
    height: 720px;
    background-image: url(${whaleImg});
    background-size: contain;
    background-repeat: no-repeat;
    top: 100px;
    left: calc(50% - 360px);
    opacity: 0.4;
`;

let timeStamp = 0;
const MarketDetails = ({ match, settings, getMarketHistory }) => {
    const { t } = useTranslation();
    const { requiredChainId } = useActiveWeb3React();
    const [marketType, setMarketType] = useState('supply');
    const [currentAsset, setCurrentAsset] = useState('');
    const [data, setData] = useState([]);
    const [marketInfo, setMarketInfo] = useState({});
    const [tvl, setTvl] = useState(0);

    useEffect(() => {
        setTvl(settings.totalLiquidity);
    }, [settings.totalLiquidity]);

    useEffect(() => {
        document.body.scrollTop = 0;
    }, []);
    useEffect(() => {
        if (match.params && match.params.asset) {
            setCurrentAsset(match.params.asset.toLowerCase());
        }
    }, [match]);

    const getGraphData = useCallback(
        async (asset, type) => {
            const tempData = [];
            await promisify(getMarketHistory, { asset, type }).then((res) => {
                res.data.result.forEach((m) => {
                    tempData.push({
                        createdAt: m.createdAt,
                        supplyApy: +new BigNumber(m.supplyApy || 0)
                            .dp(8, 1)
                            .toString(10),
                        borrowApy: +new BigNumber(m.borrowApy || 0)
                            .dp(8, 1)
                            .toString(10),
                        totalSupply: +new BigNumber(m.totalSupply || 0)
                            .dp(8, 1)
                            .toString(10),
                        totalBorrow: +new BigNumber(m.totalBorrow || 0)
                            .dp(8, 1)
                            .toString(10),
                    });
                });
                setData([...tempData.reverse()]);
            });
        },
        [getMarketHistory],
    );

    const getGovernanceData = useCallback(async () => {
        if (settings.markets && settings.markets.length > 0 && currentAsset) {
            const info = settings.markets.find(
                (item) => item.underlyingSymbol.toLowerCase() === currentAsset,
            );
            setMarketInfo(info || {});
        }
    }, [settings.markets, currentAsset]);

    useEffect(() => {
        getGovernanceData();
    }, [getGovernanceData]);

    useEffect(() => {
        if (timeStamp % 60 === 0 && currentAsset) {
            getGraphData(
                constants.CONTRACT_BBEP_ADDRESS[requiredChainId][currentAsset].address,
                process.env.REACT_APP_GRAPH_TICKER,
            );
        }
        timeStamp = Date.now();
    }, [currentAsset, getGraphData]);

    useEffect(() => {
        if (currentAsset) {
            getGraphData(
                constants.CONTRACT_BBEP_ADDRESS[requiredChainId][currentAsset].address,
                process.env.REACT_APP_GRAPH_TICKER,
            );
        }
    }, [currentAsset]);

    const findTokenIcon = (symbol) => {
        const token = settings?.assetList?.find((token) => {
            return token.name === symbol;
        });
        return token?.img || '';
    };

    return (
        <>
            <Layout
                mainClassName="py-8"
                title={'Market'}
                hideFooter={true}
                tvl={format(tvl)}
            >
                {settings.markets && settings.decimals && currentAsset && (
                    <Styles>
                        <StyledWhale />
                        {/* <TwitterSection /> */}
                        <div className="card p-4 text-white flex flex-col items-center justify-center">
                            {!!marketInfo.underlyingSymbol &&
                                marketInfo.underlyingSymbol && (
                                    <img
                                        src={findTokenIcon(
                                            marketInfo.underlyingSymbol,
                                        )}
                                        alt=""
                                        className="tokenImg"
                                    />
                                )}
                            <span className="text-4xl font-bold pl-4 font-LibreFranklinBold">
                                {!!marketInfo.underlyingSymbol &&
                                    marketInfo.underlyingSymbol}
                            </span>
                            <div className="flex mt-16 w-full graph-container">
                                <div className="flex flex-col justify-between w-1/2">
                                    <div className="flex justify-end">
                                        <div className="flex">
                                            <div className="w-6 h-6 rounded-full supply-legend"></div>
                                            <span className="ml-3">
                                                {t('Supply')}
                                            </span>
                                        </div>
                                        <div className="flex ml-8">
                                            <div className="w-6 h-6 rounded-full borrow-legend"></div>
                                            <span className="ml-3">
                                                {t('Borrow')}
                                            </span>
                                        </div>
                                    </div>
                                    <MarketDetailsChart
                                        data={data}
                                        marketType={marketType}
                                    />
                                </div>
                                <div className="flex flex-col w-1/2 ml-6 justify-around">
                                    <div className="py-2.5 px-4 w-full">
                                        <MarketInfo
                                            marketInfo={marketInfo}
                                            marketType={'borrow'}
                                        />
                                    </div>
                                    <div className="py-2.5 px-4 mt-16 w-full">
                                        <MarketInfo
                                            marketInfo={marketInfo}
                                            marketType={'supply'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Styles>
                )}
                <div className="text-white flex flex-col items-center justify-center">
                    <div className="max-w-7xl rounded-2xl p-6 mt-10 flex flex-col w-full px-8 pt-12">
                        <div className="gradient-border-tb">
                            <div>
                                <span className="text-2xl font-bold text-white w-full text-center">
                                    {t('MARKETS_REWARDS')}
                                </span>
                                <div className="gradient-divider"></div>
                                <div className="flex flex-col justify-between">
                                    <MarketSummary
                                        marketInfo={marketInfo}
                                        currentAsset={currentAsset}
                                    />
                                    {/* <InterestRateModel
                                currentAsset={currentAsset}
                            /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

MarketDetails.defaultProps = {
    match: {},
    settings: {},
};

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
    const { getMarketHistory } = accountActionCreators;

    return bindActionCreators(
        {
            getMarketHistory,
        },
        dispatch,
    );
};

export default compose(
    withRouter,
    connectAccount(mapStateToProps, mapDispatchToProps),
)(MarketDetails);
