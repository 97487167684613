import SVG from 'react-inlinesvg';
import BigNumber from 'bignumber.js';
import { useActiveWeb3React } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { restService } from 'utilities';
import { getFormatStringFromBignumber } from 'utilities/common';
import ExternalLinkIcon from '../../assets/icons/externalLink.svg';
import { getEtherscanLink } from 'utils';

const StyedContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;

    > .gradient-border-tb {
        height: 100%;

        > div {
            height: 100%;
        }
    }

    .title {
        font-size: 36px;
        font-family: LibreFranklin_Regular;
        font-weight: 600;
    }

    .divider {
        margin: 20px -20px;
        border-bottom: 1px solid #ffffff23;
    }
    .activity {
        .divider1 {
            margin: 20px -20px 0;
            border-bottom: 1px solid #ffffff23;
        }
        .divider2 {
            margin: 0px -20px 20px;
            border-bottom: 1px solid #ffffff23;
        }
        .event-log-header {
            display: flex;
            font-size: 12px;
            font-weight: 600;
            color: rgb(116, 128, 136);
            padding: 8px 0px;
            .time {
                flex-basis: 60%;
            }
            .log {
                flex-basis: 30%;
            }
            svg.link {
                width: 14px;
                cursor: pointer;
            }
        }
        .history {
            height: 540px;
            overflow: auto;
            @media only screen and (max-width: 768px) {
                height: 200px;
            }
        }
    }

    .protocol {
        .item-container {
            display: flex;
            flex-direction: column;
            gap: 5px;
            .item {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                color: rgb(116, 128, 136);
                .item-header {
                }
                .item-content {
                    font-weight: 600;
                }
            }
        }
    }
`;

function LastOverview({
    chefBulBalance,
    rewardsPerSecond,
    bulPrice,
    dLpPrice,
}) {
    const { t } = useTranslation();
    const { account, requiredChainId, isActive } = useActiveWeb3React();

    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (account && requiredChainId && isActive) {
                const apiRequest = await restService({
                    chainId: requiredChainId,
                    api: `/v1/radiant/getHistory?limit=${1000}&offset=${0}&address=${account}`,
                    method: 'GET',
                });

                if (apiRequest?.data?.data) {
                    setLogs(apiRequest.data.data.rows || []);
                } else {
                    setLogs([]);
                }
            } else {
                setLogs([]);
            }
        };

        fetchData();
        let updateTimer = setInterval(() => {
            fetchData();
        }, 10000);
        return () => {
            if (updateTimer) {
                clearInterval(updateTimer);
            }
        };
    }, [account, requiredChainId]);

    return (
        <StyedContainer>
            <div className="gradient-border-tb">
                <div className="container protocol">
                    <div className="title">{t('Protocol_Stats')}</div>
                    <div className="gradient-divider" />
                    <div className="item-container">
                        <div className="item">
                            <div className="item-header">
                                {t('Rewards_Reserve')}:
                            </div>
                            <div className="item-content">
                                {getFormatStringFromBignumber(
                                    chefBulBalance.div(1e18),
                                )}{' '}
                                BUL
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-header">
                                {t('Reward_Rate')}:
                            </div>
                            <div className="item-content">
                                {getFormatStringFromBignumber(
                                    rewardsPerSecond.div(1e18),
                                )}
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-header">
                                {t('BUL_Price')}:
                            </div>
                            <div className="item-content">
                                $
                                {getFormatStringFromBignumber(
                                    new BigNumber(bulPrice),
                                    3,
                                )}
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-header">{t('dLP_Price')}:</div>
                            <div className="item-content">
                                ${getFormatStringFromBignumber(dLpPrice, 3)}
                            </div>
                        </div>
                    </div>
                    <div className="gradient-border-tb mt-8">
                        <div className="container activity">
                            <div className="sub-title">{t('Activity_log')}</div>
                            <div className="divider1" />
                            <div className="event-log-header">
                                <div className="time">{t('Date')}</div>
                                <div className="log">{t('Event')}</div>
                            </div>
                            <div className="divider2" />
                            <div className="history">
                                {logs.map((log, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="event-log-header"
                                        >
                                            <div className="time">
                                                {new Date(
                                                    log.blockTimestamp * 1000,
                                                ).toLocaleString()}
                                            </div>
                                            <div className="log">
                                                {log.action}
                                            </div>
                                            <div
                                                onClick={() => {
                                                    window.open(
                                                        getEtherscanLink(
                                                            requiredChainId,
                                                            log.txHash,
                                                            'transaction',
                                                        ),
                                                        '_blank',
                                                    );
                                                }}
                                            >
                                                <SVG
                                                    src={ExternalLinkIcon}
                                                    className={'link'}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyedContainer>
    );
}

export default LastOverview;
