import { getNativeToken } from 'utils';
import * as constants from './constants';
import {
  getContract,
} from './ContractService';

export const sendSupply = (web3, from, amount, chainId, callback) => {
  const address = constants.CONTRACT_BBEP_ADDRESS[chainId][getNativeToken(chainId).toLowerCase()].address

  const contract = getContract(web3, chainId, constants.CONTRACT_BETH_ABI, address);
  const contractData = contract.methods.mint().encodeABI();

  const tx = {
    from,
    to: address,
    value: amount,
    data: contractData
  };
  // Send transaction
  web3.eth.sendTransaction(tx)
    .on('receipt', () => {
      callback();
    })
    .on('error', () => {
      callback();
    })
};

export const sendRepay = (web3, from, amount, chainId, callback) => {
  const address = constants.CONTRACT_BBEP_ADDRESS[chainId][getNativeToken(chainId).toLowerCase()].address

  const contract = getContract(web3, chainId, constants.CONTRACT_BETH_ABI, address);
  const contractData = contract.methods.repayBorrow().encodeABI();

  const tx = {
    from,
    to: address,
    value: amount,
    data: contractData
  };
  // Send transaction
  web3.eth.sendTransaction(tx)
    .on('receipt', () => {
      callback();
    })
    .on('error', () => {
      callback();
    })
};
