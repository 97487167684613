import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../../assets/icons/closeCirclePrimary.svg'
import { ReactComponent as BackIcon } from '../../../assets/icons/arrow_back.svg'
import { useTranslation } from 'react-i18next';

const ContainerStyles = styled.div`
  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .close-container {
      position: absolute;
      right: 1rem;
      top: 1rem;
      outline: none;
      cursor: pointer;
      background: transparent;
    }
    .back-container {
      position: absolute;
      left: 1rem;
      top: 1rem;
      outline: none;
      cursor: pointer;
      background: transparent;
    }
    svg {
      height: 22px;
      width: auto;
      circle {
        stroke: #fff;
        fill: transparent;
      }
      path {
          fill: #fff;
      }
    }
    .title {
      color: white;
      font-size: 16px;
      font-weight: 600;
    }
  }
`

function ZapModalTitle({ setOpen, step, setStep }) {
  const { t } = useTranslation();

  return (
    <ContainerStyles className="px-5 mt-4">
      <div className='header-container'>
        <div className="close-container" onClick={() => { setOpen(false) }}>
          <CloseIcon fill={'#fff'} />
        </div>
        <div className="back-container" style={step === 0 ? { opacity: 0.2, cursor: 'not-allowed' } : {}}
          onClick={() => {
            if (step > 0) {
              setStep(step - 1)
            }
          }}
        >
          <BackIcon fill={'#fff'} />
        </div>
        <div className={'title'}>{t('Zap_into_dLP')}</div>
      </div>
    </ContainerStyles>
  )
}

export default ZapModalTitle;