import BigNumber from 'bignumber.js';
import { ReactComponent as TwitterSvg } from '../../assets/icons/icon1.svg';

export default function TwitterSection({ tvl, circulatingSupply }) {
    return (
        <div className="gradient-border-tb mb-10">
            <div className="text-white flex flex-col gap-2 sm:flex-row items-center sm:gap-6">
                <div className="flex gap-2 items-center">
                    <TwitterSvg />
                    <p className="text-sm">
                        The first decentralised money market for lenders and borrowers on SEI Blockchain.
                    </p>
                </div>
                <div>
                    <span className="font-LibreFranklinThin">TVL</span>
                    <span className="font-LibreFranklinBold ml-2">
                        $ {new BigNumber(tvl).toFormat(0)}
                    </span>
                </div>
                <div>
                    <span className="font-LibreFranklinThin">
                        BUL Circulating Supply
                    </span>
                    <span className="font-LibreFranklinBold ml-2">
                        {new BigNumber(circulatingSupply).toFormat(0)}
                    </span>
                </div>
            </div>
        </div>
    );
}
