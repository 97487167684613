/* eslint-disable max-len */
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

const VoteHistoryItem = ({ proposal, support, history }) => {
  const { t } = useTranslation();
  const [forPercent, setForPercent] = useState(0);
  const [againstPercent, setAgainstPercent] = useState(0);

  useEffect(() => {
    const total = new BigNumber(proposal.forVotes).plus(
      new BigNumber(proposal.againstVotes),
    );
    setForPercent(
      new BigNumber(proposal.forVotes * 100).div(total).isNaN()
        ? '0'
        : new BigNumber(proposal.forVotes * 100).div(total).toString(),
    );
    setAgainstPercent(
      new BigNumber(proposal.againstVotes * 100).div(total).isNaN()
        ? '0'
        : new BigNumber(proposal.againstVotes * 100).div(total).toString(),
    );
  }, [proposal]);

  const getDescription = (p) => {
    if (p === 'Create Proposal') {
      return t('Create_Proposal');
    }
    return p;
  };

  const getStatus = (p) => {
    if (p.state === 'Executed') {
      return t('Passed');
    }
    if (p.state === 'Active') {
      return t('Active');
    }
    if (p.state === 'Defeated') {
      return t('Failed');
    }
    if (p.state === 'Expired') {
      return t('Expired');
    }
    return p.state;
  };

  const getStatusColor = (p) => {
    if (p.state === 'Executed') {
      return 'lightGreen3';
    }
    if (p.state === 'Active') {
      return 'midBlue';
    }
    if (p.state === 'Defeated') {
      return 'lightRed';
    }
    return 'midBlue';
  };

  return (
    <div onClick={() => history.push(`/vote/proposal/${proposal.id}`)}>
      <div className="flex flex-row space-y-4 md:space-y-0 items-center justify-between pt-8 pb-4">
        <section className="w-full">
          <div className="text-xl font-bold">
            <ReactMarkdown>
              {getDescription(proposal.description.split('\n')[0])}
            </ReactMarkdown>
          </div>

          <article className="w-full flex text-base mt-2.5 justify-between items-center">
            <div className="flex gap-3 items-center">
              <div className="flex justify-center gap-2 items-center">
                <span className="w-2 h-2 bg-paleBlue rounded-full"></span>
                <span className="text-sm text-blue3">
                  {getStatus(proposal)}
                </span>
              </div>

              <span className="text-sm text-gray3">
                {moment(proposal.createdAt).format('MMMM Do, YYYY')}
              </span>

              <div
                className={`border mr-10 text-sm px-4 rounded-lg text-${getStatusColor(
                  proposal,
                )} border-${getStatusColor(proposal)}`}
              >
                {getStatus(proposal)}
              </div>

              <CheckBtn color={support ? '#4FD000' : '#ffffff'} />
            </div>

            <div className="font-bold">{support ? t('For') : t('Against')}</div>
          </article>
        </section>
      </div>
    </div>
  );
};

export default compose(withRouter)(VoteHistoryItem);

const CheckBtn = ({ color = '#4FD000' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M19 3.5H5C3.895 3.5 3 4.395 3 5.5V19.5C3 20.605 3.895 21.5 5 21.5H19C20.105 21.5 21 20.605 21 19.5V5.5C21 4.395 20.105 3.5 19 3.5ZM10.707 17.207C10.316 17.598 9.683 17.598 9.293 17.207L6 13.914C5.61 13.524 5.61 12.89 6 12.5C6.39 12.11 7.024 12.11 7.414 12.5L10 15.086L16.586 8.5C16.976 8.11 17.61 8.11 18 8.5C18.39 8.89 18.39 9.524 18 9.914L10.707 17.207Z"
        fill={color}
      />
    </svg>
  );
};
