import styled from 'styled-components';
import { matchSorter } from 'match-sorter';
import { useTable, useSortBy, useExpanded } from 'react-table';
import React, { Fragment, useEffect, useState } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';

const Styles = styled.div`
    width: 100%;
    background: rgba(255, 255, 255, 0.03);
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
    border-radius: 6px;
    padding: 24px;

    .height-74 {
        height: 74px;
    }
    table {
        width: 100%;
        color: #0b131b;

        tr.custom-border-bottom {
            border-bottom: 1px solid #ececec;
        }
        tr {
            border-bottom: 1px solid #ececec;
        }

        tr:last-child {
            border-bottom: none;
        }

        th {
            padding: 1rem !important;
            font-size: 1.12rem;
            color: #fff;
            background-color: #172344;
        }

        td {
            padding: 0.8rem 2rem 0.8rem 0;
        }

        th,
        td {
            margin: 0;
            text-align: right;
            padding: 0.8rem 1.5rem 0.8rem 0;

            :last-child {
                border-right: 0;
                padding-right: 0;
            }

            :first-child {
                text-align: left;
            }
        }
    }
`;

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({ columns, data, onRowClick }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
                defaultColumn: 'rank',
                autoResetSortBy: false,
            },
            useSortBy,
            useExpanded,
        );

    const [isTableHorizontal, setIsTableHorizontal] = useState(true);
    const { width } = useWindowSize() || {};
    useEffect(() => {
        if (width < 1024) {
            setIsTableHorizontal(false);
        } else {
            setIsTableHorizontal(true);
        }
    }, [width]);

    return (
        <table {...getTableProps()}>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <Fragment key={i}>
                            {isTableHorizontal ? (
                                <tr
                                    {...row.getRowProps()}
                                    onClick={onRowClick.bind(this, row)}
                                    className="cursor-pointer"
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <td
                                                {...cell.getCellProps()}
                                                className=""
                                            >
                                                <div
                                                    className={`height-74 ${
                                                        cell.column.Header ===
                                                        'Rank'
                                                            ? ''
                                                            : cell.column
                                                                  .Header ===
                                                              'Supply'
                                                            ? ''
                                                            : ''
                                                    }`}
                                                >
                                                    {cell.render('Cell')}
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ) : (
                                row.cells.map((cell, index) => {
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            onClick={onRowClick.bind(this, row)}
                                            key={index}
                                            className={
                                                index === row.cells.length - 1
                                                    ? 'custom-border-bottom'
                                                    : ''
                                            }
                                        >
                                            {cell.column.Header !== '' && (
                                                <td className="padding-2rem text-white">
                                                    {typeof cell.column
                                                        .Header === 'string'
                                                        ? cell.column.Header
                                                        : cell.column.Header()}
                                                </td>
                                            )}
                                            <td
                                                className="padding-2rem"
                                                colSpan={
                                                    cell.column.Header !== ''
                                                        ? 1
                                                        : 2
                                                }
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </Fragment>
                    );
                })}
            </tbody>
        </table>
    );
}

function Application({ columns, data, onRowClick }) {
    return (
        <Styles>
            <Table
                columns={columns}
                data={data}
                onRowClick={onRowClick}
                hideHeader={true}
            />
        </Styles>
    );
}

const App = React.memo(Application);

export default App;
