export default {
  app: '/app',
  stake: '/stake',
  market: {
    index: '/market',
    marketDetails: '/market/:asset',
  },
  referral: '/referral',
  belugas: '/bul',
  farms: '/farms',
  history: '/history',
  liquidate: '/liquidate',
  pools: '/pools',
  trade: '/trade',
  vote: {
    index: '/vote',
    allProposals: '/vote/all',
    voteOverview: `/vote/proposal/:id`,
    proposerOverview: '/vote/address/:address',
    leaderboard: '/vote/leaderboard',
  },
  faucet: '/faucet',
};
