import { useTranslation } from 'react-i18next';
import { getFormatStringFromBignumber } from '../../utilities/common';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Progress from './Progress';
import AccountSummary from 'components/common/AccountSummary';
import AlertHelper from 'components/common/AlertHelper';
import { useEligibilityDataProviderInfo } from 'hooks/useLpStaking';
import BigNumber from 'bignumber.js';

const Wrapper = styled.div`
    .second-summary {
        margin-bottom: 75px;
        margin-top: 60px;
        border-radius: 8px;
        display: flex;
        @media only screen and (max-width: 768px) {
            display: block;
        }
        .emission-container {
            border-radius: 8px;
            display: flex;

            .title {
                color: rgb(225, 0, 0);
            }
            .title-active {
                color: rgb(0, 255, 170);
                margin-top: 4px;
                text-shadow: rgb(0, 255, 170) 0px 0px 20px;
            }
            .desc {
                font-size: 12px;
                margin-top: 12px;
                font-weight: 300;
                .value1 {
                    color: #ecb60b;
                    font-weight: 600;
                }
            }
            .value-container {
                margin-top: 10px;
                width: 100%;
                max-width: 450px;
                .value-desc {
                    font-size: 12px;
                }
                .value-content {
                    font-size: 20px;
                    font-weight: 700;
                    font-weight: 600;
                    margin-top: 32px;
                    span {
                        font-size: 16px;
                        color: rgba(255, 255, 255, 0.6);
                    }
                }
                .progress-bar {
                  margin-top: 10px;
                  position: relative;
                  width: 300px;
                  height: 8px;
                  border-radius: 4px;
                  background-color: rgba(0, 0, 0, 0.05);
                  @media only screen and (max-width: 768px) {
                    width: 150px;
                  }
                  .progress-line {
                    position: absolute;
                    border-right: 2px solid rgb(95, 0, 250);
                    height: 16px;
                    width: 25%;
                    bottom: -4px;
                  }
                  .progress-active-bar {
                    height: 8px;
                    background-color: rgb(95, 0, 250);
                    border-radius: 4px;
                  }
                }
            }
        }
        .earn-container {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            color: black;
            border-radius: 0px 8px 8px 0px;
            width: 50%;
            @media only screen and (max-width: 768px) {
                width: 100%;
                border-radius: 0px 0px 8px 8px;
                padding-top: 20px;
                padding-bottom: 10px;
            }
        }
    }
`;

const AccountOverview = ({
    borrowPercent,
    earnedBalance,
    withBUL,
    setwithBUL,
    netAPY,
    settings,
    hideApy = false,
}) => {
    const { t } = useTranslation();
    const { isEligibleForRewards, lockedUsdValue, requiredUsdValue } =
        useEligibilityDataProviderInfo();
    const [totalSupplyBalance, setTotalSupplyBalance] = useState(0);
    const [totalBorrowBalance, setTotalBorrowBalance] = useState(0);
    const [totalCreditUSD, setTotalCreditUSD] = useState(0);

    const progressWidth = useMemo(() => {
        if (requiredUsdValue.eq(0)) return 0;
        if (lockedUsdValue.gte(requiredUsdValue.times(4))) return 100;
        return parseInt(
            lockedUsdValue.times(100).div(requiredUsdValue.times(4)).toString(),
        );
    }, [lockedUsdValue, requiredUsdValue]);

    const awayProgress = useMemo(() => {
        if (requiredUsdValue.eq(0)) return new BigNumber(100);
        if (lockedUsdValue.gte(requiredUsdValue.times(4)))
            return new BigNumber(0);
        return new BigNumber(100).minus(
            lockedUsdValue.div(requiredUsdValue).times(100),
        );
    }, [lockedUsdValue, requiredUsdValue]);

    useEffect(() => {
        setTotalCreditUSD(settings.totalAvailableCredit);
    }, [settings.totalAvailableCredit]);

    useEffect(() => {
        setTotalSupplyBalance(settings.totalSupplyBalance);
    }, [settings.totalSupplyBalance]);

    useEffect(() => {
        setTotalBorrowBalance(settings.totalBorrowBalance);
    }, [settings.totalBorrowBalance]);

    return (
        <>
            <Wrapper className="accountOverview text-white mt-8">
                <AccountSummary
                    borrowPercent={borrowPercent}
                    totalSupplyBalance={totalSupplyBalance}
                    totalBorrowBalance={totalBorrowBalance}
                    totalCreditUSD={totalCreditUSD}
                />

                {!hideApy && (
                    <section className="second-summary">
                        <article className="emission-container w-full md:w-1/2">
                            <div className="gradient-border w-full">
                                <div className="py-8 px-10 shadow-2xl h-full">
                                    {isEligibleForRewards ? (
                                        <div className="flex justify-center items-center flex-col">
                                            <p className="title title-active">
                                                {t('Emissions_Active')}
                                            </p>
                                            <span className="desc">
                                                {t('Emissions_Active_Desc')}
                                            </span>
                                        </div>
                                    ) : (
                                        <div>
                                            <p className="text-2xl font-medium">
                                                <span>Emissions</span>{' '}
                                                <span className="text-paleBlue">
                                                    Inactive
                                                </span>
                                            </p>
                                            <div className="desc">
                                                {t('Emissions_Inactive_Desc_1')}
                                                <span className="value1">
                                                    {' '}
                                                    {getFormatStringFromBignumber(
                                                        awayProgress,
                                                        0,
                                                    )}
                                                    %{' '}
                                                </span>
                                                {t('Emissions_Inactive_Desc_2')}
                                            </div>
                                        </div>
                                    )}

                                    <div className="value-container">
                                        <p className="value-desc">
                                            {t('dLP_required_to')}
                                        </p>
                                        <p className="value-content text-white">
                                            <span
                                                className="text-[20px] font-bold"
                                                style={{ fontSize: 20 }}
                                            >
                                                $
                                                {getFormatStringFromBignumber(
                                                    lockedUsdValue.div(1e8),
                                                    2,
                                                    2,
                                                )}
                                                /
                                            </span>

                                            <span
                                                className="text-[12px] font-normal"
                                                style={{ fontSize: 12 }}
                                            >
                                                $
                                                {getFormatStringFromBignumber(
                                                    requiredUsdValue.div(1e8),
                                                    2,
                                                    2,
                                                )}
                                            </span>
                                        </p>

                                        <div className="progress-bar">
                                            <div className="progress-line" />
                                            <div
                                                className="progress-active-bar"
                                                style={{ width: `${progressWidth}%` }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>

                        <article className="py-10 px-10 blue-purple-gradient w-full md:w-1/2 rounded-lg">
                            <div className="flex justify-between">
                                <div className="flex flex-col justify-start">
                                    <div className="flex items-center justify-start mb-10">
                                        <span className="text-xl font-bold mt-2">
                                            {t('My_Earned')}
                                        </span>
                                        {/* <AlertHelper
                                            color="#ffffff"
                                            text={t('My_Earned_DESC')}
                                        /> */}
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <span className="font-normal">
                                            {earnedBalance.isZero()
                                                ? '0.00'
                                                : getFormatStringFromBignumber(
                                                    earnedBalance.dividedBy(
                                                        1e18,
                                                    ),
                                                )}
                                            {' BUL'}
                                        </span>
                                        <AlertHelper
                                            color="#ffffff"
                                            text={t('Collect_BUL_desc')}
                                        />
                                    </div>
                                </div>
                                <Progress
                                    percent={Number(netAPY || 100)}
                                    withBUL={withBUL}
                                    setwithBUL={setwithBUL}
                                />
                            </div>
                        </article>
                    </section>
                )}
            </Wrapper>
        </>
    );
};

export default AccountOverview;
