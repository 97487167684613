import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { accountActionCreators, connectAccount } from '../../core';
import React, { useCallback, useEffect, useState } from 'react';
import {
    getTokenContract,
    getVoteContract,
    methods,
} from '../../utilities/ContractService';
import Web3 from 'web3';
import { promisify } from '../../utilities';
import moment from 'moment';
import toast from '../../components/UI/Toast';
import Layout from '../../layouts/MainLayout/MainLayout';
import ProposalInfo from '../../components/vote/VoteOverview/ProposalInfo';
import Loading from '../../components/UI/Loading';
import BigNumber from 'bignumber.js';
import VoteCard from '../../components/vote/VoteOverview/VoteCard';
import ProposalDetails from '../../components/vote/VoteOverview/ProposalDetails';
import ProposalHistory from '../../components/vote/VoteOverview/ProposalHistory';
import { useActiveWeb3React, useWeb3 } from '../../hooks';
import Footer from 'layouts/MainLayout/Footer';

const ContainerStyles = styled.div``;

const Styles = styled.div`
    .tooltip {
        margin-bottom: 5px;
        .label {
            display: none;
            position: absolute;
            bottom: 115%;
            left: 0;
            color: #e2e2e2;
            font-size: 14px;
            font-weight: 400;
            max-width: 270px;
            width: 14rem;
            text-align: center;
            background: rgba(0, 2, 32, 0.7);
            backdrop-filter: blur(10px);
            padding: 5px 10px;
            min-height: 50px;
            align-items: center;
            justify-content: center;
            top: auto;
            border-radius: 10px;
            line-height: normal;
            border: none;
            height: auto;
        }
        .tooltip-label:hover + .label {
            display: flex;
        }
    }
`;

const VoteOverview = ({ settings, getVoters, getProposalById, match }) => {
    const { account, requiredChainId } = useActiveWeb3React();
    const web3 = useWeb3();
    const { t } = useTranslation();
    const [proposalInfo, setProposalInfo] = useState({});
    const [agreeVotes, setAgreeVotes] = useState({});
    const [againstVotes, setAgainstVotes] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isCancelLoading, setIsCancelLoading] = useState(false);
    const [status, setStatus] = useState('pending');
    const [cancelStatus, setCancelStatus] = useState('pending');
    const [proposalThreshold, setProposalThreshold] = useState(0);
    const [proposerVotingWeight, setProposerVotingWeight] = useState(0);
    const [isPossibleExecuted, setIsPossibleExecuted] = useState(false);
    const [executeEta, setExecuteEta] = useState('');
    const voteContract = getVoteContract(requiredChainId);

    const updateBalance = useCallback(async () => {
        if (account && proposalInfo.id) {
            const bulTokenContract = getTokenContract(
                web3,
                'bul',
                requiredChainId,
            );
            await methods
                .call(voteContract.methods.proposalThreshold, [])
                .then((res) => {
                    setProposalThreshold(+Web3.utils.fromWei(res, 'ether'));
                });
            await methods
                .call(bulTokenContract.methods.getCurrentVotes, [
                    proposalInfo.proposer,
                ])
                .then((res) => {
                    setProposerVotingWeight(+Web3.utils.fromWei(res, 'ether'));
                });
        }
    }, [account, proposalInfo]);

    useEffect(() => {
        if (account) {
            updateBalance();
        }
    }, [account, updateBalance]);

    useEffect(() => {
        setProposalInfo({
            id: 1,
            description: 'Just Abstain, Courteously',
            state: 'Queued',
            createdAt: '2024-02-11',
            queuedTimestamp: 1707639356,
            actions: [
                { title: '_setImplementation(address,bool,bytes)' },
                { title: '_setImplementation(address,bool,bytes)' },
                { title: '_setImplementation(address,bool,bytes)' },
                { title: '_setImplementation(address,bool,bytes)' },
            ],
            // cancelTimestamp: 1707639356,
            // startTimestamp: 1707639356,
        });
        // if (match.params && match.params.id) {
        //     promisify(getProposalById, {
        //         id: match.params.id,
        //     }).then((res) => {
        //         setProposalInfo({
        //             id: 1,
        //             description: 'Just Abstain, Courteously',
        //             state: 'Queued',
        //             createdAt: '2024-01-22',
        //         });
        //         setProposalInfo(res.data);
        //     });
        // }
    }, [match, getProposalById]);

    const loadVotes = useCallback(
        async (limit) => {
            if (proposalInfo.id) {
                await promisify(getVoters, {
                    id: proposalInfo.id,
                    limit,
                    filter: 'for',
                })
                    .then((res) => setAgreeVotes(res.data || {}))
                    .catch(() => {
                        setAgreeVotes({});
                    });
                await promisify(getVoters, {
                    id: proposalInfo.id,
                    limit,
                    filter: 'against',
                })
                    .then((res) => setAgainstVotes(res.data || {}))
                    .catch(() => {
                        setAgainstVotes({});
                    });
            }
        },
        [getVoters, proposalInfo],
    );

    const getIsPossibleExecuted = () => {
        methods
            .call(voteContract.methods.proposals, [proposalInfo.id])
            .then((res) => {
                setIsPossibleExecuted(res && res.eta <= Date.now() / 1000);
                setExecuteEta(moment(res.eta * 1000).format('LLLL'));
            });
    };

    useEffect(() => {
        loadVotes(4);
    }, [loadVotes]);

    useEffect(() => {
        if (proposalInfo.id) {
            getIsPossibleExecuted();
        }
    }, [proposalInfo]);

    const loadMore = (type) => {
        if (type === 'for' && agreeVotes.total) {
            promisify(getVoters, {
                id: proposalInfo.id,
                limit: agreeVotes.total,
                filter: 'for',
            })
                .then((res) => setAgreeVotes(res.data || {}))
                .catch(() => {
                    setAgreeVotes({});
                });
        } else if (againstVotes.total) {
            promisify(getVoters, {
                id: proposalInfo.id,
                limit: againstVotes.total,
                filter: 'against',
            })
                .then((res) => setAgainstVotes(res.data || {}))
                .catch(() => {
                    setAgainstVotes({});
                });
        }
    };

    const handleUpdateProposal = (statusType) => {
        if (statusType === 'Queue') {
            setIsLoading(true);
            methods
                .send(voteContract.methods.queue, [proposalInfo.id], account)
                .then(() => {
                    setIsLoading(false);
                    setStatus('success');
                    toast.success({
                        title: t(`Proposal_list_updated_message`),
                    });
                })
                .catch(() => {
                    setIsLoading(false);
                    setStatus('failure');
                });
        } else if (statusType === 'Execute') {
            setIsLoading(true);
            methods
                .send(voteContract.methods.execute, [proposalInfo.id], account)
                .then(() => {
                    setIsLoading(false);
                    setStatus('success');
                    toast.success({
                        title: t(`Proposal_list_updated_message`),
                    });
                })
                .catch(() => {
                    setIsLoading(false);
                    setStatus('failure');
                });
        } else if (statusType === 'Cancel') {
            setIsCancelLoading(true);
            methods
                .send(voteContract.methods.cancel, [proposalInfo.id], account)
                .then(() => {
                    setIsCancelLoading(false);
                    setCancelStatus('success');
                    toast.success({
                        title: t('proposal_cancel_message'),
                    });
                })
                .catch(() => {
                    setIsCancelLoading(false);
                    setCancelStatus('failure');
                });
        }
    };

    return (
        <div className="voteOverview flex flex-col">
            <Layout title={t('Vote')} background="dashboard">
                <ContainerStyles className="text-white flex flex-col justify-between items-center grow overflow-auto">
                    <div className="max-w-7xl w-full px-8 py-4">
                        <div className="flex flex-col lg:flex-row justify-between items-center">
                            <ProposalInfo proposalInfo={proposalInfo} />
                            {proposalInfo.state !== 'Executed' &&
                                proposalInfo.state !== 'Defeated' &&
                                proposalInfo.state !== 'Canceled' && (
                                    <div className="mt-6 flex justify-center md:justify-end md:mt-0 space-x-4">
                                        {proposalInfo.state === 'Succeeded' && (
                                            <button
                                                className="focus:outline-none bg-midBlue text-white py-2 px-8 rounded-xl text-xl flex items-center justify-center"
                                                disabled={
                                                    isLoading ||
                                                    status === 'success'
                                                }
                                                onClick={() =>
                                                    handleUpdateProposal(
                                                        'Queue',
                                                    )
                                                }
                                            >
                                                {isLoading && (
                                                    <Loading
                                                        size={'18px'}
                                                        margin={'8px'}
                                                    />
                                                )}
                                                {status === 'pending' ||
                                                status === 'failure'
                                                    ? t('Queue')
                                                    : t('Queued')}
                                            </button>
                                        )}
                                        {proposalInfo.state === 'Queued' && (
                                            <Styles>
                                                <div className="tooltip relative">
                                                    <div className=" text-xl flex items-center">
                                                        <button
                                                            className={`tooltip-label :outline-none 
                                                            bg-blue-gradient py-4 px-8 rounded-xl flex items-center justify-center text-base font-medium`}
                                                            disabled={
                                                                isLoading ||
                                                                status ===
                                                                    'success' ||
                                                                !isPossibleExecuted
                                                            }
                                                            onClick={() =>
                                                                handleUpdateProposal(
                                                                    'Execute',
                                                                )
                                                            }
                                                        >
                                                            {isLoading && (
                                                                <Loading
                                                                    size={
                                                                        '16px'
                                                                    }
                                                                    margin={
                                                                        '8px'
                                                                    }
                                                                />
                                                            )}
                                                            {status ===
                                                                'pending' ||
                                                            status === 'failure'
                                                                ? t('Execute')
                                                                : t('Executed')}
                                                        </button>
                                                        {!isPossibleExecuted && (
                                                            <span className="label">
                                                                Executable Date:{' '}
                                                                {moment(
                                                                    proposalInfo?.eta *
                                                                        1000,
                                                                ).format(
                                                                    'LLLL',
                                                                )}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </Styles>
                                        )}

                                        <button
                                            className="focus:outline-none bg-transparent py-4 px-8 rounded-xl flex items-center justify-center border-btn-1-gradient text-base font-medium"
                                            disabled={
                                                isCancelLoading ||
                                                proposerVotingWeight >=
                                                    proposalThreshold ||
                                                cancelStatus === 'success'
                                            }
                                            onClick={() =>
                                                handleUpdateProposal('Cancel')
                                            }
                                        >
                                            {isCancelLoading && (
                                                <Loading
                                                    size={'18px'}
                                                    margin={'8px'}
                                                />
                                            )}
                                            {cancelStatus === 'pending' ||
                                            cancelStatus === 'failure'
                                                ? t('Cancel')
                                                : t('Cancelled')}
                                        </button>
                                    </div>
                                )}
                        </div>
                        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 mt-12">
                            <VoteCard
                                label={t('For')}
                                forNumber={
                                    new BigNumber(agreeVotes.sumVotes).isNaN()
                                        ? '0'
                                        : agreeVotes.sumVotes
                                }
                                againstNumber={
                                    new BigNumber(againstVotes.sumVotes).isNaN()
                                        ? '0'
                                        : againstVotes.sumVotes
                                }
                                type="agree"
                                addressNumber={
                                    new BigNumber(agreeVotes.total).isNaN()
                                        ? 0
                                        : agreeVotes.total
                                }
                                emptyNumber={
                                    4 -
                                    (new BigNumber(agreeVotes.total).isNaN()
                                        ? 0
                                        : agreeVotes.total)
                                }
                                list={
                                    // agreeVotes.result &&
                                    // agreeVotes.result.map((v) => ({
                                    //     label: v.address,
                                    //     value: v.votes,
                                    // }))
                                    [
                                        {
                                            label: '0x1ca3ac3686071be692be7f1fbecd668641476d7e',
                                            value: '11111100110011',
                                        },
                                        {
                                            label: '0x1ca3ac3686071be692be7f1fbecd668641422222',
                                            value: '1111122213311001101111011',
                                        },
                                        {
                                            label: '0x1ca3ac3686071be692be7f1fbecd668641433333',
                                            value: '11113333333100110011',
                                        },
                                        {
                                            label: '0x1ca3ac3686071be692be7f1fbecd668641444444',
                                            value: '113331111110110011',
                                        },
                                    ]
                                }
                                onViewAll={() => loadMore('for')}
                            />

                            <VoteCard
                                label={t('Against')}
                                forNumber={
                                    new BigNumber(agreeVotes.sumVotes).isNaN()
                                        ? '0'
                                        : agreeVotes.sumVotes
                                }
                                againstNumber={
                                    new BigNumber(againstVotes.sumVotes).isNaN()
                                        ? '0'
                                        : againstVotes.sumVotes
                                }
                                type="against"
                                addressNumber={
                                    new BigNumber(againstVotes.total).isNaN()
                                        ? 0
                                        : againstVotes.total
                                }
                                emptyNumber={
                                    4 -
                                    (new BigNumber(againstVotes.total).isNaN()
                                        ? 0
                                        : againstVotes.total)
                                }
                                list={
                                    againstVotes.result &&
                                    againstVotes.result.map((v) => ({
                                        label: v.address,
                                        value: v.votes,
                                    }))
                                }
                                onViewAll={() => loadMore('against')}
                            />
                        </div>

                        <div className="flex md:justify-between md:flex-row md:items-start gap-6 flex-col items-center justify-center mt-20">
                            <ProposalDetails proposalInfo={proposalInfo} />

                            <ProposalHistory proposalInfo={proposalInfo} />
                        </div>
                    </div>
                </ContainerStyles>
            </Layout>
        </div>
    );
};

VoteOverview.defaultProps = {
    match: {},
    settings: {},
};

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
    const { getProposalById, getVoters } = accountActionCreators;

    return bindActionCreators(
        {
            getProposalById,
            getVoters,
        },
        dispatch,
    );
};

export default compose(
    withRouter,
    connectAccount(mapStateToProps, mapDispatchToProps),
)(VoteOverview);
