/* eslint-disable max-len */
export const TickRedIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            fill="none"
            viewBox="0 0 24 25"
        >
            <path
                fill="#FF4C4B"
                fillRule="evenodd"
                d="M5 3.563h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2v-14a2 2 0 012-2zm6.852 10.656l3.138 3.316 1.046-1.105-3.138-3.316 3.138-3.317-1.046-1.106-3.138 3.317-3.138-3.317-1.046 1.106 3.138 3.316-3.138 3.317 1.046 1.105 3.138-3.316z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};
