import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainLayout from '../layouts/MainLayout/MainLayout.jsx';
import DataTable from '../components/common/DataTable.js';
import Switch from '../components/UI/Switch.js';
import { fillArray } from '../utils/index.js';
import SupplyWithdrawModal from '../components/common/SupplyWithdrawModal.js';
import BorrowRepayModal from '../components/common/BorrowRepayModal.js';
import ConfirmTransactionModal from '../components/common/ConfirmTransactionModal.js';
import { connectAccount, accountActionCreators } from '../core/index.js';
import { bindActionCreators } from 'redux';
import BigNumber from 'bignumber.js';
import {
  getComptrollerContract,
  methods,
} from '../utilities/ContractService.js';
import * as constants from '../utilities/constants.js';
import { useActiveWeb3React, useWeb3 } from '../hooks/index.js';
import {
  getBigNumber,
  getFormatStringFromBignumber,
} from '../utilities/common.js';
import arrowUp from '../assets/icons/arrowUp.png';
import arrowDown from '../assets/icons/arrowDown.png';
import PendingTransaction from '../components/Dashboard/PendingTransaction.js';
import toast from '../components/UI/Toast.js';
import AccountOverview from '../components/Dashboard/AccountOverview.js';
import styled from 'styled-components';
import { useChefIncentivesInfo } from 'hooks/useLpStaking';

const Style = styled.div`
  @media (max-width: 650px) {
    .switch {
      > div {
        margin-right: 12px;
      }
    }
  }
`;
function Dashboard({ settings }) {
  const { t, i18n } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();
  const web3 = useWeb3();
  const { allPendingRewards } = useChefIncentivesInfo();

  // Borrow Limits
  const [borrowPercent, setBorrowPercent] = useState('0');
  const [tvl, setTvl] = useState(0);

  useEffect(() => {
    if (account) {
      const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
      const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
      const total = BigNumber.maximum(totalBorrowLimit, 0);
      setBorrowPercent(
        total.isZero() || total.isNaN()
          ? 0
          : totalBorrowBalance.div(total).times(100).dp(0, 1).toString(10),
      );
    }
    setTvl(settings.totalLiquidity);
  }, [settings.totalBorrowBalance, settings.totalBorrowLimit, account]);

  // Rewards
  const [earnedBalance, setEarnedBalance] = useState(new BigNumber(0));

  const getVoteInfo = async () => {
    const myAddress = account;
    const appContractCallContext = [
      {
        reference: 'appContract',
        contractAddress:
          constants.CONTRACT_COMPTROLLER_ADDRESS[requiredChainId],
        abi: constants.CONTRACT_COMPTROLLER_ABI,
        calls: [
          {
            reference: 'bulInitialIndex',
            methodName: 'bulInitialIndex',
            methodParameters: [],
          },
          {
            reference: 'bulAccrued',
            methodName: 'bulAccrued',
            methodParameters: [myAddress],
          },
        ],
        context: {},
      },
    ];
    const appContractResults = await methods.ethMulticall(
      web3,
      appContractCallContext,
      requiredChainId,
    );
    let bulInitialIndex = new BigNumber(0);
    let bulAccrued = new BigNumber(0);
    appContractResults.results.appContract.callsReturnContext.forEach(
      (result) => {
        if (result.methodName === 'bulInitialIndex') {
          bulInitialIndex = new BigNumber(result.returnValues[0].hex);
        } else if (result.methodName === 'bulAccrued') {
          bulAccrued = new BigNumber(result.returnValues[0].hex);
        }
      },
    );

    let belugasEarned = new BigNumber(0);
    let assetValues = {};
    const earnedAssetList = settings.assetList.filter(
      (asset) =>
        new BigNumber(asset.supplyBalance).isGreaterThan(0) ||
        new BigNumber(asset.borrowBalance).isGreaterThan(0),
    );

    if (earnedAssetList.length > 0) {
      try {
        const contractCallContext = [
          {
            reference: 'appContract',
            contractAddress:
              constants.CONTRACT_COMPTROLLER_ADDRESS[requiredChainId],
            abi: constants.CONTRACT_COMPTROLLER_ABI,
            calls: [],
            context: {},
          },
        ];
        earnedAssetList.forEach((asset) => {
          contractCallContext[0].calls.push({
            reference: `${asset.id}-bulSupplierIndex`,
            methodName: 'bulSupplierIndex',
            methodParameters: [asset.btokenAddress, myAddress],
          });
          contractCallContext[0].calls.push({
            reference: `${asset.id}-bulBorrowerIndex`,
            methodName: 'bulBorrowerIndex',
            methodParameters: [asset.btokenAddress, myAddress],
          });
        });
        const contractCallResults = await methods.ethMulticall(
          web3,
          contractCallContext,
          requiredChainId,
        );

        for (const value of contractCallResults?.results.appContract
          .callsReturnContext) {
          const references = value.reference.split('-');
          assetValues[references[0]] = assetValues[references[0]] || {};
          assetValues[references[0]][references[1]] = new BigNumber(
            value.returnValues[0].hex,
          );
        }
      } catch (err) {
        console.log(err);
      }

      earnedAssetList.forEach((asset) => {
        const supplyIndex = new BigNumber(asset.bulSupplyIndex);
        let supplierIndex =
          assetValues[asset.id]?.bulSupplierIndex || new BigNumber(0);
        const supplierTokens = asset.bTokenBalance;
        const initBorrowIndex = asset.bulBorrowIndex;
        const borrowerIndex = assetValues[asset.id]?.bulBorrowerIndex || 0;
        const borrowBalanceStored = asset.borrowBalanceStored;
        const borrowIndex = asset.borrowIndex;

        if (supplierIndex.isZero(0) && supplyIndex.isGreaterThan(0)) {
          supplierIndex = bulInitialIndex;
        }
        let deltaIndex = supplyIndex.minus(supplierIndex);
        const supplierDelta = new BigNumber(supplierTokens)
          .multipliedBy(deltaIndex)
          .dividedBy(1e36);

        belugasEarned = belugasEarned.plus(supplierDelta);
        if (+borrowerIndex > 0) {
          deltaIndex = new BigNumber(initBorrowIndex).minus(borrowerIndex);
          const borrowerAmount = new BigNumber(borrowBalanceStored)
            .multipliedBy(1e18)
            .dividedBy(borrowIndex);
          const borrowerDelta = borrowerAmount
            .times(deltaIndex)
            .dividedBy(1e36);
          belugasEarned = belugasEarned.plus(borrowerDelta);
        }
      });

      belugasEarned = belugasEarned.plus(bulAccrued).plus(allPendingRewards);
      setEarnedBalance(belugasEarned);
    } else {
      setEarnedBalance(new BigNumber(0));
    }
  };

  useEffect(() => {
    if (account && settings?.assetList?.length > 0) {
      getVoteInfo();
    } else {
      setEarnedBalance(new BigNumber(0));
    }
  }, [settings.assetList, account]);

  // Wallet balance
  const [netAPY, setNetAPY] = useState('0');
  const [withBUL, setwithBUL] = useState(true);
  // Markets
  const [suppliedAssets, setSuppliedAssets] = useState([]);
  const [nonSuppliedAssets, setNonSuppliedAssets] = useState([]);
  const [borrowedAssets, setBorrowedAssets] = useState([]);
  const [nonBorrowedAssets, setNonBorrowedAssets] = useState([]);

  const [isOpenCollateralConfirm, setIsCollateralConfirm] = useState(false);
  const [supplyRecord, setSupplyRecord] = useState({});
  const [isCollateralEnable, setIsCollateralEnable] = useState(true);
  const [collateralToken, setCollateralToken] = useState({});

  const [pendingTx, setPendingTx] = useState(false);

  const [borrowRecord, setBorrowRecord] = useState({});

  const [supplyWithdrawOpen, setSupplyWithdrawOpen] = useState(false);
  const [borrowRepayOpen, setBorrowRepayOpen] = useState(false);

  const handleToggleCollateral = async (r) => {
    const appContract = getComptrollerContract(web3, requiredChainId);
    if (r && account && r.borrowBalance.isZero()) {
      setPendingTx(true);
      setIsCollateralConfirm(true);
      setCollateralToken(r);
      if (!r.collateral) {
        setIsCollateralEnable(false);
        await methods
          .send(appContract.methods.enterMarkets, [[r.btokenAddress]], account)
          .then(() => { })
          .catch((e) => {
            console.log(e);
          });
      } else if (
        +r.hypotheticalLiquidity['1'] > 0 ||
        +r.hypotheticalLiquidity['2'] === 0
      ) {
        setIsCollateralEnable(true);
        await methods
          .send(appContract.methods.exitMarket, [r.btokenAddress], account)
          .then(() => { })
          .catch(() => {
            console.log('transaction failed');
          });
      } else {
        toast.error({
          title: t(`Collateral_Required`),
          description: t(`Collateral_Required_Desc`),
        });
      }
      setIsCollateralConfirm(false);
      setCollateralToken({});
      setPendingTx(false);
    } else {
      toast.error({
        title: t(`Collateral_Required`),
        description: t(`Collateral_Required_Desc`),
      });
    }
  };

  const updateNetAPY = useCallback(async () => {
    let totalSum = new BigNumber(0);
    let totalSupplied = new BigNumber(0);
    let totalBorrowed = new BigNumber(0);
    const { assetList } = settings;
    assetList.forEach((asset) => {
      const {
        supplyBalance,
        borrowBalance,
        tokenPrice,
        supplyApy,
        borrowApy,
        supplyAdditionalApy,
        borrowAdditionalApy,
      } = asset;
      const supplyBalanceUSD = getBigNumber(supplyBalance).times(
        getBigNumber(tokenPrice),
      );
      const borrowBalanceUSD = getBigNumber(borrowBalance).times(
        getBigNumber(tokenPrice),
      );
      totalSupplied = totalSupplied.plus(supplyBalanceUSD);
      totalBorrowed = totalSupplied.plus(borrowBalanceUSD);

      const supplyApywithBUL = withBUL
        ? getBigNumber(supplyApy).plus(getBigNumber(supplyAdditionalApy))
        : getBigNumber(supplyApy);
      const borrowApywithBUL = withBUL
        ? getBigNumber(borrowAdditionalApy).minus(getBigNumber(borrowApy))
        : getBigNumber(borrowApy).times(-1);

      // const supplyApywithBUL = getBigNumber(supplyApy);
      // const borrowApywithBUL = getBigNumber(borrowApy).times(-1);
      let supplyAssetApy = supplyBalanceUSD.times(supplyApywithBUL.div(100));
      let borrowAssetApy = borrowBalanceUSD.times(borrowApywithBUL.div(100));
      supplyAssetApy = supplyAssetApy.isNaN()
        ? new BigNumber(0)
        : supplyAssetApy;
      borrowAssetApy = borrowAssetApy.isNaN()
        ? new BigNumber(0)
        : borrowAssetApy;
      const assetAPY = supplyAssetApy.plus(borrowAssetApy);
      totalSum = totalSum.plus(assetAPY.isNaN() ? 0 : assetAPY);
    });

    let apy;

    if (totalSum.isZero()) {
      apy = new BigNumber(0);
    } else if (totalSum.isNaN()) {
      apy = new BigNumber(Infinity);
    } else if (totalSum.isGreaterThan(0)) {
      apy = totalSupplied.isZero() ? 0 : totalSum.div(totalSupplied).times(100);
    } else {
      apy = totalBorrowed.isZero() ? 0 : totalSum.div(totalBorrowed).times(100);
    }

    setNetAPY(apy.dp(2, 1).toString(10));
  }, [settings.assetList, withBUL]);

  const updateMarketTable = async () => {
    const tempArr = [];
    for (let index = 0; index < settings.assetList.length; index++) {
      const item = settings.assetList[index];
      const temp = {
        ...item,
        supplyApy: getBigNumber(item.supplyApy),
        borrowApy: getBigNumber(item.borrowApy),
        supplyAdditionalApy: getBigNumber(item.supplyAdditionalApy),
        borrowAdditionalApy: getBigNumber(item.borrowAdditionalApy),
        walletBalance: getBigNumber(item.walletBalance),
        supplyBalance: getBigNumber(item.supplyBalance),
        bTokenBalance: getBigNumber(item.bTokenBalance),
        borrowBalance: getBigNumber(item.borrowBalance),
        collateralFactor: getBigNumber(item.collateralFactor),
        tokenPrice: getBigNumber(item.tokenPrice),
        liquidity: getBigNumber(item.liquidity),
      };
      tempArr.push(temp);
    }

    const tempSuppliedData = [];
    const tempNonSuppliableData = [];
    const tempBorrowedData = [];
    const tempNonBorrowedData = [];

    tempArr.forEach((element) => {
      if (element.supplyBalance.isZero() || element.supplyBalance.isNaN()) {
        tempNonSuppliableData.push(element);
      } else {
        tempSuppliedData.push(element);
      }

      if (element.borrowBalance.isZero() || element.supplyBalance.isNaN()) {
        tempNonBorrowedData.push(element);
      } else {
        tempBorrowedData.push(element);
      }
    });
    setSuppliedAssets([...tempSuppliedData]);
    setNonSuppliedAssets([...tempNonSuppliableData]);
    setBorrowedAssets([...tempBorrowedData]);
    setNonBorrowedAssets([...tempNonBorrowedData]);
  };

  useEffect(() => {
    updateNetAPY();
    updateMarketTable();
  }, [updateNetAPY]);

  const baseColumns = [
    {
      Header: 'Name',
      columns: [
        {
          Header: t('Asset'),
          accessor: 'Asset',
        },
        {
          Header: t("APY"),
          accessor: 'Apy',
        },
      ],
    },
  ];

  const suppliedColumns = React.useMemo(
    () => [
      ...baseColumns,
      {
        Header: t("Supplying"),
        accessor: 'Wallet',
      },
      {
        Header: t("Collateral"),
        accessor: 'Collateral',
      },
    ],
    [i18n.language],
  );

  const supplyColumns = React.useMemo(
    () => [
      ...baseColumns,
      {
        Header: t("Wallet"),
        accessor: 'Wallet',
      },
      {
        Header: t("Collateral"),
        accessor: 'Collateral',
      },
    ],
    [i18n.language],
  );

  const borrowedColumns = React.useMemo(
    () => [
      ...baseColumns,
      {
        Header: t('Borrowed'),
        accessor: 'Wallet',
      },
      {
        Header: t('Of_Limit'),
        accessor: 'percentOfLimit',
      },
    ],
    [i18n.language],
  );
  const borrowColumns = React.useMemo(
    () => [
      ...baseColumns,
      {
        Header: t("Wallet"),
        accessor: 'Wallet',
      },
      {
        Header: t("Liquidity"),
        accessor: 'Liquidity',
      },
    ],
    [i18n.language],
  );

  const handleSupplyClickRow = (row) => {
    setSupplyRecord(row);
    setSupplyWithdrawOpen(true);
  };

  const loadingData = React.useMemo(
    () =>
      fillArray(
        {
          Asset: (
            <div className="h-13 flex items-center justify-center px-4 py-2">
              <div className="animate-pulse rounded-lg w-20 bg-lightGray flex items-center px-3 py-3" />
            </div>
          ),
          Apy: (
            <div className="h-13 flex items-center justify-center px-4 py-2">
              <div className="animate-pulse rounded-lg  bg-lightGray w-full flex items-center px-3 py-3 justify-end" />
            </div>
          ),
          Wallet: (
            <div className="h-13 flex items-center justify-center px-4 py-2">
              <div className="animate-pulse rounded-lg bg-lightGray w-full flex items-center px-3 py-3 justify-end" />
            </div>
          ),
          Collateral: (
            <div className="h-13 flex items-center justify-center px-4 py-2">
              <div className="animate-pulse rounded-lg bg-lightGray w-full flex items-center px-3 py-3 justify-end" />
            </div>
          ),
          Liquidity: (
            <div className="h-13 flex items-center justify-center px-4 py-2">
              <div className="animate-pulse rounded-lg bg-lightGray w-full flex items-center px-3 py-3 justify-end" />
            </div>
          ),
        },
        4,
      ),
    [],
  );

  const supplyData = React.useMemo(() => {
    return suppliedAssets.map((asset) => {
      const apy = withBUL
        ? asset.supplyApy.plus(asset.supplyAdditionalApy)
        : asset.supplyApy;
      return {
        Asset: (
          <div
            className="h-13 font-bold space-x-2 cursor-pointer w-full flex items-center pl-8 pr-8 py-5"
            onClick={() => handleSupplyClickRow(asset)}
          >
            <img
              className="w-6 rounded-full"
              src={asset.img}
              alt={asset.name}
            />
            <div className="text-sm">{asset.name}</div>
          </div>
        ),
        Apy: (
          <div
            className="h-13 font-bold cursor-pointer text-xs w-full flex px-3 justify-end items-center"
            onClick={() => handleSupplyClickRow(asset)}
          >
            <img src={arrowUp} alt={'up'} className={'h-3 md:h-4'} />

            <div className="w-16 ml-1 text-sm">
              {new BigNumber(apy).eq(0)
                ? '0.00'
                : getFormatStringFromBignumber(new BigNumber(apy))}
              %
            </div>
          </div>
        ),
        // Apr: (
        //   <div
        //     className="h-13 font-bold cursor-pointer w-full flex items-center px-3 justify-end items-center "
        //     onClick={() => handleSupplyClickRow(asset)}
        //   >
        //     <div className='flex items-center justify-end'>
        //       <div className="w-16 ml-2 text-sm">
        //         {asset.additionalSupplyApr.eq(0) ? '0.00' : getFormatStringFromBignumber(asset.additionalSupplyApr)}%
        //       </div>
        //     </div>
        //   </div>
        // ),
        Wallet: (
          <div
            className="h-13 font-normal cursor-pointer text-sm w-full flex items-center px-3 py-5 justify-end whitespace-nowrap"
            onClick={() => handleSupplyClickRow(asset)}
          >
            <p>
              <p>
                {getFormatStringFromBignumber(asset.supplyBalance)} {asset.symbol}
              </p>
              <span className="text-paleBlue">
                ${getFormatStringFromBignumber(asset.supplyBalance.times(asset.tokenPrice))}
              </span>
            </p>
          </div>
        ),
        Collateral: +asset.collateralFactor ? (
          <div
            className={`h-13 font-bold cursor-pointer w-full flex items-center pl-8 pr-8 ${asset.collateral ? 'switch' : ''
              } py-5 justify-end`}
          >
            <Switch
              inactiveStateColor={'bg-darkGray'}
              activeStateColor={'bg-darkGray'}
              slideClassName={'bg-lightGreen border-lightGreen'}
              inactiveSlideClassName={'bgGrayGradient border-gray2'}
              wrapperClassName="pt-1 pb-0"
              value={account ? asset.collateral : false}
              onChange={() => handleToggleCollateral(asset)}
            />
          </div>
        ) : (
          <div
            className={`h-13 font-bold cursor-pointer w-full flex items-center pl-8 pr-8 py-5 justify-end`}
          >
            <Switch
              inactiveStateColor={'bg-darkGray'}
              activeStateColor={'bg-darkGray'}
              slideClassName={'bg-lightGreen border-lightGreen'}
              inactiveSlideClassName={'bgGrayGradient border-gray2'}
              wrapperClassName="pt-1 pb-0"
              value={account ? asset.collateral : false}
              onChange={() => handleToggleCollateral(asset)}
            />
          </div>
        ),
      };
    });
  }, [suppliedAssets, withBUL, account]);

  const allMarketData = React.useMemo(() => {
    return nonSuppliedAssets.map((asset) => {
      const apy = withBUL
        ? asset.supplyApy.plus(asset.supplyAdditionalApy)
        : asset.supplyApy;

      return {
        Asset: (
          <div
            className="h-13 font-bold space-x-2 cursor-pointer w-full flex items-center pl-8 pr-8 py-5"
            onClick={() => handleSupplyClickRow(asset)}
          >
            <img
              className="w-6 h-6 rounded-full"
              src={asset.img}
              alt={asset.name}
            />
            <div className="text-sm">{asset.name}</div>
          </div>
        ),
        Apy: (
          <div
            className="h-13 font-bold cursor-pointer w-full flex px-3 justify-end items-center"
            onClick={() => handleSupplyClickRow(asset)}
          >
            {/* <img src={arrowUp} alt={'up'} className={'h-3 md:h-4'} /> */}
            <div className="w-16 ml-1 text-sm font-normal">
              {new BigNumber(apy).eq(0)
                ? '0.00'
                : getFormatStringFromBignumber(new BigNumber(apy))}
              %
            </div>
          </div>
        ),
        // Apr: (
        //   <div
        //     className="h-13 font-bold cursor-pointer w-full flex items-center px-3 justify-end items-center "
        //     onClick={() => handleSupplyClickRow(asset)}
        //   >
        //     <div className='flex items-center justify-end'>
        //       <div className="w-16 ml-2 text-sm">
        //         {asset.additionalSupplyApr.eq(0) ? '0.00' : getFormatStringFromBignumber(asset.additionalSupplyApr)}%
        //       </div>
        //     </div>
        //   </div>
        // ),
        Wallet: (
          <div
            className="h-13 font-normal cursor-pointer text-sm w-full flex items-center px-3 py-5 justify-end whitespace-nowrap"
            onClick={() => handleSupplyClickRow(asset)}
          >
            {getFormatStringFromBignumber(asset.walletBalance)} {asset.symbol}
          </div>
        ),
        Collateral: +asset.collateralFactor ? (
          <div
            className={`h-13 font-bold cursor-pointer w-full flex items-center pl-8 pr-8 ${asset.collateral ? 'switch' : ''
              } py-5 justify-end`}
          >
            <Switch
              inactiveStateColor={'bg-darkGray'}
              activeStateColor={'bg-darkGray'}
              slideClassName={'bg-lightGreen border-lightGreen'}
              inactiveSlideClassName={'bgGrayGradient border-gray2'}
              wrapperClassName="pt-1 pb-0"
              value={account ? asset.collateral : false}
              onChange={() => handleToggleCollateral(asset)}
            />
          </div>
        ) : (
          <div className={`h-13 w-full flex items-center justify-center`}>
            <Switch
              inactiveStateColor={'bg-darkGray'}
              activeStateColor={'bg-darkGray'}
              slideClassName={'bg-lightGreen border-lightGreen'}
              inactiveSlideClassName={'bgGrayGradient border-gray2'}
              wrapperClassName="pt-1 pb-0"
              value={account ? asset.collateral : false}
              onChange={() => handleToggleCollateral(asset)}
            />
          </div>
        ),
      };
    });
  }, [nonSuppliedAssets, withBUL, account]);

  const handleBorrowClickRow = (row) => {
    if (row.name !== 'BUL') {
      setBorrowRecord(row);
      setBorrowRepayOpen(true);
    }
  };

  const borrowData = React.useMemo(() => {
    return borrowedAssets.map((asset) => {
      const apy = withBUL
        ? asset.borrowAdditionalApy.minus(asset.borrowApy)
        : new BigNumber(0).minus(asset.borrowApy);

      return {
        Asset: (
          <div
            className="h-13 font-bold space-x-2 cursor-pointer w-full flex items-center pl-8 pr-8 py-5"
            onClick={() => handleBorrowClickRow(asset)}
          >
            <img
              className="w-6 rounded-full"
              src={asset.img}
              alt={asset.symbol}
            />
            <div className="text-sm">{asset.name}</div>
          </div>
        ),
        Apy: (
          <div
            className={`h-13 font-bold cursor-pointer justify-end w-full flex items-center px-3 text-${apy.isNegative() ? 'red' : 'green'
              }`}
            onClick={() => handleBorrowClickRow(asset)}
          >
            {apy.isNegative() ? (
              <img src={arrowDown} alt={'down'} className={'h-3 md:h-4'} />
            ) : (
              <img src={arrowUp} alt={'up'} className={'h-3 md:h-4'} />
            )}
            <div className="w-16 ml-2 text-sm">
              {new BigNumber(apy).eq(0)
                ? '0.00'
                : getFormatStringFromBignumber(new BigNumber(apy).abs())}
              %
            </div>
          </div>
        ),
        // Apr: (
        //   <div
        //     className="h-13 font-bold cursor-pointer w-full flex items-center px-3 justify-end items-center "
        //     onClick={() => handleBorrowClickRow(asset)}
        //   >
        //     <div className='flex items-center justify-end'>
        //       <div className="w-16 ml-2 text-sm">
        //         {asset.additionalBorrowApr.eq(0) ? '0.00' : getFormatStringFromBignumber(asset.additionalBorrowApr)}%
        //       </div>
        //     </div>
        //   </div>
        // ),
        Wallet: (
          <div
            className="h-13 font-bold cursor-pointer text-sm w-full px-3 py-6 text-green flex flex-col items-end justify-center"
            onClick={() => handleBorrowClickRow(asset)}
          >
            $
            {getFormatStringFromBignumber(
              asset.borrowBalance.times(asset.tokenPrice),
            )}
            <span className="text-paleBlue">
              {getFormatStringFromBignumber(asset.borrowBalance)} {asset.symbol}
            </span>
          </div>
        ),
        percentOfLimit: (
          <div
            className="h-13 font-bold cursor-pointer justify-end w-full flex items-center pl-8 pr-8 py-5 text-primary"
            onClick={() => handleBorrowClickRow(asset)}
          >
            {asset.percentOfLimit}%
          </div>
        ),
      };
    });
  }, [borrowedAssets, withBUL]);

  const allBorrowMarketData = React.useMemo(() => {
    return nonBorrowedAssets.map((asset) => {
      const apy = withBUL
        ? asset.borrowAdditionalApy.minus(asset.borrowApy)
        : new BigNumber(0).minus(asset.borrowApy);

      return {
        Asset: (
          <div
            className="h-13 font-bold flex items-center space-x-2 cursor-pointer w-full px-8 py-5"
            onClick={() => handleBorrowClickRow(asset)}
          >
            <img
              className="w-6 rounded-full"
              src={asset.img}
              alt={asset.symbol}
            />
            <div className="text-sm">{asset.name}</div>
          </div>
        ),
        Apy: (
          <div
            className={`h-13 font-normal text-xs cursor-pointer justify-end w-full flex items-center px-3 text-white`}
            onClick={() => handleBorrowClickRow(asset)}
          >
            {/* {apy.isNegative() ? (
              <img src={arrowDown} alt={'down'} className={'h-3 md:h-4'} />
            ) : (
              <img src={arrowUp} alt={'up'} className={'h-3 md:h-4'} />
            )} */}

            <div className="w-16 ml-2 text-sm">
              {new BigNumber(apy).eq(0)
                ? '0.00'
                : getFormatStringFromBignumber(new BigNumber(apy).abs())}
              %
            </div>
          </div>
        ),
        Wallet: (
          <div
            className="h-13 font-normal cursor-pointer text-sm w-full flex items-center px-3 py-5 justify-end whitespace-nowrap"
            onClick={() => handleBorrowClickRow(asset)}
          >
            {getFormatStringFromBignumber(asset.walletBalance)} {asset.symbol}
          </div>
        ),
        Liquidity: (
          <div
            className="h-13 font-bold cursor-pointer justify-end text-primaryLight w-full flex items-center pl-8 pr-8 py-5"
            onClick={() => handleBorrowClickRow(asset)}
          >
            ${getFormatStringFromBignumber(asset.liquidity)}
          </div>
        ),
      };
    });
  }, [nonBorrowedAssets, withBUL]);

  return (
    <MainLayout hideSummary={true} background="dashboard">
      <AccountOverview
        borrowPercent={borrowPercent}
        earnedBalance={earnedBalance}
        withBUL={withBUL}
        setwithBUL={setwithBUL}
        netAPY={netAPY}
        settings={settings}
      />

      <Style className="relative flex flex-col items-center justify-center">
        <div className="max-w-7xl w-full">
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-2 items-stretch mb-20">
            {/* TODO: borrowed Assets table */}
            <div className="w-full rounded-lg self-stretch">
              {suppliedAssets.length === 0 &&
                nonSuppliedAssets.length === 0 && (
                  <DataTable
                    title={
                      <div className="animate-pulse bg-lightGray rounded-lg w-16 h-6" />
                    }
                    columns={suppliedColumns}
                    data={loadingData}
                  />
                )}

            {suppliedAssets.length > 0 && (
                <DataTable
                  title={t('Supply')}
                  columns={suppliedColumns}
                  data={supplyData}
                />
            )}

              {nonSuppliedAssets.length > 0 && (
                <DataTable
                  title={t('All_Supply_Markets')}
                  columns={supplyColumns}
                  data={allMarketData}
                />
              )}

              {settings.pendingInfo &&
                settings.pendingInfo.status &&
                ['Supply', 'Withdraw'].includes(settings.pendingInfo.type) && (
                  <PendingTransaction />
                )}
            </div>

            {/* TODO: borrowed Assets table */}
            <div className="w-full rounded-lg self-stretch">
              {borrowedAssets.length === 0 &&
                nonBorrowedAssets.length === 0 && (
                  <DataTable
                    title={
                      <div className="animate-pulse bg-lightGray rounded-lg w-24 h-6" />
                    }
                    columns={supplyColumns}
                    data={loadingData}
                  />
                )}

              {borrowedAssets.length > 0 && (
                <DataTable
                  title={t('Borrow')}
                  columns={borrowedColumns}
                  data={borrowData}
                />
              )}

              {nonBorrowedAssets.length > 0 && (
                <DataTable
                  title={t('All_Borrow_Markets')}
                  columns={borrowColumns}
                  data={allBorrowMarketData}
                />
              )}

              {settings.pendingInfo &&
                settings.pendingInfo.status &&
                ['Borrow', 'Repay Borrow'].includes(
                  settings.pendingInfo.type,
                ) && <PendingTransaction />}
            </div>
          </div>

          <SupplyWithdrawModal
            open={supplyWithdrawOpen}
            record={supplyRecord}
            onSetOpen={() => setSupplyWithdrawOpen(true)}
            onCloseModal={() => setSupplyWithdrawOpen(false)}
          />

          <BorrowRepayModal
            open={borrowRepayOpen}
            record={borrowRecord}
            onSetOpen={() => setBorrowRepayOpen(true)}
            onCloseModal={() => setBorrowRepayOpen(false)}
          />

          <ConfirmTransactionModal
            open={isOpenCollateralConfirm}
            onSetOpen={() => setIsCollateralConfirm(true)}
            onCloseModal={() => setIsCollateralConfirm(false)}
            isCollateralEnable={isCollateralEnable}
            collateralToken={collateralToken}
            pendingTx={pendingTx}
          />
        </div>
      </Style>
    </MainLayout>
  );
}

Dashboard.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting, getMarketHistory } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
      getMarketHistory,
    },
    dispatch,
  );
};

export default connectAccount(mapStateToProps, mapDispatchToProps)(Dashboard);
