import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../../components/common/Navigation';
import { Logo } from 'components/Icons/Logo';
import { accountActionCreators, connectAccount } from '../../core';
import { bindActionCreators } from 'redux';

function Header({ connectWalletsOpen, setConnectWalletsOpen }) {
  return (
    <header
      className={`flex justify-between items-center py-6 rounded-lg w-full mb-16`}
    >
      <Link to="/">
        <Logo />
      </Link>

      <Navigation
        wrapperClassName=""
        connectWalletsOpen={connectWalletsOpen}
        setConnectWalletsOpen={setConnectWalletsOpen}
      />
    </header>
  );
}

const mapStateToProps = ({ account }) => ({
  connectWalletsOpen: account.connectWalletsOpen,
});

const mapDispatchToProps = (dispatch) => {
  const { setConnectWalletsOpen } = accountActionCreators;

  return bindActionCreators(
    {
      setConnectWalletsOpen,
    },
    dispatch,
  );
};

export default connectAccount(mapStateToProps, mapDispatchToProps)(Header);
