/* eslint-disable max-len */

export const ModalCloseIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="27"
            fill="none"
            viewBox="0 0 28 27"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M.754 13.53C.754 6.216 6.684.286 14 .286c7.315 0 13.245 5.93 13.245 13.246 0 7.315-5.93 13.245-13.245 13.245-7.316 0-13.246-5.93-13.246-13.245zM14 2.186c-6.266 0-11.346 5.08-11.346 11.346S7.734 24.876 14 24.876s11.345-5.08 11.345-11.345c0-6.266-5.08-11.346-11.345-11.346zm4.707 6.639a1 1 0 010 1.414l-3.293 3.293 3.293 3.293a1 1 0 01-1.414 1.414L14 14.945l-3.293 3.293a1 1 0 01-1.414-1.414l3.293-3.293-3.293-3.293a1 1 0 011.414-1.414L14 12.117l3.293-3.293a1 1 0 011.414 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};
