import styled from 'styled-components';
import { matchSorter } from 'match-sorter';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy, useExpanded } from 'react-table';
import React, { Fragment, useEffect, useState } from 'react';

import { ReactComponent as FilterSvg } from '../../assets/icons/filter.svg';
import { useWindowSize } from 'hooks/useWindowSize';
import { Menu, Transition } from '@headlessui/react';
import QuestionHelper from 'components/common/QuestionHelper';

const Styles = styled.div`
    width: 100%;
    overflow: auto;
    border-radius: 10px;
    margin-top: 2rem;
    overflow: visible;

    table {
        color: #fff;
        border-spacing: 0;
        border-radius: 10px;
        width: 100%;
        background: #0e323b;

        thead {
            border-radius: 6px 6px 0px 0px;
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            border-bottom: 1px solid var(--Light-Text, #8a8fa7);
        }
        tbody {
            background-color: transparent;
            font-size: 14px;
            font-weight: 400;
        }

        tr {
            :last-child {
                border-bottom: none;
                td {
                    border-bottom: 0;
                }
            }
        }

        tr.custom-border-bottom {
            border-bottom: 5px solid #eeeeee;
        }

        th,
        td.horizontal {
            margin: 0;
            padding: 20px 0px 20px 20px;
            :last-child {
                padding: 20px 20px 20px 20px;
                border-right: 0;
                text-align: right;
            }
            :first-child {
                text-align: left;
            }
        }
    }
`;
const ButtonContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .filter {
        font-size: 16px;
        background: rgb(16, 125, 239);
        color: rgb(255, 255, 255);
        width: 70px;
        border-radius: 8px;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
    .cancel {
        font-size: 16px;
        color: rgb(248, 73, 96);
        border: 2px solid rgb(248, 73, 96);
        border-radius: 8px;
        width: 70px;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
`;
const BlockFilterContainer = styled.div`
    padding: 20px 20px;

    input {
        font-size: 16px;
        margin: 5px 0px;
        width: 100%;
        color: rgba(0, 0, 0, 0.65);
        padding: 0px 5px;
        border-radius: 4px;

        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
`;

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function HistoryTable({
    columns,
    data,
    block,
    setBlock,
    fromAddress,
    setFromAddress,
    toAddress,
    setToaddress,
}) {
    const { t } = useTranslation();
    const [isTableHorizontal, setIsTableHorizontal] = useState(true);
    const { width } = useWindowSize() || {};

    useEffect(() => {
        if (width < 1024) {
            setIsTableHorizontal(false);
        } else {
            setIsTableHorizontal(true);
        }
    }, [width]);

    // Use the state and functions returned from useTable to build your UI
    const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            defaultColumn: 'rank',
            autoResetSortBy: false,
        },
        useSortBy,
        useExpanded,
    );

    return (
        <StyledHistoryTable>
            <table className="">
                {isTableHorizontal && (
                    <thead className="text-xl">
                        {[headerGroups[1]].map((headerGroup, index) => (
                            <tr key={index}>
                                {headerGroup.headers.map((column, index) => {
                                    return (
                                        <th key={column.Header}>
                                            {column.render('Header')}
                                            {index === 1 && (
                                                <span>
                                                    <QuestionHelper
                                                        text={t(
                                                            'History_method_help',
                                                        )}
                                                    />
                                                </span>
                                            )}
                                            {index === 2 && (
                                                <span>
                                                    <Menu
                                                        as="span"
                                                        className="relative inline-block text-left"
                                                    >
                                                        {({ open }) => (
                                                            <>
                                                                <Menu.Button
                                                                    className={`px-2`}
                                                                >
                                                                    <FilterSvg />
                                                                </Menu.Button>

                                                                <Transition
                                                                    show={open}
                                                                    as={
                                                                        Fragment
                                                                    }
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items
                                                                        className={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md 
                                    bg-filter text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                                                                    >
                                                                        <BlockFilterContainer>
                                                                            <div className="title">
                                                                                {t(
                                                                                    'From',
                                                                                )}
                                                                            </div>
                                                                            <input
                                                                                id="first"
                                                                                placeholder={t(
                                                                                    'Block_Number',
                                                                                )}
                                                                                defaultValue={
                                                                                    block?.from ||
                                                                                    ''
                                                                                }
                                                                                type="number"
                                                                            />
                                                                            <div className="title">
                                                                                {t(
                                                                                    'To',
                                                                                )}
                                                                            </div>
                                                                            <input
                                                                                id="second"
                                                                                placeholder={t(
                                                                                    'Block_Number',
                                                                                )}
                                                                                defaultValue={
                                                                                    block?.to ||
                                                                                    ''
                                                                                }
                                                                                type="number"
                                                                            />
                                                                            <ButtonContainer>
                                                                                <button
                                                                                    className="filter"
                                                                                    onClick={() => {
                                                                                        const from =
                                                                                            document.getElementById(
                                                                                                'first',
                                                                                            )?.value;
                                                                                        const to =
                                                                                            document.getElementById(
                                                                                                'second',
                                                                                            )?.value;
                                                                                        setBlock(
                                                                                            {
                                                                                                from,
                                                                                                to,
                                                                                            },
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <Menu.Item>
                                                                                        {() => (
                                                                                            <div>
                                                                                                {t(
                                                                                                    'Filter',
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                </button>
                                                                                <button
                                                                                    className="cancel"
                                                                                    onClick={() => {
                                                                                        document.getElementById(
                                                                                            'first',
                                                                                        ).value =
                                                                                            '';
                                                                                        document.getElementById(
                                                                                            'second',
                                                                                        ).value =
                                                                                            '';
                                                                                    }}
                                                                                >
                                                                                    {t(
                                                                                        'Clear',
                                                                                    )}
                                                                                </button>
                                                                            </ButtonContainer>
                                                                        </BlockFilterContainer>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </>
                                                        )}
                                                    </Menu>
                                                </span>
                                            )}
                                            {(index === 4 || index === 5) && (
                                                <span>
                                                    <Menu
                                                        as="span"
                                                        className="relative inline-block text-left"
                                                    >
                                                        {({ open }) => (
                                                            <>
                                                                <Menu.Button
                                                                    className={`px-2`}
                                                                >
                                                                    <FilterSvg />
                                                                </Menu.Button>

                                                                <Transition
                                                                    show={open}
                                                                    as={
                                                                        Fragment
                                                                    }
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items
                                                                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md 
                                                                    bg-filter 
                                                                    text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    >
                                                                        <BlockFilterContainer>
                                                                            <div className="title">
                                                                                {t(
                                                                                    'Address',
                                                                                )}
                                                                            </div>
                                                                            <input
                                                                                id="first"
                                                                                placeholder={t(
                                                                                    'Search_by_address',
                                                                                )}
                                                                                defaultValue={
                                                                                    index ===
                                                                                        4
                                                                                        ? fromAddress
                                                                                        : toAddress
                                                                                }
                                                                            />
                                                                            <ButtonContainer>
                                                                                <button
                                                                                    className="filter"
                                                                                    onClick={() => {
                                                                                        const address =
                                                                                            document.getElementById(
                                                                                                'first',
                                                                                            )?.value;
                                                                                        if (
                                                                                            index ===
                                                                                            4
                                                                                        ) {
                                                                                            setFromAddress(
                                                                                                address,
                                                                                            );
                                                                                        } else {
                                                                                            setToaddress(
                                                                                                address,
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <Menu.Item>
                                                                                        {() => (
                                                                                            <div>
                                                                                                {t(
                                                                                                    'Filter',
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                </button>
                                                                                <button
                                                                                    className="cancel"
                                                                                    onClick={() => {
                                                                                        document.getElementById(
                                                                                            'first',
                                                                                        ).value =
                                                                                            '';
                                                                                    }}
                                                                                >
                                                                                    {t(
                                                                                        'Clear',
                                                                                    )}
                                                                                </button>
                                                                            </ButtonContainer>
                                                                        </BlockFilterContainer>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </>
                                                        )}
                                                    </Menu>
                                                </span>
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                )}
                <tbody {...getTableBodyProps()} className="text-lg">
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            // eslint-disable-next-line react/jsx-key
                            <Fragment key={i}>
                                {isTableHorizontal ? (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                // eslint-disable-next-line react/jsx-key
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="horizontal"
                                                >
                                                    <div
                                                        className={
                                                            cell.column
                                                                .Header ===
                                                                'Rank'
                                                                ? ''
                                                                : cell.column
                                                                    .Header ===
                                                                    'Supply'
                                                                    ? ''
                                                                    : ''
                                                        }
                                                    >
                                                        {cell.render('Cell')}
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ) : (
                                    row.cells.map((cell, index) => {
                                        return (
                                            <tr
                                                {...row.getRowProps()}
                                                key={index}
                                                className={
                                                    index ===
                                                        row.cells.length - 1
                                                        ? 'custom-border-bottom'
                                                        : ''
                                                }
                                            >
                                                {cell.column.Header !== '' && (
                                                    <td className="p-5 w-1/2">
                                                        {typeof cell.column
                                                            .Header === 'string'
                                                            ? cell.column.Header
                                                            : cell.column.Header()}
                                                    </td>
                                                )}
                                                <td
                                                    className="p-5 w-1/2"
                                                    colSpan={
                                                        cell.column.Header !==
                                                            ''
                                                            ? 1
                                                            : 2
                                                    }
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                            </Fragment>
                        );
                    })}
                </tbody>
            </table>
        </StyledHistoryTable>
    );
}
const StyledHistoryTable = styled.div`
    svg:hover {
        opacity: 0.7;
    }
`;

function Application({
    columns,
    data,
    block,
    setBlock,
    fromAddress,
    setFromAddress,
    toAddress,
    setToaddress,
}) {
    return (
        <Styles>
            <HistoryTable
                columns={columns}
                data={data}
                block={block}
                setBlock={setBlock}
                fromAddress={fromAddress}
                setFromAddress={setFromAddress}
                toAddress={toAddress}
                setToaddress={setToaddress}
            />
        </Styles>
    );
}

const App = React.memo(Application);

export default App;
