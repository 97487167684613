import React, { useMemo, useState, useEffect } from 'react';

// 3rd party packages
import styled from 'styled-components';
import commaNumber from 'comma-number';
import { useTranslation } from 'react-i18next';
// componets
import Layout from '../layouts/MainLayout/MainLayout';
import LiquidateTable from 'components/Liquidate/Table';
import { restService } from 'utilities';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { accountActionCreators, connectAccount } from 'core';
import Select from 'components/UI/Select';
import Loader from 'components/UI/Loader';
import {
    LIQUIDITY_CLOSE_FACTOR,
    LIQUIDITY_INCENTIVE,
    ERC20_TOKEN_ABI,
    CONTRACT_BBEP_ADDRESS,
    CONTRACT_BETH_ABI,
    CONTRACT_BBEP_ABI,
} from '../utilities/constants';
import BigNumber from 'bignumber.js';
import { useActiveWeb3React, useWeb3 } from '../hooks';
import { getContract, methods } from '../utilities/ContractService';
import toast from 'components/UI/Toast';
import ConnectWalletButton from '../components/common/ConnectWalletButton';
import { getEtherscanLink, getNativeToken } from 'utils';
import { NotificationManager } from 'react-notifications';

const format = commaNumber.bindWith(',', '.');

const Card = styled.div`
    width: 100%;
    box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
`;

const Liquidate = ({ settings }) => {
    const { requiredChainId, account } = useActiveWeb3React();
    const web3 = useWeb3();
    const { t, i18n } = useTranslation();
    const [tvl, setTvl] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedBorrowers, setSelectedBorrowers] = useState(null);
    const [selectedBorrowInfo, setSelectedBorrowInfo] = useState(null);
    const [selectedSupplyInfo, setSelectedSupplyInfo] = useState(null);
    const [selectedBorrowerValue, setSelectedBorrowerValue] = useState('');
    const [selectedSupplyValue, setSelectedSupplyValue] = useState('');

    const [tokenAllowance, setTokenAllowance] = useState({});
    const [buttonText, setButtonText] = useState(t('Approve'));
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [requiredChainId]);

    useEffect(() => {
        getApprove();
    }, [settings.assetList]);

    useEffect(() => {
        if (selectedBorrowInfo) {
            const repayAssetInfo = selectedBorrowers?.borrowerInfos.find(
                (item) =>
                    item.type === 'borrow' &&
                    item.id == selectedBorrowInfo?.value,
            );
            const amount = new BigNumber(selectedBorrowerValue).times(
                new BigNumber(10).pow(repayAssetInfo?.decimals),
            );
            const allowance =
                tokenAllowance[selectedBorrowInfo.name] || new BigNumber(0);
            if (allowance.gte(amount)) {
                setButtonText(t('Liquidate'));
            } else {
                setButtonText(t('Approve'));
            }
        }
    }, [tokenAllowance, selectedBorrowInfo, selectedBorrowerValue]);

    const fetchData = async () => {
        setTableLoading(true);
        const apiRequest = await restService({
            chainId: requiredChainId,
            api: `${'/v1/borrowers'}`,
            method: 'GET',
        });
        setTableLoading(false);
        if (apiRequest?.data?.data?.result) {
            setData(apiRequest.data.data.result);
        }
    };

    const findTokenIcon = (symbol) => {
        const token = settings?.assetList?.find((token) => {
            return token.name === symbol;
        });
        return token?.img || '';
    };

    const borrowSelectOption = useMemo(() => {
        if (selectedBorrowers?.borrowerInfos) {
            let arr = selectedBorrowers.borrowerInfos.map((item) => {
                if (item.type == 'borrow') {
                    return {
                        name: item.symbol,
                        logo: findTokenIcon(item.symbol),
                        value: item.id,
                    };
                }
                return null;
            });
            arr = arr.filter(Boolean);
            setSelectedBorrowInfo(arr[0]);
            return arr;
        }
        return [{ name: t('Select_to_Borrow_To_Close'), value: '' }];
    }, [selectedBorrowers]);

    const supplySelectOption = useMemo(() => {
        if (selectedBorrowers?.borrowerInfos) {
            let arr = selectedBorrowers.borrowerInfos.map((item) => {
                if (item.type == 'supply') {
                    return {
                        name: item.symbol,
                        logo: findTokenIcon(item.symbol),
                        value: item.id,
                    };
                }
                return null;
            });
            arr = arr.filter(Boolean);
            setSelectedSupplyInfo(arr[0]);
            return arr;
        }
        return [{ name: t('Select_Desired_Collateral'), value: '' }];
    }, [selectedBorrowers]);

    useEffect(() => {
        if (selectedBorrowInfo && selectedSupplyInfo) {
            selectAssets(selectedBorrowInfo, selectedSupplyInfo);
        }
    }, [selectedBorrowInfo, selectedSupplyInfo]);

    const findSelectedBorrowInfo = useMemo(() => {
        return selectedBorrowers?.borrowerInfos.find(
            (item) =>
                item.type === 'borrow' && item.id == selectedBorrowInfo?.value,
        );
    }, [selectedBorrowInfo]);
    const findSelectedSupplyInfo = useMemo(() => {
        return selectedBorrowers?.borrowerInfos.find(
            (item) =>
                item.type === 'supply' && item.id == selectedSupplyInfo?.value,
        );
    }, [selectedSupplyInfo]);

    const getErrorMessage = (msg) => {
        if (!msg) return msg;
        if (
            msg.includes(
                'MetaMask Tx Signature: User denied transaction signature',
            )
        ) {
            return t('User_denied');
        }
        return msg;
    };

    const handleLiquidateClick = async () => {
        if (!account) {
            toast.error({
                title: t(`Please_connect_to_wallet`),
            });
            return;
        }
        if (!selectedBorrowerValue) {
            toast.error({
                title: t(`Invalid_Value`),
            });
            return;
        }
        if (!findSelectedBorrowInfo || !findSelectedBorrowInfo) {
            toast.error({
                title: t(`Invalid_Value`),
            });
            return;
        }
        const amount = new BigNumber(selectedBorrowerValue)
            .times(new BigNumber(10).pow(findSelectedBorrowInfo?.decimals))
            .dp(0, 1);
        const allowance =
            tokenAllowance[findSelectedBorrowInfo?.symbol] || new BigNumber(0);

        try {
            if (amount.isGreaterThan(allowance)) {
                setButtonLoading(true);
                const appContract = getContract(
                    web3,
                    requiredChainId,
                    ERC20_TOKEN_ABI,
                    findSelectedBorrowInfo.address,
                );
                await methods
                    .send(
                        appContract.methods.approve,
                        [
                            CONTRACT_BBEP_ADDRESS[requiredChainId][
                                findSelectedBorrowInfo.symbol.toLowerCase()
                            ].address,
                            amount.toString(10),
                        ],
                        account,
                    )
                    .then(() => {
                        setTokenAllowance({
                            ...tokenAllowance,
                            [findSelectedBorrowInfo.symbol]: amount,
                        });
                        NotificationManager.success(t('Approve_successfully'));
                    })
                    .catch(() => {
                        NotificationManager.error(t('Tx_rejected'));
                    });

                setButtonLoading(false);
                return;
            }

            const balance = new BigNumber(findWalletBalance()).times(
                new BigNumber(10).pow(findSelectedBorrowInfo.decimals),
            );
            if (balance.isLessThan(amount)) {
                toast.error({
                    title: t(`Insufficient_Balance`),
                });
                return;
            }

            setButtonLoading(true);
            if (findSelectedBorrowInfo.symbol === getNativeToken(requiredChainId)) {
                const liquidityContract = getContract(
                    web3,
                    requiredChainId,
                    CONTRACT_BETH_ABI,
                    CONTRACT_BBEP_ADDRESS[requiredChainId][
                        findSelectedBorrowInfo.symbol.toLowerCase()
                    ].address,
                );
                methods
                    .sendWithValue(
                        liquidityContract.methods.liquidateBorrow,
                        [
                            findSelectedBorrowInfo.borrower,
                            CONTRACT_BBEP_ADDRESS[requiredChainId][
                                findSelectedSupplyInfo.symbol.toLowerCase()
                            ].address,
                        ],
                        account,
                        amount.toString(10),
                    )
                    .then(() => {
                        setButtonLoading(false);
                        NotificationManager.success(
                            t('Liquidate_successfully'),
                        );
                    })
                    .catch(() => {
                        setButtonLoading(false);
                        NotificationManager.error(t('Tx_rejected'));
                    });
            } else {
                const liquidityContract = getContract(
                    web3,
                    requiredChainId,
                    CONTRACT_BBEP_ABI,
                    CONTRACT_BBEP_ADDRESS[requiredChainId][
                        findSelectedBorrowInfo.symbol.toLowerCase()
                    ].address,
                );
                methods
                    .send(
                        liquidityContract.methods.liquidateBorrow,
                        [
                            findSelectedBorrowInfo.borrower,
                            amount.toString(10),
                            CONTRACT_BBEP_ADDRESS[requiredChainId][
                                findSelectedSupplyInfo.symbol.toLowerCase()
                            ].address,
                        ],
                        account,
                    )
                    .then(() => {
                        setButtonLoading(false);
                        NotificationManager.success(
                            t('Liquidate_successfully'),
                        );
                    })
                    .catch(() => {
                        setButtonLoading(false);
                        NotificationManager.error(t('Tx_rejected'));
                    });
            }
        } catch (err) {
            console.log(err);
            setButtonLoading(false);
            toast.error({
                title: getErrorMessage(err?.message) || t('Error_message'),
            });
        }
    };

    const handleBorrowAmountChange = (val) => {
        if (findSelectedBorrowInfo) {
            setSelectedBorrowerValue(val);

            let repayMax = new BigNumber(val);
            let seizeMax = new BigNumber(findSelectedSupplyInfo.amount).div(
                new BigNumber(10).pow(findSelectedSupplyInfo.decimals),
            );
            repayMax = repayMax.times(findSelectedBorrowInfo.price);
            seizeMax = seizeMax
                .div(LIQUIDITY_INCENTIVE)
                .times(findSelectedSupplyInfo.price);

            if (repayMax.isLessThan(seizeMax)) {
                const seizeAmount = repayMax
                    .times(LIQUIDITY_INCENTIVE)
                    .div(findSelectedSupplyInfo.price);
                setSelectedSupplyValue(seizeAmount.toString(10));
            }
        }
    };

    const findWalletBalance = () => {
        const find = settings.assetList.find((item) => {
            if (item.symbol === findSelectedBorrowInfo?.symbol) {
                return true;
            }
            return false;
        });
        if (find) {
            return find.walletBalance == '0'
                ? '0'
                : find.walletBalance.toString(10);
        }
        return '0';
    };

    const selectAssets = (repayAsset = null, seizeAsset = null) => {
        if (!repayAsset) {
            repayAsset = selectedBorrowInfo;
        } else {
            if (repayAsset.value !== '') {
                setSelectedBorrowInfo(repayAsset);
            }
        }
        if (!seizeAsset) {
            seizeAsset = selectedSupplyInfo;
        } else {
            if (seizeAsset.value !== '') {
                setSelectedSupplyInfo(seizeAsset);
            }
        }

        if (!repayAsset || !seizeAsset) return;

        const repayAssetInfo = selectedBorrowers?.borrowerInfos.find(
            (item) => item.type === 'borrow' && item.id == repayAsset?.value,
        );
        const seizeAssetInfo = selectedBorrowers?.borrowerInfos.find(
            (item) => item.type === 'supply' && item.id == seizeAsset?.value,
        );

        let repayMax = new BigNumber(repayAssetInfo?.amount).div(
            new BigNumber(10).pow(repayAssetInfo?.decimals),
        );
        let seizeMax = new BigNumber(seizeAssetInfo?.amount).div(
            new BigNumber(10).pow(seizeAssetInfo?.decimals),
        );
        repayMax = repayMax
            .times(LIQUIDITY_CLOSE_FACTOR)
            .times(repayAssetInfo?.price);
        seizeMax = seizeMax
            .div(LIQUIDITY_INCENTIVE)
            .times(seizeAssetInfo?.price);

        if (repayMax.isLessThan(seizeMax)) {
            const seizeAmount = repayMax
                .times(LIQUIDITY_INCENTIVE)
                .div(seizeAssetInfo?.price);
            setSelectedBorrowerValue(
                repayMax
                    .div(repayAssetInfo.price)
                    .dp(findSelectedBorrowInfo.decimals, 1)
                    .toString(10),
            );
            setSelectedSupplyValue(seizeAmount.toString(10));
        } else {
            const repayAmount = seizeMax
                .div(LIQUIDITY_INCENTIVE)
                .div(repayAssetInfo?.price);
            setSelectedBorrowerValue(
                repayAmount.dp(findSelectedBorrowInfo.decimals, 1).toString(10),
            );
            setSelectedSupplyValue(
                seizeMax.div(seizeAssetInfo?.price).toString(10),
            );
        }
    };

    const getApprove = async () => {
        if (account) {
            const borrowInfos = settings?.assetList.filter(
                (item) => item.tokenAddress,
            );
            const tokenContractCallContext = borrowInfos.map((item) => {
                return {
                    reference: item.symbol,
                    contractAddress: item.tokenAddress,
                    abi: ERC20_TOKEN_ABI,
                    calls: [
                        {
                            reference: 'allowance',
                            methodName: 'allowance',
                            methodParameters: [account, item.btokenAddress],
                        },
                    ],
                    context: {},
                };
            });

            let allowanceData = {
                SEI: new BigNumber(2).pow(256).minus(1),
            };
            const bBepContractResults = await methods.ethMulticall(
                web3,
                tokenContractCallContext,
                requiredChainId,
            );
            for (const [itemId, value] of Object.entries(
                bBepContractResults?.results,
            )) {
                allowanceData[itemId] = new BigNumber(
                    value.callsReturnContext[0].returnValues[0].hex,
                );
            }

            setTokenAllowance(allowanceData);
        }
    };

    const getHealthColor = (value) => {
        if (!value) return '#000';
        if (value >= 1) return '#40BA79';
        if (value < 1) return '#FF0100';
        return '#000';
    };

    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                columns: [
                    {
                        Header: t('Total_Borrowed'),
                        accessor: 'totalBorrowBalanceUSD',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value }) => {
                            return (
                                <div className="text-lg text-left gray10">
                                    ${new BigNumber(value).toFormat(2)}
                                </div>
                            );
                        },
                    },
                    {
                        Header: t('Health'),
                        accessor: 'health',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value }) => {
                            return (
                                <div
                                    className="text-lg"
                                    style={{ color: getHealthColor(value) }}
                                >
                                    {value}
                                </div>
                            );
                        },
                    },
                    {
                        Header: (
                            <div className="w-full custom-align">
                                {t('Address')}
                            </div>
                        ),
                        accessor: 'address',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value }) => {
                            return (
                                <div className="text-lg custom-align">
                                    <a
                                        className="address"
                                        href={getEtherscanLink(
                                            requiredChainId,
                                            value,
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {value}
                                        {/* {value?.substring(0, 4)}...{value?.substring(38)} */}
                                    </a>
                                </div>
                            );
                        },
                    },
                ],
            },
        ];
    }, [i18n.language]);

    return (
        <Layout
            title={t('Liquidate')}
            tvl={format(tvl)}
            hideSummary={true}
            background={'liquidatePage'}
        >
            <section className="max-w-[1200px] mx-auto my-20">
                <div className="flex flex-col md:flex-row gap-6">
                    <Card className="flex-auto shadow-md rounded-md border-y-2-gradient">
                        <h4 className="border-b-2-gradient text-2xl text-white px-7 py-5 font-semibold">
                            {t('Repay_Borrow')}
                        </h4>
                        <div className="p-7 text-white">
                            <div className="bg-tp-30 rounded-lg">
                                <Select
                                    options={borrowSelectOption}
                                    selectedProp={
                                        selectedBorrowInfo || {
                                            name: t(
                                                'Select_to_Borrow_To_Close',
                                            ),
                                            value: '',
                                        }
                                    }
                                    type={'custom'}
                                    selectedClassName={
                                        'px-2 py-4 bg-red-100 rounded-lg w-full border-y-1-gradient'
                                    }
                                    selectedTextClassName={
                                        'text-white text-base px-2.5'
                                    }
                                    listContainer={`absolute bg-white text-gray3 w-full py-2 mt-0.5 overflow-auto text-base rounded shadow-lg max-h-58 focus:outline-none`}
                                    width={'w-full'}
                                    imgStyle={{ height: 32, width: 32 }}
                                    onChange={(item) => selectAssets(item)}
                                />
                            </div>
                            <div className="text-gray-100 mt-3 group-open:animate-fadeIn">
                                <div className="px-6 mb-3 mt-6 flex flex-col gap-6 md:gap-8">
                                    <div className="flex justify-between">
                                        <p className="font-normal">
                                            {t('Price')}
                                        </p>
                                        <p className="font-black">
                                            {findSelectedBorrowInfo
                                                ? new BigNumber(
                                                    findSelectedBorrowInfo.price,
                                                )
                                                    .dp(5, 1)
                                                    .toString(10)
                                                : 0}
                                        </p>
                                    </div>
                                    <div className="flex justify-between">
                                        <p className="font-normal">
                                            {t('User_Borrowed')}
                                        </p>
                                        <p className="font-black">
                                            {findSelectedBorrowInfo
                                                ? new BigNumber(
                                                    findSelectedBorrowInfo.amount,
                                                )
                                                    .div(
                                                        new BigNumber(10).pow(
                                                            findSelectedBorrowInfo.decimals,
                                                        ),
                                                    )
                                                    .toString(10)
                                                : 0}
                                        </p>
                                    </div>
                                    <div className="flex justify-between">
                                        <p className="font-normal">
                                            {t('Wallet_Balance')}
                                        </p>
                                        <p className="font-black">
                                            {findWalletBalance()}
                                        </p>
                                    </div>
                                    <div className="flex justify-between items-center border-y-1-gradient whitespace-nowrap">
                                        <p className="font-normal">
                                            {t('You_Will_Pay')}
                                        </p>
                                        <input
                                            type="number"
                                            className="form-control focus:outline-none
                                                block
                                                my-6
                                                px-2
                                                text-right
                                                font-normal
                                                bg-clip-padding
                                                rounded
                                                text-white
                                                placeholder-white
                                                transition
                                                ease-in-out
                                                bg-transparent
                                                text-lg"
                                            placeholder={t('Amount_to_Close')}
                                            value={selectedBorrowerValue}
                                            onChange={(e) => {
                                                handleBorrowAmountChange(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card className="flex-auto shadow-md rounded-m border-y-2-gradient">
                        <h4 className="border-b-2-gradient text-2xl text-white px-7 py-5 font-semibold">
                            {t('Seize_Collateral')}
                        </h4>
                        <div className="p-7 text-white">
                            <div className="bg-tp-30 rounded-lg border-y-2-gradient">
                                <Select
                                    options={supplySelectOption}
                                    selectedProp={
                                        selectedSupplyInfo || {
                                            name: t(
                                                'Select_Desired_Collateral',
                                            ),
                                            value: '',
                                        }
                                    }
                                    type={'custom'}
                                    selectedClassName={
                                        'px-2 py-4 bg-red-100 rounded-lg w-full'
                                    }
                                    selectedTextClassName={
                                        'text-white text-base px-2.5'
                                    }
                                    listContainer={`absolute bg-white text-gray3 w-full py-2 mt-0.5 overflow-auto text-base rounded shadow-lg max-h-58 focus:outline-none`}
                                    width={'w-full'}
                                    imgStyle={{ height: 32, width: 32 }}
                                    onChange={(item) =>
                                        selectAssets(null, item)
                                    }
                                />
                            </div>
                            <div className="px-7 mb-3 mt-6 flex flex-col gap-6 md:gap-8">
                                <div className="flex justify-between">
                                    <p className="font-normal">{t('Price')}</p>
                                    <p className="font-black">
                                        {findSelectedSupplyInfo
                                            ? new BigNumber(
                                                findSelectedSupplyInfo.price,
                                            )
                                                .dp(5, 1)
                                                .toString(10)
                                            : 0}
                                    </p>
                                </div>
                                <div className="flex justify-between">
                                    <p className="font-normal">
                                        {t('User_Supplied')}
                                    </p>
                                    <p className="font-black">
                                        {findSelectedSupplyInfo
                                            ? new BigNumber(
                                                findSelectedSupplyInfo.amount,
                                            )
                                                .div(
                                                    new BigNumber(10).pow(
                                                        findSelectedSupplyInfo.decimals,
                                                    ),
                                                )
                                                .toString(10)
                                            : 0}
                                    </p>
                                </div>
                                <div className="flex justify-between">
                                    <p className="font-normal">
                                        {t('Liquidation_Incentive')}
                                    </p>
                                    <p className="font-black">
                                        {new BigNumber(
                                            LIQUIDITY_INCENTIVE,
                                        ).toFixed(2)}
                                    </p>
                                </div>
                                <div className="flex justify-between">
                                    <p className="font-normal">
                                        {t('You_Will_Receive')}
                                    </p>
                                    <p className="font-black">
                                        {new BigNumber(selectedSupplyValue || 0)
                                            .dp(2, 1)
                                            .toString(10)}
                                    </p>
                                </div>
                            </div>

                            {account && (
                                <button
                                    className={`blue-purple-gradient w-full py-4 btn-solid mt-5 btn-liquidate relative`}
                                    onClick={handleLiquidateClick}
                                    disabled={
                                        buttonLoading ||
                                        tableLoading ||
                                        !selectedBorrowers ||
                                        selectedBorrowers?.health > 1
                                    }
                                >
                                    <div className='flex justify-center items-center'>
                                        {buttonLoading && (
                                            <Loader
                                                size="20px"
                                                className="mr-2.5 mt-[3px]"
                                                stroke="#ffffff"
                                            />
                                        )}
                                        {buttonText}
                                    </div>
                                </button>
                            )}
                            {!account && (
                                <div className="text-center">
                                    <ConnectWalletButton className="my-4 mx-auto" />
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
                <div className="shadow-md rounded-md my-20">
                    <LiquidateTable
                        column={columns}
                        data={data}
                        onRowClick={(row) => {
                            setSelectedBorrowers(row);
                        }}
                        selectedBorrowers={selectedBorrowers}
                    />
                </div>
            </section>
        </Layout>
    );
};

Liquidate.defaultProps = {
    match: {},
    settings: {},
};

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
    const { getMarketHistory } = accountActionCreators;

    return bindActionCreators(
        {
            getMarketHistory,
        },
        dispatch,
    );
};

export default compose(
    withRouter,
    connectAccount(mapStateToProps, mapDispatchToProps),
)(Liquidate);
