import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import commaNumber from 'comma-number';
import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import RouteMap from '../../../routes/RouteMap';
import Web3 from 'web3';
import styled from 'styled-components';

const Styles = styled.div`
    background: rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 4px;

    .other-bg {
        background: rgba(0, 0, 0, 0.25);
    }
`;

const format = commaNumber.bindWith(',', '.');

const VoteCard = ({
    history,
    label,
    forNumber,
    againstNumber,
    type,
    addressNumber,
    emptyNumber,
    list,
    onViewAll,
}) => {
    const { t } = useTranslation();
    const [isViewAll, setIsViewAll] = useState(true);

    const handleAddLink = (v) => {
        history.push(RouteMap.vote.proposerOverview?.replace(':address', v));
    };

    const emptyList = [];
    if (emptyNumber > 0) {
        for (let i = 0; i < emptyNumber; i += 1) {
            emptyList.push(i);
        }
    }

    return (
        <Styles className="py-5 border-y-1-gradient">
            <div className="p-4 mx-16 other-bg font-bold border-y-1-gradient">
                <div className="text-lg">{label}</div>
                <div className="text-4xl text-right mt-4">
                    {format(
                        new BigNumber(
                            Web3.utils.fromWei(
                                // type === 'agree' ? forNumber : againstNumber,
                                type === 'agree'
                                    ? '3332221331100110111312011'
                                    : againstNumber,
                                'ether',
                            ),
                        )
                            .dp(2, 1)
                            .toString(10),
                    )}
                </div>
            </div>
            <div className="flex text-lg border-b-1-gradient font-bold justify-between pb-4 px-1 mx-12 mt-6">
                <div className="">
                    {addressNumber} {t('addresses')}
                </div>
                <div className="">{t('Vote')}</div>
            </div>
            {list.map((l, index) => (
                <div
                    key={index}
                    className="flex justify-between cursor-pointer
                        py-1.5 px-1 mx-12"
                    onClick={() => handleAddLink(l.label)}
                >
                    <div className="">
                        {l.label
                            ? `${l.label.substr(0, 5)}...${l.label.substr(
                                  -4,
                                  4,
                              )}`
                            : ''}
                    </div>
                    <div className="font-bold">
                        {format(
                            new BigNumber(Web3.utils.fromWei(l.value, 'ether'))
                                .dp(8, 1)
                                .toString(10),
                        )}
                    </div>
                </div>
            ))}

            {/* {emptyList.map((v) => (
                <div
                    key={v}
                    className="flex justify-between border-b-2 border-solid border-gray4 py-1.5 px-1 mx-5"
                >
                    <div className="text-2xl font-bold">-</div>
                    <div className="text-2xl font-bold">-</div>
                </div>
            ))} */}

            {Boolean(list?.length || 0) && (
                <div className="flex justify-center mt-4">
                    <button
                        className={`${
                            !(isViewAll && addressNumber > 3) ? '' : ''
                        } font-medium text-center py-4 px-44 bg-blue-gradient`}
                        onClick={() => {
                            setIsViewAll(false);
                            onViewAll();
                        }}
                        disabled={!(isViewAll && addressNumber > 3)}
                    >
                        {t('View_All')}
                    </button>
                </div>
            )}
        </Styles>
    );
};

VoteCard.defaultProps = {
    history: {},
    label: '',
    forNumber: '0',
    againstNumber: '0',
    type: 'agree',
    addressNumber: 0,
    emptyNumber: 0,
    list: [],
};

export default compose(withRouter)(VoteCard);
