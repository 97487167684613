import { matchSorter } from 'match-sorter';
import React, { Fragment, useEffect, useState } from 'react';
import { useExpanded, useSortBy, useTable } from 'react-table';
import styled from 'styled-components';

import { LongLineGradient } from 'components/common/lineGradient';
import { useWindowSize } from 'hooks/useWindowSize';

const Styles = styled.div`
    position: relative;
    width: 100%;
    overflow: auto;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.03);
    color: #fff;

    table {
        border-radius: 8px;
        width: 100%;

        thead {
            font-weight: bold;
        }

        tr {
            cursor: pointer;

            .custom-text-color {
                color: #1e9cd7 !important;
            }

            &:first-child {
                td {
                    margin-top: 40px;
                }
            }

            &:last-child {
                border-bottom: none;
                td {
                    border-bottom: 0;
                    margin-bottom: 40px;
                }
            }
        }

        th {
            font-size: 1.12rem;
            font-weight: 500;
            padding: 30px 50px;
        }

        td {
            padding: 15px 50px;
        }

        th,
        td.horizontal {
            margin: 0;
            text-align: left;
            :last-child {
                border-right: 0;
                text-align: right;
            }

            :first-child {
                text-align: center;
            }
        }
    }

    @media (min-width: 1024px) {
        .custom-align {
            text-align: right;
        }
    }

    @media (min-width: 1024px) and (max-width: 1105px) {
        table {
            th,
            td.horizontal {
                padding: 8px 15px;
            }
        }
    }
`;

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({ columns, data, onRowClick, selectedBorrowers }) {
    const [isTableHorizontal, setIsTableHorizontal] = useState(true);
    const { width } = useWindowSize() || {};
    useEffect(() => {
        if (width < 1024) {
            setIsTableHorizontal(false);
        } else {
            setIsTableHorizontal(true);
        }
    }, [width]);

    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
                defaultColumn: 'rank',
                autoResetSortBy: false,
            },
            useSortBy,
            useExpanded,
        );

    return (
        <table {...getTableProps()}>
            {isTableHorizontal && (
                <thead className="text-xl border-b-2-gradient">
                    {[headerGroups[1]].map((headerGroup, i) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                            {headerGroup.headers.map((column, index) => {
                                return (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps(),
                                        )}
                                        key={column.Header}
                                    >
                                        <div className="flex">
                                            {column.render('Header')}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
            )}

            <tbody {...getTableBodyProps()} className="text-lg">
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <Fragment key={i}>
                            {isTableHorizontal ? (
                                <tr
                                    {...row.getRowProps()}
                                    className={`${
                                        selectedBorrowers &&
                                        selectedBorrowers.id === row.original.id
                                            ? 'bg-midBlue text-white'
                                            : ''
                                    }`}
                                    onClick={() => onRowClick(row.original)}
                                >
                                    {row.cells.map((cell, j) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className="horizontal"
                                                key={j}
                                            >
                                                <div
                                                    className={
                                                        cell.column.Header ===
                                                        'Rank'
                                                            ? ''
                                                            : cell.column
                                                                  .Header ===
                                                              'Supply'
                                                            ? ''
                                                            : ''
                                                    }
                                                >
                                                    {cell.render('Cell')}
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ) : (
                                row.cells.map((cell, index) => {
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            onClick={onRowClick.bind(
                                                this,
                                                row.original,
                                            )}
                                            key={index}
                                            className={
                                                index === row.cells.length - 1
                                                    ? 'custom-border-bottom'
                                                    : ''
                                            }
                                        >
                                            {cell.column.Header !== '' && (
                                                <td className="p-5 w-1/2">
                                                    {cell.column.Header}
                                                </td>
                                            )}
                                            <td
                                                className="p-5 w-1/2"
                                                colSpan={
                                                    cell.column.Header !== ''
                                                        ? 1
                                                        : 2
                                                }
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </Fragment>
                    );
                })}
            </tbody>
        </table>
    );
}

function Application({ column, data, onRowClick, selectedBorrowers }) {
    return (
        <div className="border-y-2-gradient">
            <Styles>
                <Table
                    columns={column}
                    data={data}
                    onRowClick={onRowClick}
                    selectedBorrowers={selectedBorrowers}
                />
            </Styles>
        </div>
    );
}

const App = React.memo(Application);

export default App;
