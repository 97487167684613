import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import commaNumber from 'comma-number';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { MediumLineGradient } from 'components/common/lineGradient';

const Styles = styled.div`
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(16px);
  border-radius: 8px;

  .custom-text-size {
    font-size: 3rem;
    line-height: 1;
  }
  @media (max-width: 890px) {
    .custom-text-size {
      font-size: 2rem;
      line-height: 1;
    }
  }
`;

const format = commaNumber.bindWith(',', '.');

const Holding = ({ address, holdingInfo }) => {
  const { t } = useTranslation();

  return (
    <Styles className="text-white col-span-3 px-8">
      <p className="text-2xl font-bold py-5 border-y-2-gradient">
        {t('Holding')}
      </p>

      <div className="flex justify-between items-center flex-wrap">
        <div className="pt-6 pb-1.5 flex-1">
          <p className="text-lg font-bold">Belugas {t('Balance')}</p>
          <p className="custom-text-size mt-6">
            {format(holdingInfo.balance || '0.0')}
          </p>
        </div>

        <div className="pt-6 pb-1.5 flex-1">
          <div className="flex justify-between">
            <p className="text-lg font-bold">{t('Votes')}</p>
            <p className="text-lg">{holdingInfo.delegateCount || 0}</p>
          </div>
          <div className="mt-6 custom-text-size">
            {format(holdingInfo.votes || '0.0')}
          </div>
        </div>
      </div>

      <p className="text-lg font-bold mt-5">Setup Voting</p>

      <div className="pb-2 mt-5 flex justify-end items-center gap-5">
        <button className="text-sm border border-lightGreen text-lightGreen px-4 py-2 rounded-lg">
          {t('Delegating_To')}
        </button>

        <button className="text-sm border border-blue3 text-blue3 px-4 py-2 rounded-lg">
          {holdingInfo.delegates !==
            '0x0000000000000000000000000000000000000000' &&
          holdingInfo.delegates !== address.toLowerCase()
            ? t('Delegated')
            : t('Undelegated')}{' '}
        </button>
      </div>

      <div className="h-8 w-full"></div>
      <MediumLineGradient />
    </Styles>
  );
};

export default compose(withRouter)(Holding);
