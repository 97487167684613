import { getAddress } from "@ethersproject/address";

export { fillArray } from './fillArray';

export function isAddress(value) {
	try {
		return getAddress(value);
	} catch {
		return false;
	}
}

const EXPLORERS = {
	713715: 'https://seitrace.com/',
	1329: 'https://seitrace.com/',
};

export function getEtherscanLink(chainId, data, type) {
	const prefix = `${EXPLORERS[chainId]}`;

	switch (type) {
		case "transaction": {
			return `${prefix}/tx/${data}`;
		}
		case "token": {
			return `${prefix}/token/${data}`;
		}
		case "block": {
			return `${prefix}/block/${data}`
		}
		case "address":
		default: {
			return `${prefix}/address/${data}`;
		}
	}
}

export function showTokenOnExplorer(address, chainId) {
	const url = getEtherscanLink(chainId, address, 'token');
	window.open(url, "_blank");
	return url;
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address, chars = 4) {
	const parsed = isAddress(address);
	if (!parsed) {
		throw Error(`Invalid 'address' parameter '${address}'.`);
	}
	return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

export function isSeiNetwork(chainId) {
	if (chainId === 1329 || chainId === 713715) {
		return true;
	}
	return false;
}

export function getNativeToken(chainId) {
	if (chainId === 713715 || chainId === 1329) {
		return 'SEI';
	}
	return 'ETH';
}