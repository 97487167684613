import { CHAIN_IDS } from "connectors";

const account = {
  setting: {
    decimals: {},
    assetList: [],
    totalLiquidity: '0',
    totalSupplyBalance: '0',
    totalBorrowBalance: '0',
    totalBorrowLimit: '0',
    pendingInfo: {
      type: '',
      status: false,
      amount: 0,
      symbol: ''
    },
    markets: []
  },
  connectWalletsOpen: false,
};

const application = {
  currentNetworkId: 0,
  currentChainId: CHAIN_IDS[0]
}

export const initialState = {
  account,
  application,
};
