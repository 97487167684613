import React from 'react';
import Table from '../UI/Table';

export default function DataTable({ title, columns, data }) {
  return (
    <div className="dataTable w-full mt-5">
      {title && (
        <div className="text-white text-3xl lg:text-4xl font-medium w-full mb-12">
          {title}
        </div>
      )}

      <Table columns={columns} data={data} />
    </div>
  );
}
