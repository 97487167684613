import React from "react";
import { ArrowRight, ArrowLeft } from 'react-feather';
import styled from "styled-components";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 40px;
  user-select: none;
`;
const Item = styled.div`
  display: ${props => props.hidden ? 'none' : 'flex'};
  background: rgba(151, 151, 151, 0.15);
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  border-color: ${props => props.active ? 'rgb(16,125,239)' : '#0000003F'};
  border-width: 1px;
  &:hover {
    border-color: rgb(16,125,239);
  }
`;
const Item1 = styled.div`
  display: ${props => props.hidden ? 'none' : 'flex'};
  background: #fff;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  color: #0000005F
`;
const WebStyle = styled.div`
  display: flex;
  @media (max-width: 767px) {
    display: none;
  }
`;
const MobileStyle = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
`;

export default function Pagination({ current, total, setCurrent }) {
  const next = () => {
    if (current === total) return;

    setCurrent(current + 1);
  };

  const prev = () => {
    if (current === 1) return;

    setCurrent(current - 1);
  };

  return (
    <PaginationContainer className="flex items-center gap-3">
      <Item
        className="item"
        onClick={prev}
        hidden={current === 1}
      >
        <ArrowLeft size={18} />
      </Item>
      <WebStyle className="gap-3">
        {
          Array.from(Array(9).keys()).map((i) => {
            if ((i === 1 || i === 7) && (current + i - 4 !== 1 && current + i - 4 !== total)) {
              return (
                <Item1 key={i} className="item"
                  hidden={current + i - 4 <= 0 || current + i - 4 > total}
                >
                  {'•••'}
                </Item1>
              )
            }
            return (
              <Item key={i} className="item"
                hidden={current + i - 4 <= 0 || current + i - 4 > total}
                active={current === current + i - 4}
                onClick={() => setCurrent(current + i - 4)}
              >
                {current + i - 4}
              </Item>
            )
          })
        }
      </WebStyle>
      <MobileStyle>
        <Item className="item"
          hidden={current <= 0 || current > total}
          active={current === current}
          onClick={() => setCurrent(current)}
        >
          {current}
        </Item>
      </MobileStyle>
      <Item
        className="item"
        onClick={next}
        hidden={current === total || total === 0}
      >
        <ArrowRight size={18} />
      </Item>
    </PaginationContainer>
  );
}