/* eslint-disable max-len */
import React from 'react';

export const CloseIcon = () => {
    return (
        <svg width="30" height="31" viewBox="0 0 30 31" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.75415 15.5308C1.75415 8.21545 7.68444 2.28516 14.9998 2.28516C22.3152 2.28516 28.2454 8.21545 28.2454 15.5308C28.2454 22.8461 22.3152 28.7765 14.9998 28.7765C7.68444 28.7765 1.75415 22.8461 1.75415 15.5308ZM14.9998 4.18515C8.73378 4.18515 3.65416 9.26479 3.65416 15.5308C3.65416 21.7969 8.73378 26.8765 14.9998 26.8765C21.2658 26.8765 26.3454 21.7969 26.3454 15.5308C26.3454 9.26479 21.2658 4.18515 14.9998 4.18515ZM19.7072 10.8239C20.0976 11.2145 20.0976 11.8476 19.7072 12.2382L16.4143 15.531L19.7072 18.8239C20.0976 19.2145 20.0976 19.8476 19.7072 20.2382C19.3166 20.6287 18.6835 20.6287 18.2929 20.2382L15 16.9453L11.7072 20.2382C11.3166 20.6287 10.6835 20.6287 10.2929 20.2382C9.90242 19.8476 9.90242 19.2145 10.2929 18.8239L13.5858 15.531L10.2929 12.2382C9.90242 11.8476 9.90242 11.2145 10.2929 10.8239C10.6835 10.4334 11.3166 10.4334 11.7072 10.8239L15 14.1168L18.2929 10.8239C18.6835 10.4334 19.3166 10.4334 19.7072 10.8239Z"
                fill="white"
            />
        </svg>
    );
};
