import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import styled from 'styled-components';
import Web3 from 'web3';
import { connectAccount } from '../../core';
import { useActiveWeb3React, useWeb3 } from '../../hooks';
import RouteMap from '../../routes/RouteMap';
import {
    getTokenContract,
    getVoteContract,
    methods,
} from '../../utilities/ContractService';
import Loading from '../UI/Loading';
import toast from '../UI/Toast';
import NewProposalModal from './Modals/NewProposalModal';
import Proposal from './Proposal';
import config from 'config';

const ContainerStyles = styled.div`
    background: rgba(255, 255, 255, 0.03);
    box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: #ffffff;
`;

const EmptyDataStyles = styled.div`
    width: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
`;

const Proposals = ({
    isLoadingProposal,
    settings,
    votingWeight,
    pageNumber,
    proposals,
    total,
    onChangePage,
    onCreateProposal,
}) => {
    const { account: address, requiredChainId } = useActiveWeb3React();
    const web3 = useWeb3();
    const { t } = useTranslation();
    const [proposalModal, setProposalModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [proposalThreshold, setProposalThreshold] = useState(0);
    const [maxOperation, setMaxOperation] = useState(0);
    const [delegateAddress, setDelegateAddress] = useState('');
    const history = useHistory();
    const voteContract = getVoteContract(requiredChainId);

    useEffect(() => {
        if (address) {
            methods
                .call(voteContract.methods.proposalThreshold, [])
                .then((res) => {
                    setProposalThreshold(+Web3.utils.fromWei(res, 'ether'));
                });
            methods
                .call(voteContract.methods.proposalMaxOperations, [])
                .then((res) => {
                    setMaxOperation(res);
                });
        }
    }, [address]);

    useEffect(() => {
        if (
            address &&
            (delegateAddress === '' ||
                delegateAddress ===
                    '0x0000000000000000000000000000000000000000')
        ) {
            const tokenContract = getTokenContract(
                web3,
                'bul',
                requiredChainId,
            );
            methods
                .call(tokenContract.methods.delegates, [address])
                .then((res) => {
                    setDelegateAddress(res);
                })
                .catch(() => {});
        }
    }, [address, address, delegateAddress]);

    const handleShowProposalModal = () => {
        if (+votingWeight < +proposalThreshold) {
            toast.error({
                title: `${t(
                    'You_cannot_create_proposal',
                )} ${proposalThreshold} BUL ${t('at_least_dot')}`,
            });
            return;
        }
        setIsLoading(true);
        methods
            .call(voteContract.methods.latestProposalIds, [address])
            .then((pId) => {
                if (pId !== '0') {
                    methods
                        .call(voteContract.methods.state, [pId])
                        .then((status) => {
                            if (status === '0' || status === '1') {
                                toast.error({
                                    title: t('proposal_progressing_message'),
                                });
                            } else {
                                setProposalModal(true);
                            }
                            setIsLoading(false);
                        });
                } else {
                    setProposalModal(true);
                    setIsLoading(false);
                }
            });
    };

    return (
        <ContainerStyles className="flex flex-col flex-1 active-proposals">
            <div className="flex items-center justify-between border-y-2-gradient pr-7">
                <h4 className="text-2xl font-libre font-semibold px-8 py-5 ">
                    Active Proposals
                </h4>

                {address && (
                    <button
                        className="focus:outline-none blue-purple-gradient rounded-lg py-3 px-4 text-sm"
                        onClick={handleShowProposalModal}
                        disabled={config.disableButtonsForComingSoon}
                    >
                        Create Proposal
                    </button>
                )}
            </div>

            <section className="flex flex-col items-stretch flex-grow relative mt-4">
                {isLoadingProposal && (
                    <div className="flex items-center justify-center py-16 flex-grow">
                        <Loading
                            size={'36px'}
                            margin={'0'}
                            className={'text-midBlue'}
                        />
                    </div>
                )}
                {!isLoadingProposal && (
                    <>
                        {proposals && proposals.length !== 0 ? (
                            proposals.map((item) => {
                                return (
                                    <Proposal
                                        proposal={item}
                                        votingWeight={votingWeight}
                                        delegateAddress={delegateAddress}
                                        address={address}
                                        key={item.id}
                                    />
                                );
                            })
                        ) : (
                            <EmptyDataStyles>
                                <div className="text-2xl font-bold p-32 flex justify-center">
                                    <span className="text-center title-text">
                                        No active proposals
                                    </span>
                                </div>
                            </EmptyDataStyles>
                        )}
                    </>
                )}
            </section>

            <div className="mt-9">
                <div className="flex justify-center py-5 border-y-2-gradient">
                    <button
                        className="focus:outline-none blue-purple-gradient py-4 px-24 rounded-lg"
                        onClick={() => history.push(RouteMap.vote.allProposals)}
                        disabled={config.disableButtonsForComingSoon}
                    >
                        All Proposals
                    </button>
                </div>
            </div>

            <NewProposalModal
                address={address}
                visible={proposalModal}
                maxOperation={maxOperation}
                onCreateProposal={onCreateProposal}
                onCancel={() => setProposalModal(false)}
            />
        </ContainerStyles>
    );
};

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

export default compose(connectAccount(mapStateToProps, undefined))(Proposals);
