import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../UI/Modal';
import BelugasLoader from '../../assets/icons/loading_modal.svg';
import TickIcon from '../../assets/icons/tickGreen.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/closeCirclePrimary.svg';
import styled from 'styled-components';
import { useActiveWeb3React } from '../../hooks';
import { showTokenOnExplorer } from 'utils';

const Styles = styled.div`
  color: white;
  .settings-close-container {
    position: absolute;
    right: 1rem;
    top: 1rem;
    outline: none;
    background: transparent;
    cursor: pointer;
    svg {
      height: 26px;
      width: auto;
      circle {
        stroke: #ffffff;
        fill: transparent;
      }
      path {
        fill: #ffffff;
      }
    }
  }

  svg.logo-icon {
    width: 47px;
    height: auto;
  }

  img.transaction-img {
    height: 50px;
    width: auto;
  }

  svg.animate-spin {
    circle {
      fill: #002d74;
    }
    path {
      stroke: #ffffff;
    }
  }

  svg.logo-icon-compact {
    path {
      fill: #ffffff;
    }
  }

  .custom-border {
    border-bottom: 2px solid #002a6f;
  }
  .w-45 {
    width: 45%;
  }
  .link {
    color: #002a6f;
    text-decoration: underline;
  }
  .confirm-btn {
    background: #0061a7;
    border-radius: 4px;
    padding: 17px 80px;
  }

  .tick-green {
    path {
      fill: #ffffff;
    }
  }
`;

function WalletsModal({
  open,
  onSetOpen,
  onCloseModal,
  isCollateralEnable,
  collateralToken,
  pendingTx,
}) {
  const { t } = useTranslation();
  const { requiredChainId } = useActiveWeb3React();

  const title = (
    <Styles className="flex flex-row justify-center items-center mt-10 px-8">
      <div className="settings-close-container" onClick={onCloseModal}>
        <CloseIcon fill={'#fff'} className="close-icon" />
      </div>
      <div className="text-4xl font-bold border-b border-gray4 pb-5 w-full text-center">
        {t('Confirm_Transaction')}
      </div>
    </Styles>
  );

  const content = (
    <Styles className="p-14">
      <div className="flex flex-col items-center">
        <div className="relative">
          {pendingTx ? (
            <img
              className="transaction-img animate-spin"
              src={BelugasLoader}
              alt="Loader"
            />
          ) : (
            <img className="transaction-img" src={TickIcon} alt="Tick" />
          )}
        </div>
        <div className="text-2xl font-semiBold mt-8">
          {`${
            !isCollateralEnable
              ? t('Enable_as_collateral')
              : t('Disable_as_collateral')
          }`}
        </div>
      </div>
      {/* <div className="flex justify-center mt-8">
        <button
          onClick={showTokenOnExplorer.bind(
            this,
            collateralToken?.btokenAddress,
            requiredChainId,
          )}
          className="focus:outline-none text-white text-xl confirm-btn"
          disabled={pendingTx}
        >
          {t('View_on_Explorer')}
        </button>
      </div> */}
    </Styles>
  );

  return (
    <div>
      <Modal
        title={title}
        content={content}
        open={open}
        onSetOpen={onSetOpen}
        onCloseModal={onCloseModal}
        afterCloseModal={() => {}}
        width="max-w-xl"
      />
    </div>
  );
}

export default WalletsModal;
