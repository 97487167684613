import { useTranslation } from 'react-i18next';
import { Archive } from 'react-feather';
import styled from 'styled-components';
import Loading from 'components/UI/Loading';
import { getFormatStringFromBignumber } from 'utilities/common';
import {
    useClaimAllChef,
    useExit,
    useIndividualExit,
    useLockZapInfo,
    useWithdraw,
    useZapFromVesting,
} from 'hooks/useLpStaking';
import { NotificationManager } from 'react-notifications';
import { useActiveWeb3React } from 'hooks';
import { getNativeToken } from 'utils';

const StyedContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;

    .title {
        font-size: 36px;
        font-family: LibreFranklin_Regular;
        font-weight: 600;
    }
    .divider {
        margin: 20px -20px;
        border-bottom: 1px solid #ffffff23;
    }
    .sub-title {
        font-size: 14px;
        font-weight: 600;
        span {
            color: rgb(95, 0, 250);
        }
    }
    .desc {
        padding-top: 10px;
        font-size: 12px;
        color: rgb(116, 128, 136);
    }
    .history-container {
        .divider1 {
            margin: 20px -20px 0;
            border-bottom: 1px solid #ffffff23;
        }
        .divider2 {
            margin: 0px -20px 10px;
            border-bottom: 1px solid #ffffff23;
        }
        .history-header {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            font-weight: 600;
            font-size: 13px;
            padding: 5px 0;
        }
        .history-items {
            height: 290px;
            overflow: auto;
            .history-item {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                color: rgb(116, 128, 136);
                font-size: 13px;
                padding-bottom: 10px;
                .penalty-container {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    span {
                        color: rgb(225, 0, 0);
                        flex-basis: 60%;
                    }
                    svg {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .first {
        padding: 20px;
        background: rgba(255, 255, 255, 0.03);
        box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
        border-radius: 8px;

        .gradient-divider {
            margin: 10px 0;
        }

        .withdraw-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .value-container {
                display: flex;
                align-items: center;
                img {
                    margin-right: 5px;
                    width: 25px;
                    height: 25px;
                }
                .value {
                    font-size: 25px;
                    font-weight: 700;
                }
            }
            .button-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .button {
                    width: 130px;
                    height: 36px;
                    border-radius: 5px;
                    color: white;
                    border: solid 1px #0061a7;
                    font-size: 14px;
                    @media only screen and (max-width: 768px) {
                        font-size: 12px;
                        width: 100px;
                        height: 25px;
                    }
                }
                .button1 {
                    width: 130px;
                    height: 36px;
                    border-radius: 5px;
                    color: #0061a7;
                    border-color: #0061a7;
                    border-width: 1px;
                    background-color: transparent;
                    font-size: 14px;
                    @media only screen and (max-width: 768px) {
                        font-size: 12px;
                        width: 100px;
                        height: 25px;
                    }
                }
                .zap-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .warning {
                        font-size: 12px;
                        color: #0061a7;
                        font-style: italic;
                    }
                    .pair-container {
                        padding-top: 10px;
                        display: flex;
                        width: 150px;
                        justify-content: space-between;
                        font-size: 12px;
                        color: rgb(116, 128, 136);
                        .value1 {
                        }
                        .value2 {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
`;

function VestOverview({
    update,
    setUpdate,
    defaultLockIndex,
    allPendingRewards,
    penaltyAmount,
    vested,
    currentlyVesting,
    vestingList,
}) {
    const { t } = useTranslation();
    const { account, requiredChainId } = useActiveWeb3React();

    const { seiValue } = useLockZapInfo(currentlyVesting);
    const { pending: claimAllPending, handleClaimAll } = useClaimAllChef();
    const { pending: exitPending, handleExit } = useExit();
    const { pending: individualExitPending, handleIndividualExit } =
        useIndividualExit();
    const { pending: zapPending, handleZapFromVesting } = useZapFromVesting();
    const { pending: withdrawPending, handleWithdraw } = useWithdraw();

    const onClaimAll = async () => {
        const tx = await handleClaimAll();
        if (tx) {
            NotificationManager.success(t('Claim_successfully'));
            setUpdate(!update);
        } else {
            NotificationManager.error(t('Tx_rejected'));
        }
    };

    const onExit = async () => {
        const tx = await handleExit();
        if (tx) {
            NotificationManager.success(t('Exit_successfully'));
            setUpdate(!update);
        } else {
            NotificationManager.error(t('Tx_rejected'));
        }
    };

    const onIndividualExit = async (unlocktime) => {
        if (individualExitPending) return;
        const tx = await handleIndividualExit(unlocktime);
        if (tx) {
            NotificationManager.success(t('Exit_successfully'));
            setUpdate(!update);
        } else {
            NotificationManager.error(t('Tx_rejected'));
        }
    };

    const onZap = async () => {
        const tx = await handleZapFromVesting(
            !defaultLockIndex ? 1 : defaultLockIndex,
            seiValue,
        );
        if (tx) {
            NotificationManager.success(t('Zap_successfully'));
            setUpdate(!update);
        } else {
            NotificationManager.error(t('Tx_rejected'));
        }
    };

    const onWithdraw = async () => {
        const tx = await handleWithdraw(vested);
        if (tx) {
            NotificationManager.success(t('Withdraw_successfully'));
            setUpdate(!update);
        } else {
            NotificationManager.error(t('Tx_rejected'));
        }
    };

    const getTimeText = (timeStamp) => {
        const diff = parseInt(timeStamp - Date.now() / 1000);
        if (diff < 0) return '00:00';
        const days = diff / (24 * 3600);
        if (days >= 2) return `${parseInt(days)} ${t('days')}`;
        if (days >= 1) return `${parseInt(days)} ${t('day')}`;
        const hours = parseInt(diff / 3600);
        const mins = parseInt((diff - hours * 3600) / 60);
        return `${hours > 9 ? hours : `0${hours}`}:${
            mins > 9 ? mins : `0${mins}`
        }`;
    };

    return (
        <StyedContainer>
            <div className="gradient-border-tb">
                <div className="container">
                    <div className="title">{t('Vesting_overview')}</div>
                    <div className="gradient-divider" />
                    <div className="gradient-border-tb">
                        <div className="first">
                            <div className="sub-title">
                                {t('Ready_to_vest')}
                            </div>
                            <div className="gradient-divider" />

                            <div className="withdraw-container">
                                <div>
                                    <div className="value-container">
                                        <img
                                            src={`/images/coins/logo.svg`}
                                            alt={'bul'}
                                        />
                                        <div className={'value'}>
                                            {getFormatStringFromBignumber(
                                                allPendingRewards.div(1e18),
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <div>
                                        <button
                                            type="button"
                                            className="button"
                                            disabled={
                                                !account ||
                                                claimAllPending ||
                                                allPendingRewards.eq(0)
                                            }
                                            onClick={onClaimAll}
                                        >
                                            {claimAllPending ? (
                                                <div className="flex justify-center items-center">
                                                    <Loading
                                                        size={'16px'}
                                                        margin={'7px'}
                                                    />
                                                    {t('Pending')}
                                                </div>
                                            ) : (
                                                <>{t('Start_vesting')}</>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="desc">
                                {t('BUL_earned_from_lending')}
                            </div>
                        </div>
                    </div>
                    <div className="gradient-border-tb mt-5">
                        <div className="first">
                            <div className="sub-title">
                                {t('Currently_vesting')}
                            </div>
                            <div className="gradient-divider" />
                            <div className="withdraw-container">
                                <div>
                                    <div className="value-container">
                                        <img
                                            src={`/images/coins/logo.svg`}
                                            alt={'bul'}
                                        />
                                        <div className={'value'}>
                                            {getFormatStringFromBignumber(
                                                currentlyVesting.div(1e18),
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <div className="zap-container">
                                        <button
                                            type="button"
                                            className="button"
                                            disabled={
                                                !account ||
                                                zapPending ||
                                                currentlyVesting.eq(0)
                                            }
                                            onClick={onZap}
                                        >
                                            {zapPending ? (
                                                <div className="flex justify-center items-center">
                                                    <Loading
                                                        size={'16px'}
                                                        margin={'7px'}
                                                    />
                                                    {t('Pending')}
                                                </div>
                                            ) : (
                                                <>{t('Zap_into_dLP')}</>
                                            )}
                                        </button>
                                        <div className="warning">
                                            {t('No_exit_penalty')}
                                        </div>
                                        <div className="pair-container">
                                            <div className="value1">
                                                {t('Pair_width')}:
                                            </div>
                                            <div className="value2">
                                                {getFormatStringFromBignumber(
                                                    seiValue.div(1e18),
                                                )}{' '}
                                                {getNativeToken(
                                                    requiredChainId,
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="zap-container">
                                        <button
                                            type="button"
                                            className="button1"
                                            disabled={
                                                !account ||
                                                exitPending ||
                                                currentlyVesting.eq(0)
                                            }
                                            onClick={onExit}
                                        >
                                            {exitPending ? (
                                                <div className="flex justify-center items-center">
                                                    <Loading
                                                        size={'16px'}
                                                        margin={'7px'}
                                                    />
                                                    {t('Pending')}
                                                </div>
                                            ) : (
                                                <>{t('Exit_early')}</>
                                            )}
                                        </button>
                                        <div className="pair-container">
                                            <div className="value1">
                                                {t('Penalty')}:
                                            </div>
                                            <div className="value2">
                                                {getFormatStringFromBignumber(
                                                    penaltyAmount.div(1e18),
                                                )}{' '}
                                                BUL
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="desc">{t('Pending')}</div>
                        </div>
                    </div>
                    <div className="history-container">
                        <div className="divider1" />
                        <div className="history-header">
                            <div>{t('Amount')}</div>
                            <div>{t('Unlockable_in')}</div>
                            <div>{t('Penalty')}</div>
                        </div>
                        <div className="divider2" />
                        <div className="history-items">
                            {vestingList.map((data, index) => {
                                return (
                                    <div key={index} className="history-item">
                                        <div>
                                            {getFormatStringFromBignumber(
                                                data.amount.div(1e18),
                                            )}{' '}
                                            BUL
                                        </div>
                                        <div>
                                            {getTimeText(data.unlockTime)}
                                        </div>
                                        <div
                                            className="penalty-container"
                                            onClick={() => {
                                                onIndividualExit(
                                                    data.unlockTime,
                                                );
                                            }}
                                        >
                                            <span>
                                                {getFormatStringFromBignumber(
                                                    data.penalty.div(1e18),
                                                )}
                                            </span>
                                            <Archive size={13} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="divider" />
                    <div className="first">
                        <div className="withdraw-container">
                            <div>
                                <div className="sub-title">{t('Vested')}</div>
                                <div className="value-container">
                                    <img
                                        src={`/images/coins/logo.svg`}
                                        alt={'bul'}
                                    />
                                    <div className={'value'}>
                                        {getFormatStringFromBignumber(
                                            vested.div(1e18),
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="button-container">
                                <div>
                                    <button
                                        type="button"
                                        className="button1"
                                        disabled={
                                            !account ||
                                            withdrawPending ||
                                            vested.eq(0)
                                        }
                                        onClick={onWithdraw}
                                    >
                                        {withdrawPending ? (
                                            <div className="flex justify-center items-center">
                                                <Loading
                                                    size={'16px'}
                                                    margin={'7px'}
                                                />
                                                {t('Pending')}
                                            </div>
                                        ) : (
                                            <>{t('Withdraw')}</>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="desc">
                            {t('BUL_that_has_completed')}
                        </div>
                    </div>
                </div>
            </div>
        </StyedContainer>
    );
}

export default VestOverview;
