import React, { useCallback, useEffect, useState } from 'react';
import { promisify } from '../../utilities';
import { getTokenContract, methods } from '../../utilities/ContractService';
import BigNumber from 'bignumber.js';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { accountActionCreators, connectAccount } from '../../core';
import { useActiveWeb3React, useWeb3 } from '../../hooks';
import Layout from '../../layouts/MainLayout/MainLayout';
import VotingWallet from '../../components/vote/VotingWallet';
import Proposals from '../../components/vote/Proposals';
import styled from 'styled-components';
import commaNumber from 'comma-number';
import * as constants from '../../utilities/constants';
import { useChefIncentivesInfo } from 'hooks/useLpStaking';

const format = commaNumber.bindWith(',', '.');

let timeStamp = 0;

const Styles = styled.div`
    .active-proposals {
        margin-left: 1.25rem;
    }
    @media (max-width: 840px) {
        flex-direction: column;
        .active-proposals {
            margin-left: 0;
            margin-top: 1.25rem;
        }
    }
`;

const Vote = ({ settings, getProposals, setSetting }) => {
    const { account, requiredChainId } = useActiveWeb3React();
    const web3 = useWeb3();
    const { allPendingRewards } = useChefIncentivesInfo();
    const [balance, setBalance] = useState(0);
    const [votingWeight, setVotingWeight] = useState(0);
    const [proposals, setProposals] = useState({});
    const [current, setCurrent] = useState(1);
    const [isLoadingProposal, setIsLoadingProposal] = useState(false);
    const [earnedBalance, setEarnedBalance] = useState(new BigNumber(0));
    // const [xaiMint, setXaiMint] = useState('0.00000000');
    const [delegateAddress, setDelegateAddress] = useState('');
    const [delegateStatus, setDelegateStatus] = useState('');
    const [tvl, setTvl] = useState(0);

    useEffect(() => {
        setTvl(settings.totalLiquidity);
    }, [settings.totalLiquidity]);

    const loadInitialData = useCallback(async () => {
        setIsLoadingProposal(true);
        await promisify(getProposals, {
            offset: 0,
            limit: 3,
        })
            .then((res) => {
                setIsLoadingProposal(false);
                setProposals(res.data);
            })
            .catch(() => {
                setIsLoadingProposal(false);
            });
    }, [getProposals]);

    useEffect(() => {
        loadInitialData();
    }, [loadInitialData]);

    const handleChangePage = (pageNumber, offset, limit) => {
        setCurrent(pageNumber);
        setIsLoadingProposal(true);
        promisify(getProposals, {
            offset,
            limit,
        })
            .then((res) => {
                setProposals(res.data);
                setIsLoadingProposal(false);
            })
            .catch(() => {
                setIsLoadingProposal(false);
            });
    };

    const updateBalance = async () => {
        if (account) {
            const bulTokenContract = getTokenContract(
                web3,
                'bul',
                requiredChainId,
                false,
            );
            let weight = await methods.call(
                bulTokenContract.methods.getCurrentVotes,
                [account],
            );
            weight = new BigNumber(weight).div(1e18).toString(10);
            setVotingWeight(weight);
            let temp = await methods.call(bulTokenContract.methods.balanceOf, [
                account,
            ]);
            temp = new BigNumber(temp).dividedBy(1e18).dp(4, 1).toString(10);
            setBalance(temp);
        } else {
            setBalance('0');
            setVotingWeight('0');
        }
    };

    const getVoteInfo = async () => {
        const myAddress = account;
        const appContractCallContext = [
            {
                reference: 'appContract',
                contractAddress:
                    constants.CONTRACT_COMPTROLLER_ADDRESS[requiredChainId],
                abi: constants.CONTRACT_COMPTROLLER_ABI,
                calls: [
                    {
                        reference: 'bulInitialIndex',
                        methodName: 'bulInitialIndex',
                        methodParameters: [],
                    },
                    {
                        reference: 'bulAccrued',
                        methodName: 'bulAccrued',
                        methodParameters: [myAddress],
                    },
                ],
                context: {},
            },
        ];
        const appContractResults = await methods.ethMulticall(
            web3,
            appContractCallContext,
            requiredChainId,
        );
        let bulInitialIndex = new BigNumber(0);
        let bulAccrued = new BigNumber(0);
        appContractResults.results.appContract.callsReturnContext.forEach(
            (result) => {
                if (result.methodName === 'bulInitialIndex') {
                    bulInitialIndex = new BigNumber(result.returnValues[0].hex);
                } else if (result.methodName === 'bulAccrued') {
                    bulAccrued = new BigNumber(result.returnValues[0].hex);
                }
            },
        );

        let belugasEarned = new BigNumber(0);
        let assetValues = [];
        const earnedAssetList = settings.assetList.filter(
            (asset) =>
                new BigNumber(asset.supplyBalance).isGreaterThan(0) ||
                new BigNumber(asset.borrowBalance).isGreaterThan(0),
        );

        if (earnedAssetList.length > 0) {
            try {
                const contractCallContext = [
                    {
                        reference: 'appContract',
                        contractAddress:
                            constants.CONTRACT_COMPTROLLER_ADDRESS[
                                requiredChainId
                            ],
                        abi: constants.CONTRACT_COMPTROLLER_ABI,
                        calls: [],
                        context: {},
                    },
                ];
                earnedAssetList.forEach((asset) => {
                    contractCallContext[0].calls.push({
                        reference: `${asset.id}_bulSupplierIndex`,
                        methodName: 'bulSupplierIndex',
                        methodParameters: [asset.btokenAddress, myAddress],
                    });
                    contractCallContext[0].calls.push({
                        reference: `${asset.id}_bulBorrowerIndex`,
                        methodName: 'bulBorrowerIndex',
                        methodParameters: [asset.btokenAddress, myAddress],
                    });
                });
                const contractCallResults = await methods.ethMulticall(
                    web3,
                    contractCallContext,
                    requiredChainId,
                );

                for (const value of contractCallResults?.results?.appContract
                    ?.callsReturnContext) {
                    const references = value.reference.split('_');
                    assetValues[references[0]] =
                        assetValues[references[0]] || {};
                    assetValues[references[0]][references[1]] = new BigNumber(
                        value.returnValues[0].hex,
                    );
                }
            } catch (err) {
                console.log(err);
            }

            earnedAssetList.forEach((asset) => {
                const supplyIndex = new BigNumber(asset.bulSupplyIndex);
                let supplierIndex =
                    assetValues[asset.id]?.bulSupplierIndex || new BigNumber(0);
                const supplierTokens = asset.bTokenBalance;
                const initBorrowIndex = asset.bulBorrowIndex;
                const borrowerIndex = new BigNumber(
                    assetValues[asset.id]?.bulBorrowerIndex || 0,
                );
                const borrowBalanceStored = asset.borrowBalanceStored;
                const borrowIndex = asset.borrowIndex;

                if (supplierIndex.isZero(0) && supplyIndex.isGreaterThan(0)) {
                    supplierIndex = bulInitialIndex;
                }
                let deltaIndex = supplyIndex.minus(supplierIndex);
                const supplierDelta = new BigNumber(supplierTokens)
                    .multipliedBy(deltaIndex)
                    .dividedBy(1e36);

                belugasEarned = belugasEarned.plus(supplierDelta);
                if (borrowerIndex.isGreaterThan(0)) {
                    deltaIndex = new BigNumber(initBorrowIndex).minus(
                        borrowerIndex,
                    );
                    const borrowerAmount = new BigNumber(borrowBalanceStored)
                        .multipliedBy(1e18)
                        .dividedBy(borrowIndex);
                    const borrowerDelta = borrowerAmount
                        .times(deltaIndex)
                        .dividedBy(1e36);
                    belugasEarned = belugasEarned.plus(borrowerDelta);
                }
            });

            belugasEarned = belugasEarned
                .plus(bulAccrued)
                .plus(allPendingRewards);

            setEarnedBalance(belugasEarned);
        } else {
            setEarnedBalance(new BigNumber(0));
        }
    };

    const updateDelegate = async () => {
        if (account && timeStamp % 3 === 0) {
            const tokenContract = getTokenContract(
                web3,
                'bul',
                requiredChainId,
                false,
            );
            methods
                .call(tokenContract.methods.delegates, [account])
                .then((res) => {
                    setDelegateAddress(res);
                    if (res !== '0x0000000000000000000000000000000000000000') {
                        setDelegateStatus(
                            res === account ? 'self' : 'delegate',
                        );
                    } else {
                        setDelegateStatus('');
                    }
                })
                .catch(() => {});
        }
        timeStamp = Date.now();
    };

    useEffect(() => {
        updateBalance();
        updateDelegate();
        if (account) {
            if (settings?.assetList?.length > 0) {
                getVoteInfo();
            }
        } else {
            setEarnedBalance(new BigNumber(0));
        }
    }, [settings.assetList, account]);

    return (
        <div className="flex flex-col">
            <Layout title={'Vote'} background="dashboard">
                <Styles className="flex shadow-lg">
                    <VotingWallet
                        balance={balance !== '0' ? `${balance}` : '0.00000000'}
                        earnedBalance={earnedBalance}
                        votingWeight={votingWeight}
                        delegateAddress={delegateAddress}
                        delegateStatus={delegateStatus}
                    />
                    <Proposals
                        isLoadingProposal={isLoadingProposal}
                        pageNumber={current}
                        proposals={proposals?.result}
                        total={proposals.total || 0}
                        votingWeight={votingWeight}
                        onCreateProposal={() => {
                            handleChangePage(1, 0, 3);
                        }}
                        onChangePage={handleChangePage}
                    />
                </Styles>
            </Layout>
        </div>
    );
};

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
    const { getProposals, setSetting } = accountActionCreators;

    return bindActionCreators(
        {
            getProposals,
            setSetting,
        },
        dispatch,
    );
};

export default compose(
    withRouter,
    connectAccount(mapStateToProps, mapDispatchToProps),
)(Vote);
