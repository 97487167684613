import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  .background {
    border-radius: 6px;
    background-color: #064076;
  }
`

export default function Modal({ open, title, content, onCloseModal, afterCloseModal, width, containerClass }) {
  const cancelButtonRef = useRef();

  function closeModal() {
    onCloseModal();
    if (afterCloseModal) {
      afterCloseModal();
    }
  }

  return (
    <div className="modal relative">
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-75" />
          <Styles className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`inline-block relative w-full my-8 background 
              text-left align-middle transition-all transform shadow-xl rounded-2xl ${width ? width : 'max-w-xl'
                  } ${containerClass}`}
              >
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-black">
                  {title}
                </Dialog.Title>
                <div className="text-black">{content}</div>
                <button className="hide"></button>
              </div>
            </Transition.Child>
          </Styles>
        </Dialog>
      </Transition>
    </div>
  );
}
