import React from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';

const Styles = styled.div`
  width: 100%;
  border-radius: 8px;
  box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.6);

  table {
    width: 100%;
    color: #fff;
    border-radius: 8px;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);

    thead {
      color: #ffffff;
      background: #0a64b0;
      th, td {
        font-size: 14px;
        font-weight: 400;
      }

      th:nth-child(1) {
        border-radius: 8px 0px 0px 0px;
        width: 40%;
      }

      th:nth-last-child(1) {
        border-radius: 0px 8px 0px 0px;
      }
    }

    tbody {
      tr:hover {
        td:first-child {
          .td-div {
            background: rgba(255, 255, 255, 0.2);
          }
        }
        td {
          .td-div {
            background: rgba(255, 255, 255, 0.2);
          }
        }
        td:last-child {
          .td-div {
            background: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
    th,
    td {
      margin: 0;
      text-align: right;
      padding: 0px 0px;

      .td-div {
        padding: 6px 0px;
      }

      :first-child {
        text-align: left;
      }

      :last-child {
        border-right: 0;
      }
    }
    th:first-child {
      padding: 15px 1rem 15px 40px;
    }
    th {
      padding: 15px 1rem 15px 1rem;
    }
    th:last-child {
      padding: 15px 40px 15px 1rem;
    }
    @media (max-width: 650px) {
      th:last-child {
        padding: 15px 2rem 15px 1rem;
      }
    }
  }
`;

function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table {...getTableProps()}>
      <thead>
        {[headerGroups[1]].map((headerGroup, i) => (
          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) => (
              <th key={j} {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={i} {...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                return (
                  <td key={j} {...cell.getCellProps()}>
                    <div className="td-div">{cell.render('Cell')}</div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function App({ columns, data, onRowClick }) {
  return (
    <Styles>
      <Table columns={columns} data={data} onRowClick={onRowClick} />
    </Styles>
  );
}

export default App;
