/* eslint-disable max-len */

export const VoteLoadingIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="240"
            height="241"
            fill="none"
            viewBox="0 0 240 241"
            className="animate-spin"
        >
            <g clipPath="url(#clip0_2773_6073)">
                <g filter="url(#filter0_d_2773_6073)">
                    <path
                        fill="url(#paint0_linear_2773_6073)"
                        d="M115 215.062v-15a5 5 0 015-5 5 5 0 015 5v15c0 2.765-2.24 5-5 5s-5-2.235-5-5z"
                    ></path>
                </g>
                <path
                    fill="url(#paint1_linear_2773_6073)"
                    d="M49.29 183.702l10.605-10.605a4.999 4.999 0 117.07 7.07L56.36 190.772a4.999 4.999 0 11-7.07-7.07z"
                ></path>
                <g filter="url(#filter1_d_2773_6073)">
                    <path
                        fill="url(#paint2_linear_2773_6073)"
                        d="M66.966 173.097a4.999 4.999 0 010 7.07L56.36 190.772a4.999 4.999 0 11-7.07-7.07l10.605-10.605a4.994 4.994 0 017.07 0z"
                    ></path>
                </g>
                <g filter="url(#filter2_d_2773_6073)">
                    <path
                        fill="url(#paint3_linear_2773_6073)"
                        d="M54.99 157.463a4.998 4.998 0 01-1.84 6.825l-13 7.48a4.997 4.997 0 01-6.825-1.84 4.998 4.998 0 011.84-6.825l13-7.48a4.997 4.997 0 016.825 1.84z"
                    ></path>
                </g>
                <g filter="url(#filter3_d_2773_6073)">
                    <path
                        fill="url(#paint4_linear_2773_6073)"
                        d="M47.554 139.472a5 5 0 01-3.535 6.125l-14.49 3.88a5.002 5.002 0 01-2.59-9.66l14.49-3.88a5 5 0 016.125 3.535z"
                    ></path>
                </g>
                <path
                    fill="#47ACDE"
                    stroke="#074B8A"
                    strokeWidth="5"
                    d="M165.27 203.63v-.001l-7.49-12.994v-.001a2.502 2.502 0 01.919-3.416 2.5 2.5 0 013.414.918h0l7.49 12.995s0 0 0 0a2.502 2.502 0 01-4.333 2.499z"
                ></path>
                <path
                    fill="#1E9CD7"
                    stroke="#074B8A"
                    strokeWidth="5"
                    d="M145.512 213.914h-.001a2.5 2.5 0 01-3.061-1.773v-.001-.001l-3.864-14.492h-.001a2.5 2.5 0 011.772-3.061h.001a2.5 2.5 0 013.061 1.774s0 0 0 0l3.864 14.491v.001a2.497 2.497 0 01-1.771 3.062z"
                ></path>
                <g filter="url(#filter4_d_2773_6073)">
                    <path
                        fill="url(#paint5_linear_2773_6073)"
                        d="M94.046 216.637a4.995 4.995 0 01-3.53-6.125l3.894-14.485a4.999 4.999 0 019.655 2.595l-3.895 14.485a4.998 4.998 0 01-6.124 3.53z"
                    ></path>
                </g>
                <g filter="url(#filter5_d_2773_6073)">
                    <path
                        fill="url(#paint6_linear_2773_6073)"
                        d="M69.905 206.608a4.996 4.996 0 01-1.82-6.83l7.515-12.98a4.992 4.992 0 016.83-1.82 4.996 4.996 0 011.82 6.83l-7.515 12.98a4.99 4.99 0 01-6.83 1.82z"
                    ></path>
                </g>
                <g filter="url(#filter6_d_2773_6073)">
                    <path
                        fill="url(#paint7_linear_2773_6073)"
                        d="M120 20.063c-55.225 0-100 44.775-100 99.999 0 2.761 2.235 5 5 5h15c2.765 0 5-2.239 5-5 0-41.42 33.58-75 75-75s75 33.58 75 75c0 2.761 2.235 5 5 5h15c2.765 0 5-2.239 5-5 0-55.224-44.775-100-100-100z"
                    ></path>
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_2773_6073"
                    width="132"
                    height="147"
                    x="58"
                    y="140.062"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="4" dy="6"></feOffset>
                    <feGaussianBlur stdDeviation="30.5"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.388235 0 0 0 0 0.407843 0 0 0 0 0.905882 0 0 0 0.2 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2773_6073"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2773_6073"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter1_d_2773_6073"
                    width="142.604"
                    height="142.605"
                    x="-9.174"
                    y="116.631"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="4" dy="6"></feOffset>
                    <feGaussianBlur stdDeviation="30.5"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.388235 0 0 0 0 0.407843 0 0 0 0 0.905882 0 0 0 0.2 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2773_6073"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2773_6073"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter2_d_2773_6073"
                    width="144.998"
                    height="139.48"
                    x="-24.342"
                    y="99.955"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="4" dy="6"></feOffset>
                    <feGaussianBlur stdDeviation="30.5"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.388235 0 0 0 0 0.407843 0 0 0 0 0.905882 0 0 0 0.2 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2773_6073"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2773_6073"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter3_d_2773_6073"
                    width="146.494"
                    height="135.883"
                    x="-33.768"
                    y="80.766"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="4" dy="6"></feOffset>
                    <feGaussianBlur stdDeviation="30.5"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.388235 0 0 0 0 0.407843 0 0 0 0 0.905882 0 0 0 0.2 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2773_6073"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2773_6073"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter4_d_2773_6073"
                    width="135.896"
                    height="146.484"
                    x="33.342"
                    y="137.325"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="4" dy="6"></feOffset>
                    <feGaussianBlur stdDeviation="30.5"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.388235 0 0 0 0 0.407843 0 0 0 0 0.905882 0 0 0 0.2 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2773_6073"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2773_6073"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter5_d_2773_6073"
                    width="139.516"
                    height="144.981"
                    x="10.41"
                    y="129.302"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="4" dy="6"></feOffset>
                    <feGaussianBlur stdDeviation="30.5"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.388235 0 0 0 0 0.407843 0 0 0 0 0.905882 0 0 0 0.2 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2773_6073"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2773_6073"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter6_d_2773_6073"
                    width="322"
                    height="227"
                    x="-37"
                    y="-34.938"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="4" dy="6"></feOffset>
                    <feGaussianBlur stdDeviation="30.5"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.388235 0 0 0 0 0.407843 0 0 0 0 0.905882 0 0 0 0.2 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2773_6073"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2773_6073"
                        result="shape"
                    ></feBlend>
                </filter>
                <linearGradient
                    id="paint0_linear_2773_6073"
                    x1="115"
                    x2="125"
                    y1="207.563"
                    y2="207.563"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4776E6"></stop>
                    <stop offset="1" stopColor="#0061A7"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2773_6073"
                    x1="-48.999"
                    x2="123.621"
                    y1="74.807"
                    y2="247.427"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#33C481"></stop>
                    <stop offset="1" stopColor="#21A366"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_2773_6073"
                    x1="47.826"
                    x2="68.43"
                    y1="181.934"
                    y2="181.934"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4776E6"></stop>
                    <stop offset="1" stopColor="#0061A7"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_2773_6073"
                    x1="32.658"
                    x2="55.656"
                    y1="163.695"
                    y2="163.695"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4776E6"></stop>
                    <stop offset="1" stopColor="#0061A7"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear_2773_6073"
                    x1="23.232"
                    x2="47.726"
                    y1="142.707"
                    y2="142.707"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4776E6"></stop>
                    <stop offset="1" stopColor="#0061A7"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear_2773_6073"
                    x1="90.342"
                    x2="104.239"
                    y1="204.567"
                    y2="204.567"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4776E6"></stop>
                    <stop offset="1" stopColor="#0061A7"></stop>
                </linearGradient>
                <linearGradient
                    id="paint6_linear_2773_6073"
                    x1="67.41"
                    x2="84.925"
                    y1="195.793"
                    y2="195.793"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4776E6"></stop>
                    <stop offset="1" stopColor="#0061A7"></stop>
                </linearGradient>
                <linearGradient
                    id="paint7_linear_2773_6073"
                    x1="20"
                    x2="220"
                    y1="72.563"
                    y2="72.563"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4776E6"></stop>
                    <stop offset="1" stopColor="#0061A7"></stop>
                </linearGradient>
                <clipPath id="clip0_2773_6073">
                    <path
                        fill="#fff"
                        d="M0 0H240V240H0z"
                        transform="translate(0 .063)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
