import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  top: calc(-100% + 26px);
  right: 5px;
  @media (max-width: 650px) {
    right: -3px;
  }
  @media (min-width: 1440px) {
    right: 7px;
  }
  svg {
    height: 160px;
    width: auto;
    transform: rotateX(180deg);
  }
`;

const SemiCircleProgress = ({ percent, color }) => {
  const pathRef = useRef();

  useEffect(() => {
    setProgress(percent);
  }, [percent]);

  const setProgress = (percent) => {
    var bar = pathRef.current;
    bar.setAttribute('stroke-dasharray', percent + ' 100');
  };

  return (
    <Wrapper className="absolute">
      <svg viewBox="0 0 110 110">
        <path
          id="progress"
          ref={pathRef}
          d="M 5 5 A 50 50 0 0 0 105 5"
          fill="none"
          strokeLinecap={'round'}
          stroke={color}
          pathLength="100"
          strokeWidth="10"
        />
      </svg>
    </Wrapper>
  );
};

export default SemiCircleProgress;
