import { useEffect, useState } from 'react';
import { Switch as _Switch } from '@headlessui/react';
import { useWindowSize } from 'hooks/useWindowSize';

export default function Switch({
    wrapperClassName,
    style,
    slideClassName,
    inactiveSlideClassName,
    activeStateColor,
    inactiveStateColor,
    value: isChecked = false,
    onChange = (val) => val,
}) {
    const [isWidthSmall, setIsWidthSmall] = useState(true);
    const { width } = useWindowSize() || {};
    useEffect(() => {
        if (width <= 775) {
            setIsWidthSmall(true);
        } else {
            setIsWidthSmall(false);
        }
    }, [width]);

    return (
        <div className={wrapperClassName || 'py-2'}>
            <_Switch
                checked={isChecked}
                onChange={(val) => onChange(val)}
                className={`
          relative inline-flex items-center flex-shrink-0
          ${style == 'small' ? 'h-5 w-10' : 'h-5 w-10'}  
           border ${
               isChecked ? 'border-midBlue' : 'border-gray'
           } rounded-full cursor-pointer
           transition-colors ease-in-out duration-200 focus:outline-none 
           focus-visible:ring-2 focus-visible:ring-white 
           focus-visible:ring-opacity-75 
           ${
               slideClassName
                   ? isChecked
                       ? slideClassName
                       : inactiveSlideClassName
                       ? inactiveSlideClassName
                       : slideClassName
                   : 'bg-gradient'
           }
            `}
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className={`${isChecked ? '' : 'translate-x-0'} 
            ${
                activeStateColor && inactiveStateColor
                    ? isChecked
                        ? activeStateColor
                        : inactiveStateColor
                    : isChecked
                    ? 'bg-midBlue'
                    : 'bg-gray'
            } 
            pointer-events-none inline-block
            rounded-full shadow-lg transform ring-0 transition ease-in-out duration-200`}
                    style={
                        style === 'small'
                            ? {
                                  height: '15px',
                                  width: '15px',
                                  '--tw-translate-x': isChecked
                                      ? '20px'
                                      : isWidthSmall
                                      ? '-3vw'
                                      : '-2px',
                              }
                            : {
                                  height: '14px',
                                  width: '14px',
                                  '--tw-translate-x': isChecked
                                      ? '25px'
                                      : isWidthSmall
                                      ? '0px'
                                      : '4px',
                              }
                    }
                />
            </_Switch>
        </div>
    );
}
