import React, { useCallback, useEffect, useState } from 'react';
import { Logo } from 'components/Icons/Logo';
import Modal from '../UI/Modal';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { getBigNumber } from '../../utilities/common';
import { accountActionCreators, connectAccount } from '../../core';
import { bindActionCreators } from 'redux';
import { useActiveWeb3React, useWeb3 } from '../../hooks';
import {
  getAbepContract,
  getTokenContract,
  methods,
} from '../../utilities/ContractService';
import { sendRepay } from '../../utilities/SeiContract';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import commaNumber from 'comma-number';
import arrowThin from '../../assets/icons/arrowThin.svg';
import { CloseIcon } from './IconClose';
import Loading from '../UI/Loading';
import { MediumLineGradient } from './lineGradient';

const StyledNumberFormat = styled(NumberFormat)`
  width: 100%;
  height: 100%;
  font-size: 36px;
  margin-top: 5px;
  text-align: end;
  background: transparent;
  font-weight: bold;
  color: #ffffff;

  &:focus {
    outline: none;
  }

  @media all and (max-width: 1024px) {
    font-size: 30px;
  }

  @media all and (max-width: 767px) {
    font-size: 24px;
  }
`;

const Styles = styled.div`
  .tooltip {
    .label {
      left: auto;
      right: 0;
    }
  }
`;

const ContainerStyles = styled.div`
  background: #064076;
  padding-top: 25px;

  .settings-close-container {
    position: absolute;
    right: 1rem;
    top: 1rem;
    outline: none;
    background: transparent;
    cursor: pointer;
  }

  svg.logo-icon {
    width: 47px;
    height: auto;
  }

  .custom-border2 {
    border-bottom: 2px solid #0061a7;
  }
  .w-45 {
    width: 45%;
  }
  .link {
    color: #002a6f;
    text-decoration: underline;
  }
  .confirm-btn {
    background: #0061a7;
    border-radius: 4px;
    padding: 17px 80px;
  }

  .custom-text-darkGrey {
    color: #878787;
  }
  .custom-text-grey {
    color: #9e9e9e;
  }
  .input-wrapper-repayBorrow {
    padding: 33px 47px;
    margin: 0 -5.5rem;
    background: #e6e6e6;
  }
  .input-wrapper-borrow {
    padding: 33px 47px;
    margin: 0 -5.5rem;
    background: #e6e6e6;
  }
  .btn-sm {
    height: 36px;
    padding: 0px 15px;
    line-height: 36px;
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 16px;
  }
  .mobile {
    display: none;
    @media all and (max-width: 767px) {
      display: block;
    }
  }
  .web {
    display: block;
    @media all and (max-width: 767px) {
      display: none;
    }
  }
`;
const ErrorMessage = styled.div`
  color: red;
  height: 0px;
  text-align: right;
  width: 100%;
  position: absolute;
  bottom: 2px;
  right: 2px;
`;

const format = commaNumber.bindWith(',', '.');

function BorrowRepayModal({
  open,
  onSetOpen,
  onCloseModal,
  record: asset,
  settings,
  setSetting,
  connectWalletsOpen,
  setConnectWalletsOpen,
}) {
  const { t } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();
  const web3 = useWeb3();
  const [currentTab, setCurrentTab] = useState('borrow');
  const [showBorrowError, setShowBorrowError] = useState(false);
  const [showRepayError, setShowRepayError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [borrowBalance, setBorrowBalance] = useState(new BigNumber(0));
  const [borrowPercent, setBorrowPercent] = useState(new BigNumber(0));
  const [newBorrowBalance, setNewBorrowBalance] = useState(new BigNumber(0));
  const [newBorrowPercent, setNewBorrowPercent] = useState(new BigNumber(0));

  const updateInfo = useCallback(() => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(asset.tokenPrice);
    if (!amount || amount.isZero() || amount.isNaN()) {
      setBorrowBalance(totalBorrowBalance);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercent(new BigNumber(0));
        setNewBorrowPercent(new BigNumber(0));
      } else {
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercent(
          totalBorrowBalance.div(totalBorrowLimit).times(100),
        );
      }
    } else {
      const temp = totalBorrowBalance.plus(amount.times(tokenPrice));
      setBorrowBalance(totalBorrowBalance);
      setNewBorrowBalance(temp);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercent(new BigNumber(0));
        setNewBorrowPercent(new BigNumber(0));
      } else {
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercent(temp.div(totalBorrowLimit).times(100));
      }
    }
  }, [account, amount, asset]);

  /**
   * Get Allowed amount
   */
  useEffect(() => {
    if (asset.btokenAddress && account) {
      updateInfo();
    }
  }, [account, updateInfo, asset]);

  /**
   * Borrow
   */
  const handleBorrow = () => {
    const appContract = getAbepContract(web3, asset.id, requiredChainId);
    if (asset && account) {
      setIsLoading(true);
      setSetting({
        pendingInfo: {
          type: 'Borrow',
          status: true,
          amount: amount.dp(8, 1).toString(10),
          symbol: asset.symbol,
        },
      });
      methods
        .send(
          appContract.methods.borrow,
          [
            amount
              .times(new BigNumber(10).pow(settings.decimals[asset.id].token))
              .integerValue()
              .toString(10),
          ],
          account,
        )
        .then(() => {
          setAmount();
          setIsLoading(false);
          setSetting({
            pendingInfo: {
              type: '',
              status: false,
              amount: 0,
              symbol: '',
            },
          });
          onCloseModal();
        })
        .catch(() => {
          setIsLoading(false);
          setSetting({
            pendingInfo: {
              type: '',
              status: false,
              amount: 0,
              symbol: '',
            },
          });
        });
    }
  };
  /**
   * Max amount
   */
  const handleMaxAmount = () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(asset.tokenPrice);
    const safeMax = BigNumber.maximum(
      totalBorrowLimit.times(70).div(100).minus(totalBorrowBalance),
      new BigNumber(0),
    );
    setAmount(
      BigNumber.minimum(safeMax, asset.liquidity)
        .div(tokenPrice)
        .decimalPlaces(settings.decimals[asset.id].token),
    );
  };
  const getBorrowMaxAmount = () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(asset.tokenPrice);
    const max = BigNumber.maximum(
      totalBorrowLimit.minus(totalBorrowBalance),
      new BigNumber(0),
    );
    return BigNumber.minimum(max, asset.liquidity)
      .div(tokenPrice)
      .toFormat(2, BigNumber.ROUND_DOWN);
  };

  const [isLoadingRepay, setIsLoadingRepay] = useState(false);
  const [isEnabledRepay, setIsEnabledRepay] = useState(false);
  const [allowanceAmount, setAllowanceAmount] = useState(new BigNumber(0));
  const [amountRepay, setAmountRepay] = useState();
  const [borrowBalanceRepay, setBorrowBalanceRepay] = useState(
    new BigNumber(0),
  );
  const [borrowPercentRepay, setBorrowPercentRepay] = useState(
    new BigNumber(0),
  );
  const [newBorrowBalanceRepay, setNewBorrowBalanceRepay] = useState(
    new BigNumber(0),
  );
  const [newBorrowPercentRepay, setNewBorrowPercentRepay] = useState(
    new BigNumber(0),
  );

  const updateInfoRepay = useCallback(() => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(asset.tokenPrice);
    if (!amountRepay || amountRepay.isZero() || amountRepay.isNaN()) {
      setBorrowBalanceRepay(totalBorrowBalance);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercentRepay(new BigNumber(0));
        setNewBorrowPercentRepay(new BigNumber(0));
      } else {
        setBorrowPercentRepay(
          totalBorrowBalance.div(totalBorrowLimit).times(100),
        );
        setNewBorrowPercentRepay(
          totalBorrowBalance.div(totalBorrowLimit).times(100),
        );
      }
    } else {
      const temp = totalBorrowBalance.minus(amountRepay.times(tokenPrice));
      setBorrowBalanceRepay(totalBorrowBalance);
      setNewBorrowBalanceRepay(temp);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercentRepay(new BigNumber(0));
        setNewBorrowPercentRepay(new BigNumber(0));
      } else {
        setBorrowPercentRepay(
          totalBorrowBalance.div(totalBorrowLimit).times(100),
        );
        setNewBorrowPercentRepay(temp.div(totalBorrowLimit).times(100));
      }
    }
    if (
      amountRepay &&
      amountRepay.isGreaterThan(
        BigNumber.minimum(asset.walletBalance, asset.borrowBalance),
      )
    ) {
      setShowRepayError(true);
    } else {
      setShowRepayError(false);
    }
  }, [account, amountRepay, asset]);

  useEffect(() => {
    if (asset.liquidity) {
      if (
        (amount &&
          amount.isGreaterThan(asset.liquidity.div(asset.tokenPrice))) ||
        newBorrowPercent.isGreaterThan(100) ||
        (!new BigNumber(asset.borrowCaps || 0).isZero() &&
          amount.plus(asset.totalBorrows).isGreaterThan(asset.borrowCaps))
      ) {
        setShowBorrowError(true);
      } else {
        setShowBorrowError(false);
      }
    }
  }, [amount, newBorrowPercent, asset]);

  useEffect(() => {
    if (asset.btokenAddress && account) {
      updateInfoRepay();
    }
  }, [account, updateInfoRepay, asset]);

  useEffect(() => {
    if (open) {
      getAllowanceAmount();
    } else {
      setAmount();
      setAmountRepay();
    }
  }, [open]);

  useEffect(() => {
    if (asset.id !== 'sei') {
      if (!amountRepay || allowanceAmount.gte(amountRepay)) {
        setIsEnabledRepay(true);
      } else {
        setIsEnabledRepay(false);
      }
    } else {
      setIsEnabledRepay(true);
    }
  }, [amountRepay, allowanceAmount]);

  const getAllowanceAmount = async () => {
    if (asset && account && asset.id !== 'sei') {
      const tokenContract = getTokenContract(web3, asset.id, requiredChainId);
      const result = await methods.call(tokenContract.methods.allowance, [
        account,
        asset.btokenAddress,
      ]);
      setAllowanceAmount(
        new BigNumber(result || 0).dividedBy(
          new BigNumber(10).pow(settings.decimals[asset.id]?.token || 18),
        ),
      );
    }
  };

  /**
   * Approve underlying token
   */
  const onApprove = async () => {
    if (asset && account && asset.id !== 'sei') {
      setIsLoadingRepay(true);
      const tokenContract = getTokenContract(web3, asset.id, requiredChainId);
      methods
        .send(
          tokenContract.methods.approve,
          [
            asset.btokenAddress,
            amountRepay
              .times(new BigNumber(10).pow(settings.decimals[asset.id].token))
              .plus(new BigNumber(10).pow(Number(settings.decimals[asset.id].token) - 3))
              .integerValue()
              .toString(10),
          ],
          account,
        )
        .then(() => {
          getAllowanceAmount();
          setIsLoadingRepay(false);
        })
        .catch(() => {
          setIsLoadingRepay(false);
        });
    }
  };

  /**
   * Repay Borrow
   */
  const handleRepayBorrow = () => {
    const appContract = getAbepContract(web3, asset.id, requiredChainId);
    if (asset && account) {
      setIsLoadingRepay(true);
      setSetting({
        pendingInfo: {
          type: 'Repay Borrow',
          status: true,
          amount: amountRepay.dp(8, 1).toString(10),
          symbol: asset.symbol,
        },
      });
      try {
        const initialize = (close) => {
          if (close) {
            setAmountRepay();
            onCloseModal();
            setCurrentTab('borrow');
          }

          setIsLoadingRepay(false);
          setSetting({
            pendingInfo: {
              type: '',
              status: false,
              amount: 0,
              symbol: '',
            },
          });
        };

        if (asset.id !== 'sei') {
          if (amountRepay.gte(asset.borrowBalance)) {
            methods
              .send(
                appContract.methods.repayBorrow,
                [new BigNumber(2).pow(256).minus(1).toString(10)],
                account,
              )
              .then(() => {
                initialize(true);
              })
              .catch(() => {
                initialize(false);
              });
          } else {
            methods
              .send(
                appContract.methods.repayBorrow,
                [
                  amountRepay
                    .times(
                      new BigNumber(10).pow(settings.decimals[asset.id].token),
                    )
                    .integerValue()
                    .toString(10),
                ],
                account,
              )
              .then(() => {
                initialize(true);
              })
              .catch(() => {
                initialize(false);
              });
          }
        } else {
          sendRepay(
            web3,
            account,
            amountRepay
              .times(new BigNumber(10).pow(settings.decimals[asset.id].token))
              .integerValue()
              .toString(10),
            requiredChainId,
            () => {
              setAmountRepay();
              setIsLoadingRepay(false);
              onCloseModal();
              setSetting({
                pendingInfo: {
                  type: '',
                  status: false,
                  amount: 0,
                  symbol: '',
                },
              });
            },
          );
        }
      } catch (e) {
        setIsLoadingRepay(false);
        setSetting({
          pendingInfo: {
            type: '',
            status: false,
            amount: 0,
            symbol: '',
          },
        });
      }
    }
  };

  /**
   * Max amount
   */
  const handleMaxAmountRepay = () => {
    setAmountRepay(BigNumber.minimum(asset.walletBalance, asset.borrowBalance));
  };

  const PrimaryList = () => (
    <>
      <div className="flex p-5 pb-9 borber-gradient shadow-xl rounded-lg flex-col text-white space-y-5 mt-8">
        <div className="text-lg font-black">{t('Borrow_Rates')}</div>
        <MediumLineGradient />

        <div>
          <div className="flex justify-between items-center">
            <h3 className="text-base">{t('Borrow_APY')}</h3>
            <span className={'text-lg font-black'}>
              {asset.borrowApy.dp(2, 1).toString(10)}%
            </span>
          </div>

          <div className="flex justify-between items-center pt-6">
            <h3 className="text-base">{t('Distribution_APY')}</h3>
            <span className={'text-lg font-black'}>
              {getBigNumber(asset.borrowAdditionalApy).dp(2, 1).toString(10) +
                '%'}
            </span>
          </div>

          {!new BigNumber(asset.borrowCaps || 0).isZero() && (
            <div className="flex justify-between items-center pt-6">
              <div className="text-base">{t('Borrow_Caps')}</div>
              <span className={'text-lg font-black'}>
                {format(
                  new BigNumber(asset.borrowCaps || 0).dp(2, 1).toString(10),
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );

  const SecondaryList = () => {
    return (
      <div className="flex p-5 pb-9 borber-gradient shadow-xl rounded-lg flex-col text-white space-y-5 mt-8">
        <div className="text-lg">{t('Borrow_Limit')}</div>

        <MediumLineGradient />

        <div>
          <div className="flex justify-between items-center">
            <div className="text-base">{t('Balance')}</div>
            {(currentTab === 'repayBorrow' &&
              (!amountRepay || amountRepay.isZero() || amountRepay.isNaN())) ||
              (currentTab === 'borrow' &&
                (!amount || amount.isZero() || amount.isNaN())) ? (
              <span className={'text-lg font-black'}>
                $
                {(currentTab === 'repayBorrow'
                  ? borrowBalanceRepay
                  : borrowBalance
                )
                  .dp(2, 1)
                  .toString(10)}
              </span>
            ) : (
              <div className="flex items-center">
                <div className="text-lg font-black">
                  $
                  {(currentTab === 'repayBorrow'
                    ? borrowBalanceRepay
                    : borrowBalance
                  )
                    .dp(2, 1)
                    .toString(10)}
                </div>
                <div className="text-midBlue">
                  <img src={arrowThin} alt="arrow" className="mx-4" />
                </div>
                <div className="text-lg font-black">
                  $
                  {(currentTab === 'repayBorrow'
                    ? newBorrowBalanceRepay
                    : newBorrowBalance
                  )
                    .dp(2, 1)
                    .toString(10)}
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-between items-center pt-6">
            <div className="  text-base">{t('Borrow_Limit_Used')}</div>
            {(currentTab === 'repayBorrow' &&
              (!amountRepay || amountRepay.isZero() || amountRepay.isNaN())) ||
              (currentTab === 'borrow' &&
                (!amount || amount.isZero() || amount.isNaN())) ? (
              <span className={'text-lg font-black'}>
                {(currentTab === 'repayBorrow'
                  ? borrowPercentRepay
                  : borrowPercent
                )
                  .dp(2, 1)
                  .toString(10)}
                %
              </span>
            ) : (
              <div className="flex items-center">
                <div className="text-lg font-black">
                  {(currentTab === 'repayBorrow'
                    ? borrowPercentRepay
                    : borrowPercent
                  )
                    .dp(2, 1)
                    .toString(10)}
                  %
                </div>
                <div className="text-midBlue">
                  <img src={arrowThin} alt="arrow" className="mx-4" />
                </div>
                <div className="text-lg font-black">
                  {(currentTab === 'repayBorrow'
                    ? newBorrowPercentRepay
                    : newBorrowPercent
                  )
                    .dp(2, 1)
                    .toString(10)}
                  %
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const title = (
    <ContainerStyles className="flex justify-start items-center px-12 mt-4 rounded-t-lg">
      <div
        className="absolute top-8 right-12 cursor-pointer"
        onClick={() => {
          setAmountRepay();
          setAmount();
          onCloseModal();
        }}
      >
        <CloseIcon />
      </div>
      <Logo />
    </ContainerStyles>
  );

  const content = (
    <ContainerStyles className="pb-8 px-14">
      {currentTab === 'repayBorrow' ? (
        <div className="flex p-5 pb-9 borber-gradient shadow-xl rounded-lg flex-col text-white space-y-5 mt-8">
          <div className="">
            <div className="flex justify-between w-full">
              <p className="text-lg font-semibold">{t("Wallet_Balance")}:</p>
              <Styles className="">
                <div className="tooltip relative">
                  <div className="tooltip-label text-xl font-bold">
                    {asset.borrowBalance &&
                      format(asset.walletBalance.dp(2, 1).toString(10))}{' '}
                    {asset.symbol}
                  </div>
                  <span className="label">
                    {asset.borrowBalance &&
                      format(asset.walletBalance.toString(10))}{' '}
                    {asset.symbol}
                  </span>
                </div>
              </Styles>
            </div>
            <StyledNumberFormat
              value={!amountRepay ? '' : amountRepay.toString(10)}
              onValueChange={({ value }) => {
                setAmountRepay(new BigNumber(value));
              }}
              disabled={!account}
              isAllowed={({ value }) => {
                return value.length < 30;
              }}
              thousandSeparator
              allowNegative={false}
              placeholder="0"
            />
            <div className="flex justify-end w-full">
              <button onClick={() => handleMaxAmountRepay()}>Max</button>
            </div>
          </div>
          <ErrorMessage>
            {showRepayError
              ? `${t('You_can_repay')} ${BigNumber.minimum(
                asset.walletBalance,
                asset.borrowBalance,
              ).toFormat(2)}${asset.symbol}`
              : ''}
          </ErrorMessage>
        </div>
      ) : (
        <div className="flex p-5 pb-9 borber-gradient shadow-xl rounded-lg flex-col text-white space-y-5 mt-8">
          <div className="">
            {/*  */}
            <div className="flex justify-between w-full">
              <div className="text-lg font-semibold">Balance:</div>
              <Styles className="">
                <div className="tooltip relative">
                  <div className="tooltip-label text-xl font-bold">
                    {asset.borrowBalance && asset.borrowBalance.toFormat(2)}{' '}
                    {asset.symbol}
                  </div>
                  <span className="label">
                    {asset.borrowBalance && asset.borrowBalance.toFormat(2)}{' '}
                    {asset.symbol}
                  </span>
                </div>
              </Styles>
            </div>
            <StyledNumberFormat
              value={!amount ? '' : amount.toString(10)}
              onValueChange={({ value }) => {
                setAmount(new BigNumber(value));
              }}
              disabled={!account}
              isAllowed={({ value }) => {
                return value.length < 30;
              }}
              thousandSeparator
              allowNegative={false}
              placeholder="0"
            />
            <div className="flex justify-end w-full">
              <button onClick={() => handleMaxAmount()}>Safe Max</button>
            </div>
          </div>
          <ErrorMessage>
            {showBorrowError
              ? `${t('You_can_borrow')} ${getBorrowMaxAmount()} ${asset.symbol
              } ${t('at_least')}`
              : ''}
          </ErrorMessage>
        </div>
      )}


      <div className="flex mt-8 gap-7">
        <button
          className={`btn-sm w-full rounded-lg ${currentTab === 'borrow'
            ? 'blue-purple-gradient'
            : 'border border-paleBlue'
            } `}
          onClick={() => setCurrentTab('borrow')}
        >
          {t("Borrow")}
        </button>

        <button
          className={`btn-sm w-full rounded-lg ${currentTab === 'repayBorrow'
            ? 'blue-purple-gradient'
            : 'border border-paleBlue'
            } `}
          onClick={() => setCurrentTab('repayBorrow')}
        >
          <span className='web'>{t("Repay_Borrow")}</span>
          <span className='mobile'>{t("Repay")}</span>
        </button>
      </div>

      <div className="w-full mt-10">
        <PrimaryList />
        {currentTab === 'borrow' ||
          (currentTab === 'repayBorrow' && isEnabledRepay) ? (
          <SecondaryList />
        ) : null}

        <div className="flex justify-center">
          {!account ? (
            <div className="flex justify-center mt-16">
              <button
                onClick={() => {
                  onCloseModal();
                  setConnectWalletsOpen(true);
                }}
                className="btn-sm btn-solid blue-purple-gradient mr-5"
              >
                {t('Connect_Wallet')}
              </button>
            </div>
          ) : currentTab === 'repayBorrow' ? (
            <div className="flex justify-center mt-16">
              {!isEnabledRepay && asset.id !== 'sei' ? (
                <button
                  disabled={
                    isLoadingRepay ||
                    !amountRepay ||
                    amountRepay.isZero() ||
                    amountRepay.isNaN()
                  }
                  onClick={() => {
                    onApprove();
                  }}
                  className="btn-sm btn-solid blue-purple-gradient mr-5 flex items-center justify-center"
                >
                  {isLoadingRepay && <Loading size={'18px'} margin={'8px'} />}{' '}
                  {t('Enable')}
                </button>
              ) : (
                <button
                  disabled={
                    isLoadingRepay ||
                    !amountRepay ||
                    amountRepay.isZero() ||
                    amountRepay.isNaN()
                  }
                  onClick={() => {
                    handleRepayBorrow();
                  }}
                  className="btn-sm btn-solid blue-purple-gradient mr-5 flex items-center justify-center"
                >
                  {isLoadingRepay && <Loading size={'18px'} margin={'8px'} />}{' '}
                  {t("Repay_Borrow")}
                </button>
              )}
            </div>
          ) : (
            <div className="flex justify-center mt-16">
              <button
                className="btn-sm btn-solid blue-purple-gradient mr-5 flex items-center justify-center"
                disabled={
                  isLoading ||
                  !amount ||
                  amount.isZero() ||
                  amount.isNaN() ||
                  amount.isGreaterThan(asset.liquidity.div(asset.tokenPrice)) ||
                  newBorrowPercent.isGreaterThan(100) ||
                  (!new BigNumber(asset.borrowCaps || 0).isZero() &&
                    amount
                      .plus(asset.totalBorrows)
                      .isGreaterThan(asset.borrowCaps))
                }
                onClick={handleBorrow}
              >
                {isLoading && <Loading size={'18px'} margin={'8px'} />}{' '}
                {t('Borrow')}
              </button>
            </div>
          )}
        </div>
        <div>
          {currentTab === 'repayBorrow' && !isEnabledRepay && (
            <div className="flex justify-between mt-6 text-white">
              <div className="text-lg">{t('Currently_Borrowing')}</div>
              <Styles>
                <div className="tooltip relative">
                  <div className="tooltip-label text-lg">
                    {asset.borrowBalance &&
                      format(asset.borrowBalance.dp(2, 1).toString(10))}{' '}
                    {asset.symbol}
                  </div>
                  <span className="label">
                    {asset.borrowBalance &&
                      format(asset.borrowBalance.toString(10))}{' '}
                    {asset.symbol}
                  </span>
                </div>
              </Styles>
            </div>
          )}
        </div>
      </div>
    </ContainerStyles>
  );

  return (
    <div>
      <Modal
        title={title}
        content={content}
        width={'max-w-xl'}
        open={open}
        onSetOpen={onSetOpen}
        onCloseModal={() => {
          setAmountRepay();
          setAmount();
          onCloseModal();
        }}
        afterCloseModal={() => setCurrentTab('borrow')}
      />
    </div>
  );
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
  connectWalletsOpen: account.connectWalletsOpen,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting, setConnectWalletsOpen } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
      setConnectWalletsOpen,
    },
    dispatch,
  );
};

export default connectAccount(
  mapStateToProps,
  mapDispatchToProps,
)(BorrowRepayModal);
