import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import * as moment from 'moment';
import { getVoteContract, methods } from '../../utilities/ContractService';
import { useActiveWeb3React } from '../../hooks';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import RouteMap from '../../routes/RouteMap';
import { ReactComponent as TickGreen } from '../../assets/icons/tickGreen.svg';
import { TickRedIcon } from 'components/Icons/TickRedIcon';
import { TickGreenIcon } from 'components/Icons/TickGreenIcon';

const Styles = styled.div`
    padding: 16px 20px;
    font-family: Inter, sans-serif;

    div.cell-container-first {
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    div.cell-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        border-left: 1px solid #717579;
        padding: 0px 0px 0px 45px;
    }

    .icon {
        height: 45px;
        width: 45px;
    }

    .custom-bg-color-lightGreen3 {
        background-color: #4fd000;
    }
    .custom-bg-color-lightRed {
        background-color: #ff0100;
    }

    .btn-queue {
        width: 75px;
        height: 21px;
        border-radius: 6px;
        border-width: 1px;
        font-size: 14px;
    }
`;

const ProposalOverview = ({ address, proposal, history }) => {
    const { requiredChainId } = useActiveWeb3React();
    const { t } = useTranslation();
    const voteContract = getVoteContract(requiredChainId);

    const getStatus = (p) => {
        if (p.state === 'Executed') {
            return 'Passed';
        }
        if (p.state === 'Active') {
            return t('Active');
        }
        if (p.state === 'Defeated') {
            return 'Failed';
        }
        return p.state;
    };

    const getStatusColor = (p) => {
        if (p.state === 'Executed') {
            return 'lightGreen3';
        }
        if (p.state === 'Active') {
            return 'lightGreen3';
        }
        if (p.state === 'Defeated') {
            return 'lightRed';
        }
        return 'lightGreen3';
    };

    const getIsHasVoted = useCallback(async () => {
        await methods.call(voteContract.methods.getReceipt, [
            proposal.id,
            address,
        ]);
    }, [address, proposal]);

    useEffect(() => {
        if (address && proposal.id) {
            getIsHasVoted();
        }
    }, [address, proposal, getIsHasVoted]);

    const getTitle = (descs) => {
        const index = descs.findIndex((d) => d !== '');
        if (index !== -1) {
            return descs[index];
        }
        return '';
    };

    return (
        <Styles
            className="flex justify-between cursor-pointer text-sm"
            onClick={() =>
                history.push(
                    RouteMap.vote.voteOverview.replace(':id', proposal.id),
                )
            }
        >
            <div>
                <ReactMarkdown className={'font-bold'}>
                    {getTitle(proposal.description?.split('\n'))}
                </ReactMarkdown>
                <div className="flex items-center gap-4 mt-2">
                    <button
                        className={`btn-queue focus:outline-none bg-transparent border-${getStatusColor(
                            proposal,
                        )} text-${getStatusColor(proposal)}`}
                    >
                        Queued
                    </button>
                    <span className="text-xs">
                        {`${proposal.id} - Queued ${moment(
                            proposal.createdAt,
                        ).format('MMMM Do, YYYY')}`}
                    </span>
                </div>
            </div>
            <div className="flex items-center justify-end">
                <span className="text-sm mr-5">{getStatus(proposal)}</span>
                {proposal.state === 'Defeated' ? (
                    <TickRedIcon />
                ) : (
                    <TickGreenIcon />
                )}
            </div>
        </Styles>
    );
};

export default compose(withRouter)(ProposalOverview);
