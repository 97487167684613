import _ from 'lodash';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import commaNumber from 'comma-number';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { connectAccount } from 'core';
import { getFormatStringFromBignumber } from 'utilities/common';
import styled from 'styled-components';

const format = commaNumber.bindWith(',', '.');

const MarketInfo = ({ marketInfo, marketType, settings }) => {
    if (!marketInfo.underlyingSymbol) return null;
    const { t } = useTranslation();

    const netRate = useMemo(() => {
        let value = 0;
        if (marketType === 'supply') {
            value = new BigNumber(
                +marketInfo.supplyApy < 0.01 ? 0.01 : marketInfo.supplyApy,
            ).plus(
                new BigNumber(
                    +marketInfo.supplyBulApy < 0.01
                        ? 0.01
                        : marketInfo.supplyBulApy,
                ),
            );
        } else {
            value = new BigNumber(
                marketInfo.borrowBulApy < 0.01 ? 0.01 : marketInfo.borrowBulApy,
            ).minus(+marketInfo.borrowApy < 0.01 ? 0.01 : marketInfo.borrowApy);
        }
        if (value.isGreaterThan(10000)) {
            return 'Infinity';
        }
        return value.dp(2, 1).toString(10);
    }, [marketInfo, marketType]);

    const supplyApy = useMemo(() => {
        let value = 0;
        if (marketType === 'supply') {
            value = new BigNumber(
                +marketInfo.supplyApy < 0.01 ? 0.01 : marketInfo.supplyApy,
            );
        } else {
            value = new BigNumber(
                +marketInfo.borrowApy < 0.01 ? 0.01 : marketInfo.borrowApy,
            );
        }
        if (value.isGreaterThan(10000)) {
            return 'Infinity';
        }
        return value.dp(2, 1).toString(10);
    }, [marketInfo, marketType]);

    const totalSupply = useMemo(() => {
        let value = new BigNumber(
            marketType === 'supply'
                ? marketInfo.totalSupplyUsd
                : marketInfo.totalBorrowsUsd,
        );

        if (value.isGreaterThan(100000000)) {
            return 'Infinity';
        }
        return value.dp(2, 1).toString(10);
    }, [marketInfo, marketType]);

    const distributionApy = useMemo(() => {
        if (
            marketInfo.underlyingSymbol === 'SEI'
        ) {
            if (marketType === 'supply') {
                return new BigNumber(marketInfo.supplyBulApy);
            } else {
                return new BigNumber(marketInfo.borrowBulApy);
            }
        } else {
            if (marketType === 'supply') {
                return new BigNumber(marketInfo.supplyAdditionalApy);
            } else {
                return new BigNumber(marketInfo.borrowAdditionalApy);
            }
        }
    }, [marketInfo, marketType]);

    return (
        <StyledMarketInfo>
            <div className="gradient-border-tb mb-6">
                <div className="flex flex-1 flex-col justify-center">
                    <span className={`text-lg text-gray5 font-bold`}>
                        {t(`Total_${_.startCase(marketType)}`)}
                    </span>
                    <span
                        className={` 
                 text-4xl md:text-2xl lg:text-4xl font-bold py-4 font-LibreFranklinBold
                 
                 `}
                    >
                        $ {format(totalSupply)}
                    </span>
                </div>
            </div>
            <div className="flex flex-1 text-lg font-bold gap-2">
                <div className="flex flex-col flex-1">
                    <span className={`text-gray5`}>{t('NET_Rate')}</span>
                    <div className="gradient-divider"></div>
                    <span
                        className={
                            marketType === 'supply'
                                ? 'text-gray5'
                                : 'text-gray3'
                        }
                    >
                        {netRate} %
                    </span>
                </div>
                <div className="flex flex-col flex-1">
                    <span className={`text-gray5`}>
                        {t('Distribution_APR')}
                    </span>
                    <div className="gradient-divider"></div>
                    <span
                        className={
                            marketType === 'supply'
                                ? 'text-gray5'
                                : 'text-gray3'
                        }
                    >
                        {getFormatStringFromBignumber(distributionApy)} %
                    </span>
                </div>
                <div className="flex flex-col flex-1">
                    <span className={`text-gray5 `}>
                        {t(_.startCase(marketType) + '_APR')}
                    </span>
                    <div className="gradient-divider"></div>
                    <span
                        className={
                            marketType === 'supply'
                                ? 'text-gray5'
                                : 'text-gray3'
                        }
                    >
                        {supplyApy} %
                    </span>
                </div>
            </div>
        </StyledMarketInfo>
    );
};

const StyledMarketInfo = styled.div`
    .gradient-border-tb {
        > div {
            background: rgba(255, 255, 255, 0.03);
            box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
            padding: 24px;
            border-radius: 8px;
        }
    }
`;

MarketInfo.defaultProps = {
    marketInfo: {},
    marketType: 'supply',
};

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {};
export default compose(
    withRouter,
    connectAccount(mapStateToProps, mapDispatchToProps),
)(MarketInfo);
