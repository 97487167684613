import React from 'react';
import styled from 'styled-components';

import { getBigNumber } from '../../utilities/common';
import BorrowLimitProgress from './BorrowLimitProgress';
import { useActiveWeb3React } from 'hooks';
import commaNumber from 'comma-number';
import { useTranslation } from 'react-i18next';
import AlertHelper from './AlertHelper';

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
  margin-top: 50px;
  margin-bottom: 75px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .custom-size {
    font-size: 4vw;
    @media (min-width: 1366px) {
      font-size: 3vw;
    }
  }
  .icon-container:hover {
    .tip {
      display: block;
    }
  }
  .tip {
    display: none;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    position: absolute;
    top: 125%;
    left: 20px;
  }
  .flex-2 {
    flex: 2;
  }
  .alert-circle {
    path {
      stroke: white;
    }
  }
  .balance-container {
    padding: 2rem;
    flex-direction: column;
    width: 100%;
    justify-content: center;

    @media only screen and (max-width: 414px) {
      padding: 0rem;
    }
  }
  .borrowLimit {
    height: 38px;
    margin-top: -1px;
  }
`;

const format = commaNumber.bindWith(',', '.');

const formatValue = (value) => {
  if (getBigNumber(value).isGreaterThan(1000000000000)) {
    return 'Infinity';
  }
  return `$${format(getBigNumber(value).dp(2, 1).toString(10))}`;
};

export default function AccountSummary({
  borrowPercent,
  totalSupplyBalance,
  totalBorrowBalance,
  totalCreditUSD,
}) {
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();

  return (
    <Wrapper className="relative bg-transparent-30">
      <section className="flex-1">
        <article className="blue-purple-gradient flex flex-col justify-center px-5 h-40 rounded-lg">
          <span className="text-sm font-semibold uppercase">
            {t('Available_Credit')}
          </span>
          <div className="mt-3 mx-auto">
            <span className="font-bold text-5xl">
              {getBigNumber(totalCreditUSD).dp(2, 1).toString(10) || 0}
            </span>
            <span className="text-sm font-bold ml-2.5">USD</span>
          </div>
        </article>

        <div className="relative flex items-center justify-between p-3 h-10">
          <span className="icon-container">
            <AlertHelper text={t('Liquidation_only_applies')} color="#fff" />
          </span>
          <span className="text-base font-medium">{t('BORROW_LIMIT')}</span>
          <span></span>
        </div>
      </section>

      <section className="flex-1">
        <article className="borber-gradient">
          <div className="flex h-60 md:h-40 flex-col md:flex-row">
            <div className="flex-1 flex items-center balance-container">
              <span className="lg:text-2xl font-bold mt-5">
                {t('Supply_Balance')}
              </span>
              <div className="mt-1 sm:mt-3">
                <span className="text-3xl font-semibold text-green">
                  {!account
                    ? '--'
                    : formatValue(
                      getBigNumber(totalSupplyBalance).dp(2, 1).toString(10),
                    )}
                </span>
              </div>
            </div>

            <div className="flex-1 flex items-center balance-container">
              <span className="lg:text-2xl font-bold mt-5">
                {t('Borrow_Balance')}
              </span>
              <div className="mt-1 sm:mt-3">
                <span className="text-3xl font-semibold text-red">
                  {!account
                    ? '--'
                    : formatValue(
                      getBigNumber(totalBorrowBalance).dp(2, 1).toString(10),
                    )}
                </span>
              </div>
            </div>
          </div>
        </article>

        <div className="flex borrowLimit">
          <BorrowLimitProgress percent={Number(borrowPercent)} />
        </div>
      </section>
    </Wrapper>
  );
}
