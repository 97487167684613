import { accountActionCreators, connectAccount } from './core';
import { bindActionCreators } from 'redux';
import * as constants from './utilities/constants';
import {
  getComptrollerContract,
  getBalance,
  methods,
} from './utilities/ContractService';
import { promisify } from './utilities';
import { useActiveWeb3React, useWeb3 } from './hooks';
import { useEffect } from 'react';
import BigNumber from 'bignumber.js';

const disabledBorrowTokens = []
const disabledBorrowRepayTokens = []

const APIProvider = ({ settings, setSetting, getGovernanceBelugas }) => {
  const { account, requiredChainId } = useActiveWeb3React();
  const web3 = useWeb3();

  const setDecimals = () => {
    const decimals = {};

    const contractAddresses = Object.values(constants.CONTRACT_TOKEN_ADDRESS[requiredChainId]).filter(item => {
      return item.id !== 'bul'
    });

    const contractDecimals =
      contractAddresses.map(item => {
        return [
          item.id,
          item.decimals,
          8,
        ]
      })

    contractDecimals.forEach(item => {
      decimals[`${item[0]}`] = {
        token: Number(item[1]),
        btoken: Number(item[2]),
        price: 18 + 18 - Number(item[1]),
      };
    });

    decimals.mantissa = +process.env.REACT_APP_MANTISSA_DECIMALS;
    decimals.comptroller = +process.env.REACT_APP_COMPTROLLER_DECIMALS;
    setSetting({ decimals });
  };

  const initSettings = async () => {
    setDecimals();
    setSetting({
      assetList: [],
      totalLiquidity: '0',
      totalSupplyBalance: '0',
      totalBorrowBalance: '0',
      totalBorrowLimit: '0',
      pendingInfo: {
        type: '',
        status: false,
        amount: 0,
        symbol: '',
      },
      markets: []
    });
  };

  useEffect(() => {
    initSettings();
  }, [requiredChainId]);

  const getMarkets = async () => {
    const res = await promisify(getGovernanceBelugas, {});
    if (!res.status || res.data.chainId !== requiredChainId) {
      return;
    }
    setSetting({
      bulPrice: res.data.bulPrice,
      markets: res.data.markets,
      dailyBelugas: res.data.dailyBelugas,
      blockNumber: res.data.blockNumber,
      farmTVL: res.data.farmTVL,
      totalDistributeAmount: res.data.totalDistributeAmount,
      remainedBalance: res.data.remainedBalance,
      circulatingSupply: res.data.circulatingSupply,
      totalLiquidity: new BigNumber(res.data.totalLiquidity).dp(2, 1).toString(10),
    });
  };

  const updateMarketInfo = async () => {
    const accountAddress = account;
    if (!settings.decimals || !settings.markets) {
      return;
    }

    const appContract = getComptrollerContract(web3, requiredChainId);
    const assetsIn = accountAddress ? await methods.call(appContract.methods.getAssetsIn, [accountAddress]) : [];

    let totalSupplyBalance = new BigNumber(0);
    let totalBorrowBalance = new BigNumber(0);
    let totalBorrowLimit = new BigNumber(0);
    let totalAvailableCredit = new BigNumber(0);
    const assetList = [];

    const contractAddresses = Object.values(constants.CONTRACT_TOKEN_ADDRESS[requiredChainId]).filter(item => {
      return settings.decimals[item.id]
    });

    const ethBalance = accountAddress ? await getBalance(web3, accountAddress, requiredChainId) : 0

    let aBepContractData = {}
    if (accountAddress) {
      const aBepContractCallContext = contractAddresses.map(item => {
        return {
          reference: item.id,
          contractAddress: constants.CONTRACT_BBEP_ADDRESS[requiredChainId][item.id].address,
          abi: item.id !== 'sei'
            ? constants.CONTRACT_BBEP_ABI
            : constants.CONTRACT_BETH_ABI
          ,
          calls: [
            { reference: 'balanceOfUnderlying', methodName: 'balanceOfUnderlying', methodParameters: [accountAddress] },
            { reference: 'borrowBalanceCurrent', methodName: 'borrowBalanceCurrent', methodParameters: [accountAddress] },
            { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [accountAddress] },
            { reference: 'borrowBalanceStored', methodName: 'borrowBalanceStored', methodParameters: [accountAddress] },
          ],
          context: {}
        }
      })
      const aBepContractResults = await methods.ethMulticall(web3, aBepContractCallContext, requiredChainId);

      for (const [itemId, value] of Object.entries(aBepContractResults?.results)) {
        aBepContractData[itemId] = {
          supplyBalance: new BigNumber(value.callsReturnContext[0].returnValues[0].hex),
          borrowBalance: new BigNumber(value.callsReturnContext[1].returnValues[0].hex),
          balance: new BigNumber(value.callsReturnContext[2].returnValues[0].hex),
          borrowBalanceStored: new BigNumber(value.callsReturnContext[3].returnValues[0].hex),
        }
      }
    } else {
      contractAddresses.forEach(item => {
        aBepContractData[item.id] = {
          supplyBalance: new BigNumber(0),
          borrowBalance: new BigNumber(0),
          balance: new BigNumber(0),
          borrowBalanceStored: new BigNumber(0),
        }
      })
    }

    let tokenContractData = {}
    if (accountAddress) {
      const tokenContractCallContext = []
      contractAddresses.forEach(item => {
        if (item.id !== 'sei') {
          tokenContractCallContext.push({
            reference: item.id,
            contractAddress: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId][item.id].address,
            abi: constants.ERC20_TOKEN_ABI,
            calls: [
              { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [accountAddress] },
              {
                reference: 'allowance',
                methodName: 'allowance',
                methodParameters: [accountAddress, constants.CONTRACT_BBEP_ADDRESS[requiredChainId][item.id].address]
              },
            ],
            context: {}
          })
        }
      })
      const tokenContractResults = await methods.ethMulticall(web3, tokenContractCallContext, requiredChainId);
      for (const [itemId, value] of Object.entries(tokenContractResults?.results)) {
        tokenContractData[itemId] = {
          balance: new BigNumber(value.callsReturnContext[0].returnValues[0].hex),
        }
      }
    } else {
      contractAddresses.forEach(item => {
        tokenContractData[item.id] = {
          balance: new BigNumber(0),
        }
      })
    }

    let contractCallData = {}
    if (accountAddress) {
      const contractCallContext = [{
        reference: 'appContract',
        contractAddress: constants.CONTRACT_COMPTROLLER_ADDRESS[requiredChainId],
        abi: constants.CONTRACT_COMPTROLLER_ABI,
        calls: [],
        context: {}
      }]
      for (const [itemId, value] of Object.entries(aBepContractData)) {
        contractCallContext[0].calls.push({
          reference: itemId,
          methodName: 'getHypotheticalAccountLiquidity',
          methodParameters: [accountAddress, constants.CONTRACT_BBEP_ADDRESS[requiredChainId][itemId].address, value.balance.toString(10), 0]
        })
      }

      const contractCallResults = await methods.ethMulticall(web3, contractCallContext, requiredChainId);

      contractCallResults?.results?.appContract?.callsReturnContext.forEach(item => {
        contractCallData[item.reference] = [
          new BigNumber(item.returnValues[0].hex).toNumber(),
          new BigNumber(item.returnValues[1].hex).toNumber(),
          new BigNumber(item.returnValues[2].hex).toNumber(),
        ]
      })
    } else {
      for (const [itemId, value] of Object.entries(aBepContractData)) {
        contractCallData[itemId] = [
          new BigNumber(0).toNumber(),
          new BigNumber(0).toNumber(),
          new BigNumber(0).toNumber(),
        ]
      }
    }

    contractAddresses.forEach((item, index) => {
      let market = settings.markets.find((ele) => {
        return item.address && ele.underlyingAddress
          ? ele.underlyingAddress.toLowerCase() === item.address.toLowerCase()
          : ele.underlyingSymbol.toLowerCase() === item.symbol.toLowerCase()
      });
      if (!market) market = {};

      const tokenDecimal = settings.decimals[item.id].token;
      const walletBalance = (item.id !== 'sei')
        ? tokenContractData[item.id].balance.div(new BigNumber(10).pow(tokenDecimal))
        : new BigNumber(ethBalance).div(new BigNumber(10).pow(tokenDecimal));
      const borrowBalance = aBepContractData[item.id].borrowBalance.div(new BigNumber(10).pow(tokenDecimal))
      const borrowBalanceStored = aBepContractData[item.id].borrowBalanceStored
      const bTokenBalance = aBepContractData[item.id].balance
      const percentOfLimit = new BigNumber(settings.totalBorrowLimit).isZero()
        ? '0'
        : borrowBalance
          .times(new BigNumber(market.tokenPrice || 0))
          .div(settings.totalBorrowLimit)
          .times(100)
          .dp(0, 1)
          .toString(10);

      const asset = {
        key: index,
        id: item.id,
        delist: false,
        disabledBorrow: disabledBorrowTokens.includes(item.symbol) ? true : false,
        disabledBorrowRepay: disabledBorrowRepayTokens.includes(item.symbol) ? true : false,
        img: `/images/coins/${(market.underlyingSymbol || item.symbol).toLowerCase()}.png`,
        aimg: `/images/coins/a${(market.underlyingSymbol || item.symbol).toLowerCase()}.png`,
        name: market.underlyingSymbol || item.symbol,
        symbol: market.underlyingSymbol || item.symbol,
        tokenAddress: item.address,
        asymbol: market.symbol,
        btokenAddress: constants.CONTRACT_BBEP_ADDRESS[requiredChainId][item.id].address,
        supplyApy: new BigNumber(market.supplyApy || 0),
        borrowApy: new BigNumber(market.borrowApy || 0),
        supplyAdditionalApy: new BigNumber(market.supplyAdditionalApy || 0),
        borrowAdditionalApy: new BigNumber(market.borrowAdditionalApy || 0),
        collateralFactor: accountAddress ? new BigNumber(market.collateralFactor || 0).div(1e18) : new BigNumber(0),
        tokenPrice: new BigNumber(market.tokenPrice || 0),
        liquidity: new BigNumber(market.liquidity || 0),
        borrowCaps: new BigNumber(market.borrowCaps || 0),
        totalBorrows: new BigNumber(market.totalBorrows2 || 0),
        supplyRate: market.supplyRate,
        borrowRate: market.borrowRate,
        totalSupply: new BigNumber(market.totalSupplyUsd),
        bulBorrowPerDay: market.bulBorrowPerDay,
        bulSupplyPerDay: market.bulSupplyPerDay,
        bulBorrowIndex: market.bulBorrowIndex,
        bulSupplyIndex: market.bulSupplyIndex,
        borrowIndex: market.borrowIndex,
        borrowBalanceStored,
        bTokenBalance,
        walletBalance,
        supplyBalance: aBepContractData[item.id].supplyBalance.div(new BigNumber(10).pow(tokenDecimal)),
        borrowBalance,
        collateral: assetsIn.includes(constants.CONTRACT_BBEP_ADDRESS[requiredChainId][item.id].address),
        percentOfLimit,
        hypotheticalLiquidity: contractCallData[item.id]
      }

      assetList.push(asset);

      const supplyBalanceUSD = asset.supplyBalance.times(asset.tokenPrice);
      const borrowBalanceUSD = asset.borrowBalance.times(asset.tokenPrice);

      totalSupplyBalance = totalSupplyBalance.plus(supplyBalanceUSD);
      totalBorrowBalance = totalBorrowBalance.plus(borrowBalanceUSD);

      const tokenCreditUsd = supplyBalanceUSD.times(asset.collateralFactor)
      totalAvailableCredit = totalAvailableCredit.plus(tokenCreditUsd)

      if (asset.collateral) {
        totalBorrowLimit = totalBorrowLimit.plus(supplyBalanceUSD.times(asset.collateralFactor));
      }
    });

    const totalWalletBalanceUSD = assetList.reduce((prev, curr) => {
      return prev.plus(curr.walletBalance.times(curr.tokenPrice))
    }, new BigNumber(0))
    totalAvailableCredit = totalAvailableCredit.minus(totalBorrowBalance)

    setSetting({
      assetList,
      totalSupplyBalance: totalSupplyBalance.toString(10),
      totalBorrowBalance: totalBorrowBalance.toString(10),
      totalBorrowLimit: totalBorrowLimit.toString(10),
      totalWalletBalanceUSD: totalWalletBalanceUSD.toString(10),
      totalAvailableCredit: totalAvailableCredit.toString(10)
    });
  };

  useEffect(() => {
    let updateTimer = setInterval(() => {
      getMarkets();
    }, 10000);
    return function cleanup() {
      if (updateTimer) {
        clearInterval(updateTimer);
      }
    };
  }, []);

  useEffect(() => {
    updateMarketInfo();
  }, [settings.markets]);

  useEffect(async () => {
    await getMarkets();
  }, [requiredChainId]);

  return null;
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting, getGovernanceBelugas } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
      getGovernanceBelugas,
    },
    dispatch,
  );
};

export default connectAccount(mapStateToProps, mapDispatchToProps)(APIProvider);
