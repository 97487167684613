import { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { useActiveWeb3React } from 'hooks';
import Layout from '../../layouts/MainLayout/MainLayout';
import Loading from '../../components/UI/Loading';
import HistoryTable from '../../components/History/HistoryTable';
import Pagination from 'components/History/Pagination';
import styled from 'styled-components';
import { restService } from 'utilities';
import noData from '../../assets/images/bin.png';
import { getEtherscanLink } from 'utils';

const ContainerStyles = styled.div`
    flex-grow: 1;

    .table-container {
        position: relative;
        border-radius: 6px;

        @media (max-width: 767px) {
        }
    }
    .tx-container {
        display: flex;
        justify-content: flex-start;
        .tx-hash {
            color: white;
            font-size: 16px;
            text-align: left;
            @media (max-width: 767px) {
                font-size: 12px;
            }
        }
        @media (max-width: 767px) {
            justify-content: flex-end;
        }
    }
    .method {
        color: white;
        font-size: 13px;
        padding: 0px 8px;
        border-radius: 5px;
        text-align: center;
        display: inline-block;
        min-width: 80px;
    }
    .block-container {
        display: flex;
        justify-content: flex-start;
        .block-hash {
            color: white;
            font-size: 16px;
            text-align: right;
            @media (max-width: 767px) {
                font-size: 12px;
            }
        }
        @media (max-width: 767px) {
            justify-content: flex-end;
        }
    }
    .age {
        text-align: right;
        color: #0094ff;

        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
    .address {
        color: white;
        font-size: 16px;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
    .value-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: white;
        img {
            // width: 20px;
            height: 20px;
            margin-right: 3px;
        }
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
`;
const FilterContainer = styled.div`
    display: flex;
    .filter-item {
        margin-right: 20px;
        display: flex;
        align-items: center;
        color: rgb(109, 111, 123);
        background: #ffffff;
        box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 3px 5px 3px 20px;
        .close {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #0000003f;
            color: #fff;
            border-radius: 10px;
            cursor: pointer;
            margin-left: 20px;
        }
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .tab-container {
        display: flex;
    }
`;
const NoAccountContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0e323b;
    height: 640px;
    border-radius: 0px 0px 10px 10px;

    .title {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: rgb(157, 159, 167);
        text-align: center;
    }
`;

const TabItem = styled.div`
    margin-right: 30px;
    font-size: 24px;
    cursor: pointer;
    font-family: LibreFranklin_Bold;
    font-size: 24px;

    color: ${(props) => (props.active ? '#fff' : 'rgba(255, 255, 255, 0.50)')};

    &:hover {
        color: rgba(255, 255, 255, 0.7);
    }
    @media (max-width: 767px) {
        font-size: 18px;
    }
`;

const History = () => {
    const { t, i18n } = useTranslation();
    const { account, requiredChainId } = useActiveWeb3React();
    const [user, setUser] = useState(false);
    const [markets, setMarkets] = useState([]);

    const [isLoading, setIsLoding] = useState(false);
    const [block, setBlock] = useState(null);
    const [fromAddress, setFromAddress] = useState(null);
    const [toAddress, setToaddress] = useState(null);

    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrntPage] = useState(1);

    const getBULInfo = async ({
        limit = 20,
        offset = 0,
        userAddress,
        from,
        to,
        bfrom,
        bto,
    }) => {
        setIsLoding(true);
        setMarkets([]);
        const apiRequest = await restService({
            chainId: requiredChainId,
            // eslint-disable-next-line max-len
            api: `/v1/user/history?limit=${limit}&offset=${offset}&userAddress=${userAddress}&fromAddress=${from}&toAddress=${to}&action=${''}&fromBlock=${bfrom}&toBlock=${bto}`,
            method: 'GET',
        });

        if (apiRequest?.data?.data) {
            setMarkets(apiRequest.data.data.rows || []);
            setTotalPage(parseInt((apiRequest.data.data.count || 0) / 20 + 1));
        }
        setIsLoding(false);
    };

    useEffect(() => {
        getBULInfo({
            offset: (currentPage - 1) * 20,
            userAddress: user ? account : '',
            from: fromAddress || '',
            to: toAddress || '',
            bfrom: block?.from || '',
            bto: block?.to || '',
        });
    }, [account, requiredChainId]);

    const initeTab = (flag) => {
        setUser(flag);
        setBlock();
        setFromAddress();
        setCurrntPage(1);
        getBULInfo({
            offset: 0,
            userAddress: flag ? account : '',
            from: '',
            to: '',
            bfrom: '',
            bto: '',
        });
    };

    const updatePage = (value) => {
        setCurrntPage(value);
        getBULInfo({
            offset: (value - 1) * 20,
            userAddress: user ? account : '',
            from: fromAddress || '',
            to: toAddress || '',
            bfrom: block?.from || '',
            bto: block?.to || '',
        });
    };

    const updateBlock = (value) => {
        setCurrntPage(1);
        setBlock(value);
        getBULInfo({
            offset: 0,
            userAddress: user ? account : '',
            from: fromAddress || '',
            to: toAddress || '',
            bfrom: value?.from || '',
            bto: value?.to || '',
        });
    };

    const updateFromAddress = (value) => {
        setCurrntPage(1);
        setFromAddress(value);
        getBULInfo({
            offset: 0,
            userAddress: user ? account : '',
            from: value || '',
            to: toAddress || '',
            bfrom: block?.from || '',
            bto: block?.to || '',
        });
    };

    const updateToAddress = (value) => {
        setCurrntPage(1);
        setToaddress(value);
        getBULInfo({
            offset: 0,
            userAddress: user ? account : '',
            from: fromAddress || '',
            to: value || '',
            bfrom: block?.from || '',
            bto: block?.to || '',
        });
    };

    const getMethodColor = (method) => {
        if (!method) return '#A8DF8E';
        if (method.toLowerCase().includes('repay')) return '#9EB384';
        if (method.toLowerCase().includes('supply')) return '#A8DF8E';
        if (method.toLowerCase().includes('borrow')) return '#7091F5';
        if (method.toLowerCase().includes('collect')) return '#793FDF';
        if (method.toLowerCase().includes('stake')) return '#97FFF4';
        if (
            method.toLowerCase().includes('redeem') ||
            method.toLowerCase().includes('withdraw')
        )
            return '#FBA1B7';
        if (method.toLowerCase().includes('liquidated')) return '#5C5470';
        return '#A8DF8E';
    };

    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                columns: [
                    {
                        Header: t('Txn_Hash'),
                        accessor: 'txHash',
                        disableFilters: true,
                        Cell: ({ value }) => {
                            return (
                                <div className="tx-container">
                                    <a
                                        className="tx-hash"
                                        href={getEtherscanLink(
                                            requiredChainId,
                                            value,
                                            'transaction',
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {value?.substring(0, 10)}...
                                    </a>
                                </div>
                            );
                        },
                    },
                    {
                        Header: t('Method'),
                        accessor: 'action',
                        Cell: ({ value }) => {
                            return (
                                <div className="flex justify-center">
                                    <div
                                        className="method"
                                        style={{
                                            border:
                                                'solid 1px ' +
                                                getMethodColor(value),
                                        }}
                                    >
                                        {value}
                                    </div>
                                </div>
                            );
                        },
                    },
                    {
                        Header: t('Block'),
                        accessor: 'blockNumber',
                        disableFilters: true,
                        Cell: ({ value }) => {
                            return (
                                <div className="block-container">
                                    <a
                                        className="block-hash"
                                        href={getEtherscanLink(
                                            requiredChainId,
                                            value,
                                            'block',
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {value}
                                    </a>
                                </div>
                            );
                        },
                    },
                    {
                        Header: t('Age'),
                        accessor: 'blockTimestamp',
                        disableFilters: true,
                        Cell: ({ value }) => {
                            let delta = Date.now() / 1000 - value;
                            var days = Math.floor(delta / 86400);
                            delta -= days * 86400;
                            var hours = Math.floor(delta / 3600) % 24;
                            if (days > 0) {
                                return (
                                    <div className="age">
                                        {`${days} ${
                                            days > 1 ? t('days') : t('day')
                                        } ${hours} ${
                                            hours > 1 ? t('hrs') : t('hr')
                                        } ${t('ago')}`}
                                    </div>
                                );
                            } else {
                                delta -= hours * 3600;
                                var minutes = Math.floor(delta / 60) % 60;
                                delta -= minutes * 60;
                                var seconds = parseInt(delta % 60);
                                if (hours > 0) {
                                    return (
                                        <div className="age">
                                            {`${hours} ${
                                                hours > 1 ? t('hrs') : t('hr')
                                            } ${minutes} ${
                                                minutes > 1
                                                    ? t('mins')
                                                    : t('min')
                                            } ${t('ago')}`}
                                        </div>
                                    );
                                }
                                return (
                                    <div className="age">
                                        {`${minutes} ${
                                            minutes > 1 ? t('mins') : t('min')
                                        } ${seconds} ${
                                            seconds > 1 ? t('secs') : t('sec')
                                        } ${t('ago')}`}
                                    </div>
                                );
                            }
                        },
                    },
                    {
                        Header: t('From'),
                        accessor: 'from',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value }) => {
                            return (
                                <div className="flex justify-end">
                                    <a
                                        className="address"
                                        href={getEtherscanLink(
                                            requiredChainId,
                                            value,
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {value?.substring(0, 4)}...
                                        {value?.substring(38)}
                                    </a>
                                </div>
                            );
                        },
                    },
                    {
                        Header: t('To'),
                        accessor: 'to',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value }) => {
                            return (
                                <div className="flex justify-end">
                                    <a
                                        className="address"
                                        href={getEtherscanLink(
                                            requiredChainId,
                                            value,
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {value?.substring(0, 4)}...
                                        {value?.substring(38)}
                                    </a>
                                </div>
                            );
                        },
                    },
                    {
                        Header: t('Value'),
                        accessor: 'value',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ row }) => {
                            const rowData = row.original;
                            const amount = new BigNumber(
                                rowData.amount,
                            ).dividedBy(new BigNumber(10).pow(rowData.decimal));
                            return (
                                <div className="value-container">
                                    <img
                                        src={`/images/coins/${rowData.symbol?.toLowerCase()}.png`}
                                        alt={rowData.symbol}
                                    />
                                    <div>
                                        {amount.toFormat(5)} {rowData.symbol}
                                    </div>
                                </div>
                            );
                        },
                    },
                ],
            },
        ];
    }, [i18n.language, requiredChainId]);

    return (
        <div className="flex flex-col">
            <Layout
                mainClassName="pt-4"
                title={'History'}
                hideFooter={true}
                noBg={true}
                hideSummary={true}
            >
                <ContainerStyles className="pt-8 text-white flex flex-col justify-between items-center grow overflow-auto">
                    <div className="max-w-7xl w-full px-8">
                        <HeaderContainer>
                            <div className="tab-container">
                                <TabItem
                                    active={!user}
                                    onClick={() => {
                                        initeTab(false);
                                    }}
                                >
                                    {t('All_History')}
                                </TabItem>
                                <TabItem
                                    active={user}
                                    onClick={() => {
                                        initeTab(true);
                                    }}
                                >
                                    {t('User_History')}
                                </TabItem>
                            </div>
                            <FilterContainer>
                                {(block?.from > 0 || block?.to > 0) && (
                                    <div className="filter-item">
                                        {t('Block')}:
                                        {block?.from > 0
                                            ? ` ${t('From')} ${block.from}`
                                            : ''}
                                        {block?.to > 0
                                            ? ` ${t('To')} ${block.to}`
                                            : ''}
                                        <div
                                            className="close"
                                            onClick={() => updateBlock()}
                                        >
                                            X
                                        </div>
                                    </div>
                                )}
                                {fromAddress && (
                                    <div className="filter-item">
                                        {t('From')}:{' '}
                                        {fromAddress?.substring(0, 4)}...
                                        {fromAddress?.substring(38)}
                                        <div
                                            className="close"
                                            onClick={() => updateFromAddress()}
                                        >
                                            X
                                        </div>
                                    </div>
                                )}
                                {toAddress && (
                                    <div className="filter-item">
                                        {t('To')}: {toAddress?.substring(0, 4)}
                                        ...
                                        {toAddress?.substring(38)}
                                        <div
                                            className="close"
                                            onClick={() => updateToAddress()}
                                        >
                                            X
                                        </div>
                                    </div>
                                )}
                            </FilterContainer>
                        </HeaderContainer>
                        <div className="table-container mt-6 mb-20 w-full">
                            <div>
                                <HistoryTable
                                    columns={columns}
                                    data={markets}
                                    block={block}
                                    setBlock={updateBlock}
                                    fromAddress={fromAddress}
                                    setFromAddress={updateFromAddress}
                                    toAddress={toAddress}
                                    setToaddress={updateToAddress}
                                />
                                {user && !account ? (
                                    <NoAccountContainer>
                                        <img src={noData} alt="" />
                                        <div className="title">
                                            {t('Connect_Wallet_transaction')}
                                        </div>
                                    </NoAccountContainer>
                                ) : isLoading ? (
                                    <NoAccountContainer>
                                        <Loading
                                            size={'36px'}
                                            margin={'0'}
                                            className={'text-midBlue'}
                                        />
                                    </NoAccountContainer>
                                ) : !markets || markets.length === 0 ? (
                                    <NoAccountContainer>
                                        <img src={noData} alt="" />
                                    </NoAccountContainer>
                                ) : (
                                    <Pagination
                                        current={currentPage}
                                        setCurrent={updatePage}
                                        total={totalPage}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </ContainerStyles>
            </Layout>
        </div>
    );
};

export default History;
