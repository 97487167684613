import Progress from '../UI/Progress';

const VoteActionDetails = ({
  title,
  percent,
  size,
  onVote,
  disabled,
  loading,
}) => (
  <div
    className={`
        px-5 py-1.5 flex justify-between items-end 
        ${disabled || loading ? ' cursor-not-allowed opacity-50' : ''}
        
        `}
  >
    <div className="flex-1">
      <div className="flex justify-start">
        <div
          className={`text-midBlue font-bold ${
            size === 'sm' ? 'text-base' : 'text-lg'
          }`}
        >
          {title}
        </div>
        <div
          className={`text-gray3 font-bold ml-5 ${
            size === 'sm' ? 'text-base' : 'text-lg'
          }`}
        >
          {percent}%
        </div>
      </div>
      <div
        className="mt-2 relative flex-1 bg-gray8 rounded-md"
        style={{ height: '30px' }}
      >
        <div
          className="absolute top-0 left-0 rounded-md"
          style={{
            width: percent + '%',
            height: '30px',
            minWidth: '12px',
            background:
              'linear-gradient(360deg, #0061A7 -2.35%, #A9D2EE 138.24%)',
          }}
        ></div>
      </div>
    </div>
    <button
      className={`focus:outline-none ml-16 px-4 py-0.5 text-xl bg-midBlue rounded text-white font-bold 
             flex items-center justify-center`}
      disabled={disabled || loading}
      style={{ height: '40px', width: '120px' }}
      onClick={() => {
        if (!disabled || loading) {
          onVote();
        }
      }}
    >
      {title === 'For' ? 'Upvote' : 'DownVote'}
    </button>
  </div>
);

export default VoteActionDetails;
