import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { MetaMask } from '@web3-react/metamask';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { BitgetWallet } from 'connectors/Bitkeep';

import menu from '../../assets/icons/menu.svg';
import close from '../../assets/icons/closeWhite.svg';
import ConnectWalletModal from './ConnectWalletModal';
import { useActiveWeb3React } from '../../hooks';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import routes from '../../routes/RouteMap';
import config from 'config';
import arrowDown from '../../assets/icons/sortDown.svg';
import { CHAIN_IDS } from 'connectors';
import { switchNetwork } from 'connectors/addNetwork';
import { isSeiNetwork, shortenAddress } from 'utils';
import { useSelector, useDispatch } from 'react-redux';
import { applicationActionCreators } from 'core';
import { getAddChainParameters } from 'chain';
import NavDropdown from 'components/UI/NavDropdown';

const Styles = styled.div`
    .main-nav {
        li {
            cursor: pointer;
        }
    }

    li.alt-price-text {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        color: #ce2322;
    }
    a.logo-link {
        padding: 7px;
        background: linear-gradient(90deg, #002d74 0%, #000220 100%);
        border-radius: 22.5px;
        display: flex;
        width: 45px;
        height: 45px;
    }
    svg.fill-blue {
        path {
            fill: #002d74;
        }
    }

    svg.w-30 {
        width: 30px;
    }

    .certik-solidproof-logo {
        background: #2b2044;
        border-radius: 4px;
        padding: 10px 8px;
        margin-right: 19px;
        img {
            width: 99px;
            height: auto;
        }
    }
    .solidproof-container {
        padding-right: 7px;
        margin-right: 7px;
        border-right: 1px solid #ffffff;
    }
    .menu-item {
        color: #ffffff;
        font-size: 13px;
        padding: 0px 18px;
        background: #3060f6;
        border-radius: 4px;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        div {
            padding-top: 2px;
        }
    }
    .btn {
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .size-28 {
        width: 28px;
        height: 28px;
    }
    .menu-container {
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #3060f6;
        border-radius: 6px;
        height: 37px;
        width: 37px;
        .menu-icon {
        }
    }

    .menu-popup {
        z-index: 1;
        background: #064076;
        padding: 10px;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        padding-top: 5vh;
        .menu-item2 {
            color: white;
            font-size: 13px;
            padding: 5px 18px;
            justify-content: center;
            display: flex;
            align-items: center;
            font-weight: bold;
        }
    }
    .custom-padding {
        padding-top: 4.5px;
        padding-bottom: 4.5px;
    }

    .wallet-botton {
        width: 100px;
        font-size: 15px;
    }
`;

const LanguageContainer = styled.div`
    color: #ffffff;
    font-size: 13px;
    background: #3060f6;
    border-radius: 4px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    .zIndex {
        z-index: 1;
    }
    .flag-img-1 {
        width: 25px;
        height: 25px;
        max-width: 25px;
    }
    .flag-img-2 {
        width: 25px;
        height: 25px;
    }
`;

const MenuItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 60px;
    cursor: pointer;
`;

const NetwokrMenuItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    width: 120px;
    cursor: pointer;
    opacity: ${(props) => (props.active ? '0.5' : '1')};
    cursor: ${(props) => (props.active ? 'not-allowed' : 'pointer')};
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    border: none;
    .flag-img-2 {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
`;

const LANGUAGES = [
    { icon: '/images/lang/en.png', value: 'en' },
    { icon: '/images/lang/ch.png', value: 'ch' },
];

const NETWORKS = [{ icon: '/images/network/sei.png', name: 'SEI Chain' }];

function Navigation({
    wrapperClassName,
    connectWalletsOpen,
    setConnectWalletsOpen,
}) {
    const currentChainId =
        useSelector(({ application }) => application.currentChainId) || 1329;
    const currentNetworkId =
        useSelector(({ application }) => application.currentNetworkId) || 0;
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const { connector, account, requiredChainId } = useActiveWeb3React();

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(
        localStorage.getItem('language') || config.defaultLanguage,
    );

    // useEffect(() => {
    //     if (currentChainId !== CHAIN_IDS[currentNetworkId]) {
    //         dispatch(
    //             applicationActionCreators.updateNetworkId({
    //                 selectedNetworkId: currentNetworkId,
    //             }),
    //         );
    //     }
    // }, [currentChainId, currentNetworkId]);

    const ConnectWallet = ({ action, btnClass }) => (
        <button
            className={`border-y-1-gradient bg-tp-10 shadow-lg hover:bg-paleBlue btn-solid px-4`}
            onClick={() => {
                if (action) {
                    action();
                }
                setConnectWalletsOpen(true);
            }}
        >
            {account ? shortenAddress(account, 3) : t('Connect_Wallet1')}
        </button>
    );

    const LanguageSelector = () => {
        return (
            <LanguageContainer>
                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className="inline-flex w-full justify-center px-4 py-2">
                            <img
                                className="flag-img-1"
                                src={`/images/lang/${selectedLanguage}.png`}
                                alt=""
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className="absolute zIndex right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg
              ring-1 ring-black ring-opacity-5 focus:outline-none"
                            style={{ zIndex: 1 }}
                        >
                            {LANGUAGES.map((info, index) => (
                                <Menu.Item key={index}>
                                    {({ active }) => (
                                        <MenuItem
                                            active={active}
                                            className="rounded-md"
                                            onClick={() => {
                                                i18n.changeLanguage(info.value);
                                                localStorage.setItem(
                                                    'language',
                                                    info.value,
                                                );
                                                setSelectedLanguage(info.value);
                                            }}
                                        >
                                            <img
                                                className="flag-img-2"
                                                src={info.icon}
                                                alt=""
                                            />
                                        </MenuItem>
                                    )}
                                </Menu.Item>
                            ))}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </LanguageContainer>
        );
    };

    const NetworkSelector = () => {
        return (
            <Menu
                as="div"
                className="relative inline-block text-left rounded-lg"
            >
                <Menu.Button className="flex items-center blue-purple-gradient btn-solid gap-2 px-4">
                    <div className="flex items-center">
                        <img
                            className="flag-img-1 w-6 h-6 mr-2"
                            src={NETWORKS[currentNetworkId].icon}
                            alt={NETWORKS[currentNetworkId].name}
                        />
                        <div>{NETWORKS[currentNetworkId].name}</div>
                    </div>
                    <img className="arrow-down" src={arrowDown} alt="" />
                </Menu.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="absolute zIndex right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-paleBlue shadow-lg
              ring-1 ring-black ring-opacity-5 focus:outline-none px-5"
                        style={{ zIndex: 1 }}
                    >
                        {NETWORKS.map((network, index) => (
                            <Menu.Item key={index}>
                                {({ active }) => (
                                    <NetwokrMenuItem
                                        active={
                                            index === Number(currentNetworkId)
                                        }
                                        className="rounded-md"
                                        onClick={async () => {
                                            if (connector instanceof MetaMask) {
                                                const switched =
                                                    await switchNetwork(
                                                        CHAIN_IDS[index],
                                                    );

                                                if (switched) {
                                                    localStorage.setItem(
                                                        'network',
                                                        index,
                                                    );
                                                    dispatch(
                                                        applicationActionCreators.updateNetworkId(
                                                            {
                                                                selectedNetworkId:
                                                                    index,
                                                            },
                                                        ),
                                                    );
                                                    connector.activate(
                                                        CHAIN_IDS[index],
                                                    );
                                                }
                                            } else if (
                                                connector instanceof
                                                WalletConnect
                                            ) {
                                                connector
                                                    .activate(CHAIN_IDS[index])
                                                    .then(() => {
                                                        localStorage.setItem(
                                                            'network',
                                                            index,
                                                        );
                                                        dispatch(
                                                            applicationActionCreators.updateNetworkId(
                                                                {
                                                                    selectedNetworkId:
                                                                        index,
                                                                },
                                                            ),
                                                        );
                                                        connector.activate(
                                                            CHAIN_IDS[index],
                                                        );
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    });
                                            } else if (
                                                connector instanceof
                                                CoinbaseWallet ||
                                                connector instanceof
                                                BitgetWallet
                                            ) {
                                                connector
                                                    .activate(
                                                        getAddChainParameters(
                                                            CHAIN_IDS[index],
                                                        ),
                                                    )
                                                    .then(() => {
                                                        localStorage.setItem(
                                                            'network',
                                                            index,
                                                        );
                                                        dispatch(
                                                            applicationActionCreators.updateNetworkId(
                                                                {
                                                                    selectedNetworkId:
                                                                        index,
                                                                },
                                                            ),
                                                        );
                                                        connector.activate(
                                                            CHAIN_IDS[index],
                                                        );
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    });
                                            } else {
                                                localStorage.setItem(
                                                    'network',
                                                    index,
                                                );
                                                dispatch(
                                                    applicationActionCreators.updateNetworkId(
                                                        {
                                                            selectedNetworkId:
                                                                index,
                                                        },
                                                    ),
                                                );
                                            }
                                        }}
                                    >
                                        <img
                                            className="flag-img-2"
                                            src={network.icon}
                                            alt=""
                                        />
                                        <div>{network.name}</div>
                                    </NetwokrMenuItem>
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>
        );
    };

    const desktopNav = () => {
        return (
            <ul className="main-nav hidden lg:flex justify-end items-center gap-5">
                <Link to='/'>
                    <li className="blue-purple-gradient btn-solid">
                        {t('APP')}
                    </li>
                </Link>
                <Link to={routes.market.index}>
                    <li className="blue-purple-gradient btn-solid">
                        {t('MARKET')}
                    </li>
                </Link>
                <Link to={routes.stake}>
                    <li className="blue-purple-gradient btn-solid">
                        {t('STAKING')}
                    </li>
                </Link>
                <Link to={routes.history}>
                    <li className="blue-purple-gradient btn-solid">
                        {t('HISTORY')}
                    </li>
                </Link>
                <NavDropdown
                    label={t("MANAGE")}
                    options={[
                        // { label: t('REFERRAL'), link: routes.referral },
                        // { label: t('LIQUIDATE'), link: routes.liquidate },
                        { label: t('BUL'), link: routes.belugas },
                        // { label: t('VOTE'), link: routes.vote.index },
                    ]}
                />

                {/* <li className="ml-2">{NetworkSelector()}</li> */}

                <li className="ml-2">
                    <ConnectWallet />
                </li>
            </ul>
        );
    };

    const mobileNav = () => {
        return (
            <ul className="lg:hidden flex justify-end items-center pl-6">
                {/* <li>
                    <div className="flex w-full justify-center mr-5">
                        {NetworkSelector()}
                    </div>
                </li> */}
                <li className="flex justify-end ml-3 mt-0">
                    <div
                        className="relative menu-container"
                        onClick={() => {
                            setShowMobileMenu(!showMobileMenu);
                        }}
                    >
                        {showMobileMenu ? (
                            <img src={close} alt="menu" className="menu-icon" />
                        ) : (
                            <img src={menu} alt="menu" className="menu-icon" />
                        )}
                    </div>
                </li>
                <div
                    className={`absolute menu-popup ${showMobileMenu ? 'block' : 'hidden'}`}
                >
                    <div className="flex w-full mt-2">
                        <Link to="/" className={'menu-item2 w-full'}>
                            {t('APP')}
                        </Link>
                    </div>
                    <div className="flex w-full mt-2">
                        <Link
                            to={routes.market.index}
                            className={'menu-item2 w-full'}
                        >
                            {t('MARKET')}
                        </Link>
                    </div>
                    <div className="flex w-full mt-2">
                        <Link
                            to={routes.stake}
                            className={'menu-item2 w-full'}
                        >
                            {t('STAKING')}
                        </Link>
                    </div>
                    {/* <div className="flex w-full mt-2">
                        <Link
                            to={routes.referral}
                            className={'menu-item2 w-full'}
                        >
                            {t('REFERRAL')}
                        </Link>
                    </div> */}
                    <div className="flex w-full mt-2">
                        <Link
                            to={routes.history}
                            className={'menu-item2 w-full'}
                        >
                            {t('HISTORY')}
                        </Link>
                    </div>
                    {/* <div className="flex w-full mt-2">
                        <Link
                            to={routes.liquidate}
                            className={'menu-item2 w-full'}
                        >
                            {t('LIQUIDATE')}
                        </Link>
                    </div> */}
                    <div className="flex w-full mt-2">
                        <Link
                            to={routes.belugas}
                            className={'menu-item2 w-full'}
                        >
                            {t('BUL')}
                        </Link>
                    </div>
                    <div className="flex w-full mt-2">
                        <Link
                            to={routes.vote.index}
                            className={'menu-item2 w-full'}
                        >
                            {t('VOTE')}
                        </Link>
                    </div>
                    <div className="flex w-full mt-2 pt-5 justify-center">
                        <ConnectWallet
                            btnClass={
                                'wallet-botton focus:outline-none bg-midBlue py-4 px-5 rounded text-white'
                            }
                        />
                    </div>
                </div>
            </ul>
        );
    };

    return (
        <Styles className={`w-full ${wrapperClassName}`}>
            <ConnectWalletModal
                open={connectWalletsOpen}
                onSetOpen={() => setConnectWalletsOpen(true)}
                onCloseModal={() => setConnectWalletsOpen(false)}
            />

            {desktopNav()}
            {mobileNav()}
        </Styles>
    );
}

export default Navigation;
