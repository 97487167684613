import React, { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Modal from '../UI/Modal';
import { Logo } from 'components/Icons/Logo';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import metaMask from '../../assets/icons/metaMask.svg';
import { CloseIcon } from './IconClose';
import bitkeep from '../../assets/icons/bitkeep.svg';
import walletConnect from '../../assets/icons/walletConnect.svg';
import coinbaseWallet from '../../assets/icons/coinbaseWallet.svg';
import connectors, {
    CHAIN_IDS,
    connectorLocalStorageKey,
    installExtentionLinks,
} from '../../connectors';
import useCopyClipboard from '../../hooks/useCopyClipboard';
import ExternalLinkIcon from '../../assets/icons/externalLink.svg';
import styled from 'styled-components';
import { restService } from 'utilities';
import { getAddChainParameters } from 'chain';
import { NotificationManager } from 'react-notifications';
import { getEtherscanLink, shortenAddress } from 'utils';
import { useActiveWeb3React } from 'hooks';
import { MetaMask } from '@web3-react/metamask';

const Styles = styled.div`
    background: #064076;

    .settings-close-container {
        position: absolute;
        right: 2rem;
        top: 4rem;
        outline: none;
        background: transparent;
        cursor: pointer;
    }

    svg.logo-icon {
        width: 80px;
        height: auto;
    }

    div.wallet-container {
        width: 100%;
        overflow: hidden;
    }

    div.subText {
        color: #657786;
        a {
            // color: #00296D;
            text-decoration: underline;
        }
    }

    .custom-width-N-border-bottom {
        width: 45%;
        border-bottom: 2px solid #0061a7;
    }
    svg.link,
    svg.copy {
        path {
            fill: #0061a7;
        }
    }

    .arrow-fill {
        transform: rotate(180deg);
        path {
            stroke: #979797;
            stroke-width: 3px;
        }
    }
`;

function ConnectWalletModal({ open, onSetOpen, onCloseModal }) {
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const tempReferral = queryParameters.get('ref');

    const { account, requiredChainId, chainId, connector, provider, isActive } =
        useActiveWeb3React();
    const { t } = useTranslation();
    const [isCopied, setCopied] = useCopyClipboard();

    const [pendingError, setPendingError] = useState();

    useEffect(() => {
        if (open) {
            setPendingError();
        }
    }, [open]);

    const setReferralRelation = async () => {
        const apiRequest = await restService({
            chainId: requiredChainId,
            api: `/v1/referralRelation?address=${account}&referral=${tempReferral}`,
            method: 'GET',
        });

        if (apiRequest?.data?.status) {
            const msgParams = JSON.stringify({
                types: {
                    EIP712Domain: [{ name: 'name', type: 'string' }],
                    Belugas: [
                        { name: 'Description', type: 'string' },
                        { name: 'Code', type: 'string' },
                    ],
                },
                primaryType: 'Belugas',
                domain: {
                    name: 'Belugas',
                },
                message: {
                    Description:
                        'Please sign to update your active referral code.',
                    Code: tempReferral,
                },
            });

            provider
                .send('eth_signTypedData_v4', [account, msgParams])
                .then(async (signature) => {
                    const apiResponse = await restService({
                        chainId: requiredChainId,
                        api: `/v1/referralRelation`,
                        method: 'POST',
                        params: {
                            address: account,
                            referral: tempReferral,
                            signature: signature,
                        },
                    });
                    if (apiResponse?.status) {
                        NotificationManager.success(
                            t('referral_updated_successfully'),
                        );
                    }
                });
        }
    };

    useEffect(() => {
        if (account && isActive) {
            const lendingRisks = localStorage.getItem('LendingRisk') || {};
            if (!lendingRisks[account]) {
                onCloseModal();
            }
            if (tempReferral && tempReferral.length === 8) {
                setReferralRelation();
            }
        }
    }, [account]);

    const tryActivation = async (provider) => {
        setPendingError();
        const tempConnector = provider.provider;

        if (
            CHAIN_IDS[localStorage.getItem('network') || 0] ===
            requiredChainId &&
            account
        ) {
            return;
        }

        if (tempConnector instanceof WalletConnect) {
            await tempConnector
                .activate(CHAIN_IDS[localStorage.getItem('network') || 0])
                .then(() => {
                    window.localStorage.setItem(
                        connectorLocalStorageKey,
                        provider.id,
                    );
                })
                .catch((error) => {
                    console.log(error);
                    if (error?.code === 4001) {
                        setPendingError({ desc: t('User_rejected') });
                    } else {
                        setPendingError({ desc: error?.message });
                    }
                });
        } else {
            if (
                provider.id === 2 &&
                (!window.bitkeep || !window.bitkeep.ethereum)
            ) {
                // Bitkeep
                setPendingError({
                    desc: t(installExtentionLinks[provider.id].desc),
                    linkDesc: t(installExtentionLinks[provider.id].linkDesc),
                    link: t(installExtentionLinks[provider.id].link),
                });
                return;
            }

            if (tempConnector instanceof MetaMask) {
                const provider = window.ethereum
                if (provider) {
                    const chainId = parseInt(requiredChainId, 10)
                    try {
                        await provider.request({
                            method: 'wallet_switchEthereumChain',
                            params: [
                                {
                                    chainId: `0x${chainId.toString(16)}`,
                                },
                            ],
                        })
                    } catch (error) {
                        console.error(error)
                    }
                }
            }

            await tempConnector
                .activate(
                    getAddChainParameters(
                        CHAIN_IDS[localStorage.getItem('network') || 0],
                    ),
                )
                .then(() => {
                    window.localStorage.setItem(
                        connectorLocalStorageKey,
                        provider.id,
                    );
                })
                .catch((error) => {
                    console.log({ error });
                    if (
                        error?.name === 'NoMetaMaskError' ||
                        error?.name === 'r' ||
                        !window.ethereum
                    ) {
                        setPendingError({
                            desc: t(installExtentionLinks[provider.id].desc),
                            linkDesc: t(
                                installExtentionLinks[provider.id].linkDesc,
                            ),
                            link: t(installExtentionLinks[provider.id].link),
                        });
                    } else if (error?.code === 4001) {
                        setPendingError({ desc: t('User_rejected') });
                    } else {
                        setPendingError({ desc: error?.message });
                    }
                });
        }
    };

    const logout = async () => {
        window.localStorage.removeItem(connectorLocalStorageKey);
        if (account) {
            if (connector?.deactivate) {
                void connector?.deactivate();
            } else {
                void connector?.resetState();
            }
        }
    };

    const title = useMemo(() => {
        return (
            <Styles className="flex flex-col px-10 pt-6 mt-10 rounded-t-lg">
                <Logo />
                <div
                    className="settings-close-container"
                    onClick={onCloseModal}
                >
                    <CloseIcon />
                </div>

                {account ? (
                    <div className="text-2xl text-white font-medium mt-7 text-center">
                        {t('Your_Wallet')}
                    </div>
                ) : (
                    <div className="text-white flex flex-col justify-center items-center mt-7">
                        <p className="text-2xl font-semibold">Connect Wallet</p>
                        <p className="text-lg mt-2.5 font-light">
                            To start using Belugas
                        </p>
                    </div>
                )}
            </Styles>
        );
    }, [account, t]);

    const content = account ? (
        <Styles className="px-14">
            <div className="py-14">
                <div className="flex flex-col items-center justify-between">
                    <div
                        className="grid justify-center w-full text-white text-lg sm:text-xl md:text-2xl font-medium mb-14"
                        style={{
                            gridTemplateColumns: '1fr 1fr 1fr',
                            alignItems: 'center',
                        }}
                    >
                        <span>{shortenAddress(account, 14)}</span>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full mb-14 text-lg font-medium">
                        <a
                            href={getEtherscanLink(
                                requiredChainId,
                                account,
                                'address',
                            )}
                            className="text-white flex items-center justify-between custom-width-N-border-bottom"
                            target={'_blank'}
                            rel={'noreferrer noopener'}
                        >
                            {t('View_on_Explorer')} &nbsp;
                            <SVG src={ExternalLinkIcon} className={'link'} />
                        </a>
                        <div
                            onClick={setCopied.bind(this, account)}
                            className="cursor-pointer text-white flex items-center justify-between custom-width-N-border-bottom"
                        >
                            {isCopied ? t('Copied') : t('Copy_Address')} &nbsp;
                            <Copy className="copy" />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row items-stretch lg:items-center justify-center">
                        <button
                            className="blue-purple-gradient text-white rounded-lg px-32 py-4 uppercase font-bold focus:outline-none "
                            onClick={logout}
                        >
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </Styles>
    ) : (
        <Styles className="px-14 pb-14">
            <div className="flex flex-col justify-between text-white">
                {connectors.map((connector, index) => (
                    <div
                        key={index}
                        className={`cursor-pointer ${index === 1 || index === 2 ? 'box-gradient' : ''
                            } py-2`}
                        onClick={tryActivation.bind(this, connector)}
                    >
                        <div className="flex justify-between items-center flex-row py-2 w-full">
                            <div className="flex justify-between items-center">
                                <img
                                    className="w-12 h-12"
                                    src={
                                        [
                                            metaMask,
                                            bitkeep,
                                            walletConnect,
                                            coinbaseWallet,
                                        ][index]
                                    }
                                    alt="arrow"
                                />
                                <div className="text-semibold text-xl font-bold mt-4 mb-4 ml-6">
                                    {t(connector.name)}
                                </div>
                            </div>
                            <Arrow />
                        </div>
                    </div>
                ))}
            </div>
            {pendingError ? (
                <div className="text-center mt-10">
                    <div className="text-red1">{pendingError.desc}</div>
                    {pendingError.link && (
                        <a
                            href={pendingError.link}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue3"
                        >
                            <b>{pendingError.linkDesc}</b>
                        </a>
                    )}
                </div>
            ) : (
                <div className="text-base text-center mt-10 text-white">
                    By connecting, I accept Belugas
                    <a
                        href="https://www.belugas.io/terms"
                        className="text-white ml-2"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('Terms_of_Service')}
                    </a>
                </div>
            )}
        </Styles>
    );

    return (
        <div className="connectWalletModal hidden lg:flex">
            <Modal
                title={title}
                content={content}
                open={open}
                onSetOpen={onSetOpen}
                onCloseModal={onCloseModal}
                afterCloseModal={() => { }}
                width="max-w-xl"
            />
        </div>
    );
}

export default ConnectWalletModal;
