import ConnectWalletModal from "./ConnectWalletModal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ConnectWalletButton = (props) => {
    const { t } = useTranslation();
    const [connectWalletsOpen, setConnectWalletsOpen] = useState(false);
    return (
        <>
            <button
                className={`${props.className} font-inter focus:outline-none bg-blue-gradient rounded-lg text-white text-lg font-medium py-4 px-24`}
                onClick={() => setConnectWalletsOpen(true)}
            >
                {t("Connect_Wallet")}
            </button>
            <ConnectWalletModal
                open={connectWalletsOpen}
                onSetOpen={() => setConnectWalletsOpen(true)}
                onCloseModal={() => setConnectWalletsOpen(false)}
            />
        </>
    )
}

export default ConnectWalletButton;
