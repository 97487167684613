import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Logo } from 'components/Icons/Logo';
import { ModalCloseIcon } from 'components/Icons/ModalCloseIcon';

const Styles = styled.div`
    .background {
        border-radius: 6px;
        background: rgba(6, 64, 118, 1);
    }
`;

export default function BlueModal({
    open,
    header,
    title,
    content,
    onCloseModal,
    afterCloseModal,
    width,
    containerClass,
}) {
    const cancelButtonRef = useRef();

    function closeModal() {
        onCloseModal();
        if (afterCloseModal) {
            afterCloseModal();
        }
    }

    return (
        <div className="relative">
            <Transition show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-30 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    static
                    open={open}
                    onClose={closeModal}
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-75" />
                    <Styles className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className={`inline-block relative w-full my-8 background text-white
              text-left align-middle transition-all transform shadow-xl rounded-2xl ${
                  width ? width : 'max-w-2xl'
              } ${containerClass}`}
                            >
                                <div className="flex justify-between py-6 px-10">
                                    {header ? header : <Logo />}
                                    <div
                                        className="cursor-pointer"
                                        onClick={closeModal}
                                    >
                                        <ModalCloseIcon />
                                    </div>
                                </div>
                                <p className="font-inter text-center font-bold text-2xl">
                                    {title}
                                </p>
                                <div>{content}</div>
                                <button className="hide"></button>
                            </div>
                        </Transition.Child>
                    </Styles>
                </Dialog>
            </Transition>
        </div>
    );
}
