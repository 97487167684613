import styled from 'styled-components';
import React, { useMemo } from 'react';

const Border = styled.div`
  width: 100%;
  padding: 0.5px;
`;

const Container = styled.div`
  height: 100%;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Line = styled.div`
  border-radius: 20px;
  height: 10px;
  transition: 0.5s ease all;
`;

const FilledBar = styled(Line)`
  height: 100%;
  width: ${({ percent }) => `calc(${percent}%)`};
  min-width: 52px;
  font-weight: 500;
  box-shadow: 4px 6px 61px 0px rgba(99, 104, 231, 0.2);
  background: linear-gradient(90deg, #4776e6 0%, #0061a7 100%);
  border-radius: 8px;
  color: #ffffff;
  padding: 5px;

  @media only screen and (max-width: 414px) {
    min-width: 42px;
  }
`;

const BorrowLimitProgress = ({ percent }) => {
  const fixedPercent = useMemo(() => {
    let result = Number(percent);
    if (!percent || percent === 0) {
      result = 0;
    } else if (percent > 100) {
      result = 100;
    } else if (percent < 0) {
      result = 0;
    }

    return result.toFixed(2);
  }, [percent]);
  return (
    <Border>
      <Container>
        <FilledBar
          percent={fixedPercent}
          className="text-xm font-light text-black flex justify-end items-center"
        >
          {fixedPercent}%
        </FilledBar>
      </Container>
    </Border>
  );
};

export default BorrowLimitProgress;
