import { ReactComponent as TickGreen } from '../../../assets/icons/tickGreenDash.svg';
import { ReactComponent as TickGray } from '../../../assets/icons/tickGrayDash.svg';
import lineBg from '../../../assets/images/line-bg.svg';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TickGreenIcon } from 'components/Icons/TickGreenIcon';
import { TickGrayIcon } from 'components/Icons/TickGrayIcon';

const Styles = styled.div`
    // flex: 1;
    background: rgba(0, 0, 0, 0.1);
    width: 34%;
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;

const STATUSES = ['Pending', 'Active', 'Succeeded', 'Queued', 'Executed'];

const ProposalHistory = ({ proposalInfo }) => {
    const { t } = useTranslation();

    const getStepNumber = () => {
        if (
            proposalInfo.state === 'Defeated' ||
            proposalInfo.state === 'Canceled'
        )
            return 2;
        return STATUSES.findIndex((s) => s === proposalInfo.state);
    };

    return (
        <Styles className="rounded px-4 py-5 border-y-2-gradient">
            <div className="text-2xl font-semibold pb-4 border-b-1-gradient">
                {t('Proposal_history')}
            </div>
            <div className="mt-8">
                <div className="flex flex-col gap-3 py-4 px-8">
                    <div className={'flex items-center text-sm'}>
                        <div className={'flex items-center gap-4'}>
                            {getStepNumber() >= 0 ? (
                                <TickGreenIcon />
                            ) : (
                                <TickGrayIcon />
                            )}
                            <div className="border-b border-white w-14" />
                            <span
                                className={`font-bold 
              ${
                  getStepNumber() >= 0 && proposalInfo.createdTimestamp
                      ? 'text-midBlue'
                      : ''
              }`}
                            >
                                {t('Created')}
                            </span>
                        </div>
                    </div>
                    <span className={'text-xs text-right'}>
                        {proposalInfo.createdTimestamp
                            ? moment(
                                  proposalInfo.createdTimestamp * 1000,
                              ).format('LLL')
                            : ''}
                    </span>
                </div>

                <div className="flex flex-col gap-3 py-4 px-8">
                    <div className={'flex items-center text-sm'}>
                        <div className={'flex items-center gap-4'}>
                            {getStepNumber() >= 1 ? (
                                <TickGreenIcon />
                            ) : (
                                <TickGrayIcon />
                            )}
                            <div className="border-b border-white w-14" />
                            <span
                                className={`font-bold 
              ${
                  getStepNumber() >= 1 && proposalInfo.startTimestamp
                      ? 'text-midBlue'
                      : ''
              }`}
                            >
                                {t('Active')}
                            </span>
                        </div>
                    </div>
                    <span className={'text-xs text-right'}>
                        {proposalInfo.startTimestamp
                            ? moment(proposalInfo.startTimestamp * 1000).format(
                                  'LLL',
                              )
                            : ''}
                    </span>
                </div>

                <div className="flex flex-col gap-3 py-4 px-8">
                    <div className={'flex items-center text-sm'}>
                        <div className={'flex items-center gap-4'}>
                            {proposalInfo.state === 'Canceled' ||
                            proposalInfo.state === 'Defeated' ? (
                                <TickGrayIcon />
                            ) : getStepNumber() >= 2 ? (
                                <TickGreenIcon />
                            ) : (
                                <TickGrayIcon />
                            )}
                            <div className="border-b border-white w-14" />
                            <span
                                className={`font-bold 
              ${
                  proposalInfo.state === 'Canceled' ||
                  proposalInfo.state === 'Defeated'
                      ? ''
                      : getStepNumber() >= 2 && proposalInfo.endTimestamp
                      ? 'text-midBlue'
                      : ''
              }`}
                            >
                                {proposalInfo.state === 'Canceled' ||
                                proposalInfo.state === 'Defeated'
                                    ? `${
                                          proposalInfo.state === 'Defeated'
                                              ? t('Failed')
                                              : t('Canceled')
                                      }`
                                    : `${
                                          proposalInfo.state === 'Succeeded'
                                              ? t('Succeeded')
                                              : t('Succeed')
                                      }`}
                            </span>
                        </div>
                    </div>
                    <span className={'text-xs text-right'}>
                        {proposalInfo.endTimestamp
                            ? moment(proposalInfo.endTimestamp * 1000).format(
                                  'LLL',
                              )
                            : `${
                                  proposalInfo.cancelTimestamp
                                      ? moment(
                                            proposalInfo.cancelTimestamp * 1000,
                                        ).format('LLL')
                                      : ''
                              }`}
                    </span>
                </div>

                {proposalInfo.state !== 'Defeated' &&
                    proposalInfo.state !== 'Canceled' && (
                        <div className="flex flex-col gap-3 py-4 px-8">
                            <div className={'flex items-center gap-4'}>
                                {getStepNumber() >= 3 ? (
                                    <TickGreenIcon />
                                ) : (
                                    <TickGrayIcon />
                                )}
                                <div className="border-b border-white w-14" />
                                <span
                                    className={`font-bold 
                ${
                    getStepNumber() >= 3 || proposalInfo.queuedTimestamp
                        ? 'text-midBlue'
                        : ''
                }`}
                                >
                                    {proposalInfo.state === 'Queued'
                                        ? t('Queued')
                                        : t('Queue')}
                                </span>
                            </div>
                            <span className={'text-xs text-right'}>
                                {proposalInfo.queuedTimestamp
                                    ? moment(
                                          proposalInfo.queuedTimestamp * 1000,
                                      ).format('LLL')
                                    : ''}
                            </span>
                        </div>
                    )}

                {proposalInfo.state !== 'Defeated' &&
                    proposalInfo.state !== 'Canceled' && (
                        <div className="flex flex-col gap-3 py-4 px-8">
                            <div className={'flex items-center gap-4'}>
                                {getStepNumber() >= 4 ? (
                                    <TickGreenIcon />
                                ) : (
                                    <TickGrayIcon />
                                )}
                                <div className="border-b border-white w-14" />
                                <span
                                    className={`text-2xl font-bold 
                ${getStepNumber() >= 4 ? 'text-lightGreen' : 'text-gray'}`}
                                >
                                    <div className="tooltip relative">
                                        <div
                                            className={`${
                                                getStepNumber() >= 3 &&
                                                proposalInfo.executedTimestamp
                                                    ? 'text-midBlue'
                                                    : 'text-gray'
                                            } text-xl flex items-center`}
                                        >
                                            {proposalInfo.state === 'Expired'
                                                ? t('Expired')
                                                : `${
                                                      proposalInfo.state ===
                                                      'Executed'
                                                          ? t('Executed')
                                                          : t('Execute')
                                                  }`}
                                            {proposalInfo?.eta !== 0 && (
                                                <img
                                                    className="ml-3 tooltip-label"
                                                    src={
                                                        require('../../../assets/images/info.svg')
                                                            .default
                                                    }
                                                    alt=""
                                                />
                                            )}
                                            <span
                                                className="label"
                                                style={{
                                                    left: '-35%',
                                                    bottom: '30px',
                                                }}
                                            >
                                                {t('Executable_Date')}:{' '}
                                                {moment(
                                                    proposalInfo?.eta * 1000,
                                                ).format('LLLL')}
                                            </span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <span className={'text-lg font-bold mt-1.5'}>
                                {proposalInfo.executedTimestamp
                                    ? moment(
                                          proposalInfo.executedTimestamp * 1000,
                                      ).format('LLL')
                                    : ''}
                            </span>
                        </div>
                    )}
            </div>
        </Styles>
    );
};

ProposalHistory.defaultProps = {
    proposalInfo: {},
};

export default ProposalHistory;
