import moment from 'moment';
import Markdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

const ProposalInfo = ({ proposalInfo }) => {
    const { t } = useTranslation();

    const getDescription = (p) => {
        if (p === 'Create Proposal') {
            return t('Create_Proposal');
        }
        return p;
    };

    const getStatus = (p) => {
        if (p.state === 'Executed') {
            return t('Passed');
        }
        if (p.state === 'Active') {
            return t('Active');
        }
        if (p.state === 'Defeated') {
            return t('Failed');
        }
        if (p.state === 'Expired') {
            return t('Expired');
        }
        return p.state;
    };

    const getStatusColor = (p) => {
        if (p.state === 'Defeated') {
            return 'red';
        }
        return 'lightGreen';
    };

    const getRemainTime = (item) => {
        if (item.state === 'Active') {
            const diffBlock = item.endBlock - item.blockNumber;
            const duration = moment.duration(
                diffBlock < 0 ? 0 : diffBlock * 3,
                'seconds',
            );
            const days = Math.floor(duration.asDays());
            const hours = Math.floor(duration.asHours()) - days * 24;
            const minutes =
                Math.floor(duration.asMinutes()) - days * 24 * 60 - hours * 60;

            return `${
                days > 0 ? `${days} ${days > 1 ? 'days' : 'day'},` : ''
            } ${hours} ${hours > 1 ? 'hrs' : 'hr'} ${
                days === 0
                    ? `, ${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`
                    : ''
            } left`;
        }
        if (item.state === 'Pending') {
            return `${moment(item.createdTimestamp * 1000).format(
                'MMMM DD, YYYY',
            )}`;
        }
        if (item.state === 'Active') {
            return `${moment(item.startTimestamp * 1000).format(
                'MMMM DD, YYYY',
            )}`;
        }
        if (item.state === 'Canceled' || item.state === 'Defeated') {
            return `${moment(item.endTimestamp * 1000).format(
                'MMMM DD, YYYY',
            )}`;
        }
        if (item.state === 'Queued') {
            return `${moment(item.queuedTimestamp * 1000).format(
                'MMMM DD, YYYY',
            )}`;
        }
        if (item.state === 'Expired' || item.state === 'Executed') {
            return `${moment(item.executedTimestamp * 1000).format(
                'MMMM DD, YYYY',
            )}`;
        }
        return `${moment(item.updatedAt).format('MMMM DD, YYYY')}`;
    };

    return (
        <div className="">
            <div className="flex items-center">
                <div className="">
                    {`${proposalInfo.id} ${getStatus(proposalInfo)} ${moment(
                        proposalInfo.updatedAt,
                    ).format('MMMM DD, YYYY')}`}
                </div>

                <div className={`text-${getStatusColor(proposalInfo)} ml-8`}>
                    •&nbsp;&nbsp;{getStatus(proposalInfo)}
                </div>
                <div className="ml-4">{getRemainTime(proposalInfo)}</div>
            </div>
            <div className="text-2xl mt-4 font-bold">
                <Markdown>
                    {getDescription(proposalInfo.description?.split('\n')[0])}
                </Markdown>
            </div>
        </div>
    );
};

ProposalInfo.defaultProps = {
    proposalInfo: {},
};
export default ProposalInfo;
