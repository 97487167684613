import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFormatStringFromBignumber } from 'utilities/common';
import whaleImg from '../../assets/images/whaleImg.png';

const StyledConatiner = styled.div`
    margin-bottom: 40px;

    .main-container {
        display: block;

        > div {
            width: 100%;
        }

        @media (min-width: 768px) {
            display: flex;
            max-width: 100%;
            padding: 0px 20px;
        }
    }

    .container {
        position: relative;
        background: rgba(255, 255, 255, 0.03);
        box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
        padding: 60px;
        border-radius: 8px;
        margin: 0;

        .display-grid {
            display: grid;
            column-gap: 0em;
            row-gap: 1.5em;
            grid-template-columns: repeat(6, 1fr);

            @media only screen and (max-width: 768px) {
                grid-template-columns: repeat(2, 1fr);
            }

            .info_row {
                .title {
                    font-size: 14px;
                    font-family: LibreFranklin_Thin;
                    font-weight: 400;
                    margin-bottom: 16px;
                }
                .value {
                    font-size: 25px;
                    font-family: LibreFranklin_Bold;
                    font-weight: 900;
                }

                .desc {
                    font-family: LibreFranklin_Thin;
                    font-size: 12px;
                    color: #748088;
                    margin-top: 10px;
                }

                .value-container {
                    display: flex;
                    align-items: center;
                    img {
                        margin-right: 5px;
                        // width: 25px;
                        height: 25px;
                    }
                }

                @media only screen and (max-width: 768px) {
                    .title {
                        font-size: 12px;
                    }
                    .value {
                        font-size: 20px;
                    }
                    .desc {
                        font-size: 10px;
                    }
                    .value-container {
                        img {
                            margin-right: 3px;
                            // width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }
`;

const StyledWhale = styled.div`
    position: absolute;
    width: 200px;
    height: 200px;
    background-image: url(${whaleImg});
    background-size: contain;
    background-repeat: no-repeat;
    right: 100px;
    top: 10px;
`;

function Info({
    myDLpLocked,
    myShare,
    dLpLocked,
    dLpPrice,
    autoRelockDisabled,
    lockList,
    annualReward,
}) {
    const { t } = useTranslation();
    const totalSupplyBalance = useSelector(
        (state) => state.account.setting.totalSupplyBalance,
    );
    const totalBorrowBalance = useSelector(
        (state) => state.account.setting.totalBorrowBalance,
    );
    
    const averageMultiplier = useMemo(() => {
        if (lockList.length === 0) {
            return '0.00';
        } else {
            let multiplier = new BigNumber(0);
            let amount = new BigNumber(0);
            for (let index = 0; index < lockList.length; index++) {
                multiplier = multiplier.plus(
                    lockList[index].multiplier.times(lockList[index].amount),
                );
                amount = amount.plus(lockList[index].amount);
            }
            return getFormatStringFromBignumber(multiplier.div(amount));
        }
    }, [lockList]);

    return (
        <StyledConatiner className="max-w-7xl w-full ">
            <div className="main-container mt-1 mx-auto gap">
                <div className="gradient-border-tb">
                    <div className="container ">
                        <StyledWhale />
                        <div className="display-grid">
                            <div className="info_row">
                                <div className="title">
                                    {t('Your_locked_dLP')}
                                </div>
                                <div className="value-container">
                                    <img
                                        src={`/images/coins/dlp_0.png`}
                                        alt={'bul'}
                                    />
                                    <div className={`value`}>
                                        {getFormatStringFromBignumber(
                                            myDLpLocked.div(1e18),
                                        )}
                                    </div>
                                </div>
                                <div className="desc">
                                    {t('Auto_relock')}:{' '}
                                    {autoRelockDisabled
                                        ? t('Disabled')
                                        : t('Enabled')}
                                </div>
                            </div>
                            <div className="info_row">
                                <div className="title">{t('dLP_locked')}</div>
                                <div className={`value`}>
                                    {getFormatStringFromBignumber(
                                        dLpLocked.div(1e18),
                                    )}
                                </div>
                                <div className="desc">
                                    {getFormatStringFromBignumber(
                                        dLpLocked.times(dLpPrice).div(1e18),
                                    )}{' '}
                                    USD
                                </div>
                            </div>
                            <div className="info_row">
                                <div className="title">{t('Your_share')}</div>
                                <div className={`value`}>
                                    {getFormatStringFromBignumber(myShare)}%
                                </div>
                                <div className="desc">
                                    {t('Average_multiplier')}:{' '}
                                    {averageMultiplier}x
                                </div>
                            </div>
                            <div className="info_row">
                                <div className="title">
                                    {t('Supply_Balance')}
                                </div>
                                <div className="flex">
                                    <div className={`value`}>
                                        $
                                        {getFormatStringFromBignumber(
                                            new BigNumber(totalSupplyBalance),
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="info_row">
                                <div className="title">
                                    {t('Borrow_Balance')}
                                </div>
                                <div className={`value`}>
                                    $
                                    {getFormatStringFromBignumber(
                                        new BigNumber(totalBorrowBalance),
                                    )}
                                </div>
                            </div>
                            <div className="info_row">
                                <div className="title">
                                    {t('Annual_rewards')}
                                </div>
                                <div className="flex">
                                    <div className={`value`}>
                                        $
                                        {getFormatStringFromBignumber(
                                            annualReward
                                                .times(dLpPrice)
                                                .div(1e18),
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledConatiner>
    );
}

export default Info;
