import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    useChefIncentivesInfo,
    useEligibilityDataProviderInfo,
    useMultiFeeDistributionAPRs,
    useMultiFeeDistributionInfo,
    useMultiFeeDistributionRewards,
    usedBulBalances,
    usedLpBalance,
    usedLpPrice,
} from 'hooks/useLpStaking';
import MainLayout from 'layouts/MainLayout/MainLayout';
import Info from './Info';
import Revenue from './Revenue';
import LpOverview from './LpOverview';
import VestOverview from './VestOverview';
import LastOverview from './LastOverview';
import Emission from './Emission';
import BigNumber from 'bignumber.js';
import whaleImg from '../../assets/images/whaleImg.png';

const VaultContainer = styled.div`
    .main-container {
        display: block;
        padding: 0px 20px;
        @media only screen and (max-width: 768px) {
            display: flex;
            max-width: 100%;
            padding: 0;
        }
    }
    .display-grid {
        position: relative;
        display: grid;
        gap: 1em;
        grid-template-columns: repeat(3, 1fr);
        @media only screen and (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }
        .container {
            background: rgba(255, 255, 255, 0.03);

            box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
            padding: 24px;
            border-radius: 8px;
            margin: 0;
        }
    }
`;

const StyledWhale = styled.div`
    position: absolute;
    width: 720px;
    height: 720px;
    background-image: url(${whaleImg});
    background-size: contain;
    background-repeat: no-repeat;
    right: -50px;
    top: 50px;
    opacity: 0.3;
`;

function Radiant() {
    const bulPrice = useSelector(
        (state) => state.account.setting.bulPrice || 0,
    );

    const { t } = useTranslation();

    const [update, setUpdate] = useState(false);
    const { myDLpBalance, myDLpAllowance } = usedLpBalance(update);
    const {
        myDLpLocked,
        myUnlockable,
        myShare,
        dLpLocked,
        penaltyAmount,
        currentlyVesting,
        vested,
        vestingList,
        lockList,
        defaultLockIndex,
    } = useMultiFeeDistributionInfo(update);
    const { autocompoundEnabled, autoRelockDisabled, claimableRewards } =
        useMultiFeeDistributionRewards(update);
    const { allPendingRewards, rewardsPerSecond } =
        useChefIncentivesInfo(update);
    const { dLpPrice } = usedLpPrice(bulPrice);
    const { chefBulBalance } = usedBulBalances(update);
    const { isEligibleForRewards, lockedUsdValue, requiredUsdValue } =
        useEligibilityDataProviderInfo(update);
    const { rewardData, lockedSupplyWithMultiplier, lockMultipliers } =
        useMultiFeeDistributionAPRs(claimableRewards);

    const tokenList = useMemo(() => {
        return [
            { price: 1, decimals: 6 },
            { price: 1, decimals: 6 },
        ];
    }, []);

    const LockAprData = useMemo(() => {
        const lockedSupply = lockedSupplyWithMultiplier
            .times(dLpPrice)
            .div(1e18);
        if (lockedSupply.isZero())
            return [
                new BigNumber(0),
                new BigNumber(0),
                new BigNumber(0),
                new BigNumber(0),
            ];

        let totalOneYearReward = new BigNumber(0);
        for (let index = 0; index < rewardData.length; index++) {
            if (
                new BigNumber(rewardData[index][0].hex).gte(Date.now() / 1000)
            ) {
                totalOneYearReward = totalOneYearReward.plus(
                    new BigNumber(rewardData[index][1].hex)
                        .times(3600 * 24 * 365)
                        .times(tokenList[index].price)
                        .div(1e12)
                        .div(10 ** tokenList[index].decimals),
                );
            }
        }

        const temp = [];
        for (let index = 0; index < lockMultipliers.length; index++) {
            temp.push(
                totalOneYearReward
                    .times(100)
                    .times(lockMultipliers[index])
                    .div(lockedSupply),
            );
        }

        return temp;
    }, [rewardData, dLpPrice, tokenList]);

    const annualReward = useMemo(() => {
        let temp = new BigNumber(0);
        if (lockList.length > 0 && LockAprData.length > 0) {
            for (let index = 0; index < lockList.length; index++) {
                const multiplier = lockList[index].multiplier.toNumber();
                const amount = lockList[index].amount;
                switch (multiplier) {
                    case 1:
                        temp = temp.plus(amount.times(LockAprData[0]).div(100));
                        break;
                    case 4:
                        temp = temp.plus(amount.times(LockAprData[1]).div(100));
                        break;
                    case 9:
                        temp = temp.plus(amount.times(LockAprData[2]).div(100));
                        break;
                    case 25:
                        temp = temp.plus(amount.times(LockAprData[3]).div(100));
                        break;
                    default:
                        console.log('LP Lock multiplier is invalid.');
                        break;
                }
            }
        }
        return temp;
    }, [lockList, LockAprData]);

    return (
        <div className="flex flex-col">
            <MainLayout
                mainClassName="pt-4"
                title={t('Radiant')}
                hideFooter={true}
                noBg={true}
                hideSummary={true}
                bgPlainNHeaderFull={true}
            >
                <div className="text-white flex flex-col justify-between items-center grow overflow-visible">
                    <Emission
                        update={update}
                        setUpdate={setUpdate}
                        isEligibleForRewards={isEligibleForRewards}
                        lockedUsdValue={lockedUsdValue}
                        requiredUsdValue={requiredUsdValue}
                        dLpPrice={dLpPrice}
                        myDLpLocked={myDLpLocked}
                        lockedSupplyWithMultiplier={lockedSupplyWithMultiplier}
                        LockAprData={LockAprData}
                        annualReward={annualReward}
                    />
                    <Info
                        myDLpLocked={myDLpLocked}
                        myShare={myShare}
                        dLpLocked={dLpLocked}
                        dLpPrice={dLpPrice}
                        autoRelockDisabled={autoRelockDisabled}
                        lockList={lockList}
                        annualReward={annualReward}
                    />
                    <Revenue
                        update={update}
                        setUpdate={setUpdate}
                        autocompoundEnabled={autocompoundEnabled}
                        claimableRewards={claimableRewards}
                    />

                    <VaultContainer className="max-w-7xl w-full">
                        <div className="main-container mt-1 mx-auto gap">
                            <div className="display-grid">
                                <StyledWhale />
                                <LpOverview
                                    update={update}
                                    setUpdate={setUpdate}
                                    defaultLockIndex={defaultLockIndex}
                                    myDLpBalance={myDLpBalance}
                                    myDLpAllowance={myDLpAllowance}
                                    autoRelockDisabled={autoRelockDisabled}
                                    lockedSupplyWithMultiplier={
                                        lockedSupplyWithMultiplier
                                    }
                                    LockAprData={LockAprData}
                                    myUnlockable={myUnlockable}
                                    lockList={lockList}
                                />
                                <VestOverview
                                    update={update}
                                    setUpdate={setUpdate}
                                    defaultLockIndex={defaultLockIndex}
                                    allPendingRewards={allPendingRewards}
                                    penaltyAmount={penaltyAmount}
                                    currentlyVesting={currentlyVesting}
                                    vested={vested}
                                    vestingList={vestingList}
                                />
                                <LastOverview
                                    chefBulBalance={chefBulBalance}
                                    rewardsPerSecond={rewardsPerSecond}
                                    bulPrice={bulPrice}
                                    dLpPrice={dLpPrice}
                                />
                            </div>
                        </div>
                    </VaultContainer>
                </div>
            </MainLayout>
        </div>
    );
}

export default Radiant;
