/* eslint-disable max-len */
import React from 'react';

export const LongLineGradient = ({ width = 1200, ...props }) => {
  return (
    <svg
      width={'calc(100%)'}
      height="1"
      viewBox={`"0 0 ${width} 1"`}
      fill="none"
      {...props}
    >
      <mask id="path-1-inside-1_5_112" fill="white">
        <path d="M0 1H1200V0H0V1Z" />
      </mask>
      <path
        d="M0 -1H1200V3H0V-1Z"
        fill="url(#paint0_radial_5_112)"
        mask="url(#path-1-inside-1_5_112)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_5_112"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(600 0.5) scale(583.245 7.99609)"
        >
          <stop stopColor="#4776E6" />
          <stop offset="1" stopColor="#8E54E9" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export const MediumLineGradient = ({ width = 441 }) => {
  return (
    <svg width={`calc(100%)`} height="2" viewBox="0 0 441 2" fill="none">
      <mask id="path-1-inside-1_14_101" fill="white">
        <path d="M0 1.4375C0 1.16136 0.223858 0.9375 0.5 0.9375H440.5C440.776 0.9375 441 1.16136 441 1.4375V1.4375C441 1.71364 440.776 1.9375 440.5 1.9375H0.500006C0.223863 1.9375 0 1.71364 0 1.4375V1.4375Z" />
      </mask>
      <path
        d="M0 1.4375C0 1.16136 0.223858 0.9375 0.5 0.9375H440.5C440.776 0.9375 441 1.16136 441 1.4375V1.4375C441 1.71364 440.776 1.9375 440.5 1.9375H0.500006C0.223863 1.9375 0 1.71364 0 1.4375V1.4375Z"
        fill="white"
        fillOpacity="0.03"
      />
      <path
        d="M-0.5 0.4375C-0.5 -0.114785 -0.0522847 -0.5625 0.5 -0.5625H440.5C441.052 -0.5625 441.5 -0.114785 441.5 0.4375L441 1.4375C441 1.57011 440.947 1.69729 440.854 1.79105C440.76 1.88482 440.633 1.9375 440.5 1.9375L0.5 1.9375H0L0.5 1.9375C0.367392 1.9375 0.240215 1.88482 0.146447 1.79105C0.0526784 1.69729 -5.79649e-09 1.57011 0 1.4375L-0.5 0.4375ZM441.5 2.4375C441.5 2.98978 441.052 3.4375 440.5 3.4375H0.5C-0.0522847 3.4375 -0.5 2.98978 -0.5 2.4375L0 1.4375C1.58134e-09 1.30489 0.0526784 1.17771 0.146447 1.08395C0.240215 0.990178 0.367392 0.9375 0.5 0.9375H0.5H0H440.5C440.633 0.9375 440.76 0.990178 440.854 1.08395C440.947 1.17771 441 1.30489 441 1.4375L441.5 2.4375ZM0.5 3.4375C-0.0522847 3.4375 -0.5 2.98978 -0.5 2.4375V0.4375C-0.5 -0.114785 -0.0522847 -0.5625 0.5 -0.5625V1.9375V0.9375V3.4375ZM440.5 -0.5625C441.052 -0.5625 441.5 -0.114785 441.5 0.4375V2.4375C441.5 2.98978 441.052 3.4375 440.5 3.4375V0.9375V1.9375V-0.5625Z"
        fill="url(#paint0_radial_14_101)"
        mask="url(#path-1-inside-1_14_101)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_14_101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(220.5 1.4375) scale(214.342 7.99609)"
        >
          <stop stopColor="#4776E6" />
          <stop offset="1" stopColor="#8E54E9" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export const ShortLineGradient = ({ width = 128, height = 3, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 128 2"
      fill="none"
      {...props}
    >
      <rect
        x="0.5"
        y="1.43756"
        width="126.453"
        height="1"
        rx="0.5"
        stroke="url(#paint0_radial_15_2)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_15_2"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(63.7263 1.93756) scale(61.9467 15.9922)"
        >
          <stop stopColor="#4776E6" />
          <stop offset="1" stopColor="#8E54E9" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};