import { useHistory, withRouter } from 'react-router-dom';
import Pagination from 'rc-pagination';

import Layout from '../../layouts/MainLayout/MainLayout';

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    getTokenContract,
    getVoteContract,
    methods,
} from '../../utilities/ContractService';
import Web3 from 'web3';
import toast from '../../components/UI/Toast';
import { ReactComponent as LeftArrow } from '../../assets/icons/arrow.svg';

import { accountActionCreators, connectAccount } from '../../core';
import { bindActionCreators, compose } from 'redux';
import { useActiveWeb3React, useWeb3 } from '../../hooks';
import { promisify } from '../../utilities';
import Loading from '../../components/UI/Loading';
import ProposalOverview from '../../components/vote/ProposalOverview';
import styled from 'styled-components';
import commaNumber from 'comma-number';
import { useTranslation } from 'react-i18next';
import Footer from 'layouts/MainLayout/Footer';

const format = commaNumber.bindWith(',', '.');

const Styles = styled.div`
    flex-grow: 1;
    div.pagination.space-x-4 {
        .rc-pagination-item {
            margin-left: 0px !important;
            margin-right: 0px !important;
            // background-color: #000220;
            border-radius: 50%;

            a {
                color: #aaa;
            }
        }
        .rc-pagination-item-active:focus a,
        .rc-pagination-item-active:hover a,
        .rc-pagination-item-active a {
            color: #3060f6;
        }
    }

    svg.arrow {
        path {
            stroke: #979797;
        }
    }

    .card {
        box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        border: 1.5px, 0.5px, 1.5px, 0.5px;
    }
`;

const EmptyDataStyles = styled.div`
    width: 100%;
    height: 50vh;
    overflow: auto;
    //   border: double 2px transparent;
    //   border-radius: 30px;
    //   background-image: linear-gradient(white, white),
    //                     linear-gradient(90deg, #002D74 0%, #000220 100.04%);
    //   background-origin: border-box;
    //   background-clip: content-box, border-box;
    display: flex;
    justify-content: center;
`;

const AllProposals = ({ settings, getProposals }) => {
    const { account: address, requiredChainId } = useActiveWeb3React();
    const web3 = useWeb3();
    const { t } = useTranslation();

    const [votingWeight, setVotingWeight] = useState(0);
    const [proposals, setProposals] = useState({});
    const [isLoadingProposal, setIsLoadingProposal] = useState(false);

    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    const [proposalModal, setProposalModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [proposalThreshold, setProposalThreshold] = useState(0);
    const [maxOperation, setMaxOperation] = useState(0);
    const [delegateAddress, setDelegateAddress] = useState('');
    const voteContract = getVoteContract(requiredChainId);
    const [tvl, setTvl] = useState(0);

    useEffect(() => {
        setTvl(settings.totalLiquidity);
    }, [settings.totalLiquidity]);

    const loadInitialData = useCallback(async () => {
        setIsLoadingProposal(true);
        await promisify(getProposals, {
            offset: 0,
            limit: 4,
        })
            .then((res) => {
                setIsLoadingProposal(false);
                setProposals(res.data);

                const result = [];
                for (let i = 1; i <= 10; i++) {
                    result.push({
                        id: i,
                        description: 'Just Abstain, Courteously',
                        state: i % 3 ? 'Active' : 'Defeated',
                        createdAt: '2024-01-22',
                    });
                }
                setProposals({
                    limit: 10,
                    offset: 0,
                    total: 10,
                    result: result,
                });
            })
            .catch(() => {
                setIsLoadingProposal(false);
            });
    }, [getProposals]);

    useEffect(() => {
        loadInitialData();
    }, [loadInitialData]);

    const onChangePage = (pageNumber, offset, limit) => {
        setIsLoadingProposal(true);
        promisify(getProposals, {
            offset,
            limit,
        })
            .then((res) => {
                setProposals(res.data);
                setIsLoadingProposal(false);
            })
            .catch(() => {
                setIsLoadingProposal(false);
            });
    };

    useEffect(() => {
        if (address) {
            methods
                .call(voteContract.methods.proposalThreshold, [])
                .then((res) => {
                    setProposalThreshold(+Web3.utils.fromWei(res, 'ether'));
                });
            methods
                .call(voteContract.methods.proposalMaxOperations, [])
                .then((res) => {
                    setMaxOperation(res);
                });
        }
    }, [address]);

    useEffect(() => {
        if (
            address &&
            (delegateAddress === '' ||
                delegateAddress ===
                    '0x0000000000000000000000000000000000000000')
        ) {
            const tokenContract = getTokenContract(
                web3,
                'bul',
                requiredChainId,
                false,
            );
            methods
                .call(tokenContract.methods.delegates, [address])
                .then((res) => {
                    setDelegateAddress(res);
                })
                .catch(() => {});
        }
    }, [address, address, delegateAddress]);

    const handleChangePage = (page, size) => {
        setCurrent(page);
        setPageSize(size);
        onChangePage(page, (page - 1) * size, size);
    };

    const onNext = () => {
        if (current * pageSize <= proposals.total) {
            handleChangePage(current + 1, 5);
        }
    };

    const onPrev = () => {
        if (current > 1) {
            handleChangePage(current - 1, 5);
        }
    };

    return (
        <Layout title={'Vote'} background="dashboard" tvl={format(tvl)}>
            <Styles className="pt-6 text-white flex flex-col justify-between items-center grow overflow-auto">
                <div className="max-w-7xl w-full px-8">
                    <div className="text-2xl mb-8">
                        {t('Governance_Proposal')}
                    </div>
                    <div
                        className="card"
                        style={{ background: 'rgba(0,0,0,0.05)' }}
                    >
                        {isLoadingProposal && (
                            <div className="flex items-center justify-center py-16 flex-grow">
                                <Loading
                                    size={'36px'}
                                    margin={'0'}
                                    className={'text-midBlue'}
                                />
                            </div>
                        )}
                        {!isLoadingProposal && (
                            <>
                                {proposals?.result &&
                                proposals?.result?.length !== 0 ? (
                                    proposals?.result?.map((item) => {
                                        return (
                                            <ProposalOverview
                                                proposal={item}
                                                votingWeight={votingWeight}
                                                delegateAddress={
                                                    delegateAddress
                                                }
                                                address={address}
                                                key={item.id}
                                            />
                                        );
                                    })
                                ) : (
                                    <EmptyDataStyles>
                                        <div className="text-base p-20 flex justify-center items-center">
                                            <span className="text-center text-2xl md:text-3xl text-border title-text">
                                                {t('No_Proposals')}
                                            </span>
                                        </div>
                                    </EmptyDataStyles>
                                )}
                            </>
                        )}
                        {proposals?.result &&
                            proposals?.result?.length !== 0 && (
                                <div className="flex justify-center p-6">
                                    <div className="flex just-between align-center pagination space-x-4">
                                        {
                                            <div
                                                className={`flex ${
                                                    current > 1
                                                        ? ''
                                                        : 'cursor-not-allowed'
                                                }`}
                                                onClick={onPrev}
                                            >
                                                <LeftArrow
                                                    className={'arrow'}
                                                    height={'11px'}
                                                    width={'auto'}
                                                />
                                            </div>
                                        }
                                        <Pagination
                                            defaultCurrent={1}
                                            defaultPageSize={5}
                                            current={current}
                                            pageSize={pageSize}
                                            total={proposals.total}
                                            onChange={handleChangePage}
                                        />
                                        {
                                            <div
                                                className={`flex ml-0 ${
                                                    current * pageSize <=
                                                    proposals.total
                                                        ? ''
                                                        : 'cursor-not-allowed'
                                                }
                                                `}
                                                onClick={onNext}
                                            >
                                                <LeftArrow
                                                    className={
                                                        'transform rotate-180 arrow'
                                                    }
                                                    height={'11px'}
                                                    width={'auto'}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </Styles>
        </Layout>
    );
};

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
    const { getProposals, setSetting } = accountActionCreators;

    return bindActionCreators(
        {
            getProposals,
            setSetting,
        },
        dispatch,
    );
};

export default compose(
    withRouter,
    connectAccount(mapStateToProps, mapDispatchToProps),
)(AllProposals);
