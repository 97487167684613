import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { accountActionCreators, connectAccount } from '../../../core';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { promisify } from '../../../utilities';
import Loading from '../../UI/Loading';
import { getEtherscanLink, shortenAddress } from '../../../utils';
import avatar from '../../../assets/icons/avatar.svg';
import expandBoxPrimary from '../../../assets/icons/expandBoxPrimary.svg';
import RouteMap from '../../../routes/RouteMap';
import { useActiveWeb3React } from '../../../hooks';
import styled from 'styled-components';

const Styles = styled.div`
    padding: 0px;
    color: white;

    input.address-input {
        background: rgba(0, 0, 0, 0.03);
    }

    div.address-content {
        padding: 0px 76px 0px 76px;
    }
    @media (max-width: 640px) {
        div.address-content {
            padding: 0px 20px 0px 20px;
        }
    }
    .leaderboard {
        padding: 0px 76px 0px 76px;

        @media (max-width: 500px) {
            padding: 0px 0px 0px 0px;
        }
    }
    .view-leaderboard-button {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        padding: 17px 40px;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #fff;
        background: #0061a7;
        border-radius: 4px;
        margin-bottom: 55px;
        margin-left: 10px;
        margin-right: 10px;
    }
    @media (max-width: 640px) {
        .view-leaderboard-button {
            font-size: 14px;
        }
    }
`;

const DelegationVoting = ({
    isLoading,
    onDelegate,
    getVoterAccounts,
    history,
}) => {
    const { requiredChainId } = useActiveWeb3React();
    const { t } = useTranslation();
    const [delegateAddress, setDelegateAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [voterAccounts, setVoterAccounts] = useState([]);
    const [showLeaderboard, setShowLeaderboard] = useState(false);

    const getVoterLeaderboard = () => {
        setLoading(true);
        promisify(getVoterAccounts, { limit: 3, offset: 0 })
            .then((res) => {
                setLoading(false);
                setVoterAccounts(res?.data?.result || []);
            })
            .catch(() => {
                setLoading(false);
                setVoterAccounts([]);
            });
    };

    useEffect(() => {
        if (showLeaderboard) {
            getVoterLeaderboard();
        }
    }, [showLeaderboard]);

    return (
        <Styles className="delegation-vote-container mt-14">
            <div className={'address-content'}>
                <div className="font-bold text-lg">
                    {t('Select_an_Address')}
                </div>
                <p className="text-sm mt-11">{t('delegate_message')}</p>
                <div className="flex justify-between mt-8">
                    <div className="font-bold">{t('Delegate_Address')}</div>
                    <div
                        className="font-bold text-lighterRed cursor-pointer"
                        onClick={() => setShowLeaderboard(true)}
                    >
                        {t('Delegate_Leaderboard')}
                    </div>
                </div>
                <input
                    name="actionText"
                    type="text"
                    className="address-input rounded w-full focus:outline-none py-4 px-4 mt-6 mb-4 border-y-2-gradient"
                    value={delegateAddress}
                    onChange={(e) => setDelegateAddress(e.target.value)}
                    placeholder={t('Enter_a_0x_address')}
                />
            </div>
            {showLeaderboard && (
                <div className="flex justify-center items-center flex-col leaderboard">
                    {loading ? (
                        <div className="flex w-full py-4 items-center justify-center">
                            <Loading
                                margin={'0'}
                                size={'36px'}
                                className={'text-midBlue'}
                            />
                        </div>
                    ) : (
                        voterAccounts?.map((v) => {
                            return (
                                <div
                                    key={v.address}
                                    className="flex justify-between rounded py-4 w-full bg-gray4 mb-2 px-5"
                                >
                                    <div
                                        className="flex space-x-4 cursor-pointer"
                                        onClick={() => {
                                            setDelegateAddress(v.address);
                                            setShowLeaderboard(false);
                                        }}
                                    >
                                        <img src={avatar} alt="" />
                                        <div className="text-gray3">
                                            {/* <div className="text-base">{shortenAddress(v.address)}</div> */}
                                            <div className="text-base">
                                                {v.address}
                                            </div>
                                            <div className="text-base">
                                                {t('Voting_Weight')}:{' '}
                                                {parseFloat(
                                                    v.voteWeight * 100,
                                                ).toFixed(2)}
                                                %
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => {
                                            window.open(
                                                getEtherscanLink(
                                                    requiredChainId,
                                                    v.address,
                                                    'address',
                                                ),
                                                '_blank',
                                            );
                                        }}
                                        className={'cursor-pointer'}
                                    >
                                        <img src={expandBoxPrimary} alt="" />
                                    </div>
                                </div>
                            );
                        })
                    )}
                    <a
                        className="cursor-pointer view-leaderboard-button"
                        href={RouteMap.vote.leaderboard}
                        rel="noreferrer"
                        target={'_blank'}
                    >
                        {t('View_Delegate_Leaderboard')}
                    </a>
                </div>
            )}
            {!showLeaderboard && (
                <div className="flex justify-center mt-6">
                    <button
                        className={`focus:outline-none py-4 px-10 mb-12 rounded font-medium flex items-center 
                        justify-center bg-transparent border-y-2-gradient`}
                        disabled={isLoading || delegateAddress === ''}
                        onClick={() => onDelegate(delegateAddress)}
                    >
                        {isLoading && (
                            <Loading
                                margin={'8px'}
                                size={'18px'}
                                className={'text-gray'}
                            />
                        )}
                        {t('Delegate_Voting')}
                    </button>
                </div>
            )}
        </Styles>
    );
};

DelegationVoting.defaultProps = {
    isLoading: false,
    onDelegate: () => {},
};

const mapDispatchToProps = (dispatch) => {
    const { getVoterAccounts } = accountActionCreators;

    return bindActionCreators(
        {
            getVoterAccounts,
        },
        dispatch,
    );
};

export default compose(
    withRouter,
    connectAccount(undefined, mapDispatchToProps),
)(DelegationVoting);
