import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useActiveWeb3React, useWeb3 } from '../../../hooks';
import { getTokenContract, methods } from '../../../utilities/ContractService';
import Modal from '../../UI/Modal';

import { ReactComponent as TickGreen } from '../../../assets/icons/tickGreen.svg';
import { ReactComponent as CloseWhite } from '../../../assets/icons/closeCirclePrimary.svg';
import { ReactComponent as BigArrow } from '../../../assets/icons/arrow.svg';
import loadingModal from '../../../assets/icons/loading_modal.svg';
import TickIcon from '../../../assets/icons/tickGreen.svg';
import * as constants from '../../../utilities/constants';
import DelegationVoting from './DelegationVoting';
import styled from 'styled-components';
import { getEtherscanLink } from 'utils';
import BlueModal from 'components/UI/BlueModal';
import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon';
import { VoteLoadingIcon } from 'components/Icons/VoteLoadingIcon';

const DelegationTypeStyles = styled.div`
    svg.forward-arrow {
        path {
            stroke: #979797;
        }
    }
    a.delegation-close-container {
        position: absolute;
        right: 1rem;
        top: 1rem;
        outline: none;
        background: transparent;
        svg {
            height: 26px;
            width: auto;
            circle {
                stroke: #000000;
                fill: transparent;
            }
            path {
                fill: #000000;
            }
        }
    }

    .tick-green {
        background: #4fd000;
        height: auto;
        width: 20px;
        path {
            fill: rgba(6, 64, 118, 1);
        }
    }
`;

const DelegationTypeModal = ({
    visible,
    balance,
    delegateStatus,
    address,
    onCancel,
}) => {
    const { t } = useTranslation();
    const { requiredChainId } = useActiveWeb3React();
    const web3 = useWeb3();
    const [child, setChild] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [txnHash, setTxnHash] = useState('');

    const handleVoting = (dAddress) => {
        setIsLoading(true);
        const tokenContract = getTokenContract(web3, 'bul', requiredChainId);
        methods
            .send(
                tokenContract.methods.delegate,
                [dAddress || address],
                address,
            )
            .then((res) => {
                setTxnHash(res.transactionHash);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setChild('');
                onCancel();
            });
    };

    const getBefore = (value) => {
        const position = value.indexOf('.');
        return position !== -1 ? value.slice(0, position + 5) : value;
    };

    const getAfter = (value) => {
        const position = value.indexOf('.');
        return position !== -1 ? value.slice(position + 5) : null;
    };

    let title = t('Choose_Delegation_Type');

    let content = (
        <DelegationTypeStyles className="pb-8 mt-5 overflow-auto font-inter">
            <div className="flex flex-col">
                <div
                    className="cursor-pointer flex gap-8 justify-between items-center py-7 mx-4 sm:mx-12 border-y-2-gradient"
                    onClick={(e) => {
                        if (delegateStatus === 'self') {
                            e.preventDefault();
                            return;
                        }
                        setChild('manual');
                        setTxnHash('');
                        handleVoting('');
                    }}
                >
                    <TickGreen className="tick-green" />
                    <div className="flex-1">
                        <div className="font-bold text-lg">
                            {t('Manual_Voting')}
                        </div>
                        <div className=" text-lg mt-4">
                            {t(
                                'This_option_allows_you_to_vote_on_proposals_directly_from_your_connected_wallet',
                            )}
                        </div>
                    </div>
                    <div className="flex justify-end w-20">
                        {delegateStatus !== 'self' ? (
                            <div
                                className="cursor-pointer"
                            >
                                <ArrowRightIcon />
                            </div>
                        ) : (
                            <span className="text-green text-2xl font-medium">
                                {t('Active')}
                            </span>
                        )}
                    </div>
                </div>
                <div
                    className="cursor-pointer flex gap-8 justify-between items-center py-7 mx-4 sm:mx-12 border-b-2-gradient"
                    onClick={() => {
                        setChild('delegate');
                        setTxnHash('');
                    }}
                >
                    <TickGreen className="tick-green" />
                    <div className="flex-1">
                        <div className="font-bold text-lg">
                            {t('Delegate_Voting')}
                        </div>
                        <div className=" text-lg mt-4">
                            {t('undelegate_message')}
                        </div>
                    </div>
                    {delegateStatus === 'self' ? (
                        <button className="mt-2 focus:outline-none">
                            <ArrowRightIcon />
                        </button>
                    ) : (
                        <span className="text-green text-lg font-medium">
                            {t('Active')}
                        </span>
                    )}
                </div>
            </div>
        </DelegationTypeStyles>
    );

    if (child === 'manual') {
        title = isLoading
            ? t('Transaction_Pending')
            : t('Transaction_Completed');

        content = (
            <div className="font-inter">
                <div className="text-lg mt-2 text-center">
                    {getBefore(balance)} {t('Votes')}
                </div>
                <div className="text-sm text-center mt-6">
                    {t('Manual_Voting_From')}{' '}
                    <span className={''}>{`${address.substr(
                        0,
                        4,
                    )}...${address.substr(address.length - 4, 4)}`}</span>
                </div>
                <div className="flex flex-col items-center mt-5">
                    {isLoading ? (
                        <VoteLoadingIcon />
                    ) : (
                        <img
                            className="mb-4"
                            src={TickIcon}
                            alt="Tick"
                            width={'192px'}
                            height={'auto'}
                        />
                    )}
                </div>
                <div className="flex justify-center mt-6">
                    <button
                        disabled={!txnHash}
                        className="focus:outline-none bg-blue-gradient rounded py-2 px-14 mb-2 font-medium"
                        onClick={() => {
                            window.open(
                                getEtherscanLink(
                                    requiredChainId,
                                    txnHash,
                                    'transaction',
                                ),
                                '_blank',
                            );
                        }}
                    >
                        {t('View_on_Explorer')}
                    </button>
                </div>
                <div className="text-lg text-center mt-8">
                    {t('Transaction_Broadcast')}
                </div>
            </div>
        );
    } else if (child === 'delegate') {
        title = t('Delegate_Voting');
        content = (
            <DelegationVoting isLoading={isLoading} onDelegate={handleVoting} />
        );
    }

    return (
        <div>
            <BlueModal
                header={''}
                title={title}
                content={content}
                open={visible}
                onCloseModal={onCancel}
                containerClass={'modal-container'}
                afterCloseModal={() => {
                    setChild('');
                    setTxnHash('');
                }}
            />
        </div>
    );
};

DelegationTypeModal.defaultProps = {
    address: '',
    balance: '0',
    visible: false,
    delegateStatus: '',
    onCancel: () => {},
};

export default DelegationTypeModal;
