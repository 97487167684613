import { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import { useActiveWeb3React } from 'hooks';
import Loading from 'components/UI/Loading';
import HistoryTable from 'components/Referral/HistoryTable';
import Pagination from 'components/Referral/Pagination';
import styled from 'styled-components';
import { restService } from 'utilities';
import noData from '../../assets/images/bin.png';
import { getEtherscanLink } from 'utils';

const ContainerStyles = styled.div`
    width: 100%;
    padding: 0px 20px;
    .main-container1 {
        margin-bottom: 40px;

        .table-container {
            position: relative;

            @media (max-width: 767px) {
            }
        }
        .tx-container {
            display: flex;
            justify-content: flex-start;
            .tx-hash {
                color: rgb(16, 125, 239);
                font-size: 16px;
                text-align: left;
                @media (max-width: 767px) {
                    font-size: 12px;
                }
            }
            @media (max-width: 767px) {
                justify-content: flex-end;
            }
        }
        .value-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
            img {
                width: 20px;
                height: 20px;
                margin-right: 3px;
            }
            @media (max-width: 767px) {
                justify-content: flex-end;
                font-size: 12px;
            }
        }
    }
`;
const FilterContainer = styled.div`
    display: flex;
    .filter-item {
        margin-right: 20px;
        display: flex;
        align-items: center;
        color: rgb(109, 111, 123);
        background: #ffffff;
        box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 3px 5px 3px 20px;
        .close {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #0000003f;
            color: #fff;
            border-radius: 10px;
            cursor: pointer;
            margin-left: 20px;
        }
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 48px;

    .title {
        font-size: 24px;
        font-weight: 700;
        font-family: LibreFranklin_Bold;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
    .tab-container {
        display: flex;
    }
`;
const NoAccountContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.03);
    height: 640px;
    border-radius: 0px 0px 10px 10px;

    @media (max-width: 768px) {
        height: 45vh;
    }
    .title {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: rgb(157, 159, 167);
        text-align: center;
        @media (max-width: 768px) {
            font-size: 16px;
            max-width: 70%;
        }
    }
`;

const ReferralHistory = () => {
    const { t, i18n } = useTranslation();
    const { account, requiredChainId } = useActiveWeb3React();
    const [markets, setMarkets] = useState([]);

    const [isLoading, setIsLoding] = useState(false);
    const [block, setBlock] = useState(null);
    const [fromAddress, setFromAddress] = useState(null);

    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrntPage] = useState(1);

    const getBULInfo = async ({ limit = 20, offset = 0 }) => {
        setMarkets([]);
        if (!account) return;
        setIsLoding(true);
        const apiRequest = await restService({
            chainId: requiredChainId,
            // eslint-disable-next-line max-len
            api: `/v1/referral/rewardHistory?limit=${limit}&offset=${offset}&address=${account}`,
            method: 'GET',
        });

        if (apiRequest?.data?.data) {
            setMarkets(apiRequest.data.data.rows || []);
            setTotalPage(parseInt((apiRequest.data.data.count || 0) / 20 + 1));
        }
        setIsLoding(false);
    };

    useEffect(() => {
        getBULInfo({
            offset: (currentPage - 1) * 20,
        });
    }, [account, requiredChainId]);

    const updatePage = (value) => {
        setCurrntPage(value);
        getBULInfo({
            offset: (value - 1) * 20,
        });
    };

    const columns = useMemo(() => {
        return [
            {
                Header: t('Date'),
                accessor: 'blockTimestamp',
                disableFilters: true,
                Cell: ({ value }) => {
                    let delta = Date.now() / 1000 - value;
                    var days = Math.floor(delta / 86400);
                    delta -= days * 86400;
                    var hours = Math.floor(delta / 3600) % 24;
                    if (days > 0) {
                        return (
                            <div className="tx-container">
                                <div className="age">
                                    {`${days} ${
                                        days > 1 ? t('days') : t('day')
                                    } ${hours} ${
                                        hours > 1 ? t('hrs') : t('hr')
                                    } ${t('ago')}`}
                                </div>
                            </div>
                        );
                    } else {
                        delta -= hours * 3600;
                        var minutes = Math.floor(delta / 60) % 60;
                        delta -= minutes * 60;
                        var seconds = parseInt(delta % 60);
                        if (hours > 0) {
                            return (
                                <div className="tx-container">
                                    <div className="age">
                                        {`${hours} ${
                                            hours > 1 ? t('hrs') : t('hr')
                                        } ${minutes} ${
                                            minutes > 1 ? t('mins') : t('min')
                                        } ${t('ago')}`}
                                    </div>
                                </div>
                            );
                        }
                        return (
                            <div className="tx-container">
                                <div className="age">
                                    {`${minutes} ${
                                        minutes > 1 ? t('mins') : t('min')
                                    } ${seconds} ${
                                        seconds > 1 ? t('secs') : t('sec')
                                    } ${t('ago')}`}
                                </div>
                            </div>
                        );
                    }
                },
            },
            {
                Header: t('From'),
                accessor: 'fromAddress',
                disableFilters: true,
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <div className="tx-container">
                            <a
                                className="tx-hash"
                                href={getEtherscanLink(requiredChainId, value)}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {value?.substring(0, 4)}...
                                {value?.substring(38)}
                            </a>
                        </div>
                    );
                },
            },
            {
                Header: t('Tier'),
                accessor: 'asReferrer',
                disableFilters: true,
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <div className="tx-container">
                            <div>
                                {value
                                    ? `${t('Tier1')} (10%)`
                                    : `${t('Tier2')} (5%)`}
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: t('Amount'),
                accessor: 'amount',
                disableFilters: true,
                // eslint-disable-next-line react/display-name
                Cell: ({ row }) => {
                    const rowData = row.original;
                    const amount = new BigNumber(rowData.amount).dividedBy(
                        new BigNumber(1e18),
                    );
                    return (
                        <div className="value-container">
                            <img
                                src={`/images/coins/logo.svg`}
                                alt={rowData.symbol}
                            />
                            <div>
                                {amount.toFormat(3)} {rowData.symbol}
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Name',
                columns: [
                    {
                        Header: t('Txn_Hash'),
                        accessor: 'txHash',
                        disableFilters: true,
                        Cell: ({ value }) => {
                            return (
                                <div
                                    className="tx-container"
                                    style={{ justifyContent: 'flex-end' }}
                                >
                                    <a
                                        className="tx-hash"
                                        href={getEtherscanLink(
                                            requiredChainId,
                                            value,
                                            'transaction',
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {value?.substring(0, 6)}...
                                        {value?.substring(60)}
                                    </a>
                                </div>
                            );
                        },
                    },
                    // {
                    //   Header: t("Block"),
                    //   accessor: 'blockNumber',
                    //   disableFilters: true,
                    //   Cell: ({ value }) => {
                    //     return (
                    //       <div className="tx-container">
                    //         <a className="tx-hash" href={getEtherscanLink(requiredChainId, value, 'block')} target="_blank" rel="noreferrer">
                    //           {value}
                    //         </a>
                    //       </div>
                    //     )
                    //   }
                    // },
                ],
            },
        ];
    }, [i18n.language, requiredChainId]);

    return (
        <ContainerStyles>
            <div className="main-container1">
                <HeaderContainer>
                    <div className="title">{t('Reward_History')}</div>
                </HeaderContainer>
                <div className="table-container mt-4 w-full">
                    <div>
                        <HistoryTable columns={columns} data={markets} />
                        {!account ? (
                            <NoAccountContainer>
                                <img src={noData} alt="" />
                                <div className="title">
                                    {t('Connect_Wallet_reward_history')}
                                </div>
                            </NoAccountContainer>
                        ) : isLoading ? (
                            <NoAccountContainer>
                                <Loading
                                    size={'36px'}
                                    margin={'0'}
                                    className={'text-midBlue'}
                                />
                            </NoAccountContainer>
                        ) : !markets || markets.length === 0 ? (
                            <NoAccountContainer>
                                <img src={noData} alt="" />
                            </NoAccountContainer>
                        ) : (
                            <Pagination
                                current={currentPage}
                                setCurrent={updatePage}
                                total={totalPage}
                            />
                        )}
                    </div>
                </div>
            </div>
        </ContainerStyles>
    );
};

export default ReferralHistory;
