/* eslint-disable max-len */
import React from 'react';

export const Twitter = ({ color = 'white', ...props }) => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" {...props}>
      <g filter="url(#filter0_b_61_1521)">
        <rect
          x="0.000488281"
          y="0.0622559"
          width="48"
          height="48"
          rx="8"
          fill={color}
          fillOpacity="0.1"
        />
        <path
          d="M28.3139 16.5623H30.614L25.5889 22.9161L31.5005 31.5623H26.8718L23.2464 26.3184L19.0982 31.5623H16.7967L22.1715 24.7661L16.5005 16.5623H21.2467L24.5237 21.3553L28.3139 16.5623ZM27.5066 30.0392H28.7811L20.5542 18.0053H19.1865L27.5066 30.0392Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_61_1521"
          x="-7.99951"
          y="-7.93774"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_61_1521" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_61_1521"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
