import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import Modal from '../UI/Modal';
import { useWeb3React } from '@web3-react/core';
import { connectorLocalStorageKey } from '../../connectors';
import ExternalLinkIcon from '../../assets/icons/externalLink.svg';
import styled from 'styled-components';

const Styles = styled.div`
  .item-container {
    padding: 10px 0;
    .item {
      display: flex;
      margin-top: 20px;
      cursor: pointer;
      @media all and (max-width: 1024px) {
        margin-top: 10px;
      }

      .input-container {
        margin: 5px 10px 0 0;
        input {
          width: 18px;
          height: 18px;
          @media all and (max-width: 1024px) {
            width: 16px;
            height: 16px;
          }
        }
      }
      .item-text {
        color: #ffffff;
        font-size: 15px;
        @media all and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
  }
  .link-item-container {
    padding: 10px 0;
    .link-item {
      color: #ffffff;
      display: flex;
      margin-top: 20px;
      cursor: pointer;
      @media all and (max-width: 1024px) {
        margin-top: 10px;
      }

      svg.link {
        width: 15px;
        path {
          fill: #fff;
        }
        @media all and (max-width: 1024px) {
          width: 13px;
        }
      }
      .item-text {
        font-size: 15px;
        color: #ffffff;
        font-weight: 600;
        padding-right: 10px;
        @media all and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
  }
  .button-container {
    padding: 20px 0;
    display: flex;
    .button {
      padding: 10px 40px;
      margin-right: 30px;
    }
  }
`;

function LendingRiskModal() {
  const { t } = useTranslation();
  const { account, connector } = useWeb3React();

  const [showModal, setShowModal] = useState(false);
  const [lendingRisks, setLendingRiskModal] = useState(
    JSON.parse(localStorage.getItem('LendingRisk') || '{}'),
  );

  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);

  useEffect(() => {
    let timer;
    if (account) {
      timer = setTimeout(() => {
        setShowModal(!lendingRisks[account]);
      }, 1000);
    } else {
      setShowModal(false);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [account]);

  const continueWithRisk = async () => {
    setLendingRiskModal({ ...lendingRisks, [account]: true });
    localStorage.setItem(
      'LendingRisk',
      JSON.stringify({ ...lendingRisks, [account]: true }),
    );
    setShowModal(false);
  };

  const logout = async () => {
    // window.localStorage.removeItem(connectorLocalStorageKey);
    // if (account) {
    //   if (connector?.deactivate) {
    //     void connector?.deactivate()
    //   } else {
    //     void connector?.resetState()
    //   }
    // }

    setShowModal(false);
  };

  const title = useMemo(() => {
    return (
      <Styles className="flex flex-row justify-center items-center mt-10 mb-4">
        <div className="text-3xl text-white font-medium ml-2.5">
          {t('Leding_Risk')}
        </div>
      </Styles>
    );
  }, [t]);

  const content = () => {
    return (
      <Styles className="px-14">
        <div className="item-container">
          <div className="item" onClick={() => setCheck1(!check1)}>
            <div className="input-container">
              <input type="checkbox" checked={check1} onChange={() => {}} />
            </div>
            <div className="item-text">{t('Leding_Risk_1')}</div>
          </div>
          <div className="item" onClick={() => setCheck2(!check2)}>
            <div className="input-container">
              <input type="checkbox" checked={check2} onChange={() => {}} />
            </div>
            <div className="item-text">{t('Leding_Risk_2')}</div>
          </div>
          <div className="item" onClick={() => setCheck3(!check3)}>
            <div className="input-container">
              <input type="checkbox" checked={check3} onChange={() => {}} />
            </div>
            <div className="item-text">{t('Leding_Risk_3')}</div>
          </div>
          <div className="item" onClick={() => setCheck4(!check4)}>
            <div className="input-container">
              <input type="checkbox" checked={check4} onChange={() => {}} />
            </div>
            <div className="item-text">{t('Leding_Risk_4')}</div>
          </div>
        </div>
        <div className="link-item-container">
          <div
            className="link-item"
            onClick={() => window.open('https://t.me/officialbelugas', '_blank')}
          >
            <div className="item-text">{t('Leding_Risk_5')}</div>
            <SVG src={ExternalLinkIcon} className={'link'} />
          </div>
          <div
            className="link-item"
            onClick={() => window.open('https://docs.belugas.io', '_blank')}
          >
            <div className="item-text">{t('Leding_Risk_6')}</div>
            <SVG src={ExternalLinkIcon} className={'link'} />
          </div>
        </div>
        <div className="button-container">
          <button
            className="button bg-gray4 text-black rounded-lg uppercase font-bold focus:outline-none"
            onClick={logout}
          >
            {t('Cancel')}
          </button>

          <div className="divider" />
          <button
            className="button bg-midBlue text-white rounded-lg uppercase font-bold focus:outline-none"
            disabled={!check1 || !check2 || !check3 || !check4}
            onClick={continueWithRisk}
          >
            {t('Continue')}
          </button>
        </div>
      </Styles>
    );
  };

  return (
    <div className="hidden lg:flex">
      <Modal
        title={title}
        content={content()}
        open={showModal}
        onSetOpen={() => {}}
        onCloseModal={() => {}}
        afterCloseModal={() => {}}
        width="max-w-3xl"
      />
    </div>
  );
}

export default LendingRiskModal;
