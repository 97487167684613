import Modal from "components/UI/Modal";
import { useMemo, useState } from 'react';
import ZapModalTitle from './ZapModalTitle';
import ZapModalStep1 from "./ZapModalStep1";
import ZapModalStep2 from "./ZapModalStep2";
import ZapModalStep3 from "./ZapModalStep3";
import BigNumber from "bignumber.js";

function ZapModal({
  open,
  setOpen,
  update,
  setUpdate,
  lockedUsdValue,
  requiredUsdValue,
  dLpPrice,
  myDLpLocked,
  lockedSupplyWithMultiplier,
  LockAprData,
  annualReward,
}) {
  const [step, setStep] = useState(0);
  const [tokenInfo, setTokenInfo] = useState();
  const [amount, setAmount] = useState('');
  const [amountBul, setAmountBul] = useState('');
  const [newZapInUsd, setNewZapInUsd] = useState(new BigNumber(0));
  const [selectedMuliplier, setSelectedMuliplier] = useState(0);

  const title = useMemo(() => {
    return (
      <ZapModalTitle setOpen={setOpen} step={step} setStep={setStep} />
    )
  }, [setOpen, step, setStep]);

  const content = () => {
    if (step === 0) {
      return (
        <ZapModalStep1
          update={update}
          setUpdate={setUpdate}
          setStep={setStep}
          amount={amount}
          setAmount={setAmount}
          amountBul={amountBul}
          setAmountBul={setAmountBul}
          setTokenInfo={setTokenInfo}
          setNewZapInUsd={setNewZapInUsd}
          lockedUsdValue={lockedUsdValue}
          requiredUsdValue={requiredUsdValue}
        />
      )
    } else if (step === 1) {
      return (
        <ZapModalStep2
          setStep={setStep}
          selectedMuliplier={selectedMuliplier}
          setSelectedMuliplier={setSelectedMuliplier}
          dLpPrice={dLpPrice}
          myDLpLocked={myDLpLocked}
          newZapInUsd={newZapInUsd}
          lockedUsdValue={lockedUsdValue}
          requiredUsdValue={requiredUsdValue}
          lockedSupplyWithMultiplier={lockedSupplyWithMultiplier}
          LockAprData={LockAprData}
          annualReward={annualReward}
        />
      )
    } else if (step === 2) {
      return (
        <ZapModalStep3
          update={update}
          setUpdate={setUpdate}
          setOpen={setOpen}
          tokenInfo={tokenInfo}
          amount={amount}
          amountBul={amountBul}
          selectedMuliplier={selectedMuliplier}
          newZapInUsd={newZapInUsd}
          lockedUsdValue={lockedUsdValue}
          requiredUsdValue={requiredUsdValue}
          LockAprData={LockAprData}
        />
      )
    }
    return null;
  }

  return (
    <Modal
      title={title}
      content={content()}
      open={open}
      onSetOpen={() => { }}
      onCloseModal={() => { }}
      afterCloseModal={() => { }}
    />
  )
}

export default ZapModal;