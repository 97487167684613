import { bindActionCreators } from 'redux';
import logo from '../../assets/icons/dark-logo.png';
import { accountActionCreators, connectAccount } from '../../core';
import { Twitter } from '../../components/Icons/Twitter';
import { Medium } from '../../components/Icons/Medium';
import { Telegram } from '../../components/Icons/Telegram';
import { Discord } from '../../components/Icons/Discord';

export const Footer = () => {
    return (
        <footer className="flex flex-col items-center text-white mt-20 w-full px-6 py-[50px] text-white/60 lg:px-0">
            <div id="navbar" className="!w-full">
                <span id="navbar_content">
                    <img
                        src={logo}
                        width={175}
                        height={40}
                        alt="logo"
                        className="mb-8 pb-3"
                    />
                </span>
            </div>

            <div className="w-full max-w-[1160px] pt-0.5">
                <div className="flex flex-col items-center justify-between py-6 lg:flex-row border-t-1-gradient">
                    <p className="order-2 mt-4 text-white lg:order-1 lg:mt-0 opacity-60">
                        © Copyright {new Date().getFullYear()} Belugas. All
                        rights reserved.
                    </p>

                    <ul className="order-1 flex gap-3 lg:order-2">
                        {mocSocials.map(({ logo: Logo, link }, i) => {
                            return (
                                <li key={i}>
                                    <a href={link} target='_blank' rel="noreferrer">
                                        <Logo
                                            color={'white'}
                                            className={`rounded-lg bg-[#383a45] shadow-button`}
                                        />
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </footer>
    );
};

const mocSocials = [
    { logo: Twitter, link: 'https://twitter.com/belugasdefi' },
    { logo: Medium, link: 'https://medium.com/@belugasdefi' },
    { logo: Telegram, link: 'https://t.me/officialbelugas' },
    // { logo: Discord, link: 'https://discord.com' },
];

Footer.defaultProps = {
    settings: {},
};

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
    const { setSetting } = accountActionCreators;

    return bindActionCreators(
        {
            setSetting,
        },
        dispatch,
    );
};

export default connectAccount(mapStateToProps, mapDispatchToProps)(Footer);
