import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Loading from 'components/UI/Loading';
import {
    useAutoReLock,
    useLock,
    useReLock,
    useSetDefaultRelockTypeIndex,
    useWithdrawExpiredLocksForWithOptions,
    usedLpApprove,
} from 'hooks/useLpStaking';
import BigNumber from 'bignumber.js';
import { NotificationManager } from 'react-notifications';
import { getFormatStringFromBignumber } from 'utilities/common';
import { useActiveWeb3React } from 'hooks';
import { useSelector } from 'react-redux';

const StyedContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;
    
    > .gradient-border-tb {
        height: 100%;

        > div {
            height: 100%;
        }
    }
    .title {
        font-size: 36px;
        font-family: LibreFranklin_Regular;
        font-weight: 600;
    }
    .divider {
        margin: 20px -20px;
        border-bottom: 1px solid #ffffff23;
    }
    .sub-title {
        font-size: 14px;
        font-weight: 600;
        span {
            color: rgb(95, 0, 250);
        }
    }
    .desc {
        padding-top: 10px;
        font-size: 12px;
        color: rgb(116, 128, 136);
    }
    .dlp-first {
      .lock-button-container {
        padding-top: 20px;
        button {
          width: 100%;
          height: 38px;
          color: white;
          background-color: #0061a7;
          border-radius: 5px;
        }
      }
    }
    .dlp-second {
        padding-top: 20px;
        .tab-container {
            user-select: none;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            height: 32px;
            padding: 2px;
            margin-top: 20px;
            gap: 4px;

            .tab {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                cursor: pointer;
                border: solid 1px #4776e6;
                border-radius: 8px;
                padding: 8px 0;
            }
            .active-tab {
                background: linear-gradient(90deg, #4776e6 0%, #0061a7 100%);
            }
        }
        .apr-container {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            margin-top: 10px;
            .content {
                font-size: 12px;
                font-weight: 600;
                color: rgb(116, 128, 136);
                text-align: center;
            }
        }
    }
    .dlp-third {
        .sub-container {
            display: flex;
            justify-content: space-between;
        }
    }
    .dlp-fourth {
        padding-top: 20px;
        .withdraw-container {
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .value-container {
                display: flex;
                align-items: center;
                img {
                    margin-right: 5px;
                    // width: 25px;
                    height: 25px;
                }
                .value {
                    font-size: 25px;
                    font-weight: 700;
                }
            }
            .button-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .button {
                    width: 130px;
                    height: 36px;
                    border-radius: 5px;
                    color: white;
                    background-color: #0061a7;
                    font-size: 14px;
                    @media only screen and (max-width: 768px) {
                        font-size: 12px;
                        width: 160px;
                        height: 30px;
                    }
                }
                .button1 {
                    width: 130px;
                    height: 36px;
                    border-radius: 5px;
                    color: #0061a7;
                    border-color: #0061a7;
                    border-width: 1px;
                    background-color: transparent;
                    font-size: 14px;
                    @media only screen and (max-width: 768px) {
                        font-size: 12px;
                        width: 160px;
                        height: 30px;
                    }
                }
            }
        }
    }

    .input-conatiner {
        padding: 16px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.03);
        box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);

        .sub-container {
            display: flex;
        }
        input {
            flex: 1;
            background: transparent;
            border: none;
            &:focus {
                outline: none;
            }
            ::placeholder {
                padding: 0 !important;
            }
        }
        .max-button {
            background: #0061a7;
            padding: 0px 10px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 10px;
            color: #fff;
            &:hover {
            }
        }
        .balance {
            padding-top: 5px;
            font-size: 12px;
            text-align: right;
        }
    }
    .history-container {
        .divider1 {
            margin: 20px -20px 0;
            border-bottom: 1px solid #ffffff23;
        }
        .divider2 {
            margin: 0px -20px 10px;
            border-bottom: 1px solid #ffffff23;
        }
        .history-header {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            font-weight: 600;
            font-size: 13px;
            padding: 5px 0;
        }
        .history-items {
            height: 200px;
            overflow: auto;
            .history-item {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                color: rgb(116, 128, 136);
                font-size: 13px;
                padding-bottom: 10px;
                .amount-container {
                    display: flex;
                    align-items: center;
                    .multifier {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba(0, 0, 0, 0.2);
                        padding: 0px 5px;
                        width: 35px;
                        border-radius: 3px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
`;

function LpOverview({
    update,
    setUpdate,
    defaultLockIndex,
    myDLpBalance,
    myDLpAllowance,
    myUnlockable,
    autoRelockDisabled,
    lockedSupplyWithMultiplier,
    LockAprData,
    lockList,
}) {
    const { t } = useTranslation();
    const { account } = useActiveWeb3React();

    const { pending: approvePending, handleApprove } = usedLpApprove();
    const {
        pending: approveRelockPending,
        handleApprove: handleApproveRelock,
    } = usedLpApprove();
    const { pending: lockPending, handleLock } = useLock();
    const { pending: reLockPending, handleReLock } = useReLock();
    const { pending: withdrawPending, handleWithdraw } =
        useWithdrawExpiredLocksForWithOptions();
    const { pending: autoReLockPending, handleAutoReLock } = useAutoReLock();
    const { pending: defaultIndexPending, handleSetDefaultRelockTypeIndex } =
        useSetDefaultRelockTypeIndex();

    const [lockAmount, setLockAmount] = useState('');

    const needToApprove = useMemo(() => {
        if (!lockAmount && new BigNumber(lockAmount).lte(0)) return false;
        return new BigNumber(lockAmount).times(1e18).gt(myDLpAllowance);
    }, [myDLpAllowance, lockAmount]);

    const needToApproveForRelock = useMemo(() => {
        if (!lockAmount && new BigNumber(myUnlockable).lte(0)) return false;
        return new BigNumber(myUnlockable).gt(myDLpAllowance);
    }, [myDLpAllowance, myUnlockable]);

    const onLock = async () => {
        const bigLockAmount = new BigNumber(lockAmount).times(1e18);
        if (bigLockAmount.gt(myDLpBalance)) {
            NotificationManager.error(t('Insufficient_Balance'));
            return;
        }
        if (needToApprove) {
            const tx = await handleApprove(bigLockAmount);
            if (tx) {
                NotificationManager.success(t('Approve_successfully'));
                setUpdate(!update);
                setLockAmount('');
            } else {
                NotificationManager.error(t('Tx_rejected'));
            }
        } else {
            const tx = await handleLock(bigLockAmount, defaultLockIndex);
            if (tx) {
                NotificationManager.success(t('Set_lock_successfully'));
                setUpdate(!update);
            } else {
                NotificationManager.error(t('Tx_rejected'));
            }
        }
    };

    const onReLock = async () => {
        if (needToApproveForRelock) {
            const tx = await handleApproveRelock(myUnlockable);
            if (tx) {
                NotificationManager.success(t('Approve_successfully'));
                setUpdate(!update);
            } else {
                NotificationManager.error(t('Tx_rejected'));
            }
        } else {
            const tx = await handleReLock();
            if (tx) {
                NotificationManager.success(t('Set_relock_successfully'));
                setUpdate(!update);
            } else {
                NotificationManager.error(t('Tx_rejected'));
            }
        }
    };

    const onWithdraw = async () => {
        const tx = await handleWithdraw();
        if (tx) {
            NotificationManager.success(t('Set_withdraw_successfully'));
            setUpdate(!update);
        } else {
            NotificationManager.error(t('Tx_rejected'));
        }
    };

    const onAutoReLock = async () => {
        if (autoReLockPending || !account) return;
        const tx = await handleAutoReLock(autoRelockDisabled);
        if (tx) {
            NotificationManager.success(t('Set_auto_lock_successfully'));
            setUpdate(!update);
        } else {
            NotificationManager.error(t('Tx_rejected'));
        }
    };

    const onSetDefaultLockIndex = async (index) => {
        if (!account || defaultLockIndex === index || defaultIndexPending)
            return;
        const tx = await handleSetDefaultRelockTypeIndex(index);
        if (tx) {
            NotificationManager.success(t('Set_default_lock_successfully'));
            setUpdate(!update);
        } else {
            NotificationManager.error(t('Tx_rejected'));
        }
    };

    const getTimeText = (timeStamp) => {
        const diff = parseInt(timeStamp - Date.now() / 1000);
        if (diff < 0) return '00:00';
        const days = diff / (24 * 3600);
        if (days >= 2) return `${parseInt(days)} ${t('days')}`;
        if (days >= 1) return `${parseInt(days)} ${t('day')}`;
        const hours = parseInt(diff / 3600);
        const mins = parseInt((diff - hours * 3600) / 60);
        return `${hours > 9 ? hours : `0${hours}`}:${
            mins > 9 ? mins : `0${mins}`
        }`;
    };

    const LockData = useMemo(() => {
        return [
            { title: `1 ${t('month')}` },
            { title: `3 ${t('months')}` },
            { title: `6 ${t('months')}` },
            { title: `12 ${t('months')}` },
        ];
    }, [t]);

    return (
        <StyedContainer>
            <div className="gradient-border-tb">
                <div className="container">
                    <div className="title">{t('dLP_overview')}</div>
                    <div className="gradient-divider" />
                    <div className="dlp-first">
                        <div className="sub-title">{t('Lock_your_dLP')}</div>
                        <div className="desc">{t('Earn_platform_fees')}</div>
                        <div className="gradient-border-tb mt-3">
                            <div className="input-conatiner ">
                                <div className="sub-container">
                                    <input
                                        placeholder="0.00"
                                        value={lockAmount}
                                        onChange={(event) => {
                                            if (
                                                !event.target.value.length ||
                                                Number(event.target.value) >= 0
                                            )
                                                setLockAmount(
                                                    event.target.value,
                                                );
                                        }}
                                    />
                                    <button
                                        type="button"
                                        className="max-button"
                                        disabled={!account}
                                        onClick={() => {
                                            setLockAmount(
                                                myDLpBalance
                                                    .div(1e18)
                                                    .toString(),
                                            );
                                        }}
                                    >
                                        {t('MAX')}
                                    </button>
                                </div>
                                <div className="balance">
                                    {t('Balance')}
                                    {': '}
                                    {myDLpBalance.div(1e18).toFormat(2)}{' '}
                                    <span className="span-bul">LP</span>
                                </div>
                            </div>
                        </div>

                        <div className="lock-button-container">
                            <button
                                type="button"
                                disabled={
                                    !account ||
                                    lockPending ||
                                    approvePending ||
                                    !lockAmount ||
                                    new BigNumber(lockAmount).eq(0)
                                }
                                className="btn-primary"
                                onClick={onLock}
                            >
                                {lockPending || approvePending ? (
                                    <div className="flex justify-center items-center">
                                        <Loading size={'16px'} margin={'7px'} />
                                        {t('Pending')}
                                    </div>
                                ) : (
                                    <>
                                        {needToApprove
                                            ? t('Approve')
                                            : t('Lock')}
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="dlp-second">
                        <div className="sub-title">
                            {t('Default_lock_length')}
                        </div>
                        <div className="desc">
                            {t('Change_made_to_default')}
                        </div>
                        <div className="tab-container">
                            {LockData.map((data, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`tab ${
                                            defaultLockIndex === index
                                                ? 'active-tab'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            onSetDefaultLockIndex(index)
                                        }
                                    >
                                        {data.title}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="apr-container">
                            {LockAprData.map((data, index) => {
                                return (
                                    <div key={index} className="content">
                                        {lockedSupplyWithMultiplier.isZero()
                                            ? '--%'
                                            : `${getFormatStringFromBignumber(
                                                  data,
                                              )}%`}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {/* <div className="divider" />
        <div className="dlp-third">
          <div className="sub-title">
            {t('Auto_relock')}: <span>{autoRelockDisabled ? t('Disabled') : t('Enabled')}</span>
          </div>
          <div className="sub-container">
            <div className="desc">
              {t('Automatically_relock_expired', { value: LockData[defaultLockIndex].title })}
            </div>
            <Switch
              inactiveStateColor={"bg-darkGray"}
              activeStateColor={"bg-darkGray"}
              slideClassName={"bg-lightGreen border-lightGreen"}
              inactiveSlideClassName={'bgGrayGradient border-gray2'}
              wrapperClassName="pt-1 pb-0"
              style={'small'}
              value={!autoRelockDisabled}
              onChange={onAutoReLock}
            />
          </div>
        </div> */}
                    <div className="gradient-divider" />
                    <div className="dlp-fourth">
                        <div className="sub-title">{t('Redeemable_locks')}</div>
                        <div className="desc">{t('Relock_your_dLP')}</div>
                        <div className="withdraw-container">
                            <div className="value-container">
                                <img
                                    src={`/images/coins/dlp_0.png`}
                                    alt={'bul'}
                                />
                                <div className={'value'}>
                                    {getFormatStringFromBignumber(
                                        myUnlockable.div(1e18),
                                    )}
                                </div>
                            </div>
                            <div className="button-container">
                                <div>
                                    <button
                                        type="button"
                                        className="button"
                                        disabled={
                                            !account ||
                                            myUnlockable.lte(0) ||
                                            reLockPending ||
                                            approveRelockPending
                                        }
                                        onClick={onReLock}
                                    >
                                        {reLockPending ||
                                        approveRelockPending ? (
                                            <div className="flex justify-center items-center">
                                                <Loading
                                                    size={'16px'}
                                                    margin={'7px'}
                                                />
                                                {t('Pending')}
                                            </div>
                                        ) : (
                                            <>
                                                {needToApproveForRelock
                                                    ? t('Approve_For_Lock')
                                                    : t('Lock')}
                                            </>
                                        )}
                                    </button>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="button1"
                                        disabled={
                                            !account ||
                                            myUnlockable.lte(0) ||
                                            withdrawPending
                                        }
                                        onClick={onWithdraw}
                                    >
                                        {withdrawPending ? (
                                            <div className="flex justify-center items-center">
                                                <Loading
                                                    size={'16px'}
                                                    margin={'7px'}
                                                />
                                                {t('Pending')}
                                            </div>
                                        ) : (
                                            <>{t('Withdraw')}</>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="history-container">
                        <div className="gradient-divider" />
                        <div className="history-header">
                            <div>{t('Amount')}</div>
                            <div>{t('Unlockable_in')}</div>
                        </div>
                        <div className="history-items">
                            {lockList.map((data, index) => {
                                return (
                                    <div key={index} className="history-item">
                                        <div className="amount-container">
                                            <div className="multifier">
                                                {getFormatStringFromBignumber(
                                                    data.multiplier,
                                                    0,
                                                    2,
                                                )}
                                                x
                                            </div>
                                            {getFormatStringFromBignumber(
                                                data.amount.div(1e18),
                                            )}{' '}
                                            LP
                                        </div>
                                        <div>
                                            {getTimeText(data.unlockTime)}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </StyedContainer>
    );
}

export default LpOverview;
