import config from "./config"

const SEI = {
  name: 'SEI',
  symbol: 'SEI',
  decimals: 18,
}

export const CHAINS = {
  // Mainnet
  1329: {
    urls: [config.seiWeb3Provider],
    name: 'Sei',
    nativeCurrency: SEI,
    blockExplorerUrls: ['https://seitrace.com/'],
  },

  // Testnet
  713715: {
    urls: [config.seiWeb3TestProvider],
    name: 'Sei Arctic',
    nativeCurrency: SEI,
    blockExplorerUrls: ['https://seitrace.com/'],
  },
}

function isExtendedChainInformation(chainInformation) {
  return !!chainInformation?.nativeCurrency
}

export function getAddChainParameters(chainId) {
  const chainInformation = CHAINS[chainId]
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    }
  } else {
    return chainId
  }
}

export const URLS = Object.keys(CHAINS).reduce(
  (accumulator, chainId) => {
    const validURLs = CHAINS[Number(chainId)].urls

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs
    }

    return accumulator
  },
  {}
)
