/* eslint-disable max-len */
export const TickGrayIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            fill="none"
            viewBox="0 0 24 25"
        >
            <path
                fill="#717579"
                d="M19 3.563H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-14a2 2 0 00-2-2zM10.707 17.27a.999.999 0 01-1.414 0L6 13.977a1 1 0 011.414-1.415L10 15.15l6.586-6.586A1 1 0 0118 9.976l-7.293 7.293z"
            ></path>
        </svg>
    );
};
