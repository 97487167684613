import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ZapModal from './ZapModal';
import { getFormatStringFromBignumber } from 'utilities/common';
import { useActiveWeb3React } from 'hooks';
import BigNumber from 'bignumber.js';

const StyledConatiner = styled.div`
    margin-bottom: 40px;

    .main-container {
        display: block;

        @media (min-width: 768px) {
            display: flex;
            max-width: 100%;
            padding: 0px 20px;
        }
    }

    .container {
        border-radius: 8px;
        background: linear-gradient(90deg, #4776e6 0%, #0061a7 100%);
        box-shadow: 4px 6px 61px 0px rgba(99, 104, 231, 0.2);
        padding: 24px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;

        .title {
            font-family: LibreFranklin_Regular;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            margin-bottom: 10px;

            @media only screen and (max-width: 768px) {
                font-size: 30px;
            }
        }
        .title-active {
            color: rgb(0, 255, 170);
            text-shadow: rgb(0, 255, 170) 0px 0px 20px;
        }
        .title-inactive {
            color: #ff3333;
            text-shadow: #ff3333 0px 0px 20px;
        }
        .desc {
            font-size: 15px;
            .value1 {
                color: #00ff75;
                font-weight: 400;
            }
        }
        .zap-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 100px;
            padding-top: 30px;
            @media only screen and (max-width: 768px) {
                padding-top: 20px;
                gap: 0px;
                width: 100%;
            }
            .value-container {
                .value-desc {
                    font-size: 12px;
                    font-weight: 600;
                }
                .value-content {
                    font-size: 18px;
                    font-weight: 600;
                    span {
                        font-size: 16px;
                        color: rgba(255, 255, 255, 0.6);
                    }
                }
                .progress-bar {
                  margin-top: 10px;
                  position: relative;
                  width: 300px;
                  height: 8px;
                  border-radius: 4px;
                  background-color: rgba(0, 0, 0, 0.05);
                  @media only screen and (max-width: 768px) {
                    width: 150px;
                  }
                  .progress-line {
                    position: absolute;
                    border-right: 2px solid rgb(95, 0, 250);
                    height: 16px;
                    width: 25%;
                    bottom: -4px;
                  }
                  .progress-active-bar {
                    height: 8px;
                    background-color: rgb(95, 0, 250);
                    border-radius: 4px;
                  }
                }
            }
            .button-container {
                .button {
                    width: 150px;
                    height: 54px;
                    border-radius: 8px;
                    background: linear-gradient(
                        90deg,
                        #4776e6 0%,
                        #0061a7 100%
                    );
                    box-shadow: 4px 6px 61px 0px rgba(99, 104, 231, 0.2);
                    color: white;

                    font-size: 14px;
                    @media only screen and (max-width: 768px) {
                        font-size: 12px;
                        width: 100px;
                        height: 30px;
                    }
                }
            }
        }
    }
`;

function Emission({
    update,
    setUpdate,
    isEligibleForRewards,
    lockedUsdValue,
    requiredUsdValue,
    myDLpLocked,
    dLpPrice,
    lockedSupplyWithMultiplier,
    LockAprData,
    annualReward,
}) {
    const { t } = useTranslation();
    const { account } = useActiveWeb3React();

    const [showZapModal, setShowZapModal] = useState(false);

    const progressWidth = useMemo(() => {
        if (requiredUsdValue.eq(0)) return 0;
        if (lockedUsdValue.gte(requiredUsdValue.times(4))) return 100;
        return parseInt(
            lockedUsdValue.times(100).div(requiredUsdValue.times(4)).toString(),
        );
    }, [lockedUsdValue, requiredUsdValue]);

    const awayProgress = useMemo(() => {
        if (requiredUsdValue.eq(0)) return new BigNumber(100);
        if (lockedUsdValue.gte(requiredUsdValue.times(4)))
            return new BigNumber(0);
        return new BigNumber(100).minus(
            lockedUsdValue.div(requiredUsdValue).times(100),
        );
    }, [lockedUsdValue, requiredUsdValue]);

    return (
        <>
            <StyledConatiner className="max-w-7xl w-full">
                <div className=" mt-1 mx-auto gap">
                    <div className="container">
                        {isEligibleForRewards ? (
                            <div className="flex justify-center items-center flex-col">
                                <div className="title title-active">
                                    {t('Emissions_Active')}
                                </div>
                                <div className="desc">
                                    {t('Emissions_Active_Desc')}
                                </div>
                            </div>
                        ) : (
                            <div className="flex justify-center items-center flex-col">
                                <div className="title title-inactive">
                                    {t('Emissions_Inactive')}
                                </div>
                                <div className="desc">
                                    {t('Emissions_Inactive_Desc_1')}
                                    <span className="value1">
                                        {' '}
                                        {getFormatStringFromBignumber(
                                            awayProgress,
                                            0,
                                        )}
                                        %{' '}
                                    </span>
                                    {t('Emissions_Inactive_Desc_2')}
                                </div>
                            </div>
                        )}
                        <div className="zap-container">
                            <div className="value-container">
                                <div className="value-desc">
                                    {t('dLP_required_to')}
                                </div>
                                <div className="value-content">
                                    $
                                    {getFormatStringFromBignumber(
                                        lockedUsdValue.div(1e8),
                                        2,
                                        2,
                                    )}
                                    <span>
                                        /$
                                        {getFormatStringFromBignumber(
                                            requiredUsdValue.div(1e8),
                                            2,
                                            2,
                                        )}
                                    </span>
                                </div>
                                <div className="progress-bar">
                                    <div className="progress-line" />
                                    <div
                                        className="progress-active-bar"
                                        style={{ width: `${progressWidth}%` }}
                                    />
                                </div>
                            </div>
                            <div className="button-container">
                                <button
                                    type="button"
                                    className={'button'}
                                    disabled={false}
                                    onClick={() => {
                                        if (account) {
                                            setShowZapModal(true);
                                        }
                                    }}
                                >
                                    {t('Zap_into_dLP')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledConatiner>
            {showZapModal && (
                <ZapModal
                    open={showZapModal}
                    setOpen={setShowZapModal}
                    update={update}
                    setUpdate={setUpdate}
                    lockedUsdValue={lockedUsdValue}
                    requiredUsdValue={requiredUsdValue}
                    dLpPrice={dLpPrice}
                    myDLpLocked={myDLpLocked}
                    lockedSupplyWithMultiplier={lockedSupplyWithMultiplier}
                    LockAprData={LockAprData}
                    annualReward={annualReward}
                />
            )}
        </>
    );
}

export default Emission;
