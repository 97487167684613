import { useCallback, useState } from "react";
import { useActiveWeb3React } from "hooks";
import { useReferralContract } from "./useContract";

export const useReferralClaimCallback = () => {
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useReferralContract(requiredChainId);

  const handleClaim = useCallback(
    async (amount, nonce, code, v, r, s) => {
      try {
        setPending(true);
        const tx = await contract.methods.claim(
          account,
          amount,
          nonce,
          code,
          v,
          r,
          s
        ).send({
          from: account
        });
        setPending(false);
        return tx;
      } catch (e) {
        console.error('ReferralClaim had error :>> ', e);
        setPending(false);
        return false;
      }
    },
    [account, contract]
  );

  return { handleClaim, pending };
};