/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import Modal from '../UI/Modal';
import NumberFormat from 'react-number-format';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import { getBigNumber } from '../../utilities/common';
import { bindActionCreators } from 'redux';
import { accountActionCreators, connectAccount } from '../../core';
import { useActiveWeb3React, useWeb3 } from '../../hooks';
import {
  getAbepContract,
  getTokenContract,
  methods,
} from '../../utilities/ContractService';
import { sendSupply } from '../../utilities/SeiContract';
import commaNumber from 'comma-number';
import arrowThin from '../../assets/icons/arrowThin.svg';
import { CloseIcon } from './IconClose';
import styled from 'styled-components';
import Loading from '../UI/Loading';
import { Logo } from 'components/Icons/Logo';
import { MediumLineGradient } from './lineGradient';

const StyledNumberFormat = styled(NumberFormat)`
  width: 100%;
  height: 100%;
  font-size: 36px;
  margin-top: 5px;
  text-align: end;
  background: transparent;
  font-weight: bold;
  color: #ffffff;

  &:focus {
    outline: none;
  }

  @media all and (max-width: 1024px) {
    font-size: 30px;
  }

  @media all and (max-width: 767px) {
    font-size: 24px;
  }
`;

const Styles = styled.div`
  .tooltip {
    .label {
      left: auto;
      right: 0;
    }
  }
`;

const ContainerStyles = styled.div`
  background: #064076;
  padding-top: 25px;

  .btn-sm {
    height: 36px;
    padding: 0px 15px;
    line-height: 36px;
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 16px;
  }

  .settings-close-container {
    position: absolute;
    right: 1rem;
    top: 1rem;
    outline: none;
    cursor: pointer;
    background: transparent;
  }

  svg.logo-icon {
    width: 47px;
    height: auto;
  }

  .custom-border2 {
    border-bottom: 2px solid #0061a7;
  }
  .w-45 {
    width: 45%;
  }
  .confirm-btn {
    background: #0061a7;
    border-radius: 4px;
    padding: 17px 80px;
  }

  .custom-text-darkGrey {
    color: #878787;
  }
  .custom-text-grey {
    color: #9e9e9e;
  }
  .input-wrapper-withdraw {
    padding: 33px 47px;
    margin: 0 -5.5rem;
    background: #e6e6e6;
  }
`;
const ErrorMessage = styled.div`
  color: red;
  text-align: right;
  width: 100%;
  padding: 5px;
`;

const commaFormatter = commaNumber.bindWith(',', '.');
const format = (number) => {
  if (isNaN(number)) {
    return 0;
  }
  return commaFormatter(number);
};

function SupplyWithdrawModal({
  open,
  onSetOpen,
  onCloseModal,
  record,
  settings,
  setSetting,
  setConnectWalletsOpen,
}) {
  const { t } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();
  const web3 = useWeb3();
  const [currentTab, setCurrentTab] = useState('supply');
  const [showSupplyError, setShowSupplyError] = useState(false);
  const [showWithdrawError, setShowWithdrawError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [allowanceAmount, setAllowanceAmount] = useState(new BigNumber(0));
  const [amount, setAmount] = useState();
  const [borrowLimit, setBorrowLimit] = useState(new BigNumber(0));
  const [borrowPercent, setBorrowPercent] = useState(new BigNumber(0));
  const [newBorrowLimit, setNewBorrowLimit] = useState(new BigNumber(0));
  const [newBorrowPercent, setNewBorrowPercent] = useState(new BigNumber(0));

  const updateInfo = useCallback(async () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(record.tokenPrice);
    const collateralFactor = getBigNumber(record.collateralFactor);

    if (tokenPrice && amount && !amount.isZero() && !amount.isNaN()) {
      const temp = totalBorrowLimit.plus(
        amount.times(tokenPrice).times(collateralFactor),
      );
      setNewBorrowLimit(BigNumber.maximum(temp, 0));
      setNewBorrowPercent(totalBorrowBalance.div(temp).times(100));
      if (totalBorrowLimit.isZero()) {
        setBorrowLimit(new BigNumber(0));
        setBorrowPercent(new BigNumber(0));
      } else {
        setBorrowLimit(totalBorrowLimit);
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    } else if (BigNumber.isBigNumber(totalBorrowLimit)) {
      setBorrowLimit(totalBorrowLimit);
      setNewBorrowLimit(totalBorrowLimit);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercent(new BigNumber(0));
        setNewBorrowPercent(new BigNumber(0));
      } else {
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercent(
          totalBorrowBalance.div(totalBorrowLimit).times(100),
        );
      }
    }
    if (new BigNumber(amount || 0).gt(record.walletBalance)) {
      setShowSupplyError(true);
    } else {
      setShowSupplyError(false);
    }
  }, [account, amount, record]);

  useEffect(() => {
    if (record.btokenAddress && account) {
      updateInfo();
    }
  }, [account, updateInfo]);

  useEffect(() => {
    if (record.id !== 'sei') {
      if (!amount || allowanceAmount.gte(amount)) {
        setIsEnabled(true);
      } else {
        setIsEnabled(false);
      }
    } else {
      setIsEnabled(true);
    }
  }, [amount, allowanceAmount]);

  useEffect(() => {
    if (open) {
      getAllowanceAmount();
    } else {
      setAmount();
      setWithdrawAmount();
    }
  }, [open]);

  const getAllowanceAmount = async () => {
    if (record && account && record.id !== 'sei') {
      const tokenContract = getTokenContract(web3, record.id, requiredChainId);
      const result = await methods.call(tokenContract.methods.allowance, [
        account,
        record.btokenAddress,
      ]);
      setAllowanceAmount(
        new BigNumber(result || 0).dividedBy(
          new BigNumber(10).pow(settings.decimals[record.id]?.token || 18),
        ),
      );
    }
  };
  /**
   * Approve underlying token
   */
  const onApprove = async () => {
    if (record.id && account && record.id !== 'sei') {
      setIsLoading(true);
      const tokenContract = getTokenContract(web3, record.id, requiredChainId);
      methods
        .send(
          tokenContract.methods.approve,
          [
            record.btokenAddress,
            amount
              .times(new BigNumber(10).pow(settings.decimals[record.id].token))
              .toString(10),
          ],
          account,
        )
        .then(() => {
          getAllowanceAmount();
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * Supply
   */
  const handleSupply = () => {
    if (record.id && account) {
      setIsLoading(true);
      setSetting({
        pendingInfo: {
          type: 'Supply',
          status: true,
          amount: amount.dp(8, 1).toString(10),
          symbol: record.symbol,
        },
      });
      if (record.id !== 'sei') {
        const appContract = getAbepContract(web3, record.id, requiredChainId);
        methods
          .send(
            appContract.methods.mint,
            [
              amount
                .times(
                  new BigNumber(10).pow(settings.decimals[record.id].token),
                )
                .toString(10),
            ],
            account,
          )
          .then(() => {
            setAmount();
            setIsLoading(false);
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: '',
              },
            });
            onCloseModal();
          })
          .catch(() => {
            setIsLoading(false);
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: '',
              },
            });
          });
      } else {
        sendSupply(
          web3,
          account,
          amount
            .times(new BigNumber(10).pow(settings.decimals[record.id].token))
            .toString(10),
          requiredChainId,
          () => {
            setAmount();
            setIsLoading(false);
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: '',
              },
            });
            onCloseModal();
          },
        );
      }
    }
  };

  /**
   * Max amount
   */
  const handleMaxAmount = () => {
    setAmount(record.walletBalance);
  };

  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(new BigNumber(0));
  const [withdrawBorrowLimit, setWithdrawBorrowLimit] = useState(
    new BigNumber(0),
  );
  const [withdrawBorrowPercent, setWithdrawBorrowPercent] = useState(
    new BigNumber(0),
  );
  const [withdrawNewBorrowLimit, setWithdrawNewBorrowLimit] = useState(
    new BigNumber(0),
  );
  const [withdrawNewBorrowPercent, setWithdrawNewBorrowPercent] = useState(
    new BigNumber(0),
  );

  const updateWithdrawInfo = useCallback(async () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(record.tokenPrice);
    const collateralFactor = getBigNumber(record.collateralFactor);

    if (
      tokenPrice &&
      withdrawAmount &&
      !withdrawAmount.isZero() &&
      !withdrawAmount.isNaN()
    ) {
      const temp = record?.collateral ? totalBorrowLimit.minus(
        withdrawAmount.times(tokenPrice).times(collateralFactor),
      ) : totalBorrowLimit;
      setWithdrawNewBorrowLimit(temp);
      setWithdrawNewBorrowPercent(record?.collateral ? totalBorrowBalance.div(temp).times(100) : totalBorrowBalance.times(100));
      if (totalBorrowLimit.isZero()) {
        setWithdrawBorrowLimit(new BigNumber(0));
        setWithdrawBorrowPercent(new BigNumber(0));
      } else {
        setWithdrawBorrowLimit(totalBorrowLimit);
        setWithdrawBorrowPercent(
          totalBorrowBalance.div(totalBorrowLimit).times(100),
        );
      }
    } else {
      setWithdrawBorrowLimit(totalBorrowLimit);
      setWithdrawNewBorrowLimit(totalBorrowLimit);
      if (totalBorrowLimit.isZero()) {
        setWithdrawBorrowPercent(new BigNumber(0));
        setWithdrawNewBorrowPercent(new BigNumber(0));
      } else {
        setWithdrawBorrowPercent(
          totalBorrowBalance.div(totalBorrowLimit).times(100),
        );
        setWithdrawNewBorrowPercent(
          totalBorrowBalance.div(totalBorrowLimit).times(100),
        );
      }
    }
  }, [account, withdrawAmount]);

  useEffect(() => {
    if (
      (withdrawAmount && withdrawAmount.isGreaterThan(record.supplyBalance)) ||
      withdrawNewBorrowPercent.isGreaterThan(new BigNumber(100))
    ) {
      setShowWithdrawError(true);
    } else {
      setShowWithdrawError(false);
    }
  }, [withdrawAmount, withdrawNewBorrowPercent]);

  useEffect(() => {
    if (record.btokenAddress && account) {
      updateWithdrawInfo();
    }
  }, [account, updateWithdrawInfo]);

  /**
   * Withdraw
   */
  const handleWithdraw = async () => {
    const { id: assetId } = record;
    const appContract = getAbepContract(web3, assetId, requiredChainId);
    if (assetId && account) {
      setIsWithdrawLoading(true);
      setSetting({
        pendingInfo: {
          type: 'Withdraw',
          status: true,
          amount: withdrawAmount.dp(8, 1).toString(10),
          symbol: record.symbol,
        },
      });
      try {
        const initialize = (close) => {
          if (close) {
            setWithdrawAmount();
            onCloseModal();
            setCurrentTab('supply');
          }

          setIsWithdrawLoading(false);
          setSetting({
            pendingInfo: {
              type: '',
              status: false,
              amount: 0,
              symbol: '',
            },
          });
        };
        if (withdrawAmount.eq(record.supplyBalance)) {
          const bTokenBalance = await methods.call(
            appContract.methods.balanceOf,
            [account],
          );
          methods
            .send(appContract.methods.redeem, [bTokenBalance], account)
            .then(() => {
              initialize(true);
            })
            .catch(() => {
              initialize(false);
            });
        } else {
          methods
            .send(
              appContract.methods.redeemUnderlying,
              [
                withdrawAmount
                  .times(
                    new BigNumber(10).pow(settings.decimals[assetId].token),
                  )
                  .integerValue()
                  .toString(10),
              ],
              account,
            )
            .then(() => {
              initialize(true);
            })
            .catch(() => {
              initialize(false);
            });
        }
      } catch (error) {
        setIsWithdrawLoading(false);
        setSetting({
          pendingInfo: {
            type: '',
            status: false,
            amount: 0,
            symbol: '',
          },
        });
      }
    }
  };

  /**
   * Max amount
   */
  const handleWithdrawMaxAmount = () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(record.tokenPrice);
    const { collateral } = record;
    const supplyBalance = getBigNumber(record.supplyBalance);
    const collateralFactor = getBigNumber(record.collateralFactor);
    if (!collateral) {
      setWithdrawAmount(supplyBalance);
      return;
    }
    const safeMax = BigNumber.maximum(
      totalBorrowLimit
        .minus(totalBorrowBalance.div(70).times(100))
        .div(collateralFactor)
        .div(tokenPrice),
      new BigNumber(0),
    );
    setWithdrawAmount(
      BigNumber.minimum(safeMax, supplyBalance).decimalPlaces(
        settings.decimals[record.id].token,
      ),
    );
  };
  const getWithdrawMaxAmount = () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(record.tokenPrice);
    const { collateral } = record;
    const supplyBalance = getBigNumber(record.supplyBalance);
    const collateralFactor = getBigNumber(record.collateralFactor);
    if (!collateral) {
      return supplyBalance.toFormat(2, BigNumber.ROUND_DOWN);
    }
    const max = BigNumber.maximum(
      totalBorrowLimit
        .minus(totalBorrowBalance)
        .div(collateralFactor)
        .div(tokenPrice),
      new BigNumber(0),
    );
    return BigNumber.minimum(max, supplyBalance).toFormat(
      2,
      BigNumber.ROUND_DOWN,
    );
  };

  const PrimaryList = () => (
    <div className="flex p-5 borber-gradient shadow-xl rounded-lg flex-col text-white space-y-5 mt-8">
      <div className="flex justify-between">
        <h3 className="text-lg font-black">Calculator</h3>
        <span className="flex items-center gap-2 border border-paleBlue px-6 py-2 rounded-lg">
          <img className="w-8" src={record?.img} alt={record?.name} />
          <div className={'text-sm text-semibold'}>{record?.name}</div>
        </span>
      </div>
      <MediumLineGradient />

      <div>
        <div className="flex justify-between items-center">
          <h3 className="text-base">{t("Supply_APY")}</h3>
          <span className={'text-lg font-black'}>
            {record.supplyApy.dp(2, 1).toString(10)}%
          </span>
        </div>

        <div className="flex justify-between items-center pt-6">
          <h3 className="text-base">{t("Distribution_APY")}</h3>
          <span className={'text-lg font-black'}>
            {getBigNumber(record.supplyAdditionalApy).dp(2, 1).toString(10) +
              '%'}
          </span>
        </div>
      </div>
    </div>
  );

  const SecondaryList = () => {
    return (
      <div className="flex p-5 borber-gradient shadow-xl rounded-lg flex-col text-white space-y-5 mt-8">
        <div className="text-lg">{t('Borrow_Limit')}</div>

        <MediumLineGradient />

        <div>
          <div className="flex justify-between items-center">
            <div className="text-base">{t('Limit')}</div>
            {!withdrawAmount ||
              withdrawAmount.isZero() ||
              withdrawAmount.isNaN() ? (
              <span className={'text-lg font-black'}>
                ${format(withdrawBorrowLimit.dp(2, 1).toString(10))}
              </span>
            ) : (
              <div className="flex">
                <div className="text-lg font-black">
                  ${format(withdrawBorrowLimit.dp(2, 1).toString(10))}
                </div>
                <div className="text-midBlue">
                  <img src={arrowThin} alt="arrow" className="mx-4" />
                </div>
                <div className="text-lg font-black">
                  ${format(withdrawNewBorrowLimit.dp(2, 1).toString(10))}
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-between items-center pt-6">
            <div className="  text-base">{t('Limit_Used')}</div>
            {!withdrawAmount ||
              withdrawAmount.isZero() ||
              withdrawAmount.isNaN() ? (
              <span className={'text-lg font-black'}>
                {format(withdrawBorrowPercent.dp(2, 1).toString(10))}%
              </span>
            ) : (
              <div className="flex">
                <div className="text-lg font-black">
                  {format(withdrawBorrowPercent.dp(2, 1).toString(10))}%
                </div>
                <div className="text-midBlue">
                  <img src={arrowThin} alt="arrow" className="mx-4" />
                </div>
                <div className="text-lg font-black">
                  {format(withdrawNewBorrowPercent.dp(2, 1).toString(10))}%
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const SecondarySupplyList = () => {
    return (
      <div className="flex p-5 borber-gradient shadow-xl rounded-lg flex-col text-white space-y-5 mt-8">
        <div className="text-lg font-black">{t('Borrow_Limit')}</div>

        <MediumLineGradient />

        <div>
          <div className="flex justify-between items-center">
            <div className="text-base">Limit</div>
            {!amount || amount.isZero() || amount.isNaN() ? (
              <span className="text-lg font-black">
                ${format(borrowLimit.dp(2, 1).toString(10))}
              </span>
            ) : (
              <div className="flex">
                <div className="text-lg font-black">
                  ${format(borrowLimit.dp(2, 1).toString(10))}
                </div>
                <div className="text-midBlue">
                  <img src={arrowThin} alt="arrow" className="mx-4" />
                </div>
                <div className="text-lg font-black">
                  ${format(newBorrowLimit.dp(2, 1).toString(10))}
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-between items-center pt-6">
            <div className="  text-base">{t('Limit_Used')}</div>
            {!amount || amount.isZero() || amount.isNaN() ? (
              <span className={'text-lg font-black'}>
                {format(borrowPercent.dp(2, 1).toString(10))}%
              </span>
            ) : (
              <div className="flex">
                <div className="text-lg font-black">
                  {format(borrowPercent.dp(2, 1).toString(10))}%
                </div>
                <div className="text-midBlue">
                  <img src={arrowThin} alt="arrow" className="mx-4" />
                </div>
                <div className="text-lg font-black">
                  {format(newBorrowPercent.dp(2, 1).toString(10))}%
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const title = (
    <ContainerStyles className="flex justify-start items-center px-12 mt-4 rounded-t-lg">
      <div
        className="absolute top-8 right-12 cursor-pointer"
        onClick={() => {
          setWithdrawAmount();
          setAmount();
          onCloseModal();
        }}
      >
        <CloseIcon />
      </div>
      <Logo />
    </ContainerStyles>
  );

  const content = (
    <ContainerStyles className="pb-8 px-14">
      {currentTab === 'supply' && (
        <>
          <div className="flex p-5 borber-gradient shadow-xl rounded-lg flex-col text-white space-y-5 mt-8">
            <div className="">
              {/*  */}
              <div className="flex justify-between w-full">
                <p className="text-lg font-semibold">{t("Wallet_Balance")}:</p>
                <Styles className="">
                  <div className="tooltip relative">
                    <div className="tooltip-label text-xl font-bold">
                      {record?.walletBalance?.toFormat(2)}{' '}
                      {record.symbol}
                    </div>
                    <span className="label">
                      {record?.walletBalance?.toFormat(2)}{' '}
                      {record.symbol}
                    </span>
                  </div>
                </Styles>
              </div>
              <StyledNumberFormat
                value={!amount ? '' : amount.toString(10)}
                onValueChange={({ value }) => {
                  setAmount(new BigNumber(value));
                }}
                disabled={!account}
                isAllowed={({ value }) => {
                  return value?.length || 0 < 30;
                  // return (
                  //   ((record.id === 'sei') || isEnabled)
                  //     && new BigNumber(value || 0).isLessThanOrEqualTo(record.walletBalance)
                  // )
                }}
                thousandSeparator
                allowNegative={false}
                placeholder="0"
              />
              <div className="flex justify-end w-full">
                <button className='focus:outline-none' onClick={() => handleMaxAmount()}>Max</button>
              </div>
            </div>

          </div>
          <ErrorMessage>
            {showSupplyError ? t('Insufficient_Balance') : ''}
          </ErrorMessage>
        </>
      )}

      {currentTab === 'withdraw' && (
        <div className="flex p-5 borber-gradient shadow-xl rounded-lg flex-col text-white space-y-5">
          <div className="">
            <div className="flex justify-between w-full">
              <p className="text-lg font-semibold">{t("Wallet_Balance")}:</p>
              <Styles className="">
                <div className="tooltip relative">
                  <div className="tooltip-label text-xl font-bold">
                    {record?.walletBalance?.toFormat(2)}{' '}
                    {record.symbol}
                  </div>
                  <span className="label">
                    {record?.walletBalance?.toFormat(2)}{' '}
                    {record.symbol}
                  </span>
                </div>
              </Styles>
            </div>
            <StyledNumberFormat
              value={!withdrawAmount ? '' : withdrawAmount.toString(10)}
              onValueChange={({ value }) => {
                setWithdrawAmount(new BigNumber(value));
              }}
              disabled={!account}
              isAllowed={({ value }) => {
                return value.length < 30;
              }}
              thousandSeparator
              allowNegative={false}
              placeholder="0"
            />
            <div className="flex justify-end w-full">
              <button onClick={() => handleWithdrawMaxAmount()}>Safe Max</button>
            </div>
          </div>

        </div>
      )}
      {showWithdrawError && (
        <ErrorMessage>
          {`${t('You_can_withdraw')} ${getWithdrawMaxAmount()} ${record.symbol
            } ${t('at_least')}`
          }
        </ErrorMessage>
      )}


      {/* <p className="mt-6 text-center text-sm text-white"> */}
      {/*   {t('amount_Total_Borrowed')} */}
      {/* </p> */}

      <div className="flex mt-7 gap-7">
        <button
          className={`btn-sm w-full rounded-lg ${currentTab === 'supply'
            ? 'blue-purple-gradient'
            : 'border border-paleBlue'
            } `}
          onClick={() => setCurrentTab('supply')}
        >
          {t("Supply")}
        </button>

        <button
          className={`btn-sm w-full rounded-lg ${currentTab === 'withdraw'
            ? 'blue-purple-gradient'
            : 'border border-paleBlue'
            } `}
          onClick={() => setCurrentTab('withdraw')}
        >
          {t('Withdraw')}
        </button>
      </div>

      <div className="w-full mt-9">
        {currentTab === 'supply' && <PrimaryList />}

        {currentTab === 'withdraw' ? (
          <SecondaryList />
        ) : isEnabled ? (
          <SecondarySupplyList />
        ) : null}

        {!account ? (
          <div className="flex justify-center mt-9">
            <button
              onClick={() => {
                onCloseModal();
                setConnectWalletsOpen(true);
              }}
              className="btn-sm btn-solid blue-purple-gradient"
            >
              {t('Connect_Wallet')}
            </button>
          </div>
        ) : currentTab === 'withdraw' ? (
          <div className="flex justify-center mt-9">
            <button
              className="btn-sm btn-solid blue-purple-gradient flex items-center justify-center"
              disabled={
                isWithdrawLoading ||
                !withdrawAmount ||
                withdrawAmount.isNaN() ||
                withdrawAmount.isZero() ||
                withdrawAmount.isGreaterThan(record.supplyBalance) ||
                withdrawNewBorrowPercent.isGreaterThan(new BigNumber(100))
              }
              onClick={handleWithdraw}
            >
              {isWithdrawLoading && <Loading size={'18px'} margin={'8px'} />}{' '}
              {t('Withdraw')}
            </button>
          </div>
        ) : (
          <div className="flex justify-center mt-9">
            {!isEnabled && record.id !== 'sei' ? (
              <button
                disabled={
                  isLoading || !amount || amount.isNaN() || amount.isZero()
                }
                onClick={() => {
                  onApprove();
                }}
                className="btn-sm btn-solid blue-purple-gradient flex items-center justify-center"
              >
                {isLoading && <Loading size={'18px'} margin={'8px'} />}{' '}
                {t('Enable')}
              </button>
            ) : (
              <button
                className="btn-sm btn-solid blue-purple-gradient flex items-center justify-center"
                disabled={
                  isLoading ||
                  !amount ||
                  amount.isNaN() ||
                  amount.isZero() ||
                  amount.isGreaterThan(record.walletBalance)
                }
                onClick={handleSupply}
              >
                {isLoading && <Loading size={'18px'} margin={'8px'} />} Supply
              </button>
            )}
          </div>
        )}

        {currentTab === 'supply' && (
          <div className="flex text-white justify-between mt-6">
            <div className="text-lg">{t('Currently_Supplying')}</div>
            <Styles>
              <div className="tooltip relative">
                <div className="tooltip-label text-lg">
                  {format(record?.supplyBalance?.dp(8, 1)?.toString(10))}{' '}
                  {record.symbol}
                </div>

                <span className="label">
                  {format(record?.supplyBalance?.toString(10))} {record.symbol}
                </span>
              </div>
            </Styles>
          </div>
        )}
      </div>
    </ContainerStyles>
  );

  return (
    record && (
      <div>
        <Modal
          title={title}
          content={content}
          width={`max-w-xl`}
          open={open}
          onSetOpen={onSetOpen}
          onCloseModal={() => {
            setWithdrawAmount();
            setAmount();
            onCloseModal();
          }}
          afterCloseModal={() => setCurrentTab('supply')}
        />
      </div>
    )
  );
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
  connectWalletsOpen: account.connectWalletsOpen,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting, setConnectWalletsOpen } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
      setConnectWalletsOpen,
    },
    dispatch,
  );
};

export default connectAccount(
  mapStateToProps,
  mapDispatchToProps,
)(SupplyWithdrawModal);
