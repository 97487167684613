import commaNumber from 'comma-number';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import { connectAccount } from '../../core';

const Styles = styled.div` 
    width: 100%;
    @media (min-width: 1024px) {
        width: 50%
        margin-top: 0;
    }
`;

const format = commaNumber.bindWith(',', '.');

const MarketSummary = ({ marketInfo, currentAsset, settings }) => {
    if (!settings.decimals?.[currentAsset]?.token) return null;
    const { t } = useTranslation();

    return (
        <Styles>
            <div className="text-white w-full flex flex-col">
                <div className="flex justify-between ">
                    {/* Price */}
                    <div className="flex justify-between p-5 w-full">
                        <div className="font-bold text-lg">{t('PRICE')}</div>
                        <div className="font-bold text-base text-midBlue mt-1">
                            {`$${new BigNumber(marketInfo.underlyingPrice || 0)
                                .div(
                                    new BigNumber(10).pow(
                                        18 +
                                            18 -
                                            parseInt(
                                                settings.decimals[currentAsset]
                                                    .token,
                                                10,
                                            ),
                                    ),
                                )
                                .dp(8, 1)
                                .toString(10)}`}
                        </div>
                    </div>
                </div>

                {/* Market Liquidity */}
                <div className="flex justify-between p-5  ">
                    <div className="font-bold text-lg">
                        {t('Market_Liquidity')}
                    </div>
                    <div className="font-bold text-base text-midBlue">
                        {`${format(
                            new BigNumber(marketInfo.cash || 0)
                                .div(
                                    new BigNumber(10).pow(
                                        settings.decimals[currentAsset].token,
                                    ),
                                )
                                .dp(8, 1)
                                .toString(10),
                        )} ${marketInfo.underlyingSymbol || ''}`}
                    </div>
                </div>

                <div className="flex  ">
                    {/* # of Suppliers */}
                    <div className="flex justify-between p-5 w-1/2">
                        <div className="font-bold text-lg">
                            {t('#_of_Suppliers')}
                        </div>
                        <div className="font-bold text-base text-midBlue">
                            {format(marketInfo.supplierCount)}
                        </div>
                    </div>

                    {/* # of Borrowers */}
                    <div className="flex justify-between p-5 w-1/2 border-l-2 border-solid border-white_o1">
                        <div className="font-bold text-lg">
                            {t('#_of_Borrowers')}
                        </div>
                        <div className="font-bold text-base text-midBlue">
                            {format(marketInfo.borrowerCount)}
                        </div>
                    </div>
                </div>
                {/* Borrow Cap */}
                <div className="flex justify-between p-5 w-full ">
                    <div className="font-bold text-lg">
                        {marketInfo.underlyingSymbol} {t('Borrow_Cap')}
                    </div>
                    <div className="font-bold text-base text-midBlue">
                        {marketInfo.borrowCaps == 0
                            ? t('No_Limit')
                            : '$' +
                              format(
                                  new BigNumber(marketInfo.borrowCaps)
                                      .times(marketInfo.tokenPrice)
                                      .dp(2, 1)
                                      .toString(10),
                              )}
                    </div>
                </div>

                {/* Interest Paid/Day */}
                <div className="flex justify-between p-5 w-full ">
                    <div className="font-bold text-lg">
                        {t('Interest_Paid/Day')}
                    </div>
                    <div className="font-bold text-base text-midBlue">
                        $
                        {format(
                            new BigNumber(marketInfo.supplierDailyBul)
                                .plus(
                                    new BigNumber(marketInfo.borrowerDailyBul),
                                )
                                .div(new BigNumber(10).pow(18))
                                .multipliedBy(marketInfo.tokenPrice)
                                .dp(2, 1)
                                .toString(10),
                        )}
                    </div>
                </div>

                {/* Reserves */}
                <div className="flex justify-between p-5  ">
                    <div className="font-bold text-lg">{t('Reserves')}</div>
                    <div className="font-bold text-base text-midBlue">
                        {`${new BigNumber(marketInfo.totalReserves || 0)
                            .div(
                                new BigNumber(10).pow(
                                    settings.decimals[currentAsset].token,
                                ),
                            )
                            .dp(8, 1)
                            .toString(10)} ${
                            marketInfo.underlyingSymbol || ''
                        }`}
                    </div>
                </div>
                <div className="flex  ">
                    {/* Reserve Factor */}
                    <div className="flex justify-between p-5 w-1/2">
                        <div className="font-bold text-lg">
                            {t('Reserve_Factor')}
                        </div>
                        <div className="font-bold text-base text-midBlue">
                            {`${new BigNumber(marketInfo.reserveFactor || 0)
                                .div(new BigNumber(10).pow(18))
                                .multipliedBy(100)
                                .dp(8, 1)
                                .toString(10)}%`}
                        </div>
                    </div>

                    {/* Collateral Factor */}
                    <div className="flex justify-between p-5 w-1/2 border-l-2 border-solid border-white_o1">
                        <div className="font-bold text-lg">
                            {t('Collateral_Factor')}
                        </div>
                        <div className="font-bold text-base text-midBlue">
                            {`${new BigNumber(marketInfo.collateralFactor || 0)
                                .div(new BigNumber(10).pow(18))
                                .times(100)
                                .dp(2, 1)
                                .toString(10)}%`}
                        </div>
                    </div>
                </div>

                {/* Minted */}
                <div className="flex justify-between p-5  ">
                    <div className="font-bold text-lg">
                        a{marketInfo.underlyingSymbol} {t('Minted')}
                    </div>
                    <div className="font-bold text-base text-midBlue">
                        {format(marketInfo.totalSupply2)}
                    </div>
                </div>

                {/* Exchange Rate */}
                <div className="flex justify-between p-5 ">
                    <div className="font-bold text-lg">
                        {t('Exchange_Rate')}
                    </div>
                    <div className="font-bold text-base text-midBlue">
                        {`1 ${marketInfo.underlyingSymbol || ''} = ${Number(
                            new BigNumber(1)
                                .div(
                                    new BigNumber(marketInfo.exchangeRate).div(
                                        new BigNumber(10).pow(
                                            18 +
                                                +parseInt(
                                                    settings.decimals[
                                                        currentAsset || 'usdt'
                                                    ].token,
                                                    10,
                                                ) -
                                                +parseInt(
                                                    settings.decimals[
                                                        currentAsset || 'usdt'
                                                    ].btoken,
                                                    10,
                                                ),
                                        ),
                                    ),
                                )
                                .toString(10),
                        ).toFixed(6)} ${marketInfo.symbol || ''}`}
                    </div>
                </div>
            </div>
        </Styles>
    );
};

MarketSummary.defaultProps = {
    marketInfo: {},
    settings: {},
    currentAsset: '',
};

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

export default compose(
    withRouter,
    connectAccount(mapStateToProps, undefined),
)(MarketSummary);
