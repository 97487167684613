import { withRouter } from "react-router-dom";
import { accountActionCreators, connectAccount } from "../../core";
import { bindActionCreators, compose } from "redux";
import commaNumber from "comma-number";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { promisify } from "../../utilities";
import avatar from '../../assets/icons/avatar.svg';

import Layout from "../../layouts/MainLayout/MainLayout";
import LeaderboardTable from "../../components/vote/LeaderboardTable";
import BigNumber from "bignumber.js";
import Web3 from "web3";
import RouteMap from "../../routes/RouteMap";
import styled from "styled-components";
import Footer from "layouts/MainLayout/Footer";

const Styles = styled.div`
`

const EmptyDataStyles = styled.div`
    width: 100%;
    overflow: auto;
    margin-top: 46px;
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
`;

const format = commaNumber.bindWith(',', '.');

const Leaderboard = ({ history, getVoterAccounts }) => {
    const { t } = useTranslation();
    const [voterAccounts, setVoterAccounts] = useState([]);

    const columns = [
        {
            Header: 'Name',
            columns: [
                {
                    Header: t('Rank'),
                    accessor: 'rank',
                    disableFilters: true,
                    // eslint-disable-next-line react/display-name
                    Cell: ({ value }) => {
                        return (<>
                            <span className="font-bold"># {value}</span>
                        </>)
                    },
                },
                {
                    Header: '',
                    accessor: 'address',
                    disableFilters: true,
                    // eslint-disable-next-line react/display-name
                    Cell: ({ value }) => {
                        return (
                            <div
                                className="address-container space-x-8"
                            >
                                <div className="relative">
                                    <img className="" src={avatar} alt="avatar" style={{ width: '35px', height: '35px' }} />
                                    {/* <div className="w-2.5 h-2.5 rounded-full bg-green green-div absolute" /> */}
                                </div>
                                <div className="font-bold">{value}</div>
                            </div>
                        );
                    },
                },
                {
                    Header: t('Votes'),
                    accessor: 'votes',
                    // eslint-disable-next-line react/display-name
                    Cell: ({ value }) => {
                        return (<>
                            <span className="font-bold">
                                {format(
                                    new BigNumber(Web3.utils.fromWei(value, 'ether'))
                                        .dp(8, 1)
                                        .toString(10)
                                )}
                            </span>
                        </>)
                    },
                },
                {
                    Header: t('Vote_Weight'),
                    accessor: 'voteWeight',
                    disableFilters: true,
                    // eslint-disable-next-line react/display-name
                    Cell: ({ value }) => {
                        return (<>
                            <span className="font-bold">{parseFloat(value * 100).toFixed(2)}%</span>
                        </>)
                    },
                },
                {
                    Header: t('Proposals_Voted'),
                    accessor: 'proposalsVoted',
                    disableFilters: true,
                    // eslint-disable-next-line react/display-name
                    Cell: ({ value }) => {
                        return (<>
                            <span className="text-lg font-bold">{value}</span>
                        </>)
                    },
                },
            ],
        },
    ];

    useEffect(() => {
        promisify(getVoterAccounts, { limit: 100, offset: 0 })
            .then(res => {
                const results = res?.data?.result?.map((item, _i) => {
                    return {
                        ...item,
                        rank: _i
                    }
                })
                setVoterAccounts(results || []);
            })
            .catch(() => {
                setVoterAccounts([]);
            });
    }, []);

    return (
        <div className="flex flex-col">
            <Layout
                title="LEADERBOARD"
                hideFooter={true}
                noBg={true}
                hideSummary={true}
            >
            </Layout>

            <Styles className="pt-14 text-black bg-white flex flex-col justify-between items-center grow overflow-auto">
                <div className="max-w-7xl w-full px-8">
                    <div className="w-full flex justify-center items-center">
                        <span className={'text-4xl text-midBlue font-bold '}>
                            {t('Addresses_by_Voting_Weight')}
                        </span>
                    </div>

                    <LeaderboardTable
                        columns={columns}
                        data={voterAccounts}
                        onRowClick={(row, i) => {
                            history.push(RouteMap.vote.proposerOverview.replace(":address", row?.original?.address))
                        }}
                    />

                    {(!voterAccounts ||
                        (voterAccounts && voterAccounts.length === 0)) && (
                            <EmptyDataStyles>
                                <div className="text-base p-20 flex justify-center">
                                    <span className="text-center text-2xl md:text-3xl text-border title-text">
                                        {t('No_voters')}
                                    </span>
                                </div>
                            </EmptyDataStyles>
                        )}
                </div>
                <Footer containerClass={'flex justify-between items-center py-8 px-16 text-white left-0 bottom-0 w-full'} />
            </Styles>
        </div>
    )


}

const mapDispatchToProps = dispatch => {
    const { getVoterAccounts } = accountActionCreators;

    return bindActionCreators(
        {
            getVoterAccounts
        },
        dispatch
    );
};

export default compose(
    withRouter,
    connectAccount(undefined, mapDispatchToProps)
)(Leaderboard);
