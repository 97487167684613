import BigNumber from 'bignumber.js';
import { useActiveWeb3React } from 'hooks';
import { useLockZap } from 'hooks/useLpStaking';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFormatStringFromBignumber } from 'utilities/common';
import { isSeiNetwork } from 'utils';

const ContainerStyles = styled.div`
  color: white;
  .info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .amount {
      background: linear-gradient(
        268.5deg,
        rgb(0, 255, 170) 1.25%,
        rgb(76, 0, 199) 101.57%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 54px;
      font-weight: 900;
    }
    .desc {
      font-weight: 600;
      font-size: 15px;
      color: #ffffff8f;
    }
    .mark {
      margin-top: 5px;
      gap: 8px;
      font-size: 12px;
      color: rgb(3, 152, 85);
      background: rgba(3, 152, 85, 0.15);
      border: 1px solid rgba(5, 168, 46, 0.2);
      border-radius: 30px;
      padding: 5px 10px;
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #ffffff23;
    margin: 15px 0;
  }
  .v-divider {
    width: 1px;
    background-color: #ffffff23;
  }
  .desc-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    .title {
      font-size: 13px;
      color: #ffffff8f;
    }
    .value {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .continue-button-container {
    padding-top: 20px;
    button {
      width: 100%;
      height: 40px;
      color: white;
      background-color: #0061a7;
      border-radius: 5px;
      font-weight: 700;
      font-size: 16px;
    }
  }
`;

function ZapModalStep3({
  update,
  setUpdate,
  setOpen,
  tokenInfo,
  amount,
  amountBul,
  selectedMuliplier,
  newZapInUsd,
  lockedUsdValue,
  requiredUsdValue,
  LockAprData,
}) {
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const { pending, handleZap } = useLockZap();

  const LockData = useMemo(() => {
    return [
      { title: `12 ${t('months')}` },
      { title: `6 ${t('months')}` },
      { title: `3 ${t('months')}` },
      { title: `1 ${t('month')}` },
    ];
  }, [t]);

  const onZap = async () => {
    if (amount && new BigNumber(amount).gt(0)) {
      const tx = await handleZap(
          3 - selectedMuliplier,
          new BigNumber(amount).times(10 ** tokenInfo.decimals),
          new BigNumber(amountBul || 0).times(1e18),
          tokenInfo.address,
        );
      if (tx) {
        NotificationManager.success(t('Zap_successfully'));
        setUpdate(!update);
        setOpen(false);
      } else {
        NotificationManager.error(t('Tx_rejected'));
      }
    }
  };

  return (
    <ContainerStyles className="pt-3 pb-6 px-8">
      <div className="info-container">
        <div className="amount">
          $
          {getFormatStringFromBignumber(
            newZapInUsd.times(LockAprData[3 - selectedMuliplier].div(100)),
          )}
        </div>
        <div className="desc">{t('Your_new_annual_rewards')}</div>
        <div className="mark">
          <div>{t('Eligible_for_emissions')}</div>
        </div>
      </div>
      <div className="divider" />
      <div>
        <div className="desc-container">
          <div className="title">{t('Total_deposit_value')}</div>
          <div className="value">
            ${getFormatStringFromBignumber(requiredUsdValue.times(20).div(1e8))}
          </div>
        </div>
        <div className="desc-container">
          <div className="title">{t('Required_locked_dLP')}</div>
          <div className="value">
            ${getFormatStringFromBignumber(requiredUsdValue.div(1e8))}
          </div>
        </div>
        <div className="desc-container">
          <div className="title">{t('Currently_locked')}</div>
          <div className="value">
            ${getFormatStringFromBignumber(lockedUsdValue.div(1e8))}
          </div>
        </div>
      </div>
      <div className="divider" />
      <div>
        {/* <div className='desc-container'>
          <div className='title'>{t('Network_borrowed', { network: isSeiNetwork(requiredChainId) ? 'SEI' : 'Arbitrum' })}</div>
          <div className='value'>
            ${getFormatStringFromBignumber(new BigNumber(totalBorrowBalance))}
          </div>
        </div> */}
        <div className="desc-container">
          <div className="title">{t('BUL_used_from_wallet')}</div>
          <div className="value">
            {getFormatStringFromBignumber(new BigNumber(amountBul || 0))}
          </div>
        </div>
        <div className="desc-container">
          <div className="title">{t('Zap_value')}</div>
          <div className="value">
            ${getFormatStringFromBignumber(newZapInUsd)}
          </div>
        </div>
        <div className="desc-container">
          <div className="title">{t('Lock_duration')}</div>
          <div className="value">{LockData[selectedMuliplier].title}</div>
        </div>
      </div>
      <div className="continue-button-container">
        <button
          type="button"
          disabled={
            !account || !amount || new BigNumber(amount).eq(0) || pending
          }
          onClick={onZap}
        >
          {t('Zap_into_dLP')}
        </button>
      </div>
    </ContainerStyles>
  );
}

export default ZapModalStep3;
