import styled from 'styled-components';
import { matchSorter } from 'match-sorter';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy, useExpanded } from 'react-table';
import React, { Fragment, useEffect, useState } from 'react';
import { ReactComponent as FilterSvg } from '../../assets/icons/filter.svg';
import { useWindowSize } from 'hooks/useWindowSize';
import { Menu, Transition } from '@headlessui/react';

const Styles = styled.div`
    width: 100%;
    overflow: auto;
    border-radius: 10px;
    overflow: visible;

    svg {
        &:hover {
            opacity: 0.7;
        }
    }

    table {
        background: rgba(255, 255, 255, 0.03);
        box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
        padding: 24px;
        border-radius: 8px;
        color: #fff;
        border-spacing: 0;
        border-radius: 10px;
        width: 100%;

        thead {
            color: white;
            font-weight: bold;
            border-bottom: solid 1px rgba(255, 255, 255, 0.25);
        }

        tr:hover {
        }
        tr {
            border-bottom: 1px solid #eeeeee;
            :last-child {
                border-bottom: none;
                td {
                    border-bottom: 0;
                }
            }
        }

        tr.custom-border-bottom {
            border-bottom: 5px solid #eeeeee;
        }

        th {
            font-size: 1.12rem;
            font-weight: 500;
        }

        th,
        td.horizontal {
            margin: 0;
            padding: 20px 0px 22px 50px;
            text-align: left;
            :last-child {
                padding: 20px 50px 22px 0px;
                border-right: 0;
                text-align: right;
            }

            :first-child {
                text-align: left;
            }
        }
    }
`;
const ButtonContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .filter {
        font-size: 16px;
        background: rgb(16, 125, 239);
        color: rgb(255, 255, 255);
        width: 70px;
        border-radius: 8px;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
    .cancel {
        font-size: 16px;
        color: rgb(248, 73, 96);
        border: 2px solid rgb(248, 73, 96);
        border-radius: 8px;
        width: 70px;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
`;
const BlockFilterContainer = styled.div`
    padding: 20px 20px;

    input {
        font-size: 16px;
        margin: 5px 0px;
        width: 100%;
        color: rgba(0, 0, 0, 0.65);
        padding: 0px 5px;
        border-radius: 4px;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
`;

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function HistoryTable({
    columns,
    data,
}) {
    const { t } = useTranslation();
    const [isTableHorizontal, setIsTableHorizontal] = useState(true);
    const { width } = useWindowSize() || {};

    useEffect(() => {
        if (width < 1024) {
            setIsTableHorizontal(false);
        } else {
            setIsTableHorizontal(true);
        }
    }, [width]);

    // Use the state and functions returned from useTable to build your UI
    const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            defaultColumn: 'rank',
            autoResetSortBy: false,
        },
        useSortBy,
        useExpanded,
    );

    return (
        <table className="">
            {isTableHorizontal && (
                <thead className="text-xl">
                    {[headerGroups[1]].map((headerGroup, index) => (
                        <tr key={index}>
                            {headerGroup.headers.map((column, index) => {
                                return (
                                    <th key={column.Header}>
                                        {column.render('Header')}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
            )}
            <tbody {...getTableBodyProps()} className="text-lg">
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <Fragment key={i}>
                            {isTableHorizontal ? (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <td
                                                {...cell.getCellProps()}
                                                className="horizontal"
                                            >
                                                <div
                                                    className={
                                                        cell.column.Header ===
                                                        'Rank'
                                                            ? ''
                                                            : cell.column
                                                                  .Header ===
                                                              'Supply'
                                                            ? ''
                                                            : ''
                                                    }
                                                >
                                                    {cell.render('Cell')}
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ) : (
                                row.cells.map((cell, index) => {
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            key={index}
                                            className={
                                                index === row.cells.length - 1
                                                    ? 'custom-border-bottom'
                                                    : ''
                                            }
                                        >
                                            {cell.column.Header !== '' && (
                                                <td className="p-5 w-1/2">
                                                    {typeof cell.column
                                                        .Header === 'string'
                                                        ? cell.column.Header
                                                        : cell.column.Header()}
                                                </td>
                                            )}
                                            <td
                                                className="p-5 w-1/2"
                                                colSpan={
                                                    cell.column.Header !== ''
                                                        ? 1
                                                        : 2
                                                }
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </Fragment>
                    );
                })}
            </tbody>
        </table>
    );
}

function Application({
    columns,
    data,
}) {
    return (
        <Styles>
            <HistoryTable
                columns={columns}
                data={data}
            />
        </Styles>
    );
}

const App = React.memo(Application);

export default App;
