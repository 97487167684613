/* eslint-disable max-len */

import React from 'react';

export const Telegram = ({ color = 'white', ...props }) => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" {...props}>
      <g filter="url(#filter0_b_61_1517)">
        <rect
          x="0.000488281"
          y="0.0622559"
          width="48"
          height="48"
          rx="8"
          fill={color}
          fillOpacity="0.1"
        />
        <path
          d="M23.9585 15.0623C21.5788 15.0734 19.3004 16.0265 17.6217 17.7131C15.9429 19.3997 15.0005 21.6826 15.0005 24.0623C15.0005 26.4492 15.9487 28.7384 17.6365 30.4262C19.3244 32.1141 21.6135 33.0623 24.0005 33.0623C26.3874 33.0623 28.6766 32.1141 30.3644 30.4262C32.0523 28.7384 33.0005 26.4492 33.0005 24.0623C33.0005 21.6753 32.0523 19.3861 30.3644 17.6983C28.6766 16.0105 26.3874 15.0623 24.0005 15.0623C23.9865 15.0622 23.9725 15.0622 23.9585 15.0623ZM27.68 20.4803C27.755 20.4788 27.9207 20.4975 28.0287 20.5853C28.1005 20.6476 28.1463 20.7346 28.157 20.829C28.169 20.8988 28.184 21.0585 28.172 21.183C28.037 22.6065 27.4505 26.0595 27.152 27.6533C27.026 28.3283 26.7777 28.554 26.537 28.5758C26.015 28.6245 25.6182 28.2308 25.112 27.8993C24.32 27.3795 23.8722 27.0563 23.1035 26.5493C22.2147 25.9643 22.7907 25.6418 23.297 25.1168C23.4297 24.9788 25.7322 22.884 25.7772 22.6943C25.7825 22.6703 25.7877 22.5818 25.7352 22.5353C25.6827 22.4888 25.6047 22.5045 25.5485 22.5173C25.469 22.5353 24.2037 23.3723 21.7527 25.026C21.3927 25.2735 21.068 25.3935 20.7762 25.386C20.4552 25.38 19.8372 25.2053 19.3775 25.056C18.8135 24.8723 18.3657 24.7755 18.4047 24.4643C18.425 24.3023 18.6485 24.1365 19.0745 23.967C21.698 22.824 23.447 22.0703 24.323 21.7065C26.822 20.667 27.3417 20.4863 27.68 20.4803Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_61_1517"
          x="-7.99951"
          y="-7.93774"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_61_1517" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_61_1517"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
