import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { accountActionCreators, connectAccount } from '../core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import Layout from '../layouts/MainLayout/MainLayout';
import commaNumber from 'comma-number';
import { useActiveWeb3React } from '../hooks';
import Loading from '../components/UI/Loading';
import BelugasTable from '../components/Belugas/BelugasTable';
import styled from 'styled-components';
import Progress from 'components/UI/Progress';
import { getFormatStringFromBignumber } from 'utilities/common';
import { CONTRACT_TOKEN_ADDRESS } from 'utilities/constants';
import useCopyClipboard from 'hooks/useCopyClipboard';
import { ReactComponent as Copy } from '../assets/icons/copy.svg';
import { ReactComponent as BelIcon } from '../assets/icons/logo.svg';
import { showTokenOnExplorer } from 'utils';

const ContainerStyles = styled.div`
    .toast-container {
        background: #3b46f1;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }
    svg.bel-icon {
        margin-right: 5px;
    }
    svg.copy {
        margin-left: 5px;
        path {
            fill: #fff;
        }
        &.copied {
            path {
                fill: rgb(0, 148, 255);
            }
        }
    }
`;

const format = commaNumber.bindWith(',', '.');

const Belugas = ({ settings, getMarketHistory }) => {
    const totalBulAmount = 1e9;
    const { t, i18n } = useTranslation();
    const { requiredChainId } = useActiveWeb3React();
    const [search, setSearch] = useState('');
    const [markets, setMarkets] = useState([]);
    const [dailyDistribution, setDailyDistribution] = useState('0');
    const [totalDistributeAmount, setTotalDistributeAmount] = useState('0');
    const [remainAmount, setRemainAmount] = useState('0');
    const [isCopied, setCopied] = useCopyClipboard();
    const bulAddress = CONTRACT_TOKEN_ADDRESS[requiredChainId].bul.address;

    const getBULInfo = async () => {
        const tempMarkets = [];

        setDailyDistribution(
            new BigNumber(settings.dailyBelugas)
                .div(new BigNumber(10).pow(18))
                .dp(2, 1)
                .toString(10),
        );
        setRemainAmount(settings.remainedBalance);
        setTotalDistributeAmount(
            new BigNumber(settings.totalDistributeAmount).gte(new BigNumber(settings.remainedBalance)) ? settings.totalDistributeAmount : settings.remainedBalance
        );

        for (let i = 0; i < settings.markets.length; i += 1) {
            tempMarkets.push({
                underlyingSymbol: settings.markets[i].underlyingSymbol,
                perDay: new BigNumber(settings.markets[i].supplierDailyBul)
                    .plus(new BigNumber(settings.markets[i].borrowerDailyBul))
                    .div(new BigNumber(10).pow(18))
                    .dp(2, 1)
                    .toString(10),
                supplyAPY: new BigNumber(
                    settings.markets[i].supplyAdditionalApy,
                )
                    .dp(2, 1)
                    .toString(10),
                borrowAPY: new BigNumber(
                    settings.markets[i].borrowAdditionalApy,
                )
                    .dp(2, 1)
                    .toString(10),
                supplyPerDay: new BigNumber(
                    settings.markets[i].supplierDailyBul,
                )
                    .div(new BigNumber(10).pow(18))
                    .dp(2, 1)
                    .toString(10),
                borrowPerDay: new BigNumber(
                    settings.markets[i].borrowerDailyBul,
                )
                    .div(new BigNumber(10).pow(18))
                    .dp(2, 1)
                    .toString(10),
            });
        }
        setMarkets(tempMarkets);
    };

    const searchHandler = useCallback((e) => {
        setSearch(e.target.value);
    }, []);

    useEffect(() => {
        setMarkets([]);
    }, [requiredChainId]);

    useEffect(() => {
        if (
            settings.markets &&
            settings.markets.length > 0 &&
            settings.dailyBelugas
        ) {
            getBULInfo();
        }
    }, [settings.markets]);

    const filteredMarkets = useMemo(() => {
        if (!markets) {
            return [];
        }

        if (search === '') {
            return markets;
        } else {
            return markets
                .map((market) => JSON.stringify(market))
                .filter(
                    (stringMarket) =>
                        stringMarket
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) > -1,
                )
                .map((filteredMarket) => JSON.parse(filteredMarket));
        }
    }, [search, markets]);

    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                columns: [
                    {
                        Header: t('Coin_Market'),
                        accessor: 'underlyingSymbol',
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value }) => {
                            return (
                                <div className="flex justify-start items-center space-x-2">
                                    <img
                                        className={'w-10 h-10'}
                                        src={`/images/coins/${value.toLowerCase()}.png`}
                                        alt={value}
                                    />
                                    <div className="font-bold text-sm font-lato">
                                        {value}
                                    </div>
                                </div>
                            );
                        },
                    },
                    {
                        Header: t('BUL_Per_Day'),
                        accessor: 'perDay',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value }) => {
                            return <div className="text-sm">{value} BUL</div>;
                        },
                    },
                    {
                        Header: t('Supply_BUL_APY'),
                        accessor: 'supplyAPY',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value, row }) => {
                            return (
                                <div className={`text-sm`}>
                                    <div
                                        className={`${value > 0
                                            ? 'text-green'
                                            : value === '0' || value === 0
                                                ? 'text-white'
                                                : 'text-red'
                                            }`}
                                    >
                                        {value}%
                                    </div>
                                    <div className="font-small">
                                        {row.original.supplyPerDay} BUL
                                    </div>
                                </div>
                            );
                        },
                    },
                    {
                        Header: t('Borrow_BUL_APY'),
                        accessor: 'borrowAPY',
                        disableFilters: true,
                        // eslint-disable-next-line react/display-name
                        Cell: ({ value, row }) => {
                            return (
                                <div className={`text-sm`}>
                                    <div
                                        className={`${value > 0
                                            ? 'custom-text-color'
                                            : value === '0' || value === 0
                                                ? 'text-white'
                                                : 'text-red'
                                            }`}
                                    >
                                        {value}%
                                    </div>
                                    <div className="font-small">
                                        {row.original.borrowPerDay} BUL
                                    </div>
                                </div>
                            );
                        },
                    },
                ],
            },
        ];
    }, [i18n.language, requiredChainId]);

    return (
        <div className="flex flex-col">
            <Layout
                mainClassName="pt-4"
                title={'Belugas'}
                hideSummary={true}
                background="market"
            >
                <ContainerStyles>
                    <div className="max-w-7xl px-4 mb-32">
                        {
                            <>
                                <div className="flex flex-col lg:flex-row justify-between gap-4 md:items-center tools-container">
                                    <div className="w-full lg:w-1/2 custom-width">
                                        <div className="relative w-full text-white">
                                            <div
                                                className="cursor-pointer text-white flex items-center"
                                            >
                                                <BelIcon width={24} height={24} className='bel-icon' />
                                                <span
                                                    onClick={() => {
                                                        showTokenOnExplorer(bulAddress, requiredChainId);
                                                    }}
                                                >
                                                    {bulAddress}
                                                </span>
                                                <Copy
                                                    className={`copy ${isCopied && 'copied'}`}
                                                    onClick={() =>
                                                        setCopied(bulAddress)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full lg:w-1/2 lg:ml-2.5 ml-0 justify-start">
                                        <div className="flex justify-between">
                                            <div className="">
                                                <span className="text-base text-gray">
                                                    {t('Daily_Distribution')}:
                                                </span>
                                                <span className="text-medium text-white pl-2">
                                                    {format(dailyDistribution)} BUL
                                                </span>
                                            </div>
                                            <div className="">
                                                <span className="text-base text-gray">
                                                    {t('Remaining')}:
                                                </span>
                                                <span className="text-medium text-white pl-2">
                                                    {getFormatStringFromBignumber(
                                                        new BigNumber(
                                                            remainAmount,
                                                        ),
                                                    )} BUL
                                                </span>
                                            </div>
                                        </div>
                                        <Progress
                                            wrapperClassName="w-full mt-2 progress-wrapper"
                                            percent={
                                                (new BigNumber(totalDistributeAmount).minus(remainAmount))
                                                    .dividedBy(
                                                        totalBulAmount
                                                    )
                                                    .multipliedBy(100)
                                                    .toNumber() || 0
                                            }
                                            trailColor={'#C3DCF2'}
                                            color={'#3060F6'}
                                        />
                                    </div>
                                </div>
                                <div className="table-container mb-20 w-full">
                                    {!markets || markets.length === 0 ? (
                                        <div className="flex items-center justify-center py-16 flex-grow">
                                            <Loading
                                                size={'36px'}
                                                margin={'0'}
                                                className={'text-white'}
                                            />
                                        </div>
                                    ) : (
                                        <BelugasTable
                                            columns={columns}
                                            data={filteredMarkets}
                                        />
                                    )}
                                </div>
                            </>
                        }
                    </div>
                </ContainerStyles>
            </Layout>
        </div>
    );
};

Belugas.defaultProps = {
    settings: {},
};

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
    const { getVoterAccounts, getMarketHistory } = accountActionCreators;

    return bindActionCreators(
        {
            getVoterAccounts,
            getMarketHistory,
        },
        dispatch,
    );
};

export default compose(
    withRouter,
    connectAccount(mapStateToProps, mapDispatchToProps),
)(Belugas);
