/* eslint-disable max-len */

export const Medium = ({ color = 'white', ...props }) => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_b_243_1788)">
        <rect y="0.0622559" width="48" height="48" rx="8" fill="white" fillOpacity="0.1" />
        <path
          d="M25.1521 24.4898C25.1521 27.2295 22.8939 29.4542 20.0963 29.4542C17.2988 29.4542 15.0405 27.2295 15.0405 24.4898C15.0405 21.7501 17.2988 19.5254 20.0963 19.5254C22.8939 19.5254 25.1521 21.7501 25.1521 24.4898ZM30.691 24.4898C30.691 27.0652 29.5563 29.1583 28.1631 29.1583C26.77 29.1583 25.6352 27.0652 25.6352 24.4898C25.6352 21.9144 26.77 19.8213 28.1631 19.8213C29.5563 19.8213 30.691 21.9035 30.691 24.4898ZM32.9605 24.4898C32.9605 26.8021 32.5673 28.6761 32.073 28.6761C31.5786 28.6761 31.1854 26.8021 31.1854 24.4898C31.1854 22.1775 31.5786 20.3035 32.073 20.3035C32.5673 20.3035 32.9605 22.1775 32.9605 24.4898Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_b_243_1788"
          x="-8"
          y="-7.93774"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_243_1788" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_243_1788"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
