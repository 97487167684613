import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import Loading from 'components/UI/Loading';
import Switch from 'components/UI/Switch';
import AlertHelper from 'components/common/AlertHelper';
import { addToken, getFormatStringFromBignumber } from 'utilities/common';
import {
    useAutoCompound,
    useClaimAll,
    useSelfCompound,
} from 'hooks/useLpStaking';
import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import { useActiveWeb3React } from 'hooks';
import { MetaMask } from '@web3-react/metamask';
import metaMask from '../../assets/icons/metaMask.svg';

const StyledConatiner = styled.div`
    margin-bottom: 60px;
    @media (max-width: 768px) {
        margin-bottom: 40px;
    }

    .main-container {
        display: block;

        > div {
            width: 100%;
        }

        @media (min-width: 768px) {
            display: flex;
            max-width: 100%;
            padding: 0px 20px;
        }
    }

    .container {
        background: rgba(255, 255, 255, 0.03);

        box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
        padding: 24px 60px;
        border-radius: 8px;
        margin: 0;
    }

    .content-container {
        display: flex;
        gap: 30px;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            gap: 20px;
        }
        .info {
            .title {
                font-size: 16px;
                font-weight: 500;
            }
            .value {
                font-size: 36px;
                font-family: LibreFranklin_Bold;
                font-weight: 900;
            }

            @media only screen and (max-width: 768px) {
                .title {
                    font-size: 12px;
                }
                .value {
                    font-size: 20px;
                }
            }
        }

        .tokens {
            display: flex;
            flex-flow: wrap;
            flex: 1;
            gap: 20px;
            @media only screen and (max-width: 768px) {
                gap: 15px;
            }
            .token-container {
                display: flex;
                align-items: center;
                height: 30px;
                padding: 5px 10px;

                border-radius: 8px;
                border: 1px solid #4776e6;
                box-shadow: 4px 6px 61px 0px rgba(99, 104, 231, 0.2);

                .sub-container {
                    display: flex;
                    align-items: center;
                    color: #fff;

                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    img {
                        margin-right: 3px;
                        width: 20px;
                        height: 20px;
                        @media only screen and (max-width: 768px) {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
                .divider {
                    width: 1px;
                    height: 18px;
                    background-color: #fff;
                    margin: 0 7px;
                }

                .token-add {
                    padding-left: 5px;
                    cursor: pointer;
                    img {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }

        .option-container {
            display: flex;
            gap: 8px;
            align-items: start;

            .title {
                font-size: 14px;
                padding-right: 10px;

                @media only screen and (max-width: 768px) {
                    font-size: 12px;
                    padding-right: 10px;
                }
            }
            .switch {
                @media only screen and (max-width: 768px) {
                    padding: 3px 5px;
                }
            }
            .alert {
                padding-top: 2px;
            }
        }
    }

    .button-container {
        display: flex;
        gap: 30px;
        padding: 22px 0 0;

        .button {
            width: 200px;
            height: 38px;
            border-radius: 5px;
            color: white;
            background-color: #0061a7;
            font-size: 14px;
            @media only screen and (max-width: 768px) {
                font-size: 12px;
                width: 160px;
                height: 35px;
            }
        }
        .button1 {
            width: 200px;
            height: 38px;
            border-radius: 5px;
            color: #0061a7;
            border-color: #0061a7;
            border-width: 1px;
            background-color: transparent;
            font-size: 14px;
            @media only screen and (max-width: 768px) {
                font-size: 12px;
                width: 160px;
                height: 35px;
            }
        }
    }
`;

function Revenue({ update, setUpdate, claimableRewards, autocompoundEnabled }) {
    const { t } = useTranslation();
    const { account, requiredChainId, chainId, isActive, connector } = useActiveWeb3React();
    const { pending: autoCompoundPending, handleAutoCompound } =
        useAutoCompound();
    const { pending: claimPending, handleClaim } = useClaimAll();
    const { pending: selfCompoundPending, handleSelfCompound } =
        useSelfCompound();


    const totalRevenue = useMemo(() => {
        let temp = new BigNumber(0);
        for (let index = 0; index < claimableRewards.length; index++) {
            temp = temp.plus(
                claimableRewards[index].amount
                    .div(10 ** claimableRewards[index].decimals)
                    .times(1.0),
            );
        }
        return temp;
    }, [claimableRewards]);

    const onAutoCompound = async () => {
        if (!!account && !autoCompoundPending) {
            const tx = await handleAutoCompound(!autocompoundEnabled);
            if (tx) {
                NotificationManager.success(
                    t('Set_auto_compound_successfully'),
                );
                setUpdate(!update);
            } else {
                NotificationManager.error(t('Tx_rejected'));
            }
        }
    };

    const onClaimAll = async () => {
        const tx = await handleClaim();
        if (tx) {
            NotificationManager.success(t('Claim_successfully'));
            setUpdate(!update);
        } else {
            NotificationManager.error(t('Tx_rejected'));
        }
    };

    const onSelfCompound = async () => {
        const tx = await handleSelfCompound();
        if (tx) {
            NotificationManager.success(t('Self_compound_successfully'));
            setUpdate(!update);
        } else {
            NotificationManager.error(t('Tx_rejected'));
        }
    };

    return (
        <StyledConatiner className="max-w-7xl w-full">
            <div className="main-container mt-1 mx-auto gap">
                <div className="gradient-border-tb">
                    <div className="container">
                        <div className="content-container">
                            <div className="info">
                                <div className="title">
                                    {t('Total_Claimable')}
                                </div>
                                <div className="value">
                                    $
                                    {getFormatStringFromBignumber(totalRevenue)}
                                </div>
                            </div>
                            <div className="tokens">
                                {claimableRewards.map((reward, index) => {
                                    return (
                                        <div
                                            className="token-container"
                                            key={index}
                                        >
                                            <div className="sub-container">
                                                <img
                                                    src={`/images/coins/${reward.name.toLowerCase()}.png`}
                                                    alt={'bul'}
                                                />
                                                <div>{reward.name}</div>
                                            </div>
                                            <div className="divider" />
                                            <div>
                                                $
                                                {getFormatStringFromBignumber(
                                                    reward.amount
                                                        .div(
                                                            10 **
                                                            reward.decimals,
                                                        )
                                                )}
                                            </div>
                                            {
                                                (isActive && connector instanceof MetaMask && chainId === requiredChainId) &&
                                                <div className='token-add'
                                                    onClick={() => {
                                                        addToken(reward.name.toLowerCase(), reward.decimals, requiredChainId, 'token')
                                                    }}
                                                >
                                                    <img className="w-5" src={metaMask} alt="arrow" />
                                                </div>
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                            {/* <div className="option-container">
                                <div className="title">
                                    {t('Auto_compound')}
                                </div>
                                <div className="switch">
                                    <Switch
                                        inactiveStateColor={'bg-darkGray'}
                                        activeStateColor={'bg-darkGray'}
                                        slideClassName={
                                            'bg-lightGreen border-lightGreen'
                                        }
                                        inactiveSlideClassName={
                                            'bgGrayGradient border-gray2'
                                        }
                                        wrapperClassName="pt-1 pb-0"
                                        style={'small'}
                                        value={autocompoundEnabled}
                                        onChange={onAutoCompound}
                                    />
                                </div>
                                <div className="alert">
                                    <AlertHelper
                                        text={t('Automatically_compound')}
                                    />
                                </div>
                            </div> */}
                        </div>
                        <div className="gradient-divider" />
                        <div className="button-container">
                            <button
                                type="button"
                                className="button"
                                disabled={
                                    !account ||
                                    selfCompoundPending ||
                                    totalRevenue.eq(0)
                                }
                                onClick={onSelfCompound}
                            >
                                {selfCompoundPending ? (
                                    <div className="flex justify-center items-center">
                                        <Loading size={'16px'} margin={'7px'} />
                                        {t('Pending')}
                                    </div>
                                ) : (
                                    <>{t('Compound_revenue')}</>
                                )}
                            </button>
                            <button
                                type="button"
                                className="button1"
                                disabled={
                                    !account ||
                                    claimPending ||
                                    totalRevenue.eq(0)
                                }
                                onClick={onClaimAll}
                            >
                                {claimPending ? (
                                    <div className="flex justify-center items-center">
                                        <Loading
                                            size={'16px'}
                                            margin={'7px'}
                                            className={'text-midBlue'}
                                        />
                                        {t('Pending')}
                                    </div>
                                ) : (
                                    <>{t('Claim_all')}</>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </StyledConatiner>
    );
}

export default Revenue;
