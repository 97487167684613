import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pagination from 'rc-pagination';
import { ReactComponent as LeftArrow } from '../../../assets/icons/arrow.svg';
import VoteHistoryItem from './VoteHistoryItem';
import styled from 'styled-components';
import { MediumLineGradient } from 'components/common/lineGradient';

const Styles = styled.div`
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(16px);
  border-radius: 8px;

  svg.arrow {
    path {
      stroke: #979797;
    }
  }

  li.rc-pagination-item {
    margin-left: 0px !important;
    margin-right: 0px !important;
    // background: #2D82E8;
    border-radius: 50%;
  }

  li.rc-pagination-item-active a {
    color: #3060f6;
  }
  .tick-icon {
    height: 22px;
    width: auto;
  }
  @media (max-width: 890px) {
    .flex-wrap-custom {
      flex-wrap: wrap;
      > div {
        margin-left: 5px !important;
      }
    }
  }
`;

const VotingHistory = ({ data, pageNumber, total, onChangePage }) => {
  console.log(data);
  const { t } = useTranslation();
  const [current, setCurrent] = useState(pageNumber);
  const [pageSize, setPageSize] = useState(5);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage(page, (page - 1) * size, size);
  };

  const onNext = () => {
    if (current * pageSize <= total) {
      handleChangePage(current + 1, 5);
    }
  };

  const onPrev = () => {
    if (current > 1) {
      handleChangePage(current - 1, 5);
    }
  };

  return (
    <Styles className="px-8 text-white rounded-xl">
      <p className="text-2xl font-bold py-5 border-y-2-gradient">
        {t('Voting_History')}
      </p>

      {/* {data && data.length === 0 && ( */}
      {/*   <div className="flex items-center justify-center font-medium text-lg py-8"> */}
      {/*     {t('No_votes')} */}
      {/*   </div> */}
      {/* )} */}

      {mocs.map((item, index) => {
        return (
          <VoteHistoryItem
            proposal={item.proposal}
            support={item.support}
            key={index}
          />
        );
      })}

      {data && data.length !== 0 && (
        <div className="flex justify-center mt-6">
          <div className="flex just-between align-center pagination space-x-4">
            {
              <div
                className={`flex ${current > 1 ? '' : 'cursor-not-allowed'}`}
                onClick={onPrev}
              >
                <LeftArrow className={'arrow'} height={'11px'} width={'auto'} />
              </div>
            }

            <Pagination
              defaultCurrent={1}
              defaultPageSize={5}
              current={current}
              pageSize={pageSize}
              total={total}
              onChange={handleChangePage}
            />
            {
              <div
                className={` flex ml-0 ${
                  current * pageSize <= total ? '' : 'cursor-not-allowed'
                } `}
                onClick={onNext}
              >
                <LeftArrow
                  className={'transform rotate-180 arrow'}
                  height={'11px'}
                  width={'auto'}
                />
              </div>
            }
          </div>
        </div>
      )}

      <div className="h-10 w-full"></div>
      <MediumLineGradient />
    </Styles>
  );
};

VotingHistory.defaultProps = {
  data: [],
  pageNumber: 1,
  total: 0,
};

export default compose(withRouter)(VotingHistory);

const mocs = [
  {
    support: true,
    proposal: {
      id: 1,
      description: 'VIP-1 Governance Launch',
      createdAt: new Date(),
      state: 'Executed',
    },
  },
  {
    support: false,
    proposal: {
      id: 2,
      description: 'VIP-2 Governance Launch',
      createdAt: new Date(),
      state: 'Executed',
    },
  },
];
