import React from 'react';
import {
    Switch,
    Route,
    Redirect,
    BrowserRouter as Router,
    useHistory,
} from 'react-router-dom';

import routes from './RouteMap';
import Dashboard from '../pages/Dashboard';
import Home from '../pages/Belugas';
import Market from '../pages/Market';
import MarketDetails from '../pages/MarketDetails';
import History from 'pages/History/index.js';
import Vote from '../pages/Vote';
import AllProposals from '../pages/Vote/AllProposals';
import VoteOverview from '../pages/Vote/VoteOverview';
import ProposerOverview from '../pages/Vote/ProposerOverview';
import Leaderboard from '../pages/Vote/Leaderboard';
import Liquidate from '../pages/Liquidate';
import NotFound from '../pages/NotFound';
import Web3ReactManager from '../components/common/Web3ReactManager';

import Referral from 'pages/Referral';
import Radiant from 'pages/Radiant';
import { useActiveWeb3React } from 'hooks';
import { accountActionCreators, connectAccount } from 'core';
import { bindActionCreators } from 'redux';
import LendingRiskModal from 'components/common/LendingRiskModal';

const Routes = ({ connectWalletsOpen, setConnectWalletsOpen }) => {
    if (typeof connectWalletsOpen === 'undefined') {
        setConnectWalletsOpen(false);
    }

    return (
        <Web3ReactManager>
            <Router>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={(match) => (
                            <Redirect
                                to={`${routes.app}${match?.location?.search}`}
                            />
                        )}
                    />

                    <Route
                        exact
                        path={routes.app}
                        component={Dashboard}
                    />

                    <Route exact path={routes.stake} component={Radiant} />

                    <Route
                        exact
                        path={routes.market.index}
                        component={Market}
                    />
                    <Route
                        exact
                        path={routes.market.marketDetails}
                        component={MarketDetails}
                    />

                    <Route exact path={routes.referral} component={Referral} />

                    <Route exact path={routes.belugas} component={Home} />

                    <Route exact path={routes.history} component={History} />

                    <Route
                        exact
                        path={routes.liquidate}
                        component={Liquidate}
                    />

                    <Route exact path={routes.vote.index} component={Vote} />
                    <Route
                        exact
                        path={routes.vote.allProposals}
                        component={AllProposals}
                    />
                    <Route
                        exact
                        path={routes.vote.voteOverview}
                        component={VoteOverview}
                    />
                    <Route
                        exact
                        path={routes.vote.proposerOverview}
                        component={ProposerOverview}
                    />
                    <Route
                        exact
                        path={routes.vote.leaderboard}
                        component={Leaderboard}
                    />

                    <Redirect to={routes.app} />
                    <Route component={NotFound} />
                </Switch>
            </Router>

            <LendingRiskModal />
        </Web3ReactManager>
    );
};

// export default Routes;

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
    connectWalletsOpen: account.connectWalletsOpen,
});

const mapDispatchToProps = (dispatch) => {
    const { setSetting, setConnectWalletsOpen } = accountActionCreators;

    return bindActionCreators(
        {
            setSetting,
            setConnectWalletsOpen,
        },
        dispatch,
    );
};

export default connectAccount(mapStateToProps, mapDispatchToProps)(Routes);
